import { TileId } from "./tiles"

/*

“Through action, a Man becomes a Hero
Through death, a Hero becomes a Legend
Through time, a Legend becomes a Myth
and by learning from the myth a man takes action.”

*/

/////////////////////////////////////////////////////////////////////////////
// Roman Dark Fantasy Game
/////////////////////////////////////////////////////////////////////////////
/*
    - Vox Tenebrae
    - Aquila Non Capit Muscas: The Eagle Does Not Catch Flies (meaning: Do Not Sweat the Small Stuff)
    - Aeternum Vale: Farewell Forever
    - Venatus Initium: Begin the Game (or The Game Begins)
    - Sine Timore: Without Fear
    - Memento Mori: Remember that You Will Die
    - Vox Populi: Voice of the People
    - Carpe Noctem: Seize the Night
    - Liberate Te Ex Inferis: Save Yourself from Hell
    - Omnia Vincit Amor: Love Conquers All
    - Lux in Tenebris: Light in Darkness
    - Ad Astra: To the Stars
    - Veritas Vincit: Truth Conquers
    - Semper Fidelis: Always Faithful
    - Ad Maiora: Towards Greater Things
    - Si Vis Pacem, Para Bellum: If You Want Peace, Prepare for War
    - Finis Origine Pendet: The End Depends on the Beginning
    - Fiat Lux: Let There Be Light
    - Alea Iacta Est: The Die Is Cast
    - Aut Vincere Aut Mori: Either to Conquer or to Die
    - Sic Parvis Magna: Greatness from Small Beginnings
    - Memento Vivere: Remember to Live
    - Memento Audere Semper: Remember to Always Dare
*/

(window as any).gameDisable = function () {
    Config.enabled = false
}

export const Config = {
    enabled: true,
    version: "0.0.19",
    title: "Don't Go In The Forest", // "Mini-Sandbox Survival Game", // "Mini-Sandbox Game in the Future" // "Win and Rule - Vince et Impera | Divina Specie", // - "Discover and Conquer", // - "Tales of Heroes",
    storageRoot: "Vince et Impera",
    scripts: [
        '/css/style.css',
    ],

    AI_UPDATE_SECS: 1 / 10,
    PLAYER_SPEED: 5, // tiles per sec
    CHAR_RADIUS: 0.3, // radius of character in tiles

    prngSeed: 0,
    worldMapSeed: '1234',

    // legacy
    WORLD_TILES_W: 500,
    WORLD_TILES_H: 300,

    // legacy
    MAP_TILES_W: 128,
    MAP_TILES_H: 64,

    // We could also have structures that are outside of the inner world but whose
    // location is known at generation time. For example, a special castle, special biome, special character etc.
    // --
    // The part of the world that is on the fly procedurally generated
    WORLD_OUTER_SIZE: 10 * 1024,
    // The part of the world that is fully generated to complete the game
    WORLD_INNER_SIZE: 256,

    // number of pixels per tile
    TILE_PIXEL_SIZE: 16,

    DIG_BUILD_MAX_RANGE: 5,
}


