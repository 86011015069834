import { assert } from "./assert"
import { Vec2D, Box2D, RGBA } from "./core"
import { Config } from "./config"
import { RenderContext } from "./map-canvas"
import { gameState } from "./game-state"
import { PlayerState } from "./player-state"
import { randomChoice } from "./core"
import { isValidPosition } from "./character"
import { Thing } from "./thing"
import { ThingFactory } from "./thing-factory"
import { findPath } from './path-find';
import { MouseEventType, EquipmentType, Direction } from './enums';
import { getTileInfo } from './tiles';
import { GuiThingSlotUtils } from "./gui-thing-slot-utils"
import { GameTime } from "./game-time"
import { GameMap } from "./game-map"
import { GuiWidget, GuiMouseEvent } from "./gui-widget"
import { GuiWidgetMap } from "./gui-widget-map"

// -----------------------------------------------------------------------------
// GuiWidgetMulti
// -----------------------------------------------------------------------------
export class GuiWidgetMulti extends GuiWidget {
    constructor() {
        super()
        this.name = 'GuiWidgetMulti'
    }

    // Children iteration
    getChildrenCount(): number {
        return 0
    }

    // Children iteration
    getChild(index: number): GuiWidget | null {
        return null
    }

    computeBoundingBox(): Box2D {
        let box = this.computeBoundingBoxThis()
        for(let i = 0; i < this.getChildrenCount(); i++) {
            box = box.union(this.getChild(i)!.computeBoundingBox())
        }
        return box
    }
}

// -----------------------------------------------------------------------------
// GuiThingSlot
// -----------------------------------------------------------------------------
export class GuiThingSlot extends GuiWidget {
    constructor(
        x: number,
        y: number,
        w: number,
        h: number,
        name: string,
        public color1: RGBA,
        public color2: RGBA,
        public thing: Thing | null = null,
        public onClick: (btn: GuiThingSlot) => void
    ) {
        super()
        this.x = x
        this.y = y
        this.w = w
        this.h = h
        this.name = name
    }

    render(ctx: RenderContext, mouse: Vec2D): void {
        const self = this
        const texture = self.thing ? self.thing.textureIcon : null

        const iconW = Config.TILE_PIXEL_SIZE
        const iconH = Config.TILE_PIXEL_SIZE

        if(self.isHover(mouse)) {
            // hover
            ctx.setFillColor(self.color2)
            // ctx.fillStyle = self.color2.toCSS()
            ctx.fillRect(self.x, self.y, self.w, self.h)
            if (texture !== null) {
                assert(texture.tw === iconW && texture.th === iconH, `Invalid texture size ${texture.tw}x${texture.th} for gui icon`)
                const dx = (self.w - iconW) / 2 + 1
                const dy = (self.h - iconH) / 2 - 1
                ctx.drawImage(
                    texture,
                    texture.tx, texture.ty,
                    // texture.width, texture.height,
                    iconW, iconH,
                    self.x + dx, self.y + dy,
                    iconW, iconH
                )
            }
        } else {
            // not hover
            ctx.setFillColor(self.color1)
            // ctx.fillStyle = self.color1.toCSS()
            ctx.fillRect(self.x, self.y, self.w, self.h)
            if (texture !== null) {
                assert(texture.tw === iconW && texture.th === iconH, `Invalid texture size ${texture.tw}x${texture.th} for gui icon`)
                const dx = (self.w - iconW) / 2
                const dy = (self.h - iconH) / 2
                ctx.drawImage(
                    texture,
                    texture.tx, texture.ty,
                    // texture.width, texture.height,
                    iconW, iconH,
                    self.x + dx, self.y + dy,
                    iconW, iconH
                )
            }
        }

        if (self.thing !== null && self.thing.pileCount !== 0) {
            // draw text at x y position
            ctx.setFillColor(new RGBA(255, 255, 255, 255))
            ctx.setFont('8px monospace')
            ctx.setTextBaseline('bottom')
            // ctx.fillStyle = 'white'
            // ctx.font = '8px monospace'
            // ctx.textBaseline = 'bottom'
            ctx.fillText(self.thing.pileCount + '', self.x, self.y + self.h - 1)
        }
    }

    onMouseClickLeft(e: GuiMouseEvent): boolean {
        const mouse = new Vec2D(e.cx, e.cy)
        if (this.isHover(mouse)) {
            this.onClick(this)
            return true
        }

        return false
    }
}

// -----------------------------------------------------------------------------
// GuiThingSlot
// -----------------------------------------------------------------------------
export class GuiThingSlotGroup extends GuiWidgetMulti {
    allThingSlots: Array<GuiThingSlot> = []

    public hotbarSlots: Array<GuiThingSlot> = []
    public equippableSlots: Array<GuiThingSlot> = []
    public inventorySlots: Array<GuiThingSlot> = []

    private activeHotbarSlot: GuiThingSlot | null = null

    private dragSlotStart: GuiThingSlot | null = null
    private dragThing: Thing | null = null

    // we can't rely on GUI generic dragging since we make higher level checks such as is the slot empty
    isDragging: boolean = false

    countStartTime: number = 0
    countSlot: GuiThingSlot | null = null
    dragQuantity = 0

    constructor() {
        super()
        this.name = 'GuiThingSlotGroup'
    }

    // Children iteration
    getChildrenCount(): number {
        return this.allThingSlots.length
    }

    // Children iteration
    getChild(index: number): GuiWidget | null {
        return this.allThingSlots[index]
    }

    getActiveHotbarSlotThing(): Thing | null {
        return this.activeHotbarSlot ? this.activeHotbarSlot.thing : null
    }

    setActiveHotbarSlotThing(thing: Thing | null) {
        if (this.activeHotbarSlot) {
            this.activeHotbarSlot.thing = thing
        }
    }

    initialize() {
        const tilePixelSize = Config.TILE_PIXEL_SIZE
        const margin = tilePixelSize / 4
        const w = tilePixelSize + 6
        const h = tilePixelSize + 6

        let x = margin
        let y = margin
        for(let i=0; i<PlayerState.HOTBAR_SLOTS; ++i, x += w + margin) {
            const slot = new GuiThingSlot(
                x,
                y,
                w,
                h,
                `Hotbar:Slot:${i}`,
                new RGBA(0, 200, 255, 192),
                new RGBA(0, 200 - 32, 255 - 32, 192),
                null,
                (btn: GuiThingSlot) => {
                    console.log(`${btn.name} clicked at ${x}, ${y}`)
                    // gameState().meta['lastClickedSlot'] = self.name
                }
            )
            this.hotbarSlots.push(slot)
        }

        x = margin
        y += h + margin
        for(let i=0; i<PlayerState.EQUIPPABLE_SLOTS; ++i, x += w + margin) {
            const slot = new GuiThingSlot(
                x,
                y,
                w,
                h,
                `Equipment:Slot:${i}`,
                new RGBA(200, 0, 255, 192),
                new RGBA(200 - 32, 0, 255 - 32, 192),
                null,
                (btn: GuiThingSlot) => {
                    console.log(`${btn.name} clicked at ${x}, ${y}`)
                    // gameState().meta['lastClickedSlot'] = self.name
                }
            )
            this.equippableSlots.push(slot)
        }

        x = margin
        y += h + margin
        for(let i=0; i<PlayerState.INVENTORY_SLOTS; ++i) {
            const slot = new GuiThingSlot(
                x,
                y,
                w,
                h,
                `Inventory:Slot:${i}`,
                new RGBA(200, 255, 0, 192),
                new RGBA(200 - 32, 255 - 32, 0, 192),
                null,
                (btn: GuiThingSlot) => {
                    console.log(`${btn.name} clicked at ${x}, ${y}`)
                    // gameState().meta['lastClickedSlot'] = self.name
                }
            )
            this.inventorySlots.push(slot)
            x += w + margin
            if ( (i + 1) % 8 === 0 ) {
                x = margin
                y += h + margin
            }
        }

        // merge all into allThingSlots
        this.allThingSlots = this.allThingSlots.concat(this.hotbarSlots)
        this.allThingSlots = this.allThingSlots.concat(this.equippableSlots)
        this.allThingSlots = this.allThingSlots.concat(this.inventorySlots)

        // init active hotbar slot
        this.activeHotbarSlot = this.hotbarSlots[0]

        // initialize bounding box of this widget group
        const box = this.computeBoundingBox()
        this.x = box.x
        this.y = box.y
        this.w = box.w
        this.h = box.h

        this.testPopulateThings()
    }

    testPopulateThings() {
        // const thingNames = ThingFactory.getThingNames()
        const thingNames = [
            'Bridge',
            'Door',
            'Wall-8-001', 'Wall-8-002',
            'Floor-8-001', 'Floor-8-002',
            'Bow 1',  'Arrow 1',
            'Pickaxe | Iron', 'Sword | Iron', 'Shovel | Iron', 'Axe | Iron', 'Hoe | Iron',
        ]
        console.log(`thingNames`, thingNames)

        // Hotbar
        for(let i=0; i<PlayerState.HOTBAR_SLOTS; ++i) {
            // randomly fill 50% of inventory
            if (Math.random() < 0.5) {
                continue
            }
            const thing = ThingFactory.getThingInstance(randomChoice(thingNames))
            assert(thing.texture !== null, `Thing ${thing.name} texture is null`)
            this.hotbarSlots[i].thing = thing
            assert(this.hotbarSlots[i].thing !== null, `Hotbar ${i} thing is null`)
        }

        // Equippable
        for(let i=0; i<PlayerState.EQUIPPABLE_SLOTS; ++i) {
            const thing = ThingFactory.getThingInstance(randomChoice(thingNames))
            if (thing.isEquipment === false) {
                continue
            }
            assert(thing.texture !== null, `Thing ${thing.name} texture is null`)
            this.equippableSlots[i].thing = thing
            assert(this.equippableSlots[i].thing !== null, `Equippable ${i} thing is null`)
        }

        // Inventory
        for(let i=0; i<PlayerState.INVENTORY_SLOTS; ++i) {
            // randomly fill 50% of inventory
            if (Math.random() < 0.5) {
                continue
            }
            const thing = ThingFactory.getThingInstance(randomChoice(thingNames))
            thing.pileCount = thing.pileCount > 0 ? Math.floor(5 + Math.random() * 10) : 0
            assert(thing.texture !== null, `Thing ${thing.name} texture is null`)
            this.inventorySlots[i].thing = thing
            assert(this.inventorySlots[i].thing !== null, `Inventory ${i} thing is null`)
        }
    }

    render(ctx: RenderContext, mouse: Vec2D): void {
        const tilePixelSize = Config.TILE_PIXEL_SIZE

        // check if initialized
        assert(this.hotbarSlots.length === PlayerState.HOTBAR_SLOTS, `Invalid hotbarSlots length ${this.hotbarSlots.length}`)
        assert(this.equippableSlots.length === PlayerState.EQUIPPABLE_SLOTS, `Invalid equippableSlots length ${this.equippableSlots.length}`)
        assert(this.inventorySlots.length === PlayerState.INVENTORY_SLOTS, `Invalid inventorySlots length ${this.inventorySlots.length}`)

        // render allThingSlots
        this.allThingSlots.forEach((component) => {
            component.render(ctx, mouse)
        })

        // render active hotbar slot
        if (this.activeHotbarSlot !== null) {
            const slot = this.activeHotbarSlot
            // render gold border
            ctx.setStrokeColor(new RGBA(255, 255, 0, 255))
            ctx.setLineWidth(2)
            // ctx.strokeStyle = 'gold'
            // ctx.lineWidth = 2
            ctx.strokeRect(slot.x, slot.y, slot.w, slot.h)
        }

        // render counted draggging thing
        if (this.countStartTime) {
            assert(this.countSlot !== null, 'countSlot is null')
            // assert(this.countSlot!.thing !== null, 'countSlot.thing is null')
            const slot = this.countSlot!
            const thing = slot.thing!
            // there must be a Thing
            assert(thing !== null, 'thing is null')
            // we should be here only if the Thing is pileable
            assert(thing.pileCount !== 0, 'thing.pileCount is 0')
            // render gold border
            ctx.setFillColor(new RGBA(255, 0, 0, 255))
            ctx.setFont('8px monospace')
            ctx.setTextBaseline('top')
            // ctx.fillStyle = 'red'
            // ctx.font = '8px monospace'
            // ctx.textBaseline = 'top'
            const activationTime = 0.5
            if (GameTime.now() - this.countStartTime > activationTime) {
                const numberMultiplier = 1
                const stepsPerSecond = 4
                const number = Math.ceil( (GameTime.now() - this.countStartTime - activationTime)*stepsPerSecond ) * numberMultiplier
                this.dragQuantity = Math.min(number, thing!.pileCount)
                console.log(`DRAG : COUNT: ${this.dragQuantity}`)
                ctx.fillText(this.dragQuantity + '', slot.x, slot.y)
            }
        }

        // DEBUG: render square around bounding box
        // const box = this.computeBoundingBox()
        // ctx.strokeStyle = 'red'
        // ctx.lineWidth = 1
        // ctx.strokeRect(box.x, box.y, box.w, box.h)
    }

    getDragThing() : Thing | null {
        return this.dragThing
    }

    isHover(mouse: Vec2D): boolean {
        for (let widget of this.allThingSlots) {
            if (widget.isHover(mouse)) {
                return true
            }
        }
        return false
    }

    getHoverSlot(mouse: Vec2D): GuiThingSlot | null {
        for (let widget of this.allThingSlots) {
            if (widget.isHover(mouse)) {
                return widget
            }
        }

        return null
    }

    onMouseClickLeft(e: GuiMouseEvent): boolean {
        for(let hotbarSlot of this.hotbarSlots) {
            if (hotbarSlot.onMouseClickLeft(e)) {
                this.activeHotbarSlot = hotbarSlot
                return true
            }
        }
        for(let equippableSlot of this.equippableSlots) {
            if (equippableSlot.onMouseClickLeft(e)) {
                return true
            }
        }
        for(let inventorySlot of this.inventorySlots) {
            if (inventorySlot.onMouseClickLeft(e)) {
                return true
            }
        }
        return false
    }

    onMouseDragBegin(e: GuiMouseEvent): boolean {
        const self = this

        if (!e.isDraggingL) {
            return false
        }

        // when dragging begins stop counting quantity
        this.countStartTime = 0
        // this.dragQuantity = keep
        // this.countSlot = keep

        self.isDragging = false

        // ...
        // Don't drag if there is nothing under
        const dragSlotStart = this.getHoverSlot(new Vec2D(e.cx, e.cy))
        if (dragSlotStart === null) {
            return false
        }

        // Don't drag if slot is empty
        if (dragSlotStart.thing === null) {
            // true = important, because we did click on the slot
            return true
        }

        console.log(`DRAG : BEGIN`, dragSlotStart.name, ':', `Qty: ${this.dragQuantity}`, dragSlotStart.thing ? JSON.stringify(dragSlotStart.thing, null, 2) : 'null')
        self.isDragging = true

        // Split thing depending on quantity
        this.dragSlotStart = dragSlotStart
        if (this.dragQuantity === 0 || this.dragQuantity === dragSlotStart.thing!.pileCount) {
            this.dragThing = dragSlotStart.thing
            dragSlotStart.thing = null
        } else {
            this.dragThing = dragSlotStart.thing!.shallowClone()
            this.dragThing.pileCount = this.dragQuantity
            dragSlotStart.thing!.pileCount -= this.dragQuantity
        }

        // capture mouse
        gameState().theGui!.captureMouse(this)

        return true
    }

    onMouseDown(e: GuiMouseEvent): boolean {
        const slot = this.getHoverSlot(new Vec2D(e.cx, e.cy))

        // no slot then dont count
        if (slot === null) {
            return false
        }

        // if no Thing then don't count
        const thing = slot.thing
        if (thing === null) {
            // true = important, because we did click on the slot
            return true
        }

        // don't count if Thing is not stackable
        if (thing.pileCount === 0) {
            // true = important, because we did click on the slot
            return true
        }

        console.log(`COUNT : BEGIN`, slot.name, ':', thing.type)
        this.countStartTime = GameTime.now()
        this.dragQuantity = 0
        this.countSlot = slot

        // true = important, because we did click on the slot
        return true
    }

    onMouseUp(e: GuiMouseEvent): boolean {
        const self = this

        this.countStartTime = 0
        this.dragQuantity = 0
        this.countSlot = null

        if (gameState().theGui!.isMouseCaptured(this)) {
            gameState().theGui!.releaseMouse(this)
        }

        // End of dragging
        if (self.isDragging && e.isDraggingL && e.type === MouseEventType.UP_LEFT) {
            console.log('DRAG : END: isDragging =', e.isDraggingL)
            self.isDragging = false

            // Handle drag end
            const dragSlotEnd = this.getHoverSlot(new Vec2D(e.cx, e.cy))
            const isInsideGroup = this.isHoverThis(new Vec2D(e.cx, e.cy))
            let isAllowedDrop = false
            if (dragSlotEnd !== null && dragSlotEnd !== this.dragSlotStart) {
                // Check if allowed drop
                const slot1IsEquip = this.dragSlotStart!.name.startsWith('Equipment:Slot:')
                const slot2IsEquip = dragSlotEnd.name.startsWith('Equipment:Slot:')
                const thing1IsEquip = this.dragThing!.isEquipment === true
                const thing2IsEquip = dragSlotEnd.thing === null || dragSlotEnd.thing!.isEquipment === true // allow null to get swapped
                // Only allow equipment to go into equipment slots
                if (slot2IsEquip) {
                    // Allow is moving an equipment item into an eqipment slot
                    isAllowedDrop = thing1IsEquip
                } else
                if (slot1IsEquip) {
                    // Allow if swapping an equipment in an equipment slot with another equipment item
                    isAllowedDrop = thing2IsEquip
                } else {
                    // Allow in all th other cases
                    isAllowedDrop = true
                }

                // Equipment type/quantity check
                if (slot2IsEquip) {
                    isAllowedDrop = isAllowedDrop && GuiThingSlotUtils.checkEquipmentFits(this.dragThing!, dragSlotEnd.thing, this.equippableSlots)
                }
            }

            const thing0 = this.dragThing!
            const thing1 = this.dragSlotStart!.thing!
            const slot1 = this.dragSlotStart!
            if (isAllowedDrop) {
                assert(thing0 !== null, 'thing0 is null')

                // if same type and pileable then pile up - else swap
                // There can be up to 2 Things involved in a drag depending on whether the start-thing was split due to quantity
                const thing2 = dragSlotEnd!.thing
                const slot2 = dragSlotEnd!

                const isHotbarSlot = slot2.name.startsWith('Hotbar:Slot:')
                if (isHotbarSlot) {
                    this.activeHotbarSlot = slot2
                }

                // if thing0 and thing2 are pileable and of the same type then pile them up
                if (thing0 !== null && thing2 !== null && thing0.type === thing2.type && thing0.pileCount !== 0 && thing2.pileCount !== 0) {
                    thing2.pileCount += thing0.pileCount
                } else {
                    // if split Thing due to quantiy then only allow to land on empty slot else revert quantity splt
                    if (thing0 !== null && thing1 !== null) {
                        if(slot2.thing === null) {
                            slot2.thing = thing0
                        } else {
                            // Rollback drag
                            thing1.pileCount += thing0.pileCount
                        }
                    } else {
                        // if not split Thing then do simple swapping
                        slot1.thing = thing2
                        slot2.thing = thing0
                    }
                }
            } else {
                if (dragSlotEnd === null && !isInsideGroup) {
                    // Drop on map logic
                    console.log('OBJECT DROPPED')
                    if (GuiThingSlotUtils.dropThing(this.dragThing!)) {
                        // Drop thing
                        console.log(`${this.dragThing!.type} >>> DROPPED`)
                    } else {
                        // Rollback drag
                        console.log(`${this.dragThing!.type} >>> ${this.dragSlotStart!.name}`)
                        if (thing0 !== null && thing1 !== null) {
                            thing1.pileCount += thing0.pileCount
                        } else {
                            slot1.thing = thing0
                        }
                    }
                } else {
                    // Rollback drag
                    console.log(`${this.dragThing!.type} >>> ${this.dragSlotStart!.name}`)
                    if (thing0 !== null && thing1 !== null) {
                        thing1.pileCount += thing0.pileCount
                    } else {
                        slot1.thing = thing0
                    }
                }
            }

            this.dragSlotStart = null
            this.dragThing = null
            return true
        }

        return false
    }

    onMouseMove(e: GuiMouseEvent): boolean {
        const self = this
        if ((e.isDraggingR || e.isDraggingL) && self.isDragging) {
            // console.log(`DRAG : MOVE: isDragging = ${e.clientX} ${e.clientY}`)
            return true
        }
        return false
    }
}

// -----------------------------------------------------------------------------
// GUI
// -----------------------------------------------------------------------------
export class GUI {
    widgets: GuiWidget[] = []
    thingSlotGroup: GuiThingSlotGroup | null = null
    mouseCapture: GuiWidget | null = null

    constructor() {
    }

    initialize() {
        this.thingSlotGroup = new GuiThingSlotGroup()
        this.thingSlotGroup.initialize()
        this.widgets.push(this.thingSlotGroup)
        this.widgets.push(new GuiWidgetMap())
    }

    sortWidgets() {
        this.widgets.sort((a, b) => a.priority - b.priority)
    }

    isMouseCaptured(widget: GuiWidget): boolean {
        return this.mouseCapture === widget
    }

    captureMouse(widget: GuiWidget | null) {
        this.mouseCapture = widget
    }

    releaseMouse(widget: GuiWidget) {
        assert(this.mouseCapture === widget, 'releasing mouse from wrong widget')
        this.mouseCapture = null
    }

    onMouseDown(e: GuiMouseEvent): boolean {
        if (this.mouseCapture !== null) {
            this.mouseCapture.onMouseDown(e)
            return true
        }

        for(let i = 0; i < this.widgets.length; i++) {
            if(this.widgets[i].onMouseDown(e)) {
                return true
            }
        }
        return false
    }

    onMouseUp(e: GuiMouseEvent): boolean {
        if (this.mouseCapture !== null) {
            this.mouseCapture.onMouseUp(e)
            return true
        }

        for(let i = 0; i < this.widgets.length; i++) {
            if(this.widgets[i].onMouseUp(e)) {
                return true
            }
        }
        return false
    }

    onMouseClickLeft(e: GuiMouseEvent): boolean {``
        if (this.mouseCapture !== null) {
            this.mouseCapture.onMouseClickLeft(e)
            return true
        }

        for(let i = 0; i < this.widgets.length; i++) {
            if(this.widgets[i].onMouseClickLeft(e)) {
                // console.log(`GUI widget ${this.widgets[i].name} handled click`)
                return true
            }
        }
        return false
    }

    onMouseClickRight(e: GuiMouseEvent): boolean {
        if (this.mouseCapture !== null) {
            this.mouseCapture.onMouseClickRight(e)
            return true
        }

        for(let i = 0; i < this.widgets.length; i++) {
            if(this.widgets[i].onMouseClickRight(e)) {
                // console.log(`GUI widget ${this.widgets[i].name} handled click`)
                return true
            }
        }
        return false
    }

    onMouseMove(e: GuiMouseEvent): boolean {
        // It's convenient to centralize these here because we already have the computed mouse positions
        gameState().userInput!.mousePositionTile.set(e.tx, e.ty)
        gameState().userInput!.mousePositionTileTopLeft.set(e.cxs, e.cys)
        gameState().userInput!.mousePositionCanvas.set(e.cx, e.cy)

        if (this.mouseCapture !== null) {
            this.mouseCapture.onMouseMove(e)
            return true
        }

        for(let i = 0; i < this.widgets.length; i++) {
            if(this.widgets[i].onMouseMove(e)) {
                return true
            }
        }
        return false
    }

    onMouseDragBegin(e: GuiMouseEvent): boolean {
        if (this.mouseCapture !== null) {
            this.mouseCapture.onMouseDragBegin(e)
            return true
        }

        for(let i = 0; i < this.widgets.length; i++) {
            if(this.widgets[i].onMouseDragBegin(e)) {
                return true
            }
        }
        return false
    }

    render(ctx: RenderContext, mouse: Vec2D): void {
        // render in reverse order
        for(let i = this.widgets.length - 1; i >= 0; i--) {
            this.widgets[i].render(ctx, mouse)
        }
    }

    isHover(mouse: Vec2D): boolean {
        for(let i = 0; i < this.widgets.length; i++) {
            if(this.widgets[i].isHover(mouse)) {
                return true
            }
        }
        return false
    }
}
