import { Direction, TileCategory } from "./enums"
import { TileType, getTileInfo } from "./tiles"
import { Vec2D, Box2D, Circle, randomChoice, getRandomInt } from "./core"
import { GameMap } from "./game-map"
import { assert } from "./assert"
import { PathAnimation } from "./animation"
import { GameTime } from "./game-time"
import { App, theApp } from "./app"
import { Config } from "./config"
import { sprintf } from "sprintf-js"
import { TextureUtils } from "./textures"
import { gameState } from "./game-state"
import { Character, isValidPosition } from "./character"
import { findPath } from "./path-find"

export class CharacterAI {
    constructor(public character: Character, public map: GameMap) {
    }

    // run() as pure virtual function
    run(): boolean {
        throw new Error('CharacterAI.run() not implemented')
    }
}

export class CharacterAI_RandomRoam extends CharacterAI {
    private nextPath: Array<{ x: number, y: number }> = []

    constructor(
        character: Character,
        map: GameMap) {
        super(character, map)
    }

    run(): boolean {
        const self = this
        const character = this.character

        if (character.physSpeed > 0) {
            if (character.physStuck) {
                console.log('AI: stuck')
            } else {
                return true
            }
        }

        // if not stuck then recycle previous path but backwards and 90% of the time
        if (!character.physStuck && self.nextPath.length && Math.random() < 0.95) {
            // copy
            character.setPath(self.nextPath.slice())
            character.physSpeed = Config.PLAYER_SPEED
            self.nextPath.reverse()
            return true
        }

        // find a random position to move within 10 tiles and use path finding to get there
        const maxDist = 5
        const map = this.map
        let tries = 1
        const { vx, vy, vw, vh } = map.getVirtualBounds()

        while (tries) {
            tries--
            const dx = getRandomInt(-maxDist, maxDist)
            const dy = getRandomInt(-maxDist, maxDist)
            let nx = character.x + dx
            let ny = character.y + dy
            // clamp to world
            nx = Math.max(vx, Math.min(vx + vw - 1, nx))
            ny = Math.max(vy, Math.min(vy + vh - 1, ny))

            const path = findPath(this.map, character, nx, ny)
            if(path) {
                self.nextPath = path.slice().reverse()
                break
            }
        }

        // console.log('AI: found', p.x, p.y)


        // // wait until path animation is done before moving again
        // if (character.meta.pathAnimation && !character.meta.pathAnimation.killed()) {
        //     return true
        // }

        // // important to round position to nearest integer
        // character.x = Math.floor(character.x)
        // character.y = Math.floor(character.y)

        // // console.log(character.name, character.x, character.y)

        // // move character in a random direction
        // const directions = [
        //     { dx: 1, dy: 0 },
        //     { dx: -1, dy: 0 },
        //     { dx: 0, dy: 1 },
        //     { dx: 0, dy: -1 },
        // ]
        // const { dx, dy } = randomChoice(directions) as { dx: number, dy: number }
        // const newX = character.x + dx
        // const newY = character.y + dy

        // // if new position is legal then move character
        // if (isValidPosition(this.floor, newX, newY, character)) {

        //     const stepTime = Config.AI_UPDATE_SECS * (0.25 + Math.random() * 1)
        //     const pathAnimation = new PathAnimation(
        //         character,
        //         [
        //             { x: character.x, y: character.y },
        //             { x: newX, y: newY },
        //         ],
        //         GameTime.now(),
        //         stepTime,
        //     )

        //     character.meta.pathAnimation = pathAnimation

        //     gameState().animations.push(pathAnimation)
        // }

        return true
    }
}
