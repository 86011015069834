///////////////////////////////////////////////////////////////////////////////
//
// >>> NEXT >>>
//
// --- Terrain Generation ---
//
// > Color gradients: instead of flat colors use the current and darker version of the same color
//   for the tiles and interpolate based on the height of the tile
//
// > [v] Use native arrays instead of JS arrays for map heightmap
// > [v] Use simplex noise instead of Perlin: 56x FASTER!
// > [v] Can now seed simplex noise
// > [x] Use WASM noise library: no need for now
// > [ ] Seed 2D noise map to generate
//       [ ] Kingdom maps
//       [ ] Biomes maps
//
// * Minecraft Layered World Generation
//   - https://www.alanzucconi.com/2022/06/05/minecraft-world-generation/
//   - https://www.youtube.com/watch?v=YyVAaJqYAfE&t=917s&ab_channel=AlanZucconi
//   - Minecraft Guy: (Noise + Splines) https://www.youtube.com/watch?v=ob3VwY4JyzE
//
// * Rivers:
//   + Flow from mountains to lower areas, ideally to the sea
//   + When reaching the sea: create delta / erosion
//
// * Internal lakes: when river cannot reach the sea create a lake randomly big
//
// * Simple Cliffs / Mountains falling into water (external or internal) (erode and fill with water)
//
// * Volcanoes: 1-5 per map, generate them aside and place them randomly | find suitable montains and place them there
//   + LAVA RIVERS: flow from volcano to lower areas and create LAVA LAKES
//
///////////////////////////////////////////////////////////////////////////////

import { Storage } from './storage';
import { assert } from "./assert"
import { Config } from './config';
import { WorldCatalog } from './world-catalog';
import { TextureUtils } from "./textures"
import { TileInitializer } from "./tile-initializer"
import { GameTime, Stopwatch } from './game-time';
import {
  GalleryRenderers, makeWorldMap, makeCellularAutomataCaves, makeDrunkCave1, makeDrunkCave2,
  makeBinPacking, makeCastle1, makeBSPManor, makePOIonMap, makeClassicRoguelike, makeDungeon1,
  makeCity2, makeCity1, makeUndergroundCrypts, makeNarrowCaves
} from './gallery';
import { UserInput } from './user-input';
import { gameState, gameStateInit } from './game-state';
import { GuiThingSlotUtils } from './gui-thing-slot-utils';
import { ThingFactory } from './thing-factory';
import { initStructureTemplates } from './structure-templates';
import { sprintf } from "sprintf-js"

///////////////////////////////////////////////////////////////////////////////
// GL Test
///////////////////////////////////////////////////////////////////////////////
import {
  m4, v3, setUniforms, setBuffersAndAttributes, drawBufferInfo,
  createTextures, createProgramInfo, createBufferInfoFromArrays, resizeCanvasToDisplaySize,
  createTexture, createFramebufferInfo, bindFramebufferInfo, createUniformBlockInfo, setUniformBlock, createVAOFromBufferInfo
} from "twgl.js"

function printMatrix(matrix: m4.Mat4) {
  // use sprintf and one row per line
  const rows = []
  for (let i = 0; i < 4; ++i) {
    const row = []
    for (let j = 0; j < 4; ++j) {
      row.push(sprintf("%6.2f", matrix[i + j * 4]))
    }
    rows.push(row.join(" "))
  }
  console.log(rows.join("\n"))
}

const vertexShaderSource = `
attribute vec4 position;
void main() {
  gl_Position = position;
}`;

const fragmentShaderSource = `
precision mediump float;

uniform vec2 resolution;
uniform float time;

void main() {
  vec2 uv = gl_FragCoord.xy / resolution;
  float color = 0.0;
  // lifted from glslsandbox.com
  color += sin( uv.x * cos( time / 3.0 ) * 60.0 ) + cos( uv.y * cos( time / 2.80 ) * 10.0 );
  color += sin( uv.y * sin( time / 2.0 ) * 40.0 ) + cos( uv.x * sin( time / 1.70 ) * 40.0 );
  color += sin( uv.x * sin( time / 1.0 ) * 10.0 ) + sin( uv.y * sin( time / 3.50 ) * 80.0 );
  color *= sin( time / 10.0 ) * 0.5;

  gl_FragColor = vec4( vec3( color * 0.5, sin( color + time / 2.5 ) * 0.75, color ), 1.0 );
}`;

// TODO:
// - Make 2D world, 1 tile 16 units wide
// - Make 2D ortho projection, make 2D camera panning and zooming
function glTest(): boolean {
  const matrix1 = m4.identity()
  const matrix2 = m4.translate(matrix1, [1, 2, 3])
  printMatrix(matrix2)

  const canvas = document.createElement("canvas")
  // append canvas to document body
  document.body.appendChild(canvas)
  canvas.width = 8
  canvas.height = 8
  canvas.style.width = "100%"
  canvas.style.height = "100%"

  const gl = canvas.getContext("webgl2")
  if (!gl) {
    alert("no webgl2")
    return true
  }

  // print version of WebGL
  console.log(gl.getParameter(gl.VERSION))

  // clear color buffer with red
  gl.clearColor(1, 0, 0.3, 1)
  gl.clear(gl.COLOR_BUFFER_BIT)

  const programInfo = createProgramInfo(gl, [vertexShaderSource, fragmentShaderSource]);
  console.log(programInfo)
  const arrays = {
    position: [-1, -1, 0,  1, -1, 0,  -1, 1, 0,  -1, 1, 0,  1, -1, 0,  1, 1, 0],
  };
  const bufferInfo = createBufferInfoFromArrays(gl, arrays);

  function render(time: number) {
    if (!gl) {
      return
    }

    resizeCanvasToDisplaySize(gl.canvas as HTMLCanvasElement);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    // only draw wireframe with polygon mode set to LINE


    const uniforms = {
      time: time * 0.001,
      resolution: [gl.canvas.width, gl.canvas.height],
    };

    gl.useProgram(programInfo.program);
    setBuffersAndAttributes(gl, programInfo, bufferInfo);
    setUniforms(programInfo, uniforms);
    drawBufferInfo(gl, bufferInfo);

    requestAnimationFrame(render);
  }
  requestAnimationFrame(render);

  return true
}

///////////////////////////////////////////////////////////////////////////////
// App
///////////////////////////////////////////////////////////////////////////////
export class App {
  storage: Storage = new Storage(Config.storageRoot)

  frameNumber = 0

  docClientW = 0
  docClientH = 0

  fpsTargetUpdateSecs = 1.0
  fpsTarget = 120
  fpsLastTime = 0
  fpsFrameCount = 0
  fpsInterval = 1000 / this.fpsTarget

  aiStopwatch: Stopwatch = new Stopwatch()

  physLastTime = 0.0
  physTimeStep = 1.0 / 60.0

  constructor() {
    const self = this;
  }

  /**
   * App::setupFloorRendering
   */
  setupFloorRendering(description: any) {
    const self = this;

    // $('#realtime-map-wrapper p.map-description').text(description)

    assert(gameState().theFloor !== null, 'theFloor is null')
    assert(gameState().theMapCameraRenderer !== null, 'theMapCameraRenderer is null')

    const theMapCameraRenderer = gameState().theMapCameraRenderer!

    theMapCameraRenderer.centerCameraToCharacter(gameState().thePlayer)
    theMapCameraRenderer.updateFogOfWar()
  }

  handleDocumentResize() {
    const self = this;
    const theMapCameraRenderer = gameState().theMapCameraRenderer
    assert(theMapCameraRenderer !== null, 'theMapCameraRenderer is null')
    // update canvas size based on document size
    // const zoom = 1 // FPS on Mac: 55 -> 65
    // const zoom = 1.5 // 120 FPS on Mac
    const zoom = 2
    // const zoom = 4 // 120 FPS on Mac
    const camera = gameState().theCamera!
    const canvas = gameState().theMapCanvas.getCanvasElement()
    const parent = canvas.parentElement!
    self.docClientW = document.documentElement.clientWidth
    self.docClientH = document.documentElement.clientHeight
    parent.style.width = `${self.docClientW}px`
    parent.style.height = `${self.docClientH}px`
    canvas.style.width = `100%`
    canvas.style.height = `100%`
    camera.viewWidth = canvas.width = Math.floor(canvas.clientWidth / zoom)
    camera.viewHeight = canvas.height = Math.floor(canvas.clientHeight / zoom)

    GalleryRenderers.forEach((renderer) => {
      // resize the canvas to fill browser window dynamically
      const camera = renderer.getCamera()
      const mapCanvas = renderer.getMapCanvas()
      const canvas = mapCanvas.getCanvasElement()
      canvas.style.width = `${self.docClientW}px`
      canvas.style.height = `${self.docClientH}px`
      camera.viewLeft = 0
      camera.viewTop = 0
      camera.viewWidth = canvas.width = Config.MAP_TILES_W * Config.TILE_PIXEL_SIZE
      camera.viewHeight = canvas.height = Config.MAP_TILES_H * Config.TILE_PIXEL_SIZE

      // fill with fuchsia
      const ctx = canvas.getContext('2d')!
      ctx.fillStyle = 'fuchsia'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
    })
  }

  /**
   * App::init
   */
  async init() {
    const self = this;

    // if (glTest()) {
    //   return
    // }

    self.aiStopwatch.start()
    self.storage.init({ Ragomag: true });

    // mix fixme: done
    // - review steps below
    // - review Textures initialization code
    // [v] -> Wall8 -> Wall-8 | Floor8 -> Floor-8
    // [v] move noise textures out into Texture, give em a name and use the names in Thing initialization
    // * ... move all :Icon initialization inside Textures and make sure all is loaded
    // * tile icons generated at initializeTextures
    // * thing icons generated at initThingTextures -> Trees for now but need programmatic way to detect them based on type name
    // *  -> Tree -> Thing-Tree
    // * initThingTextures should call Thing virtual method initTextures()
    // * ThingDoor should reimplement initTextures() and assign open and closed textures
    // * Thing factory calls the right constructor for each thing type
    // * Thing clone supports generic html image type

    // Initialize textures
    console.log('Init textures...')
    await TextureUtils.initializeTextures()

    // Initialize tiles
    console.log('Init tiles...')
    await TileInitializer.initializeTiles()

    // Initialize templates and their textures
    ThingFactory.initThingTemplates()

    // Initialize the world's templates
    initStructureTemplates()

    // Initialize game state
    gameStateInit()

    // $('#realtime-map-wrapper').append(`<p class="map-description">Loading...</p>`);
    $('#realtime-map-wrapper').append(gameState().theMapCanvas.getCanvasElement())

    // Initialize the GUI
    console.log('Init GUI...')
    gameState().theGui!.initialize()
    gameState().userInput!.initialize()

    // Initialize the world
    const worldName = 'Standard World'
    WorldCatalog.initialize()
    const initWorld = WorldCatalog.getWorld(worldName)
    initWorld(self)
    assert(gameState().theFloor !== null, 'theFloor is null')

    self.handleDocumentResize()

    // Setup the floor rendering
    self.setupFloorRendering(`${Config.title} - "${worldName}" - v${Config.version}`)

    // Kick off the game loop
    self.runGameLoop()

    // -----------------------------------------------------------------------------

    const makeGallery = false
    if (makeGallery) {
      // makeWorldMap(self)
      // - makeCellularAutomataCaves(self) this is also not working after removing map getW/getH
      // makeDrunkCave1(self)
      // makeDrunkCave2(self)
      // makeBinPacking(self)
      // makeCastle1(self)
      // - makeBSPManor(self) // not working atm after removing map getW/getH
      // makePOIonMap(self)
      // makeClassicRoguelike(self)
      // makeDungeon1(self)
      // makeCity2(self)
      // makeCity1(self)
      // makeUndergroundCrypts(self)
      // makeNarrowCaves(self)
    }
  }

  public requestNextGameLoop() {
    const self = this

    // Estimate when to request the next game loop
    const now = GameTime.now()
    if (now - self.fpsLastTime > self.fpsTargetUpdateSecs) {
      const fps = Math.round(self.fpsFrameCount / self.fpsTargetUpdateSecs)
      console.log('FPS:', self.fpsFrameCount / self.fpsTargetUpdateSecs)

      if (fps < self.fpsTarget) {
        self.fpsInterval -= 1
      } else if (fps > self.fpsTarget) {
        self.fpsInterval += 1
      }
      self.fpsInterval = Math.max(1, self.fpsInterval)
      self.fpsInterval = Math.min(1000, self.fpsInterval)

      self.fpsLastTime = now
      self.fpsFrameCount = 0
    }
    self.fpsFrameCount += 1

    if (Config.enabled) {
      // setTimeout(function () { requestAnimationFrame(self.runGameLoop.bind(self)) }, self.fpsInterval)
      requestAnimationFrame(self.runGameLoop.bind(self))
      // setTimeout(self.runGameLoop.bind(self), 0)

      // window resize event is not reliable apparently - it sometimes misses the last update
      if (document.documentElement.clientWidth !== self.docClientW || document.documentElement.clientHeight !== self.docClientH) {
        setTimeout(self.handleDocumentResize.bind(self), 1)
      }
    }
  }

  public runGameLoop() {
    const self = this

    self.frameNumber += 1

    GameTime.updateTime()

    self.updatePhysics()

    self.playerPickupItem()

    // Update AI
    const aiElapsedSecs = self.aiStopwatch.getElapsedTime()
    if (aiElapsedSecs >= Config.AI_UPDATE_SECS) {
      self.aiStopwatch.stop()
      self.updateAI()
      self.aiStopwatch.start()
    }

    // Update Animation Interpolations
    self.updateAnimations()

    // Run Renderers
    gameState().theMapCameraRenderer!.render()

    //* draw only once
    if (self.frameNumber === 10) {
      GalleryRenderers.forEach((renderer) => {
        renderer.render()
      })
    }
    //*/

    self.requestNextGameLoop()
  }

  updateAnimations(): void {
    const self = this
    const floor = gameState().theFloor
    assert(floor !== null, 'floor is null')

    if (gameState().animations.length !== 0) {

      for (let i = 0; i < gameState().animations.length; ++i) {
        const animation = gameState().animations[i]
        const isDone = animation.killed() || animation.update()
        if (isDone) {
          gameState().animations.splice(i, 1)
          i -= 1
        }
      }
    }

    // update all characters
    gameState().thePlayer.animateTexture()
    for (const character of floor!.characters) {
      character.animateTexture()
    }
  }

  updateAI(): void {
    this.execCharacterTurn()
  }

  updatePhysics(): void {
    const self = this

    const now = GameTime.now()
    while (now >= self.physLastTime + self.physTimeStep) {
      self.physLastTime += self.physTimeStep
      for (let i = 0; i < gameState().physics.length; ++i) {
        const character = gameState().physics[i]
        character.updatePhysics(self.physLastTime, self.physTimeStep, gameState().theFloor!)
      }
    }
  }

  playerPickupItem(): void {
    const self = this
    assert(gameState().theFloor !== null, 'theFloor is null')
    const floor = gameState().theFloor!
    const player = gameState().thePlayer!
    GuiThingSlotUtils.pickupItem(player, floor)
  }

  execCharacterTurn(): void {
    assert(gameState().theFloor !== null, 'theFloor is null')
    const theFloor = gameState().theFloor!
    // loop over characters
    for (const character of theFloor.characters) {
      character.runAI()
    }

    const player = gameState().thePlayer
    if (player) {
      player.runAI()
    }
  }
}

const _theApp = new App()

export function theApp() {
  return _theApp
}
