import { Direction } from "./enums"
import { getTileInfo } from "./tiles"
import { Vec2D, Box2D, Circle, randomChoice } from "./core"
import { GameMap } from "./game-map"
import { assert } from "./assert"
import { PathAnimation } from "./animation"
import { GameTime } from "./game-time"
import { App, theApp } from "./app"
import { Config } from "./config"
import { sprintf } from "sprintf-js"
import { TextureUtils } from "./textures"
import { gameState } from "./game-state"
import { CharacterAI } from "./ai"
import { Texture } from "./textures"

export function isValidPosition(map: GameMap, newX: number, newY: number, selfChar: Character | null, checkChars: boolean = true): boolean {
    // allow for floating point positions - round to nearest integer
    newX = Math.floor(newX)
    newY = Math.floor(newY)

    const { vx, vy, vw, vh } = map.getVirtualBounds()
    // if new position outside of map then return false
    if (newX < vx || newX >= vx + vw || newY < vy || newY >= vy + vh) {
        return false
    }

    const thing = map.getThing(newX, newY)

    // check if there is a thing obstructing the way
    const isObstructingThing = thing && !thing.isFloating && !thing.canWalkThrough
    if (isObstructingThing) {
        return false
    }

    const isWalkBridgeThing = thing && !thing.isFloating && thing.canWalkBridge

    // if new position is not walkable then return false
    const tile = map.get(newX, newY)
    const tileInfo = getTileInfo(tile)
    // const walkableThing = thing && !thing.isFloating && thing.canWalkThrough
    if (!tileInfo.canWalkThrough && !isWalkBridgeThing) {
        return false
    }

    if (checkChars) {
        // if there is another character then return false
        for (const p of map!.characters) {
            if (!selfChar || selfChar.getId() !== p.getId()) {
                if (Math.floor(p.x) === newX && Math.floor(p.y) === newY) {
                    return false
                }
            }
        }
    }

    return true
}

export class Character {
    private characterAI: CharacterAI | null = null

    // generate unique id
    private static _id: number = 0
    private id: number = Character._id++
    public x: number = 0
    public y: number = 0
    public direction: Direction = Direction.UP
    public name: string = 'Character'
    public type: string = 'Character'
    public texture: Texture | null = null
    public textureSprites: Array<Texture> = []

    // Animation related properties
    public walkLoop: number = 0
    public fightLoop: number = 0
    public lastX: number = -1
    public lastY: number = -1

    // Physics properties
    public physSpeed: number = 0
    // Normalized direction vector
    public physDir: Vec2D = new Vec2D(0, 0)
    // Path to be followed by physics engine
    public physPath: Array<{ x: number, y: number }> = []
    public physStuck: boolean = false

    // Hit animation
    public hitAnimation = {
        timeStart: 0,
        dx: 0,
        dy: 0,
        duration: 1,
    }

    // Other properties
    public meta: any = {}

    constructor() {
        this.name = 'Character-' + this.id
    }

    getId() {
        return this.id
    }

    runAI() {
        if (this.characterAI) {
            if(!this.characterAI.run()) {
                this.characterAI = null
            }
        }
    }

    setAI(ai: CharacterAI) {
        this.characterAI = ai
    }

    setPath(path: Array<{ x: number, y: number }>) {
        assert(path.length >= 2 || path.length == 0, 'path must have at least 2 points')
        this.physPath = path
    }

    animateTexture() {
        if (this.textureSprites.length !== 16) {
            return
        }

        // update direction
        if (this.lastX === -1) {
            this.lastX = this.x
            this.lastY = this.y
        }

        // compute vector and assign direction in the direction that is prevalent
        const vx = this.x - this.lastX
        const vy = this.y - this.lastY

        if (vx !== 0 || vy !== 0) {
            // 1.1 = prioritize side view
            if (Math.abs(vx) * 1.1 >= Math.abs(vy)) {
                if (vx > 0) {
                    this.direction = Direction.RIGHT
                } else {
                    this.direction = Direction.LEFT
                }
            } else {
                if (vy > 0) {
                    this.direction = Direction.DOWN
                } else {
                    this.direction = Direction.UP
                }
            }
        }

        const directionOffset = this.direction * 4
        const frameOffset = Math.floor((this.walkLoop % 1) * 4)
        this.texture = this.textureSprites[directionOffset + frameOffset]

        this.lastX = this.x
        this.lastY = this.y
    }

    setAnimationTextures(baseName: string) {
        // we expand the 3 frames into 4 frames by repeating the middle frame
        this.textureSprites = []
        for (let iDir = 0; iDir < 4; ++iDir) {
            for (let iFrame = 0; iFrame < 3; ++iFrame) {
                const name = baseName + sprintf('-%d-%d', iDir, iFrame)
                this.textureSprites.push(TextureUtils.getTexture(name))
                // console.log(name)
            }
            // add center frame at the end to cycle through
            const name = baseName + sprintf('-%d-%d', iDir, 1)
            this.textureSprites.push(TextureUtils.getTexture(name))
        }
        this.texture = this.textureSprites[0]
    }

    updatePhysics(now: number, elapsedSecs: number, map: GameMap) {
        const self = this
        if (self.physSpeed) {
            // update walk loop
            self.walkLoop = (self.walkLoop + elapsedSecs * self.physSpeed * 0.33) % 1

            let dx = 0
            let dy = 0

            if (self.physPath.length) {
                // Physics Mode: Follow Path
                const x = self.x
                const y = self.y
                const a = self.physPath[0]
                const b = self.physPath[1]
                const vx = b.x - a.x
                const vy = b.y - a.y
                // update direction vector and normalize
                // note: player can only reach next view point from this direction and
                // is no able to backtrack this way
                self.physDir.x = vx
                self.physDir.y = vy
                self.physDir.normalize()
                // update position
                const nx = x + self.physDir.x * self.physSpeed * elapsedSecs
                const ny = y + self.physDir.y * self.physSpeed * elapsedSecs
                if (vx !== 0 || vy !== 0) {
                    // if we are close enough to the next point then remove the current one
                    const t = Math.abs(vx) > Math.abs(vy) ? (nx - a.x) / vx : (ny - a.y) / vy
                    // if we are past the next point remove previous point
                    // note: we don't clamp position to last point anymore as it might get stuck there
                    if (t >= 1) {
                        self.physPath.shift()
                        if (self.physPath.length === 1) {
                            self.physSpeed = 0
                            self.physPath = []
                        }
                    }
                    dx = nx - self.x
                    dy = ny - self.y
                }
            } else {
                // Physics Mode: Follow Direction
                dx = self.physDir.x * self.physSpeed * elapsedSecs
                dy = self.physDir.y * self.physSpeed * elapsedSecs
            }

            // Record the magnitude of the movement before we clamp it
            const moveMagitude1 = Math.sqrt(dx * dx + dy * dy)

            const circle1: Circle = {
                x: self.x,
                y: self.y,
                radius: Config.CHAR_RADIUS
            }

            // TODO: if we get stuck too many times we might need to implemnt a push-out
            // logic like we do for circle-circle detection based on nearest point to tile.
            // Nearest point to tile = new position clamped to the tile box.

            // super simple collision with tiles and slide
            const tileX1 = Math.floor(circle1.x + dx + Math.sign(dx) * circle1.radius)
            const tileY1 = Math.floor(circle1.y)
            const isValid1 = isValidPosition(map, tileX1, tileY1, self, false)
            if (!isValid1) {
                dx = 0
            }
            const tileX2 = Math.floor(circle1.x)
            const tileY2 = Math.floor(circle1.y + dy + Math.sign(dy) * circle1.radius)
            // recycle computation if same tile
            const isValid2 = tileX1 === tileX2 && tileY1 === tileY2 ?
                isValid1 :
                isValidPosition(map, tileX2, tileY2, self, false)
            if (!isValid2) {
                dy = 0
            }

            // circle/circle and slide collision with other characters
            const circle2: Circle = {
                x: 0,
                y: 0,
                radius: Config.CHAR_RADIUS
            }
            for (const p of map!.characters) {
                if (p.getId() === self.getId()) {
                    continue
                }

                circle2.x = p.x
                circle2.y = p.y

                const vx = circle1.x + dx - circle2.x
                const vy = circle1.y + dy - circle2.y
                const distance = Math.sqrt(vx * vx + vy * vy)
                const minDistance = circle1.radius + circle2.radius
                if (distance < minDistance) {
                    // slide along the other character
                    const v = new Vec2D(vx, vy)
                    v.normalize()
                    const nx = circle2.x + v.x * minDistance
                    const ny = circle2.y + v.y * minDistance
                    dx = nx - circle1.x
                    dy = ny - circle1.y

                    // Note: there's a chance that a character will get stuck into a wall
                    // because of the way we are sliding. Let's see how often this happens.
                    // Ideally we slide them out when we detect a collision with a wall.

                    // // super simple collision with tiles and slide
                    // const tileX1 = Math.floor(circle1.x + dx + Math.sign(dx) * circle1.radius)
                    // const tileY1 = Math.floor(circle1.y)
                    // if (!isValidPosition(floor, tileX1, tileY1, self, false)) {
                    //     dx = 0
                    // }
                    // const tileX2 = Math.floor(circle1.x)
                    // const tileY2 = Math.floor(circle1.y + dy + Math.sign(dy) * circle1.radius)
                    // if (!isValidPosition(floor, tileX2, tileY2, self, false)) {
                    //     dy = 0
                    // }
                }
            }

            self.x += dx
            self.y += dy

            // Record the magnitude of the movement after we clamp it
            const moveMagnitude2 = Math.sqrt(dx * dx + dy * dy)

            // check if we are stuck
            if (self.physSpeed > 0 && moveMagnitude2 < moveMagitude1 * 0.1) {
                self.physStuck = true
            } else {
                self.physStuck = false
            }
        }
    }
}
