import { Camera } from './camera'
import { RendererCanvas } from './camera-map-renderer'
import { Character } from './character'
import { MapCanvas } from './map-canvas';
import { GameMap } from './game-map';
import { Config } from './config';
import { Animation } from './animation';
import { GUI } from './gui';
import { PlayerState } from './player-state';
import { UserInput } from './user-input';
import { Thing } from './thing';
import { ChunkGenerator, ChunkGeneratorSimplex } from './chunk-generator';
import { WorldGenerator } from './world-generator';

export function gameState(): GameState {
    return GameState.instance!
}

export function gameStateInit(): void {
  if (GameState.instance === null) {
    GameState.instance = new GameState()
  }
}

export class GameState {
  static instance: GameState | null = null

  theChunkGenerator: ChunkGenerator | null = null
  theMapCameraRenderer: RendererCanvas
  theMapCanvas: MapCanvas
  theCamera: Camera

  floors: Array<GameMap> = []
  theFloor: GameMap | null = null

  thePlayer: Character = new Character()
  thePlayerState: PlayerState
  theGui: GUI
  userInput: UserInput

  // animations to be played
  animations: Animation[] = []

  // characters to be updated
  physics: Character[] = []

  constructor() {
    console.log('GameState constructor')

    this.theGui = new GUI
    this.userInput = new UserInput

    this.theMapCanvas = new MapCanvas
    this.theCamera = new Camera
    this.thePlayerState = new PlayerState

    this.theMapCameraRenderer = new RendererCanvas(
      this.theCamera,
      this.theMapCanvas
    )
  }

  addFloor(floor: GameMap) {
    const self = this
    self.theFloor = floor
    self.floors.push(floor)
  }

  addToPhysics(character: Character) {
    const self = this
    self.physics.push(character)
  }

  removePhysics(character: Character) {
    const self = this
    self.physics = self.physics.filter(p => p !== character)
  }

  getActiveHotbarSlotThing(): Thing | null {
    return gameState().theGui!.thingSlotGroup!.getActiveHotbarSlotThing()
  }

  setActiveHotbarSlotThing(thing: Thing | null) {
    gameState().theGui!.thingSlotGroup!.setActiveHotbarSlotThing(thing)
  }
}
