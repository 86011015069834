import { Config } from './config';
import { Signal } from 'signals';
import { assert } from './assert';
import { isValidPosition, Character } from './character';
import { Camera } from './camera';
import { Direction } from './enums';
import { GameState, gameState } from './game-state';
import { PathAnimation, Animation } from './animation';
import { Vec2D } from './core';
import { GameTime } from './game-time'
import { MouseEventType, MouseButton } from './enums';
import { GuiMouseEvent, computeMousePosition } from './gui-widget';

const DRAG_DISTANCE = 4

export class UserInput {
    keyDown: { [key: string]: boolean } = {}

    mouseDownPositionL = new Vec2D(0, 0)
    mouseDownPositionR = new Vec2D(0, 0)
    mouseDownL = false
    mouseDownR = false
    isDraggingL = false
    isDraggingR = false

    mousePositionTile: Vec2D = new Vec2D(0, 0)
    mousePositionTileTopLeft: Vec2D = new Vec2D(0, 0)
    mousePositionCanvas: Vec2D = new Vec2D(0, 0)

    initialize() {
        const self = this

        // assert(gameState().theFloor !== null, 'theFloor is null')
        // assert(gameState().theCamera !== null, 'theCamera is null')

        self.initKeyboardInput()

        document.addEventListener('contextmenu', (e) => {
            e.preventDefault()
            e.stopPropagation()
            return false
        })

        document.addEventListener('wheel', (e) => {
            // https://chromestatus.com/feature/6662647093133312
            // e.preventDefault()
            e.stopPropagation()

            return false
        })

        document.addEventListener('mousedown', (e) => {
            e.preventDefault()
            e.stopPropagation()

            // take the simple approach of only allowing L & R button to be down and only one at a time, ignore the latter one
            switch (e.button) {
                case MouseButton.LEFT:
                    if (self.mouseDownR) {
                        return false
                    }
                    break
                case MouseButton.RIGHT:
                    if (self.mouseDownL) {
                        return false
                    }
                    break
            }

            if (e.button === MouseButton.LEFT) {
                self.mouseDownL = true
                self.mouseDownPositionL.set(e.clientX, e.clientY)
            }

            if (e.button === MouseButton.RIGHT) {
                self.mouseDownR = true
                self.mouseDownPositionR.set(e.clientX, e.clientY)
            }

            // MOUSE DOWN EVENT
            const gme: GuiMouseEvent = new GuiMouseEvent()
            gme.type = [
                MouseEventType.DOWN_LEFT,
                MouseEventType.DOWN_MIDDLE,
                MouseEventType.DOWN_RIGHT
            ][e.button]
            const canvas = gameState().theMapCanvas.getCanvasElement()
            computeMousePosition(canvas, e.clientX, e.clientY, gme)
            setTimeout(function () { gameState().theGui!.onMouseDown(gme) }, 1)

            return false
        })

        document.addEventListener('mouseup', (e) => {
            e.preventDefault()
            e.stopPropagation()

            // take the simple approach of only allowing L & R button to be down and only one at a time, ignore the latter one
            switch (e.button) {
                case MouseButton.LEFT:
                    if (!self.mouseDownL) {
                        return false
                    }
                    break
                case MouseButton.RIGHT:
                    if (!self.mouseDownR) {
                        return false
                    }
                    break
            }

            // MOUSE UP EVENT
            const gme: GuiMouseEvent = new GuiMouseEvent()
            gme.type = [
                MouseEventType.UP_LEFT,
                MouseEventType.UP_MIDDLE,
                MouseEventType.UP_RIGHT
            ][e.button]
            gme.isDraggingR = self.isDraggingR
            gme.isDraggingL = self.isDraggingL
            const canvas = gameState().theMapCanvas.getCanvasElement()
            computeMousePosition(canvas, e.clientX, e.clientY, gme)
            setTimeout(function () { gameState().theGui!.onMouseUp(gme) }, 1)

            // End dragging Left
            if (e.button === MouseButton.LEFT) {
                self.isDraggingL = false
            }

            // End dragging Right
            if (e.button === MouseButton.RIGHT) {
                self.isDraggingR = false
            }

            // CLICK LEFT EVENT
            if (e.button === MouseButton.LEFT) {
                self.mouseDownL = false
                const distanceL = self.mouseDownPositionL.distance(new Vec2D(e.clientX, e.clientY))
                if ( distanceL < DRAG_DISTANCE ) {
                    const gme: GuiMouseEvent = new GuiMouseEvent()
                    gme.type = MouseEventType.CLICK_LEFT
                    computeMousePosition(canvas, e.clientX, e.clientY, gme)
                    setTimeout(function () { gameState().theGui!.onMouseClickLeft(gme) }, 1)
                }
            }

            // CLICK RIGHT EVENT
            if (e.button === MouseButton.RIGHT) {
                self.mouseDownR = false
                const distanceR = self.mouseDownPositionR.distance(new Vec2D(e.clientX, e.clientY))
                if ( distanceR < DRAG_DISTANCE ) {
                    const gme: GuiMouseEvent = new GuiMouseEvent()
                    gme.type = MouseEventType.CLICK_RIGHT
                    computeMousePosition(canvas, e.clientX, e.clientY, gme)
                    setTimeout(function () { gameState().theGui!.onMouseClickRight(gme) }, 1)
                }
            }

            return false
        })

        // FIXME? WE GET AN EXTRA MOUSE MOVE EVENT IF WE HAVE L+R BUTTONS DOWN AND RELEASE THE R BUTTON
        document.addEventListener('mousemove', function (e) {
            e.preventDefault()
            e.stopPropagation()

            if (!self.isDraggingR && self.mouseDownR) {
                const distanceR = self.mouseDownPositionR.distance(new Vec2D(e.clientX, e.clientY))
                if (distanceR >= DRAG_DISTANCE) {
                    const gme = new GuiMouseEvent()
                    gme.type = MouseEventType.DRAG_BEGIN
                    gme.isDraggingR = self.isDraggingR = true
                    const canvas = gameState().theMapCanvas.getCanvasElement()
                    // pass the position where the click started so we can identify the widget under the drag
                    computeMousePosition(canvas, self.mouseDownPositionR.x, self.mouseDownPositionR.y, gme)
                    // computeMousePosition(canvas, e.clientX, e.clientY, gme)
                    setTimeout(function () { gameState().theGui!.onMouseDragBegin(gme) }, 1)
                    return false
                }
            }

            if (!self.isDraggingL && self.mouseDownL) {
                const distanceL = self.mouseDownPositionL.distance(new Vec2D(e.clientX, e.clientY))
                if (distanceL >= DRAG_DISTANCE) {
                    const gme = new GuiMouseEvent()
                    gme.type = MouseEventType.DRAG_BEGIN
                    gme.isDraggingL = self.isDraggingL = true
                    const canvas = gameState().theMapCanvas.getCanvasElement()
                    // pass the position where the click started so we can identify the widget under the drag
                    computeMousePosition(canvas, self.mouseDownPositionL.x, self.mouseDownPositionL.y, gme)
                    // computeMousePosition(canvas, e.clientX, e.clientY, gme)
                    setTimeout(function () { gameState().theGui!.onMouseDragBegin(gme) }, 1)
                    return false
                }
            }

            const gme = new GuiMouseEvent()
            gme.type = MouseEventType.MOVE
            gme.isDraggingR = self.isDraggingR
            gme.isDraggingL = self.isDraggingL
            const canvas = gameState().theMapCanvas.getCanvasElement()
            computeMousePosition(canvas, e.clientX, e.clientY, gme)
            setTimeout(function () { gameState().theGui!.onMouseMove(gme) }, 1)

            return false
        })
    }

    // moves thePlayer in the map based on user keyboard input A S D W
    initKeyboardInput(): void {
        const self = this

        // keyboard input
        document.addEventListener('keydown', function (e) {
            e.preventDefault()
            e.stopPropagation()

            self.keyDown[e.key] = true

            // assert(self.mapCameraRenderer !== null, 'mapCameraRenderer is null')
            const thePlayer: Character = gameState().thePlayer!
            assert(gameState().theFloor !== null, 'theFloor is null')
            assert(thePlayer !== null, 'thePlayer is null')
            assert(thePlayer !== null, 'thePlayer is null')

            if (!thePlayer) {
                return false
            }

            // don't propagate the event to the browser if one of the move key is pressed
            if (e.key === 'a' || e.key === 's' || e.key === 'd' || e.key === 'w') {
                // disable path finding if enabled
                thePlayer.setPath([])

                if (self.keyDown['a']) {
                    thePlayer.direction = Direction.LEFT
                }
                if (self.keyDown['s']) {
                    thePlayer.direction = Direction.DOWN
                }
                if (self.keyDown['d']) {
                    thePlayer.direction = Direction.RIGHT
                }
                if (self.keyDown['w']) {
                    thePlayer.direction = Direction.UP
                }

                const vX = (self.keyDown['a'] ? -1 : self.keyDown['d'] ? +1 : 0)
                const vY = (self.keyDown['w'] ? -1 : self.keyDown['s'] ? +1 : 0)
                gameState().thePlayer!.physDir.set(vX, vY)
                gameState().thePlayer!.physDir.normalize()
                gameState().thePlayer!.physSpeed = Config.PLAYER_SPEED // tiles per sec
            }

            return false
        })

        document.addEventListener('keyup', function (e) {
            e.preventDefault()
            e.stopPropagation()

            self.keyDown[e.key] = false

            // assert(self.mapCameraRenderer !== null, 'mapCameraRenderer is null')
            const thePlayer: Character = gameState().thePlayer!
            assert(gameState().theFloor !== null, 'theFloor is null')
            assert(thePlayer !== null, 'thePlayer is null')
            assert(thePlayer !== null, 'thePlayer is null')

            if (!thePlayer) {
                return false
            }

            // don't propagate the event to the browser if one of the move key is pressed
            if (e.key === 'a' || e.key === 's' || e.key === 'd' || e.key === 'w') {
                const vX = (self.keyDown['a'] ? -1 : self.keyDown['d'] ? +1 : 0)
                const vY = (self.keyDown['w'] ? -1 : self.keyDown['s'] ? +1 : 0)
                gameState().thePlayer!.physDir.set(vX, vY)
                gameState().thePlayer!.physDir.normalize()
                if (gameState().thePlayer!.physDir.isNull()) {
                    gameState().thePlayer!.physSpeed = 0
                }
            }

            return false
        })
    }
}
