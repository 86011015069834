import { Gods } from './enums';
import { ElementalForces } from './enums';
import { Direction } from './enums';
import { Elements } from './enums';
import { HolyConstellations } from './enums';
import { UnholyConstellations } from './enums';

// console.log('Gods', Gods);

import { Config } from './config';
import { addScripts } from './core';
import { App, theApp } from './app';
import { sprintf } from 'sprintf-js';

export function bootstrapApp() {
    console.log(sprintf('=== Hello %s === v%s', Config.title, Config.version));

    // Load remaining scripts and resources
    addScripts(Config.scripts)

    // Set title
    window.document.title = Config.title

    // Init app on load
    $(window).on("load", function () {
        theApp().init()
        $('body').fadeIn(1000)
    })
}

bootstrapApp()