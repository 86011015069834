import { App } from './app';
import { FogOfWarType } from "./enums"
import { Config } from './config';
import { TileId } from "./tiles";
import { GameMap } from './game-map';
import { randomChoice, getRandomInt, Vec2D } from './core';
import { WorldUtils } from './world-utils';
import { FortressUtils } from './fortress-utils';
import { assert } from './assert';
import { RendererCanvas, } from './camera-map-renderer';
import { Camera } from './camera';
import { MapCanvas } from './map-canvas';

export const GalleryRenderers: RendererCanvas[] = []

// define world color type
type WorldColor = {
    height: number,
    color: string,
}

// define type as array of WorldColor
type WorldColors = Array<WorldColor>

function renderMap(description: string, map: GameMap): RendererCanvas {
    // const mapW = map.getW()
    // const mapH = map.getH()
    const mapCanvas = new MapCanvas
    const camera = new Camera

    // NOT TODO FIXME
    // We used to allow multiple game canvases but not anymore.
    // If we want to do it again we would create separeate GameStates
    // and activate them as the user clicks on the appropriate canvas.

    // const cameraManipulator = new CameraManipulator(
    //   camera,
    //   mapCanvas
    // )

    // cameraManipulator.mapSize.set(mapW, mapH)

    const mapCameraRenderer = new RendererCanvas(
      camera,
      mapCanvas
    )

    // cameraManipulator.panningCallabalck = function (x: number, y: number) {
    //     mapCameraRenderer.render()
    // }

    GalleryRenderers.push(mapCameraRenderer)

    mapCameraRenderer.fogOfWarType = FogOfWarType.NONE
    mapCameraRenderer.renderGridEnabled = false
    mapCameraRenderer.isGallerySimpleRender = true

    // Init Fog of War for the map
    map.initializeFowMap()
    if (!map.hasThingMap()) {
        map.initializeThingMap()
    }

    const floor = new GameMap
    mapCameraRenderer.updateFogOfWar()
    mapCameraRenderer.render()

    $('#gallery-map-wrapper').append(`<p class="map-description">${description}</p>`);
    $('#gallery-map-wrapper').append(mapCanvas.getCanvasElement())

    return mapCameraRenderer
}

/**
 * App::renderWorldMap
 * @param {string} description
 * @param {Array} map
 * @param {Array} biomesMap
 * @param {number} sinkFactor - 1.0 is no sink, 1.25 is default
 */
function renderWorldMap(description: string, map: Float32Array, biomesMap: Array<any>, sinkFactor: any, worldColors: any) {
    const playerView = false

    $('#gallery-map-wrapper').append(`<p class="map-description">${description}</p>`);

    const div = $('<div class="world-map-wrapper"></div>')
    div.appendTo('#gallery-map-wrapper')

    // create a canvas and draw each tile as a square on the canvas
    const TILE_PIXEL_SIZE = Config.TILE_PIXEL_SIZE

    const canvas: HTMLCanvasElement = document.createElement('canvas')

    $(canvas).appendTo(div)

    canvas.width = Config.WORLD_TILES_W * TILE_PIXEL_SIZE
    canvas.height = Config.WORLD_TILES_H * TILE_PIXEL_SIZE

    if (playerView) {
        canvas.style.width = String(Config.WORLD_TILES_W * TILE_PIXEL_SIZE)
        canvas.style.height = String(Config.WORLD_TILES_H * TILE_PIXEL_SIZE)
    } else {
        canvas.style.width = '100%'
        canvas.style.height = '100%'
    }

    canvas.style.border = '5px solid white'
    canvas.style.margin = '0px'
    canvas.style.padding = '10px'
    canvas.style.backgroundColor = 'black'
    // canvas.style.imageRendering = 'pixelated'
    // canvas.style.imageRendering = 'crisp-edges'
    canvas.style.imageRendering = 'optimizeSpeed'

    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')
    if (ctx === null) {
        throw new Error('ctx is null')
    }

    ctx.fillStyle = 'fuchsia'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    // ctx.fillStyle = 'black'

    ctx.strokeStyle = 'gray'
    ctx.lineWidth = 1

    let color = ''
    for (let y = 0; y < Config.WORLD_TILES_H; ++y) {
        for (let x = 0; x < Config.WORLD_TILES_W; ++x) {
            if (1) {
                let height = map[x + y * Config.WORLD_TILES_W]
                color = worldColors.find(function (color: any) {
                    return (height - 1) * sinkFactor + 1 <= color.height
                }).color
            } else {
                color = biomesMap[x + y * Config.WORLD_TILES_W]
            }

            // height = height <= 0.33 ? 0.0 : height
            ctx.fillStyle = color // `rgb(${255*height}, ${255*height}, ${255*height})`
            ctx.fillRect(x * TILE_PIXEL_SIZE, y * TILE_PIXEL_SIZE, TILE_PIXEL_SIZE, TILE_PIXEL_SIZE)
        }
    }

    // Draw straight lines for grid
    if (playerView) {
        ctx.strokeStyle = '#303030'
        ctx.lineWidth = 1

        for (let y = 0; y <= Config.WORLD_TILES_H; ++y) {
            ctx.beginPath()
            ctx.moveTo(0, y * TILE_PIXEL_SIZE)
            ctx.lineTo(Config.WORLD_TILES_W * TILE_PIXEL_SIZE, y * TILE_PIXEL_SIZE)
            ctx.stroke()
        }

        for (let x = 0; x <= Config.WORLD_TILES_W; ++x) {
            ctx.beginPath()
            ctx.moveTo(x * TILE_PIXEL_SIZE, 0)
            ctx.lineTo(x * TILE_PIXEL_SIZE, Config.WORLD_TILES_W * TILE_PIXEL_SIZE)
            ctx.stroke()
        }

    }

    // $('#gallery-map-wrapper').append(canvas)
}
/**
 * App::renderWorldMap
 * @param {string} description
 * @param {Array} map
 * @param {Array} biomesMap
 * @param {number} sinkFactor - 1.0 is no sink, 1.25 is default
 */
export function renderBiomesMap(description: string, map: Float32Array, biomesMap: Array<string>, sinkFactor: number, worldColors: WorldColors) {
    // const self = this;

    const playerView = false

    $('#gallery-map-wrapper').append(`<p class="map-description">${description}</p>`);

    const div = $('<div class="world-map-wrapper"></div>')
    div.appendTo('#gallery-map-wrapper')

    // create a canvas and draw each tile as a square on the canvas
    const TILE_PIXEL_SIZE = Config.TILE_PIXEL_SIZE

    const canvas: HTMLCanvasElement = document.createElement('canvas')
    $(canvas).appendTo(div)

    canvas.width = Config.WORLD_TILES_W * TILE_PIXEL_SIZE
    canvas.height = Config.WORLD_TILES_H * TILE_PIXEL_SIZE

    if (playerView) {
        canvas.style.width = String(Config.WORLD_TILES_W * TILE_PIXEL_SIZE)
        canvas.style.height = String(Config.WORLD_TILES_H * TILE_PIXEL_SIZE)
    } else {
        canvas.style.width = '100%'
        canvas.style.height = '100%'
    }

    canvas.style.border = '5px solid white'
    canvas.style.margin = '0px'
    canvas.style.padding = '10px'
    canvas.style.backgroundColor = 'black'
    // canvas.style.imageRendering = 'pixelated'
    // canvas.style.imageRendering = 'crisp-edges'
    canvas.style.imageRendering = 'optimizeSpeed'

    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')
    if (ctx === null) {
        throw new Error('ctx is null')
    }
    ctx.fillStyle = 'fuchsia'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    // ctx.fillStyle = 'black'

    ctx.strokeStyle = 'gray'
    ctx.lineWidth = 1

    let colorName: string = ''
    for (let y = 0; y < Config.WORLD_TILES_H; ++y) {
        for (let x = 0; x < Config.WORLD_TILES_W; ++x) {
            if (0) {
                let height = map[x + y * Config.WORLD_TILES_W]
                // finds the first color that is higher than the height
                colorName = worldColors.find(function (color: any) {
                    return (height - 1) * sinkFactor + 1 <= color.height
                })?.color || 'cyan'
            } else {
                colorName = biomesMap[x + y * Config.WORLD_TILES_W]
            }

            // height = height <= 0.33 ? 0.0 : height
            ctx.fillStyle = colorName // `rgb(${255*height}, ${255*height}, ${255*height})`
            ctx.fillRect(x * TILE_PIXEL_SIZE, y * TILE_PIXEL_SIZE, TILE_PIXEL_SIZE, TILE_PIXEL_SIZE)
        }
    }

    // Draw straight lines for grid
    if (playerView) {
        ctx.strokeStyle = '#303030'
        ctx.lineWidth = 1

        for (let y = 0; y <= Config.WORLD_TILES_H; ++y) {
            ctx.beginPath()
            ctx.moveTo(0, y * TILE_PIXEL_SIZE)
            ctx.lineTo(Config.WORLD_TILES_W * TILE_PIXEL_SIZE, y * TILE_PIXEL_SIZE)
            ctx.stroke()
        }

        for (let x = 0; x <= Config.WORLD_TILES_W; ++x) {
            ctx.beginPath()
            ctx.moveTo(x * TILE_PIXEL_SIZE, 0)
            ctx.lineTo(x * TILE_PIXEL_SIZE, Config.WORLD_TILES_W * TILE_PIXEL_SIZE)
            ctx.stroke()
        }

    }

    // $('#gallery-map-wrapper').append(canvas)
}

export function makeWorldMap(self: App) {
    const timeStart = Date.now()

    console.log('Generating...')

    const { map, biomes }: { map: Float32Array, biomes: Array<string> } = WorldUtils.buildWorld({
        scale: 3,
        persistence: 0.5,
        lacunarity: 3.0,
        octaves: 6,
    })

    console.log('Rendering...')

    renderBiomesMap(
        'World Map Biomes',
        map,
        biomes,

        // sinkFactor:
        1.25,

        // worldColors:
        [
            { height: 0.16, color: 'royalblue' },
            { height: 0.25, color: 'dodgerblue' },
            { height: 0.33, color: 'goldenrod' },
            { height: 0.50, color: 'green' },
            { height: 0.66, color: 'darkgreen' },
            { height: 0.70, color: '#204020' },
            { height: 0.75, color: '#404040' },
            { height: 0.85, color: '#808080' },
            { height: 1.00, color: 'white' },
        ]
    )

    renderWorldMap(
        'World Map',
        map,
        biomes,

        // sinkFactor:
        1.25,

        // worldColors:
        [
            { height: 0.16, color: 'royalblue' },
            { height: 0.25, color: 'dodgerblue' },
            { height: 0.33, color: 'goldenrod' },
            { height: 0.50, color: 'green' },
            { height: 0.66, color: 'darkgreen' },
            { height: 0.70, color: '#204020' },
            { height: 0.75, color: '#404040' },
            { height: 0.85, color: '#808080' },
            { height: 1.00, color: 'white' },
        ]
    )

    const timeEnd = Date.now()
    // print elapsed seconds
    console.log('World Generation Time:', (timeEnd - timeStart) / 1000.0)
}

export function makeCellularAutomataCaves(self: App) {
    const emptyCell = TileId.EMPTY
    const fillCell = TileId.WALL_ROCK

    const cellularCavesMap: GameMap = FortressUtils.cellularAutomataCaves({
        maxPasses: 5,
        threshold: 4,
        caveProbability: 53,
        circleRadius: Math.min(Config.MAP_TILES_W, Config.MAP_TILES_H) / 2.2,
        border: 5,
        emptyCell,
        fillCell
    })

    // Example of operations on caves

    const allCaves: { caves: [{ tiles: [] }] } = FortressUtils.findAllCaves(cellularCavesMap, emptyCell, fillCell)

    allCaves.caves.forEach(function (cave: any, i: any) {
        if (i === 0) {
            // Skip the largest cave
            return
        } else
            if (cave.tiles.length < 25) {
                // Fill small caves
                cave.tiles.forEach(function (tile: any) {
                    cellularCavesMap.setSafe_(tile.x, tile.y, fillCell)
                })
            } else {
                // Highlight other caves
                cave.tiles.forEach(function (tile: any) {
                    cellularCavesMap.setSafe_(tile.x, tile.y, TileId.WALL_CAVE)
                })
            }
    })

    // print size of all caves
    console.log('All caves:', allCaves.caves.map(function (cave: any) {
        return cave.tiles.length
    }))

    renderMap(
        'Cellular Automata Caves',
        cellularCavesMap,
    )
}

export function makeDrunkCave1(self: App) {
    renderMap(
        'Drunken Cave | Island | River Lake',
        FortressUtils.drunkCaves({
            minPercent: 33,
            maxClipping: 10,
            caveNum: 20,
            totalTiles: 10000,
            maxTries: 1000,
            wallBorder: [TileId.FLOOR_GRASS, TileId.WALL_ROCK, TileId.WALL_ROCK],
        })
    )
}

export function makeDrunkCave2(self: App) {
    renderMap(
        'Drunken Cave | Island | River Lake',
        FortressUtils.drunkCaves({
            minPercent: 33,
            maxClipping: 10,
            caveNum: 20,
            totalTiles: 10000,
            maxTries: 1000,
            wallBorder: [TileId.WALL_ROCK],
        })
    )
}

export function makeBinPacking(self: App) {
    /////////////////////////////////////////////////////////////////////////////
    // Bin Packing Algo For Castle Layout
    // - Bin packing generates general layout of the castle
    // - Fill the layout with generative templates of various room types
    // - You can pre-determine the type of rooms/areas to fill in, for example:
    //   Throne room, dining hall, kitchen, barracks, armory, library, etc.
    /////////////////////////////////////////////////////////////////////////////

    const rooms = [
        { x: 0, y: 0, w: 30, h: 20, },
        { x: 0, y: 0, w: 30, h: 20, },
        { x: 0, y: 0, w: 40, h: 10, },
        { x: 0, y: 0, w: 20, h: 40, }
    ]
    for (let i = 0; i < 50; ++i) {
        const w = getRandomInt(5, 15)
        const h = getRandomInt(5, 15)
        const x = 0
        const y = 0
        rooms.push({
            x, y, w, h
        })
    }

    const roomsPlaced: any = []

    // sort rooms by size of the rooms
    rooms.sort(function (a, b) {
        return (b.w * b.h) - (a.w * a.h)
    })

    // const map = new GameMap(Config.MAP_TILES_W, Config.MAP_TILES_H)
    const map = new GameMap()
    map.fill(TileId.FLOOR_GRASS)

    const cameraMapRenderer: RendererCanvas = renderMap(
        'Bin Packing Layout',
        map
    )

    function binPackingStep() {

        FortressUtils.binPacking({}, rooms, roomsPlaced)

        map.fill(TileId.FLOOR_SWAMP)

        // render all rooms
        rooms.forEach(function (room) {
            FortressUtils.renderRoom(
                room.x,
                room.y,
                room.w,
                room.h,
                TileId.FLOOR_CAVE,
                map,
                true // mergeWall
            )
        })

        roomsPlaced.forEach(function (room: any) {
            FortressUtils.renderRoom(
                room.x,
                room.y,
                room.w,
                room.h,
                TileId.FLOOR_CAVE,
                map,
                true // mergeWall
            )
        })

        if (rooms.length === 0) {
            // place doors
            FortressUtils.generateRoomDoors(roomsPlaced, map, false)
            // render map
            // renderMap('Bin Packing Layout', map)
        } else {
            setTimeout(function () {
                binPackingStep()
            }, 0)
        }

        cameraMapRenderer.render()
    }

    binPackingStep()
    // cameraMapRenderer.render()
}

export function makeCastle1(self: App) {
    renderMap(
        'Lion\'s Den Castle',
        FortressUtils.generateCastle({
            roomNum: randomChoice([0, 1, 2, 3, 4, 5, 1, 2, 3, 4, 5, 20]),
            border: -2,
            sizeM: 5,
            sizeN: 7,
        })
    )
}

export function makeBSPManor(self: App) {
    const roomCenter = {
        x: 0.40 * Config.MAP_TILES_W,
        y: 0.10 * Config.MAP_TILES_H,
        w: 0.30 * Config.MAP_TILES_W,
        h: 0.30 * Config.MAP_TILES_H,
    }

    const roomLeft = {
        x: roomCenter.x + roomCenter.w - 1,
        y: 0.25 * Config.MAP_TILES_H, // roomCenter.y,
        w: 0.20 * Config.MAP_TILES_W,
        h: 0.50 * Config.MAP_TILES_H,
    }

    const roomRight = {
        x: roomCenter.x - roomLeft.w + 1,
        y: 0.25 * Config.MAP_TILES_H, // roomCenter.y,
        w: 0.20 * Config.MAP_TILES_W,
        h: 0.50 * Config.MAP_TILES_H,
    }

    const rooms = [
        {
            x: roomCenter.w / 4 + roomCenter.x,
            y: roomCenter.y + roomCenter.h - 1,
            w: roomCenter.w / 2,
            h: roomCenter.h * 1.5,
        }
    ]

    renderMap(
        'BSP Grand Manor Ruins',
        FortressUtils.genManorCompositeBSP(
            [roomCenter, roomLeft, roomRight],
            rooms,
            true // blowUpDoors
        )
    )
}

export function makePOIonMap(self: App) {
    renderMap(
        'POIs on a Map',
        FortressUtils.generateRooms({
            roomNum: 20,
            sizeM: 3,
            sizeN: 3,
            border: 5,
            connect: true,
            connectNeat: false,
            connectTypes: [2],
            makeDoors: true,
            ghostRooms: [
                {
                    x: Config.MAP_TILES_W / 3,
                    y: Config.MAP_TILES_H / 3,
                    w: Config.MAP_TILES_W / 3,
                    h: Config.MAP_TILES_H / 3,
                }
            ]
        })
    )
}

export function makeClassicRoguelike(self: App) {
    renderMap(
        'Classic Roguelike',
        FortressUtils.generateRooms({
            roomNum: Config.MAP_TILES_W * Config.MAP_TILES_H / 100,
            sizeM: 5,
            sizeN: 5,
            border: 10,
            connect: true,
            connectNeat: false,
            connectTypes: [0, 1],
            makeDoors: true,
            ghostRooms: [
                {
                    x: Config.MAP_TILES_W / 3,
                    y: Config.MAP_TILES_H / 3,
                    w: Config.MAP_TILES_W / 3,
                    h: Config.MAP_TILES_H / 3,
                }
            ]
        })
    )
}

export function makeDungeon1(self: App) {
    renderMap(
        'Dungeons Around Lava',
        FortressUtils.generateRoomsAdjacent({
            roomNum: Config.MAP_TILES_W * Config.MAP_TILES_H / 200,
            seedRoomNum: 1,
            border: -2,
            sizeM: 5,
            sizeN: 5,
            connect: true,
            connectNeat: true,
            connectTypes: [0, 1],
            makeDoors: true,
            ghostRooms: [
                {
                    x: 1.5 * Config.MAP_TILES_W / 8,
                    y: 1.5 * Config.MAP_TILES_H / 8,
                    w: Config.MAP_TILES_W / 4,
                    h: Config.MAP_TILES_H / 4,
                },
                {
                    x: 4 * Config.MAP_TILES_W / 8,
                    y: 4 * Config.MAP_TILES_H / 8,
                    w: Config.MAP_TILES_W / 4,
                    h: Config.MAP_TILES_H / 4,
                }
            ]
        })
    )
}

export function makeCity2(self: App) {
    renderMap(
        'City Around Lakes I',
        FortressUtils.generateRoomsAdjacent({
            roomNum: 300,
            seedRoomNum: 1,
            border: 0,
            sizeM: 2,
            sizeN: 5,
            connect: true,
            connectNeat: true,
            connectTypes: [0, 1],
            makeDoors: true,
            ghostRooms: [
                {
                    x: 1.5 * Config.MAP_TILES_W / 8,
                    y: 1.5 * Config.MAP_TILES_H / 8,
                    w: Config.MAP_TILES_W / 4,
                    h: Config.MAP_TILES_H / 4,
                },
                {
                    x: 4 * Config.MAP_TILES_W / 8,
                    y: 4 * Config.MAP_TILES_H / 8,
                    w: Config.MAP_TILES_W / 4,
                    h: Config.MAP_TILES_H / 4,
                }
            ]
        })
    )
}

export function makeCity1(self: App) {
    renderMap(
        'City Around Lakes II',
        FortressUtils.generateRoomsAdjacent({
            roomNum: Config.MAP_TILES_W * Config.MAP_TILES_H / 200,
            seedRoomNum: 1,
            border: 2,
            sizeM: 2,
            sizeN: 7,
            connect: false,
            connectNeat: true,
            connectTypes: [0, 1],
            makeDoors: true,
            ghostRooms: [
                {
                    x: 1.5 * Config.MAP_TILES_W / 8,
                    y: 1.5 * Config.MAP_TILES_H / 8,
                    w: Config.MAP_TILES_W / 4,
                    h: Config.MAP_TILES_H / 4,
                },
                {
                    x: 4 * Config.MAP_TILES_W / 8,
                    y: 4 * Config.MAP_TILES_H / 8,
                    w: Config.MAP_TILES_W / 4,
                    h: Config.MAP_TILES_H / 4,
                }
            ]
        })
    )
}

export function makeUndergroundCrypts(self: App) {
    renderMap(
        'Underground Crypts',
        FortressUtils.generateRoomsAdjacent({
            seedRoomNum: 3,
            roomNum: 50,
            border: 1,
            sizeM: 2,
            sizeN: 5,
            connect: true,
            connectNeat: true,
            connectTypes: [0, 1],
            makeDoors: true,
            ghostRooms: null
        })
    )
}

export function makeNarrowCaves(self: App) {
    renderMap(
        'Narrow Caves',
        FortressUtils.generateRoomsAdjacent({
            seedRoomNum: 10,
            roomNum: 100,
            border: -1,
            sizeM: 3,
            sizeN: 3,
            connect: true,
            connectNeat: true,
            connectTypes: [2],
            makeDoors: true,
            ghostRooms: null
        })
    )
}

///////////////////////////////////////////////////////////////////////////////
// Other Methods To Generate Rooms
///////////////////////////////////////////////////////////////////////////////
/*
  ---
  - Stylized rooms: throne room, dining room, kitchen, bedroom, bathroom, treasure room, prison, outlook
  - Stylized buildings: castle, manor, mausoleum
  - Ex. Castle should have a set of rooms and a given arrangement.
    - Tower could have sub towers around it
    - Prison could have cells
    - Kitchen could have a pantry
    - Between rooms there could be corridors
    - Between rooms there could be stairs
    - Between buildings there could be bridges or tunnels
    - Between buildings there could be courtyards
    - Between buildings there could be gardens
    - Between buildings there could be walls
    - Between buildings there could be moats
    - Between buildings there could be towers
    - Between buildings there could be gates
    - Between buildings there could be drawbridges
    - Between buildings there could be statues
    - Between buildings there could be fountains
    - Between buildings there could be wells
    - Between buildings there could be gardens
    - Between buildings there could be trees
    - Between buildings there could be pools
    - Between buildings there could be lava
    - Between buildings there could be water
    - There could be walls around the castle such as curtain walls or
      baileys or barbicans or bastions or battlements or crenellations or embrasures or merlons or
      parapets or ramparts or towers or turrets or walls or watchtowers
    - We can add historically accurate building features such as
      arrow slits or buttresses or chimneys or corbels or gargoyles or machicolations or
      or oriel windows or portcullises or turrets or vaults or windows
    - We can add historically accurate room features such as
      alcoves or arches or balconies or beams or columns or doors or fireplaces or floors or
      fountains or furniture or hearths or pillars or pools or stairs or statues or traps or
      treasure or walls or windows, but also secret doors or secret passages or secret rooms or
      secret treasure or secret monsters or secret traps or secret stairs or secret portals

  ---

  - Apply erosion to the map
  - Binary space partitioning / quadtree fractal partitioning
  - Cellular automata
  - Random walk
  - Drunkard walk
  - Perlin noise, simplex noise
  - Wave export function collapse
  ---
  - Room with columns
  - Room with pillars
  - Room with pools
  - Room with lava
  - Room with water
  - Room with trees
  - Room with statues
  - Room with traps
  - Room with treasure
  - Room with monsters
  - Room with secret door
  - Room with secret passage
  - Room with secret room
  - Room with secret treasure
  - Room with secret monster
  - Room with secret trap
  - Room with secret stairs
  - Room with secret portal
  - Room with secret teleporter
 */

/*
 How to user binary space partitioning to generate rooms:
  1. Start with a single room
  2. Split the room into two rooms
  3. Split each room into two rooms
  4. Repeat until you have enough rooms

  You can spice up the basic algorithm by:
  - Splitting the room in a random direction
  - Splitting the room at a random position
  - Splitting the room only if it is larger than a minimum size
*/
