import { assert } from "./assert"
import { gameState } from "./game-state"
import { Thing } from "./thing"
import { getTileInfo } from './tiles'
import { GuiThingSlot } from "./gui"
import { EquipmentType, Direction } from "./enums"
import { Character } from "./character"
import { GameMap } from "./game-map"

// -----------------------------------------------------------------------------
// GuiThingSlotUtils
// -----------------------------------------------------------------------------
export class GuiThingSlotUtils {

    static pickupItem(player: Character, map: GameMap) {
        const thePlayer = gameState().thePlayer!
        const x = Math.floor(thePlayer.x)
        const y = Math.floor(thePlayer.y)
        const thing = map.getThing(x, y)
        if (thing !== null && thing.isFloating) {
            const slot = this.findEmptySlot(thing)
            if (slot !== null) {
                map.removeThingXY(x, y)
                // console.log('Picked up item', thing.type)
                // console.log(JSON.stringify(thing, null, 2))
                if (slot.thing === null) {
                    slot.thing = thing
                } else {
                    assert(slot.thing.type === thing.type, 'slot.thing.type !== thing.type')
                    slot.thing.pileCount += thing.pileCount
                }
            } else {
                console.log('No slot available to pick up item', thing.type, thing.name)
            }
        }
    }

    static findEmptySlot(thing: Thing): GuiThingSlot | null {
        // Rules
        // Prioritize piles over empty slots
        // [v] Goes in the hotbar if there is already a pile for it
        // [v] Goes in the inventory if there is already a pile for it
        // [v] Goes in the hotbar if there is an empty slot
        // [v] Goes in the inventory if there is an empty slot

        // do not pickup anything util user has finished dragging
        const isDragging = gameState().theGui!.thingSlotGroup!.isDragging
        if (isDragging) {
            return null
        }
        // search for empty slot in hotbar
        const hotbar = gameState().theGui!.thingSlotGroup!.hotbarSlots!
        const inventory = gameState().theGui!.thingSlotGroup!.inventorySlots!
        // Piles
        for(let slot of hotbar) {
            if (slot.thing !== null && slot.thing.pileCount !== 0 && slot.thing.type === thing.type) {
                return slot
            }
        }
        for(let slot of inventory) {
            if (slot.thing !== null && slot.thing.pileCount !== 0 && slot.thing.type === thing.type) {
                return slot
            }
        }
        // Empty slots
        for(let slot of hotbar) {
            if (slot.thing === null) {
                return slot
            }
        }
        for(let slot of inventory) {
            if (slot.thing === null) {
                return slot
            }
        }
        return null
    }

    static countEquipmentTypes(equippableSlots: Array<GuiThingSlot>): any {
        const equipmentTypes: any = {}
        for(let slot of equippableSlots) {
            if (slot.thing !== null) {
                assert(slot.thing.isEquipment === true, `Thing ${slot.thing.type} is not equipment`)
                const type: string = slot.thing.equipmentType
                if (equipmentTypes[type] === undefined) {
                    equipmentTypes[type] = 1
                } else {
                    equipmentTypes[type]++
                }
            }
        }
        return equipmentTypes
    }

    static checkEquipmentFits(thing: Thing, thing2: Thing | null, equippableSlots: Array<GuiThingSlot>): boolean {
        const self = this

        assert(thing !== null, 'thing is null')

        if (thing2 !== null && thing.equipmentType === thing2.equipmentType) {
            // Swapping for same type - ok
            return true
        }

        const equipmentTypes = this.countEquipmentTypes(equippableSlots)
        console.log('equipmentTypes', JSON.stringify(equipmentTypes, null, 2))

        const maxAllowed = {
            // Default is 1
            // [EquipmentType.HELMET]: 1,
            // [EquipmentType.BREASTPLATE]: 1,
            // [EquipmentType.SHIELD]: 1,
            // [EquipmentType.BOOTS]: 1,
            [EquipmentType.RING]: 4,
            [EquipmentType.NECKLACE]: 4,
        }

        if (equipmentTypes[thing.equipmentType] === undefined) {
            // There aren't any of this type - ok
            return true
        } else {
            // There are some of this type - check if we can add more
            const maxCount = maxAllowed[thing.equipmentType] || 1
            return equipmentTypes[thing.equipmentType] < maxCount
        }
    }

    static dropThing(thing: Thing): boolean {
        // Drop thing
        const map = gameState().theFloor
        assert(map !== null, 'map is null')
        const player = gameState().thePlayer
        assert(player !== null, 'player is null')

        // try dropping in front of player
        let dx = 0
        let dy = 0
        switch (player.direction) {
            case Direction.UP: dy = -1; break
            case Direction.DOWN: dy = +1; break
            case Direction.LEFT: dx = -1; break
            case Direction.RIGHT: dx = +1; break
        }

        const offsets = [
            {dx:dx, dy:dy}, // it's ok if we try this twice since we exit at the first success
            // --- 1 tile away
            {dx:-1, dy:-1},
            {dx:-1, dy: 0},
            {dx:-1, dy:+1},
            {dx: 0, dy:-1},
            {dx: 0, dy:+1},
            {dx:+1, dy:-1},
            {dx:+1, dy: 0},
            {dx:+1, dy:+1},
            // --- 2 tiles away
            {dx:-2, dy:-2},
            {dx:-2, dy:-1},
            {dx:-2, dy: 0},
            {dx:-2, dy:+1},
            {dx:-2, dy:+2},
            {dx:-1, dy:-2},
            {dx: 0, dy:-2},
            {dx:+1, dy:-2},
            {dx:-1, dy:+2},
            {dx: 0, dy:+2},
            {dx:+1, dy:+2},
            {dx:+2, dy:-2},
            {dx:+2, dy:-1},
            {dx:+2, dy: 0},
            {dx:+2, dy:+1},
            {dx:+2, dy:+2},
        ]

        const px = Math.floor(player.x)
        const py = Math.floor(player.y)

        // check for a tile around with a Thing that is pileable
        for (let offset of offsets) {
            // Compute drop position
            const dropX = px + offset.dx
            const dropY = py + offset.dy

            // Check if there is already a thing on the floor
            const thing2 = map!.getThing(dropX, dropY)
            if (thing2 !== null && thing2.isFloating && thing2.pileCount !== 0 && thing2.type === thing.type) {
                thing2.pileCount += thing.pileCount
                return true
            }
        }

        // drop thing on the floor in the first valid position
        for (let offset of offsets) {
            // Compute drop position
            const dropX = px + offset.dx
            const dropY = py + offset.dy
            // Check if drop position is valid
            const tile = map!.get(dropX, dropY)
            const tileInfo = getTileInfo(tile)
            if (tileInfo.canWalkThrough === false) {
                // Drop position is not walkable
                continue
            }

            // Check if there is already a thing on the floor
            const thing2 = map!.getThing(dropX, dropY)
            if (thing2 !== null) {
                // There is already a thing on the floor
                continue
            }

            // Drop thing on the floor
            const isFloating = true
            map!.placeThing(thing, dropX, dropY, isFloating)
            return true
        }

        return false
    }
}
