import { RGBA, HSL } from './core'
import { assert } from './assert'
import { Config } from './config'
import { Texture } from './textures'

/**
 * Uesd to implement chanis of color transformations.
 *
 * Allows chaining of color transformations:
 *
 * - targetHue
 * - setSaturation
 * - setLightness
 * - invert
 * - darken
 * - rotate90
 * - flipX
 * - flipY
 * - mapColors
 * - addNoise
 *
 * We could:
 * - Generate texture on the fly based on the name which is a combination of H S L
 * - TextureBaseName<Hue20><Saturation20><Lightness20><Rotation4><FlipX2><FlipY2><Invert><Noise>
 *
 */
export class ImageTransform {
    static run(imageData: ImageData, colorTransform: {tr:any, args:any}[] = []): void {
        if (colorTransform) {
            // for each
            for (let i = 0; i < colorTransform.length; ++i) {
                const transform = colorTransform[i]
                transform.tr(imageData, transform.args)
            }
            // colorTransform(imageData, SPRITE_SIZE, SPRITE_SIZE)
        }
    }

    static createImageData(w: number, h: number, color: RGBA): ImageData {
        const canvas = document.createElement('canvas')
        canvas.width = w
        canvas.height = h
        const ctx = canvas.getContext('2d')!
        // fill with black
        ctx.fillStyle = color.toCSS()
        ctx.fillRect(0, 0, w, h)
        return ctx.getImageData(0, 0, w, h)
    }

    static imageToData(image: HTMLImageElement): ImageData {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const ctx = canvas.getContext('2d')!
        ctx.drawImage(image, 0, 0)
        return ctx.getImageData(0, 0, image.width, image.height)
    }

    static dataToImage(imageData: ImageData): HTMLImageElement {
        const canvas = document.createElement('canvas')
        canvas.width = imageData.width
        canvas.height = imageData.height
        const ctx = canvas.getContext('2d')!
        ctx.putImageData(imageData, 0, 0)
        const image = new Image()
        image.src = canvas.toDataURL()
        return image
    }

    // mic fixme: make this work with atlased textures - only within the tx/ty/tw/th area
    // Takes the top left 16x16 pixels of the image and makes an icon
    static makeIconTopLeft(srcTex: Texture): Texture {
        const canvas = document.createElement('canvas')
        canvas.width = Config.TILE_PIXEL_SIZE
        canvas.height = Config.TILE_PIXEL_SIZE
        const ctx = canvas.getContext('2d')!
        ctx.imageSmoothingEnabled = true
        ctx.imageSmoothingQuality = 'high'
        ctx.drawImage(
            srcTex.handle!,

            srcTex.tx, srcTex.ty,
            Config.TILE_PIXEL_SIZE, Config.TILE_PIXEL_SIZE,

            0, 0,
            Config.TILE_PIXEL_SIZE, Config.TILE_PIXEL_SIZE
        )

        const icon = new Image()
        icon.src = canvas.toDataURL()

        const texture = new Texture(icon)
        // mic fixme: set tx/ty/tw/th explicitly because html image is not loaded yet
        texture.tx = 0
        texture.ty = 0
        texture.tw = Config.TILE_PIXEL_SIZE
        texture.th = Config.TILE_PIXEL_SIZE
        texture.fullW = Config.TILE_PIXEL_SIZE
        texture.fullH = Config.TILE_PIXEL_SIZE

        return texture
    }

    // mic fixme: transparent pixel cropping logic to be updated to look inside the tx/ty/tw/th area
    // mic fixme: make this work with atlased textures - only within the tx/ty/tw/th area
    // makes a 16x16 icon from any image and crops away transparent pixels
    static makeIconScale(srcTex: Texture): Texture {
        const srcCanvas = document.createElement('canvas')
        srcCanvas.width = srcTex.fullW
        srcCanvas.height = srcTex.fullW
        const srcCtx = srcCanvas.getContext('2d', {willReadFrequently: true})!
        // scan picture for pixels with alpha > 0
        srcCtx.drawImage(srcTex.handle!, 0, 0)
        const imageData = srcCtx.getImageData(0, 0, srcTex.fullW, srcTex.fullH)
        // const w = imageData.width
        // const h = imageData.height
        let minX = srcTex.tx + srcTex.tw - 1
        let minY = srcTex.ty + srcTex.th - 1
        let maxX = 0
        let maxY = 0
        for(let y = srcTex.ty; y < srcTex.ty + srcTex.th; ++y) {
            for(let x = srcTex.tx; x < srcTex.tx + srcTex.tw; ++x) {
                const i = y * imageData.width * 4 + x * 4
                const a = imageData.data[i + 3]
                if (a > 0) {
                    minX = Math.min(minX, x)
                    minY = Math.min(minY, y)
                    maxX = Math.max(maxX, x)
                    maxY = Math.max(maxY, y)
                }
            }
        }
        const cropWidth = maxX - minX + 1
        const cropHeight = maxY - minY + 1
        const canvas = document.createElement('canvas')
        canvas.width = Config.TILE_PIXEL_SIZE
        canvas.height = Config.TILE_PIXEL_SIZE
        const ctx = canvas.getContext('2d')!
        ctx.imageSmoothingEnabled = true
        ctx.imageSmoothingQuality = 'high'
        ctx.drawImage(
            srcTex.handle!,
            minX,
            minY,
            cropWidth,
            cropHeight,
            0,
            0,
            Config.TILE_PIXEL_SIZE,
            Config.TILE_PIXEL_SIZE,
        )
        const icon = new Image()
        icon.src = canvas.toDataURL()
        const texture = new Texture(icon)
        // mic fixme: explicitly set tx/ty/tw/th because html image is not loaded yet
        texture.tx = 0
        texture.ty = 0
        texture.tw = Config.TILE_PIXEL_SIZE
        texture.th = Config.TILE_PIXEL_SIZE
        texture.fullW = Config.TILE_PIXEL_SIZE
        texture.fullH = Config.TILE_PIXEL_SIZE
        return texture
    }

    // mic fixme: compute the average color of the image only within the tx/ty/tw/th area
    static averageColor(texture: Texture): RGBA {
        // assert(texture.handle !== undefined, `averageColor: texture.handle invalid: ${texture.handle}`)
        const imageData = ImageTransform.imageToData(texture!.handle!)

        const w = imageData.width
        const h = imageData.height
        let r = 0
        let g = 0
        let b = 0
        let a = 0
        let count = 0
        for(let y = 0; y < h; ++y) {
            for(let x = 0; x < w; ++x) {
                const i = y * w * 4 + x * 4
                r += imageData.data[i + 0]
                g += imageData.data[i + 1]
                b += imageData.data[i + 2]
                a += imageData.data[i + 3]
                ++count
            }
        }
        return new RGBA(Math.round(r / count), Math.round(g / count), Math.round(b / count), Math.round(a / count))
    }

    static blur(imageData: ImageData, args: any): ImageData {
        const w = imageData.width
        const h = imageData.height
        const radius = args.radius
        // const kernel = args.kernel
        assert(radius >= 1 && radius <= 10, `gaussianBlur: radius must be 1 - 10, got ${radius}`)
        // assert(kernel >= 1 && kernel <= 10, `gaussianBlur: kernel must be 1 - 10, got ${kernel}`)

        // create a new image
        const newImageData = new ImageData(w, h)

        // blur
        for(let y = 0; y < h; ++y) {
            for(let x = 0; x < w; ++x) {
                let r = 0
                let g = 0
                let b = 0
                let a = 0
                let count = 0
                for(let dy = -radius; dy <= radius; ++dy) {
                    for(let dx = -radius; dx <= radius; ++dx) {
                        const nx = x + dx
                        const ny = y + dy
                        if (nx < 0 || nx >= w || ny < 0 || ny >= h) {
                            continue
                        }
                        ++count
                        const i = ny * w * 4 + nx * 4
                        r += imageData.data[i + 0]
                        g += imageData.data[i + 1]
                        b += imageData.data[i + 2]
                        a += imageData.data[i + 3]
                        // const weight = kernel * Math.exp(-(dx * dx + dy * dy) / (2 * radius * radius))
                        // r += imageData.data[i + 0] * weight
                        // g += imageData.data[i + 1] * weight
                        // b += imageData.data[i + 2] * weight
                        // a += imageData.data[i + 3] * weight
                    }
                }

                r = Math.round(r / count)
                g = Math.round(g / count)
                b = Math.round(b / count)
                a = Math.round(a / count)

                const i = y * w * 4 + x * 4
                newImageData.data[i + 0] = r
                newImageData.data[i + 1] = g
                newImageData.data[i + 2] = b
                newImageData.data[i + 3] = a
            }
        }

        // copy back
        for(let i = 0; i < newImageData.data.length; ++i) {
            imageData.data[i] = newImageData.data[i]
        }

        return imageData
    }

    static computeAverageHue(imageData: ImageData, w: number, h: number): number {
        let avgHueSin = 0
        let avgHueCos = 0
        // compute the average hue by saturation and lightness
        for(let y = 0; y < h; ++y) {
            for(let x = 0; x < w; ++x) {
                const i = y * w * 4 + x * 4
                const r = imageData.data[i + 0]
                const g = imageData.data[i + 1]
                const b = imageData.data[i + 2]
                const hsl = HSL.fromRGB(r, g, b)
                // convert hue to radians
                const hueRadians = hsl.hue * 2 * Math.PI
                const hueSin = Math.sin(hueRadians)
                const hueCos = Math.cos(hueRadians)
                avgHueSin += hueSin
                avgHueCos += hueCos
            }
        }

        const avgHueRadians = Math.atan2(avgHueSin, avgHueCos)
        let avgHue = (avgHueRadians / (2 * Math.PI)) % 1
        if (avgHue < 0) {
            avgHue += 1
        }
        return avgHue
    }

    static targetHueData(imageData: ImageData, args: any): ImageData {
        const targetHue = args.targetHue
        assert(targetHue >= 0 && targetHue <= 1, `targetHue must be 0 - 1, got ${targetHue}`)
        const data = imageData.data
        const w = imageData.width
        const h = imageData.height

        const averageHue = this.computeAverageHue(imageData, w, h)
        const delta = targetHue - averageHue

        // rotate hue by delta
        for(let i = 0; i < data.length; i += 4) {
            const r = data[i + 0]
            const g = data[i + 1]
            const b = data[i + 2]
            // const a = data[i + 3]
            const hsl = HSL.fromRGB(r, g, b)
            hsl.rotateHue(delta)
            const newColor = hsl.toRGBA()
            data[i + 0] = newColor.r
            data[i + 1] = newColor.g
            data[i + 2] = newColor.b
            // data[i + 3] = newColor.a
        }

        // debug
        // const averageHue2 = this.computeAverageHue(imageData, w, h)
        // assert(Math.abs(averageHue2 - targetHue) < 0.05, `averageHue2: ${averageHue2}, targetHue: ${targetHue}`)

        return imageData
    }

    static targetHueImage(image: HTMLImageElement, args: any): HTMLImageElement {
        return this.dataToImage(this.targetHueData(this.imageToData(image), args))
    }

    static setSaturationData(imageData: ImageData, args: any): ImageData {
        const saturation = args.saturation
        assert(saturation >= 0 && saturation <= 1, `saturation must be 0 - 1, got ${saturation}`)
        const data = imageData.data
        const w = imageData.width
        const h = imageData.height

        // rotate hue by delta
        for(let i = 0; i < data.length; i += 4) {
            const r = data[i + 0]
            const g = data[i + 1]
            const b = data[i + 2]
            // const a = data[i + 3]
            const hsl = HSL.fromRGB(r, g, b)
            hsl.setSaturation(saturation)
            const newColor = hsl.toRGBA()
            data[i + 0] = newColor.r
            data[i + 1] = newColor.g
            data[i + 2] = newColor.b
            // data[i + 3] = newColor.a
        }

        return imageData
    }

    static setSaturationImage(image: HTMLImageElement, args: any): HTMLImageElement {
        return this.dataToImage(this.setSaturationData(this.imageToData(image), args))
    }

    static setLightnessData(imageData: ImageData, args: any): ImageData {
        const lightness = args.lightness
        assert(lightness >= 0 && lightness <= 1, `lightness must be 0 - 1, got ${lightness}`)
        const data = imageData.data
        const w = imageData.width
        const h = imageData.height

        // rotate hue by delta
        for(let i = 0; i < data.length; i += 4) {
            const r = data[i + 0]
            const g = data[i + 1]
            const b = data[i + 2]
            // const a = data[i + 3]
            const hsl = HSL.fromRGB(r, g, b)
            hsl.setLightness(lightness)
            const newColor = hsl.toRGBA()
            data[i + 0] = newColor.r
            data[i + 1] = newColor.g
            data[i + 2] = newColor.b
            // data[i + 3] = newColor.a
        }

        return imageData
    }

    static setLightnessImage(image: HTMLImageElement, args: any): HTMLImageElement {
        return this.dataToImage(this.setLightnessData(this.imageToData(image), args))
    }

    static invert(imageData: ImageData, args: any) {
        const w = imageData.width
        const h = imageData.height
        for(let y = 0; y < h; ++y) {
            for(let x = 0; x < w; ++x) {
                const i = y * w * 4 + x * 4
                // set new color
                imageData.data[i + 0] = 255 - imageData.data[i + 0]
                imageData.data[i + 1] = 255 - imageData.data[i + 1]
                imageData.data[i + 2] = 255 - imageData.data[i + 2]
                imageData.data[i + 3] = imageData.data[i + 3]
            }
        }
    }

    static darken(imageData: ImageData, args: any) {
        const factor = args.factor
        assert(factor >= 0 && factor <= 1, `darken: factor must be 0 - 1, got ${factor}`)
        const w = imageData.width
        const h = imageData.height
        for(let y = 0; y < h; ++y) {
            for(let x = 0; x < w; ++x) {
                const i = y * w * 4 + x * 4
                let r = imageData.data[i + 0]
                let g = imageData.data[i + 1]
                let b = imageData.data[i + 2]
                let a = imageData.data[i + 3]
                // darken rgba
                r = Math.round(r * factor)
                g = Math.round(g * factor)
                b = Math.round(b * factor)
                // set new color
                imageData.data[i + 0] = r
                imageData.data[i + 1] = g
                imageData.data[i + 2] = b
                imageData.data[i + 3] = a
            }
        }
    }

    /**
     * Args:
     * - times: number of times to apply the effect: 0 - 4
     */
    static rotate90(imageData: ImageData, args: any) {
        const w = imageData.width
        const h = imageData.height
        const times = args.times
        assert(times >= 0 && times <= 4, `rotate90: times must be 0 - 4, got ${times}`)
        if (times === 0 || times === 4) {
            return
        }

        for(let t = 0; t < times; ++t) {
            // create a new image
            const newImageData = new ImageData(h, w)
            // rotate
            for(let y = 0; y < h; ++y) {
                for(let x = 0; x < w; ++x) {
                    const i = y * w * 4 + x * 4
                    const j = x * h * 4 + (h - y - 1) * 4
                    // set new color
                    newImageData.data[j + 0] = imageData.data[i + 0]
                    newImageData.data[j + 1] = imageData.data[i + 1]
                    newImageData.data[j + 2] = imageData.data[i + 2]
                    newImageData.data[j + 3] = imageData.data[i + 3]
                }
            }

            // copy back
            for(let i = 0; i < newImageData.data.length; ++i) {
                imageData.data[i] = newImageData.data[i]
            }
        }
    }

    static flipX(imageData: ImageData, args: any) {
        const w = imageData.width
        const h = imageData.height
        for(let y = 0; y < h; ++y) {
            for(let x = 0; x < w / 2; ++x) {
                const i = y * w * 4 + x * 4
                const j = y * w * 4 + (w - x - 1) * 4
                // set new color
                const r = imageData.data[i + 0]
                const g = imageData.data[i + 1]
                const b = imageData.data[i + 2]
                const a = imageData.data[i + 3]
                imageData.data[i + 0] = imageData.data[j + 0]
                imageData.data[i + 1] = imageData.data[j + 1]
                imageData.data[i + 2] = imageData.data[j + 2]
                imageData.data[i + 3] = imageData.data[j + 3]
                imageData.data[j + 0] = r
                imageData.data[j + 1] = g
                imageData.data[j + 2] = b
                imageData.data[j + 3] = a
            }
        }
    }

    static flipY(imageData: ImageData, args: any) {
        const w = imageData.width
        const h = imageData.height
        for(let y = 0; y < h / 2; ++y) {
            for(let x = 0; x < w; ++x) {
                const i = y * w * 4 + x * 4
                const j = (h - y - 1) * w * 4 + x * 4
                // set new color
                const r = imageData.data[i + 0]
                const g = imageData.data[i + 1]
                const b = imageData.data[i + 2]
                const a = imageData.data[i + 3]
                imageData.data[i + 0] = imageData.data[j + 0]
                imageData.data[i + 1] = imageData.data[j + 1]
                imageData.data[i + 2] = imageData.data[j + 2]
                imageData.data[i + 3] = imageData.data[j + 3]
                imageData.data[j + 0] = r
                imageData.data[j + 1] = g
                imageData.data[j + 2] = b
                imageData.data[j + 3] = a
            }
        }
    }

    static mapColors(imageData: ImageData, args: any) {
        const colorMap: { [key: string]: RGBA } = args.colorMap
        assert(colorMap !== undefined, `mapColors: colorMap is undefined`)
        const w = imageData.width
        const h = imageData.height
        // maps a RGBA to anotehr RGBA
        for(let y = 0; y < h; ++y) {
            for(let x = 0; x < w; ++x) {
                const i = y * w * 4 + x * 4
                const r = imageData.data[i + 0]
                const g = imageData.data[i + 1]
                const b = imageData.data[i + 2]
                const a = imageData.data[i + 3]
                const key = `${r},${g},${b},${a}`
                const newColor = colorMap[key]
                if (newColor) {
                    imageData.data[i + 0] = newColor.r
                    imageData.data[i + 1] = newColor.g
                    imageData.data[i + 2] = newColor.b
                    imageData.data[i + 3] = newColor.a
                }
            }
        }
    }

    static addNoise(imageData: ImageData, args: any): ImageData {
        // randomly perturbs each pixel by + or - 0.1 luminoisty using HSL
        const noiseStrength = args.noiseStrength || 0.02
        const noiseCoverage = args.noiseCoverage || 0.10
        const w = imageData.width
        const h = imageData.height
        // 2x2 noise pixelation
        const pixelSize = 2
        for(let y = 0; y < h; y += pixelSize) {
            for(let x = 0; x < w; x += pixelSize) {
                if (Math.random() > noiseCoverage) {
                    continue
                }
                const i = y * w * 4 + x * 4
                // get current color
                const r = imageData.data[i + 0]
                const g = imageData.data[i + 1]
                const b = imageData.data[i + 2]
                const a = imageData.data[i + 3]
                // convert to HSL
                const hsl = HSL.fromRGB(r, g, b)
                // perturb lightness
                const noise = Math.random() * noiseStrength * 2.0 - noiseStrength
                hsl.addLightness(noise)
                // convert back to RGBA
                const newColor = hsl.toRGBA()
                // set new color
                for(let dy = 0; dy < pixelSize; ++dy) {
                    for(let dx = 0; dx < pixelSize; ++dx) {
                        const j = (y + dy) * w * 4 + (x + dx) * 4
                        imageData.data[j + 0] = newColor.r
                        imageData.data[j + 1] = newColor.g
                        imageData.data[j + 2] = newColor.b
                        imageData.data[j + 3] = newColor.a
                    }
                }
            }
        }

        return imageData
    }

    static addNoiseImage(image: HTMLImageElement, args: any): HTMLImageElement {
        return this.dataToImage(this.addNoise(this.imageToData(image), args))
    }
}
