import { SimplexNoise2D } from './simplex-noise'

// ----------------------------------------------------------------------------
// World Info
// ----------------------------------------------------------------------------
export class FractalNoiseSampler {
    private noise: SimplexNoise2D | null = null
    private noiseFrequencies: {frequency: number, amplitude: number}[] = []

    scale = 3
    persistence = 0.5
    lacunarity = 2.0
    octaves = 6
    xyCoordScale = 256.0

    constructor(seed: string) {
        this.noise = new SimplexNoise2D(seed)
        this.initialize()
    }

    initialize() {
        this.noiseFrequencies = []
        // init noise frequencies
        for (let i = 0; i < this.octaves; i++) {
            this.noiseFrequencies.push({
                frequency: Math.pow(this.lacunarity, i) * this.scale,
                amplitude: Math.pow(this.persistence, i)
            })
        }
    }

    getNoise(x: number, y: number): number {
        const noise = this.noise!

        // Use frequency and amplitude to add multiple layers of noise
        let value = 0.0
        for (let i = 0; i < this.noiseFrequencies.length; i++) {
            let frequency = this.noiseFrequencies[i].frequency
            let amplitude = this.noiseFrequencies[i].amplitude

            value += noise.get(
                x / this.xyCoordScale * frequency,
                y / this.xyCoordScale * frequency
            ) * amplitude
        }

        // -1 to +1 -> 0 to 1
        value = (value + 1.0) / 2.0

        return Math.min(1, Math.max(0, value))
    }
}
