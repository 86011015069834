import { GameMap } from './game-map'
import { TileId, getTileId } from './tiles'
import { assert } from './assert'
import { ThingFactory } from './thing-factory'
import { randomChoice, isNumber, Vec2D } from './core'
import { Character } from "./character"
import { Thing } from './thing'
import { CharacterAI_RandomRoam } from "./ai"
import { gameState } from "./game-state"
// import { TextureUtils } from "./textures"
// --- Import Templates ---
import { getTemplateCastle } from './tpl-castle'

// TEMPLATE MANDATE
// [?] Large city management dictates that we need to be able to partially load/unload templates
//     or that large cities should be split into multiple templates -> YES THE SECOND ONE
// [?] A template should not be a whole city, it should be a single building.
// [?] Can we have VERY large building then? -> Sort of: you either split it (best) or deal with
//     the fact that it will be loaded/unloaded only when fully contained in the map.
//     - We could also automatically split it into multiple templates when it's too large at production time
//       but be careful of rotations and mirroring transformations then.

// -----------------------------------------------------------------------------
// TemplateInstructions
// Describes the content of a template
// -----------------------------------------------------------------------------
export class TemplateInstructions {
    name: string = 'NONAME'
    placeBlocks: {
        // 'blocks': string[]
        'blocks': any[]
        'tileMapping': any
    } = { blocks: [], tileMapping: {} }
    placeThings: any[] = []
    placeCharacters: any[] = []
    stats: { w: number, h: number } = { w: -1, h: -1 }
}

// -----------------------------------------------------------------------------
// TemplateInstructionsInstance
// Generated by the WorldGenerator and added to the WorldInfo upon world generation
// and keeps track of which templates are loaded and which are not and their state
// -----------------------------------------------------------------------------
// TODO: when we instanciate a template we must cleanup any 'Thing' that is in a tile which is not TileId.EMPTY
export class TemplateInstructionsInstance {
    instructions: TemplateInstructions | null = null
    wx: number = 0
    wy: number = 0

    instanceName: string = 'NONAME'

    // whether the template has been loaded or not
    loaded: boolean = false
    // instanced characters associated with this template
    characters: Character[] = []
    // instanced things associated with this template
    // things: Thing[] = []
}

// -----------------------------------------------------------------------------
// Template registry
// -----------------------------------------------------------------------------
const templateRegistry: { [key: string]: TemplateInstructions } = {}

// -----------------------------------------------------------------------------
// cloneStructureTemplate
// Returns a clone of the template so it can be used to create variations
// -----------------------------------------------------------------------------
export function cloneStructureTemplate(name: string): TemplateInstructions {
    assert(templateRegistry[name] !== undefined, `Template ${name} not found`)
    // deep clone
    const tpl = JSON.parse(JSON.stringify(templateRegistry[name]))
    return tpl
}

// -----------------------------------------------------------------------------
// initStructureTemplates
// Initializes the templateRegistry with all the templates and computes their stats
// -----------------------------------------------------------------------------
export function initStructureTemplates(): void {
    const castle = getTemplateCastle()
    assert(templateRegistry[castle.name] === undefined, `Template '${castle.name}' already exists`)
    templateRegistry[castle.name] = castle

    // convert strings to arrays of chars so we can write to it later for rotation and mirror
    stringToArrayBlocks(castle)

    // for each compute stats: computeWidthHeight()
    for (const key in templateRegistry) {
        const template = templateRegistry[key]
        template.stats = computeWidthHeight(template)
    }
}

// -----------------------------------------------------------------------------

/*
function computeBoundingSquare(template: TemplateInstructions): any {
    let minX = Infinity
    let minY = Infinity
    let maxX = -Infinity
    let maxY = -Infinity

    const placeBlocks = template.placeBlocks

    for (let y = 0; y < placeBlocks.blocks.length; y++) {
        for (let x = 0; x < placeBlocks.blocks[y].length; x++) {
            if (placeBlocks.blocks[y][x] !== ' ') {
                minX = Math.min(minX, x)
                minY = Math.min(minY, y)
                maxX = Math.max(maxX, x)
                maxY = Math.max(maxY, y)
            }
        }
    }

    return { minX, minY, maxX, maxY }
}
*/

function computeWidthHeight(template: TemplateInstructions): { w: number, h: number} {
    const placeBlocks = template.placeBlocks

    let w = 0
    let h = placeBlocks.blocks.length

    for (let y = 0; y < placeBlocks.blocks.length; y++) {
        w = Math.max(w, placeBlocks.blocks[y].length)
    }

    return { w, h }
}

function stringToArrayBlocks(template: TemplateInstructions) {
    const placeBlocks = template.placeBlocks

    const blocks = []
    for (let y = 0; y < placeBlocks.blocks.length; y++) {
        const row = []
        for (let x = 0; x < placeBlocks.blocks[y].length; x++) {
            row.push(placeBlocks.blocks[y][x])
        }
        blocks.push(row)
    }

    template.placeBlocks.blocks = blocks
}

// -----------------------------------------------------------------------------
// TemplatePainter
// Draws the given TemplateInstructionsInstance on the given GameMap
// -----------------------------------------------------------------------------
export class TemplatePainter {
    static drawTemplate_Structure(tplInstance: TemplateInstructionsInstance, map: GameMap): void {
        assert(tplInstance !== undefined, `Template ${name} not found`)
        const xOffset = tplInstance.wx
        const yOffset = tplInstance.wy
        const placeBlocks = tplInstance.instructions!.placeBlocks

        for (let y = 0; y < placeBlocks.blocks.length; y++) {
            for (let x = 0; x < placeBlocks.blocks[y].length; x++) {
                if (placeBlocks.blocks[y][x] !== ' ') {
                    const wx = x + xOffset
                    const wy = y + yOffset

                    // We remove and cleanup on purpose any Thing already present on non empty tiles of this template
                    // so we can load/unload cleanly using this simplistic approach that does not save user changes to
                    // chunks/things/characters on disk.
                    // TODO: we don't need to do this once we move to Minecraft style of saving on disk
                    // all the chunks and info, once they're loaded.
                    const oldThing = map.getThing(wx, wy)
                    if (oldThing !== null) {
                        assert(oldThing.x === wx && oldThing.y === wy, "oldThing.x === wx && oldThing.y === wy")
                        map.removeThing(oldThing)
                    }

                    const tileChar = placeBlocks.blocks[y][x]
                    let tileName = placeBlocks.tileMapping[tileChar] || 'ERROR'
                    if (Array.isArray(tileName)) {
                        tileName = randomChoice(tileName)
                    }
                    if (tileName === 'ERROR' || tileName.startsWith('Wall') || tileName.startsWith('Floor')) {
                        const tileId = getTileId(tileName)
                        assert(tileId !== -1, `tileId should not be -1 for char ${tileChar}`)
                        if (map.isInVirtualBounds(wx, wy)) {
                            map.set(wx, wy, tileId)
                        }
                    }
                }
            }
        }
    }

    static drawTemplate_Things(tplInstance: TemplateInstructionsInstance, map: GameMap): void {
        assert(tplInstance !== undefined, `Template ${name} not found`)
        const xOffset = tplInstance.wx
        const yOffset = tplInstance.wy
        const placeThings = tplInstance.instructions!.placeThings

        placeThings.forEach((thingInfo: any) => {
            const thingName = Array.isArray(thingInfo.name) ? randomChoice(thingInfo.name) : thingInfo.name
            const thing = ThingFactory.getThingInstance(thingName)
            const wx = thingInfo.x + xOffset
            const wy = thingInfo.y + yOffset
            map.placeThing(thing, wx, wy, thingInfo.floating)
        })
    }

    static drawTemplate_Characters(tplInstance: TemplateInstructionsInstance, map: GameMap): void {
        assert(tplInstance !== undefined, `Template ${name} not found`)
        const xOffset = tplInstance.wx
        const yOffset = tplInstance.wy
        const placeCharacters = tplInstance.instructions!.placeCharacters

        placeCharacters.forEach((characterInfo: any) => {
            const wx = characterInfo.x + xOffset
            const wy = characterInfo.y + yOffset
            const name = Array.isArray(characterInfo.name) ? randomChoice(characterInfo.name) : characterInfo.name
            const character = new Character
            character.setAI(new CharacterAI_RandomRoam(character, map))
            character.x = wx + 0.5
            character.y = wy + 0.5
            // const name = 'Monster-Lich-0' // 'Monster4-7' // `${sheet.name}-${getRandomInt(0, sheet.range)}`
            // const name = `${sheet.name}-${getRandomInt(0, sheet.range)}`
            console.log('New character:', name)
            character.setAnimationTextures(name)
            map.characters.push(character)
            // add to physics
            gameState().addToPhysics(character)
            // TEMPLATE INSTANCE TRACKS CHARACTERS TO CLEAN THEM UP
            // keep track of characters instanced by this template
            tplInstance.characters.push(character)
        })
    }
}

//-----------------------------------------------------------------------------
// Template Transformations: 90 degrees rotations, X and Y mirroring
//-----------------------------------------------------------------------------
type TemplateTransform = (v: Vec2D, w: number, h: number) => void

//-----------------------------------------------------------------------------
// TemplateUtils
// Various utilities to manipulate templates
//-----------------------------------------------------------------------------
export class TemplateUtils {
    static transform(template: TemplateInstructions, transformFunc: TemplateTransform): void {
        assert(template.stats.w !== 0 && template.stats.h !== 0, `Template ${name} invalid stats: ${template.stats.w}x${template.stats.h}`)
        const tw = template.stats.w
        const th = template.stats.h

        const tileMapping: any[] = []
        let newW = 0
        let newH = 0

        for (let y = 0; y < th; y++) {
            for (let x = 0; x < tw; x++) {
                const v = new Vec2D(x, y)
                transformFunc(v, tw, th)
                newW = Math.max(newW, v.x + 1)
                newH = Math.max(newH, v.y + 1)
                tileMapping.push({ vx: v.x, vy: v.y, x: x, y: y })
            }
        }

        // allocate new blocks based on newW and newH
        const newBlocks: any[] = []
        for (let y = 0; y < newH; y++) {
            let row = new Array(newW).fill(' ')
            newBlocks.push(row)
        }

        // fill new blocks
        tileMapping.forEach((tile: any) => {
            const value = template.placeBlocks.blocks[tile.y][tile.x]
            assert(value !== undefined, `Invalid tile mapping: ${tile.x},${tile.y}`)
            newBlocks[tile.vy][tile.vx] = value
        })

        template.placeBlocks.blocks = newBlocks

        // iterate characters
        for (let i = 0; i < template.placeCharacters.length; i++) {
            const c = template.placeCharacters[i]
            const v = new Vec2D(c.x, c.y)
            transformFunc(v, tw, th)
            c.x = v.x
            c.y = v.y
        }

        // iterate items
        for (let i = 0; i < template.placeThings.length; i++) {
            const c = template.placeThings[i]
            const v = new Vec2D(c.x, c.y)
            transformFunc(v, tw, th)
            c.x = v.x
            c.y = v.y
        }

        // update stats w and h change due to transform
        template.stats.w = newW
        template.stats.h = newH
    }

    static rotateVec90(v: Vec2D, w: number, h: number) {
        const x = v.x
        v.x = h - 1 - v.y
        v.y = x
    }

    static rotateVec180(v: Vec2D, w: number, h: number) {
        v.x = w - 1 -v.x
        v.y = h - 1 -v.y
    }

    static rotateVec270(v: Vec2D, w: number, h: number) {
        const x = v.x
        v.x = v.y
        v.y = w - 1 -x
    }

    static mirrorY(v: Vec2D, w: number, h: number) {
        v.y = h - 1 - v.y
    }

    static mirrorX(v: Vec2D, w: number, h: number) {
        v.x = w - 1 - v.x
    }
}
