import { MapChunk, GameMap } from "./game-map";
import { SimplexNoise2D } from './simplex-noise'
import { Config } from './config';
import { assert } from "./assert";
import { ThingFactory } from "./thing-factory"
import { randomChoice, getRandomInt, Vec2D } from "./core";
import { TileInfo, TileType, getTileInfo, getTileId, TileId } from "./tiles"
import { Character } from "./character"
import { CharacterAI_RandomRoam } from "./ai"
import { gameState } from "./game-state"
import { TextureUtils } from "./textures"
import { TemplateInstructions, TemplateInstructionsInstance } from './structure-templates'
import { cloneStructureTemplate } from './structure-templates'
import { FractalNoiseSampler } from './fractal-noise-sampler'
import { WorldInfo, WorldGenerator } from './world-generator'

// ----------------------------------------------------------------------------
// Chunk Generator
// ----------------------------------------------------------------------------
export class ChunkGenerator {
    worldInfo: WorldInfo | null = null

    constructor() {
    }

    // generateChunk(map: GameMap, chunk: MapChunk, cx: number, cy: number) {}
    generateChunk_Structure(map: GameMap, chunk: MapChunk, cx: number, cy: number) {}
    generateChunk_Things(map: GameMap, chunk: MapChunk, cx: number, cy: number) {}
    generateChunk_Characters(map: GameMap, chunk: MapChunk, cx: number, cy: number) {}

    // returns a list of templates to be loaded and to be unloaded
    checkTemplatesStatus(bounds: { vx: number, vy: number, vw: number, vh: number }): { load: TemplateInstructionsInstance[], unload: TemplateInstructionsInstance[] } {
        const worldMin: Vec2D = new Vec2D(bounds.vx, bounds.vy)
        const worldMax: Vec2D = new Vec2D(bounds.vx + bounds.vw - 1, bounds.vy + bounds.vh - 1)
        const res: { load: TemplateInstructionsInstance[], unload: TemplateInstructionsInstance[] } = { load: [], unload: [] }
        const worldInfo = this.worldInfo!
        const instances = worldInfo.templateInstances
        instances.forEach((instance) => {
            const tplMin: Vec2D = new Vec2D(instance.wx, instance.wy)
            const tplMax: Vec2D = new Vec2D(instance.wx + instance.instructions!.stats.w - 1, instance.wy + instance.instructions!.stats.h - 1)
            // check if FULLY inside
            const fullyInside = tplMin.x >= worldMin.x && tplMin.y >= worldMin.y && tplMax.x <= worldMax.x && tplMax.y <= worldMax.y
            if (fullyInside === true && instance.loaded === false) {
                res.load.push(instance)
            } else
            if (fullyInside === false && instance.loaded === true) {
                res.unload.push(instance)
            }
        })

        return res
    }
}

// ----------------------------------------------------------------------------
// Chunk Generator Test
// ----------------------------------------------------------------------------
export class ChunkGeneratorTest extends ChunkGenerator {
    constructor() {
        super();
    }

    generateChunk_Structure(map: GameMap, chunk: MapChunk, cx: number, cy: number) {
        const tiles = [
            TileId.FLOOR_DIRT,
            TileId.FLOOR_CAVE,
            TileId.FLOOR_GRASS,
            TileId.FLOOR_ROAD,
            TileId.FLOOR_SAND,
            TileId.FLOOR_SNOW,
            TileId.FLOOR_SWAMP,
            TileId.FLOOR_STONE,
            TileId.FLOOR_WOOD,
            // TileId.FLOOR_ICE,
        ]

        // const tileIndex = (cx + cy) % tiles.length
        const tileIndex = (cx + cy * 3) % tiles.length

        for (let x = 0; x < MapChunk.CHUNK_SIZE; x++) {
            for (let y = 0; y < MapChunk.CHUNK_SIZE; y++) {
                if (Math.random() < 0.9) {
                    // chunk.tileMap[y * MapChunk.CHUNK_SIZE + x] = tiles[tileIndex]
                    chunk.setTile(x, y, tiles[tileIndex])
                } else {
                    // chunk.tileMap[y * MapChunk.CHUNK_SIZE + x] = TileId.WALL_ROCK
                    chunk.setTile(x, y, TileId.WALL_ROCK)
                }
            }
        }
    }
}

// ----------------------------------------------------------------------------
// Chunk Generator Simplex
// ----------------------------------------------------------------------------
/**
 * The chunk generator works with and is steup by the WorldGenerator which configures
 * the chunk generator to render out the world as intended by the WorldGenerator.
 *
 * Is WorldGenerator just an implementation of ChunkGenerator
 */
export class ChunkGeneratorSimplex extends ChunkGenerator {
    generateChunk_Structure(map: GameMap, chunk: MapChunk, cx: number, cy: number) {
        const fractal = this.worldInfo!.fractalNoise!
        for (let x = 0; x < MapChunk.CHUNK_SIZE; x++) {
            for (let y = 0; y < MapChunk.CHUNK_SIZE; y++) {
                const wx = cx * MapChunk.CHUNK_SIZE + x
                const wy = cy * MapChunk.CHUNK_SIZE + y
                const noise = fractal.getNoise(wx, wy)
                // assert(noise >= 0 && noise <= 1, "noise should be between 0 and 1")
                let tile = TileId.EMPTY
                if (noise > 0.95) { tile = TileId.WALL_STONE_INT } else
                if (noise > 0.9) { tile = TileId.WALL_BRICK_INT } else
                if (noise > 0.8) { tile = TileId.FLOOR_STONE } else
                if (noise > 0.7) { tile = TileId.FLOOR_CAVE } else
                if (noise > 0.6) { tile = TileId.FLOOR_DIRT } else
                if (noise > 0.4) { tile = TileId.FLOOR_GRASS } else
                if (noise > 0.3) { tile = TileId.FLOOR_SAND } else
                if (noise > 0.2) { tile = TileId.FLOOR_WATER_SHALLOW } else
                if (noise > 0.1) { tile = TileId.FLOOR_WATER_RIVER } else
                tile = TileId.FLOOR_WATER_SEA
                chunk.setTile(x, y, tile)
            }
        }
    }

    generateChunk_Things(map: GameMap, chunk: MapChunk, cx: number, cy: number) {
        const treeNames1 = [
            'Tree-Pink-1',
            'Tree-Pink-2',
            'Tree-Pink-3',
            'Tree-Pink-4',
        ]
        const treeNames2 = [
            'Tree-Green1-1',
            'Tree-Green1-2',
            'Tree-Green1-3',
            'Tree-Green1-4',
        ]
        const treeNames3 = [
            'Tree-Green2-1',
            'Tree-Green2-2',
            'Tree-Green2-3',
            'Tree-Green2-4',
        ]
        const treeNames4 = [
            'Tree-Green3-1',
            'Tree-Green3-2',
            'Tree-Green3-3',
            'Tree-Green3-4',
        ]

        for (let x = 0; x < MapChunk.CHUNK_SIZE; x++) {
            for (let y = 0; y < MapChunk.CHUNK_SIZE; y++) {
                const wx = cx * MapChunk.CHUNK_SIZE + x
                const wy = cy * MapChunk.CHUNK_SIZE + y
                const tile = chunk.getTile(x, y)
                let thing = null
                // add trees
                if (tile === TileId.FLOOR_STONE && Math.random() < 0.025) {
                    thing = ThingFactory.getThingInstance(randomChoice(treeNames1))
                } else
                if (tile === TileId.FLOOR_DIRT && Math.random() < 0.05) {
                    thing = ThingFactory.getThingInstance(randomChoice(treeNames2))
                } else
                if (tile === TileId.FLOOR_GRASS && Math.random() < 0.10) {
                    thing = ThingFactory.getThingInstance(randomChoice(treeNames3))
                } else
                if (tile === TileId.FLOOR_SAND && Math.random() < 0.05) {
                    thing = ThingFactory.getThingInstance(randomChoice(treeNames4))
                }

                if (thing) {
                    chunk.placeThing(thing, wx, wy, x, y, Math.random() > 0.9)
                }
            }
        }
    }

    generateChunk_Characters(map: GameMap, chunk: MapChunk, cx: number, cy: number) {
        const characters = map.characters
        const sheet = randomChoice([
            // { name: 'Chara6', range: 7 },
            // { name: 'Chara7', range: 7 },
            // { name: 'Chara8', range: 7 },
            // { name: 'Npc5', range: 7 },
            // { name: 'Npc6', range: 7 },
            { name: 'Orc1', range: 7 },
            // { name: 'Orc2', range: 7 },
            { name: 'Monster1', range: 7 },
            { name: 'Monster2', range: 7 },
            { name: 'Monster3', range: 7 },
            { name: 'Monster4', range: 7 },
            // { name: 'Horse', range: 7 },
            // { name: 'Mount1', range: 7 },
            // { name: 'Mount2', range: 7 },
            // { name: 'Elemental', range: 3 },
            // { name: 'Wizard', range: 3 },
            // { name: 'Monster-DNight1', range: 0 },
            // { name: 'Monster-DNight2', range: 0 },
            // { name: 'Monster-Bird1', range: 0 },
            // { name: 'Monster-Bird2', range: 0 },
            // { name: 'Monster-Bird3', range: 0 },
            // { name: 'Monster-Boar', range: 0 },
            // { name: 'Monster-Cacto', range: 0 },
            // { name: 'Monster-Elk', range: 0 },
            // { name: 'Monster-Golem1', range: 0 },
            // { name: 'Monster-Golem2', range: 0 },
            // { name: 'Monster-Lich', range: 0 },
            // { name: 'Monster-Lizardman1', range: 0 },
            // { name: 'Monster-Lizardman2', range: 0 },
            // { name: 'Monster-Minotaur', range: 0 },
            // { name: 'Monster-Phoenix', range: 0 },
            // { name: 'Monster-Raptor1', range: 0 },
            // { name: 'Monster-Raptor2', range: 0 },
            // { name: 'Monster-Treant', range: 0 },
            // { name: 'Monster-Wolf1', range: 0 },
            // { name: 'Monster-Wolf2', range: 0 },
        ])
        for (let x = 0; x < MapChunk.CHUNK_SIZE; x++) {
            for (let y = 0; y < MapChunk.CHUNK_SIZE; y++) {
                const wx = cx * MapChunk.CHUNK_SIZE + x
                const wy = cy * MapChunk.CHUNK_SIZE + y
                const tile = chunk.getTile(x, y)
                const thing = chunk.thingMap[y * MapChunk.CHUNK_SIZE + x]
                // add characters
                const tileInfo = getTileInfo(tile)
                if (thing === null && tileInfo.canWalkThrough) {
                    if (tile === TileId.FLOOR_GRASS && Math.random() < 0.0025) {
                        const character = new Character

                        character.setAI(new CharacterAI_RandomRoam(character, map))
                        character.x = wx + 0.5
                        character.y = wy + 0.5
                        // const name = 'Monster-Lich-0' // 'Monster4-7' // `${sheet.name}-${getRandomInt(0, sheet.range)}`
                        const name = `${sheet.name}-${getRandomInt(0, sheet.range)}`
                        console.log('New character:', name)
                        character.setAnimationTextures(name)
                        characters.push(character)
                        // add to physics
                        gameState().addToPhysics(character)
                    }
                }
            }
        }
    }
}
