///////////////////////////////////////////////////////////////////////////////
// Storage
///////////////////////////////////////////////////////////////////////////////
export class Storage {
    private data: { [key: string]: any };

    constructor(private storageRoot: string) {
        this.data = {};
    }

    init(defaultData: any): void {
        this.data = window.localStorage.getItem(this.storageRoot)
            ? JSON.parse(window.localStorage.getItem(this.storageRoot) as string)
            : defaultData;

        console.log('Storage::init', this.data);
    }

    get(name: string, def: any): any {
        return this.data[name] !== undefined ? this.data[name] : def;
    }

    set(name: string, value: any): void {
        this.data[name] = value;
        window.localStorage.setItem(this.storageRoot, JSON.stringify(this.data));
    }

    del(name: string): void {
        delete this.data[name];
        window.localStorage.setItem(this.storageRoot, JSON.stringify(this.data));
    }
}
