import { createNoise2D, createNoise3D, createNoise4D } from 'simplex-noise';

export class SimplexNoise2D {
    public seed: any
    public noise2D: any

    constructor(seed: any) {
        const self = this
        if (seed) {
            const PRNG = new isaacCSPRNG(String(seed))
            self.seed = seed
            self.noise2D = createNoise2D(PRNG.random)
        } else {
            self.noise2D = createNoise2D()
        }
    }

    get(x: any, y: any) {
        return this.noise2D(x, y)
    }
}

export class SimplexNoise3D {
    public seed: any
    public noise3D: any

    constructor(seed: any) {
        const self = this
        if (seed) {
            const PRNG = new isaacCSPRNG(String(seed))
            self.seed = seed
            self.noise3D = createNoise3D(PRNG.random)
        } else {
            self.noise3D = createNoise3D()
        }
    }

    get(x: any, y: any, z: any) {
        return this.noise3D(x, y, z)
    }
}

export class SimplexNoise4D {
    public seed: any
    public noise4D: any

    constructor(seed: any) {
        const self = this
        if (seed) {
            const PRNG = new isaacCSPRNG(String(seed))
            self.seed = seed
            self.noise4D = createNoise4D(PRNG.random)
        } else {
            self.noise4D = createNoise4D()
        }
    }

    get(x: any, y: any, z: any, w: any) {
        return this.noise4D(x, y, z, w)
    }
}

// //////////////////////////////////////////////
// // SimplexNoise3D
// //////////////////////////////////////////////
// const SimplexNoise3D = function (seed) {
//     const self = this
//     if (seed) {
//         const PRNG = new isaacCSPRNG(seed + '')
//         self.seed = seed
//         self.noise3D = createNoise3D(PRNG.random)
//     } else {
//         self.noise3D = createNoise3D()
//     }
// }

// SimplexNoise3D.prototype.get = function (x, y) {
//     const self = this
//     return self.noise3D(x, y)
// }

// //////////////////////////////////////////////
// // SimplexNoise4D
// //////////////////////////////////////////////
// const SimplexNoise4D = function (seed) {
//     const self = this
//     if (seed) {
//         const PRNG = new isaacCSPRNG(seed + '')
//         self.seed = seed
//         self.noise4D = SimplexNoise4D(PRNG.random)
//     } else {
//         self.noise4D = SimplexNoise4D()
//     }
// }

// SimplexNoise4D.prototype.get = function (x, y) {
//     const self = this
//     return self.noise2D(x, y)
// }

