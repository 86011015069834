import { TemplateInstructions } from './structure-templates';

// -----------------------------------------------------------------------------
// Template Castle
// -----------------------------------------------------------------------------
export function getTemplateCastle(): TemplateInstructions {
    return {
        name: 'Castle',

        placeBlocks: {
            blocks: [
                ' oooooooo      oooooooo ',
                ' oXX--XXo      oXXXXXXo ',
                ' oX----Xo      oX----Xo ',
                ' oX----XooooooooX----Xo ',
                ' oX----XXXXXXXXXX----Xo ',
                ' oX----X--------X----Xo ',
                ' oXXXX-X--------X-XXXXo ',
                ' ooooX---======---Xoooo ',
                '    oX---=@@@@=---Xo    ',
                'oooooX---=@@@@=---Xooooo',
                'oXXXXX---=@@@@=---XXXXXo',
                'oX^^^----======----^^^Xo',
                'oX^^^X-----==-----X^^^Xo',
                'oX^^^X-----==-----X^^^Xo',
                'oXXXXX-----==-----XXXXXo',
                'oooooX---XX==XX---Xooooo',
                '    oX---XooooX---Xo    ',
                '    oX---XooooX---Xo    ',
                '    ooXXXooooooXXXoo    ',
                '    oooooooooooooooo    ',
                '     ++++++++++++++     ',
                '      ++++++++++++      ',
                '       ++++++++++       ',
                // '         +++++++        ',
            ],

            tileMapping: {
                ' ': 'EMPTY',
                'X': 'Wall-8-016', // Wall-BRICK_EXT
                'Y': 'Wall-STONE_INT',
                '+': 'Floor-DIRT',
                '-': [
                    'Floor-8-016',
                    'Floor-8-017',
                    'Floor-8-018',
                    'Floor-8-019',
                    'Floor-8-020',
                    'Floor-8-021',
                ],
                '@': 'Floor-DIRT', // Floor-8-052',
                '=': 'Floor-SWAMP', // 'Floor-8-039',
                '^': 'Floor-8-040',
                'o': 'Floor-WATER_RIVER',
            },
        },

        placeCharacters: [
            { name: 'Monster-Lich-0', x: 10, y: 7 },
            { name: 'Orc1-4', x: 11, y: 7 },
            { name: 'Wizard-1', x: 12, y: 7 },
        ],

        placeThings: [
            { name: 'Armery', x: 6, y: 2, floating: false },
            { name: 'Armery', x: 5, y: 2, floating: false },
            { name: 'Armery', x: 4, y: 2, floating: false },
            { name: 'Armery', x: 3, y: 2, floating: false },

            { name: ['Shield 1', 'Shield 2', 'Shield 3', 'Shield 4'], x: 3, y: 3, floating: true },
            { name: ['Chestplate 1', 'Chestplate 2', 'Chestplate 3', 'Chestplate 4'], x: 4, y: 3, floating: true },
            { name: ['Boots 1', 'Boots 2', 'Boots 3', 'Boots 4'], x: 5, y: 3, floating: true },
            { name: ['Helmet 1', 'Helmet 2', 'Helmet 3', 'Helmet 4'], x: 6, y: 3, floating: true },

            { name: 'Treasure', x: 17, y: 2, floating: false },
            { name: 'Treasure', x: 18, y: 2, floating: false },
            { name: 'Treasure', x: 19, y: 2, floating: false },
            { name: 'Treasure', x: 20, y: 2, floating: false },

            { name: ['Ring 1', 'Neclace 1', 'Staff 1', 'Watering Can', 'Fishing Rod', 'Scissors', 'Ore | Iron', 'Ingott | Iron', 'Ore | Gold', 'Ingott | Gold'], x: 17, y: 3, floating: true },
            { name: ['Ring 1', 'Neclace 1', 'Staff 1', 'Watering Can', 'Fishing Rod', 'Scissors', 'Ore | Iron', 'Ingott | Iron', 'Ore | Gold', 'Ingott | Gold'], x: 18, y: 3, floating: true },
            { name: ['Ring 1', 'Neclace 1', 'Staff 1', 'Watering Can', 'Fishing Rod', 'Scissors', 'Ore | Iron', 'Ingott | Iron', 'Ore | Gold', 'Ingott | Gold'], x: 19, y: 3, floating: true },
            { name: ['Ring 1', 'Neclace 1', 'Staff 1', 'Watering Can', 'Fishing Rod', 'Scissors', 'Ore | Iron', 'Ingott | Iron', 'Ore | Gold', 'Ingott | Gold'], x: 20, y: 3, floating: true },

            { name: 'Pickaxe | Iron', x: 9, y: 5, floating: true },
            { name: 'Sword | Iron', x: 10, y: 5, floating: true },
            { name: 'Shovel | Iron', x: 11, y: 5, floating: true },
            { name: 'Axe | Iron', x: 12, y: 5, floating: true },
            { name: 'Hoe | Iron', x: 13, y: 5, floating: true },

            { name: 'Door', x: 6, y: 6, floating: false },
            { name: 'Door', x: 17, y: 6, floating: false },

            { name: 'Door', x: 5, y: 11, floating: false },
            { name: 'Door', x: 18, y: 11, floating: false },

            { name: 'Door', x: 11, y: 15, floating: false },
            { name: 'Door', x: 12, y: 15, floating: false },

            { name: 'StairsDown', x: 2, y: 13, floating: false },
            { name: 'StairsDown', x: 21, y: 13, floating: false },

            { name: 'Bridge', x: 11, y: 16, floating: false },
            { name: 'Bridge', x: 11, y: 17, floating: false },
            { name: 'Bridge', x: 11, y: 18, floating: false },
            { name: 'Bridge', x: 11, y: 19, floating: false },
            { name: 'Bridge', x: 11, y: 20, floating: false },
            { name: 'Bridge', x: 12, y: 16, floating: false },
            { name: 'Bridge', x: 12, y: 17, floating: false },
            { name: 'Bridge', x: 12, y: 18, floating: false },
            { name: 'Bridge', x: 12, y: 19, floating: false },
            { name: 'Bridge', x: 12, y: 20, floating: false },
        ],

        stats: { w: 0, h: 0 }
    }
}
