import { Character } from './character'
import { isValidPosition } from './character'
import { Config } from './config'
import { getRandomInt } from './core'
import { GameMap } from './game-map'
// import { TileCategory } from './enums'

function simplyfyPath(path: Array<{ x: number, y: number }>): Array<{ x: number, y: number }> {
    if (path.length < 3) {
        return path
    }
    // remove points that are on the same straight line compared to the previous point and the next point
    const newPath: Array<{ x: number, y: number }> = []
    newPath.push({ x: path[0].x, y: path[0].y })
    for (let i = 1; i < path.length - 1; ++i) {
        const p = path[i]
        const prev = path[i - 1]
        const next = path[i + 1]
        if (p.x == (prev.x + next.x) / 2 && p.y == prev.y && p.y == next.y) {
            continue
        } else
        if (p.y == (prev.y + next.y) / 2 && p.x == prev.x && p.x == next.x) {
            continue
        } else {
            newPath.push({ x: p.x, y: p.y })
        }

    }
    newPath.push({ x: path[path.length - 1].x, y: path[path.length - 1].y })
    return newPath
}

/**
 * Path finding algorithm for any character on any floor, using Dijkstra's algorithm (or A*)
 */
export function findPath(map: GameMap, char: Character, tx: number, ty: number): Array<{ x: number, y: number }> | null {
    const x = char.x
    const y = char.y

    //*
    const dijkstra = new Dijkstra(map)
    dijkstra.computeTarget(x, y, tx, ty)
    // center the path on the tile
    const path = simplyfyPath(dijkstra.getPath(tx, ty)).map(function (p) {
        return {
            // add random offset to avoid characters walking on the same path
            x: p.x + 0.5 + getRandomInt(-100, 100) / 100.0 * 0.01,
            y: p.y + 0.5 + getRandomInt(-100, 100) / 100.0 * 0.01
        }
    })
    //*/

    /*
    const astar = new AStar(floor)
    astar.compute(x, y, tx, ty)
    // center the path on the tile
    const path = simplyfyPath(astar.getPath(tx, ty)).map(function (p) {
        return {
            // add random offset to avoid characters walking on the same path
            x: p.x + 0.5 + getRandomInt(-100, 100) / 100.0 * 0.01,
            y: p.y + 0.5 + getRandomInt(-100, 100) / 100.0 * 0.01
        }
    })
    //*/


    if (path.length === 1 || path.length === 0) {
        // console.log('findPath: no path found')
        return null
    }

    // restore original starting position
    path[0].x = x
    path[0].y = y

    // mark path on map with dirt
    // for (const { x, y } of path) {
    //     map.set(x, y, TileId.FLOOR_DIRT)
    // }

    // const ai: CharacterAI_PathFind = new CharacterAI_PathFind(char, floor, path)
    // char.setAI(ai)
    char.setPath(path)
    char.physSpeed = Config.PLAYER_SPEED

    return path
}

// ----------------------------------------------------------------------------
// PathFindAlgorithm
// ----------------------------------------------------------------------------
class PathFindAlgorithm {
    protected map: GameMap
    protected minX: number = 0
    protected minY: number = 0
    protected maxX: number = 0
    protected maxY: number = 0
    protected queue: Array<{ x: number, y: number, d: number, h: number }> = []
    protected visited: any
    protected parent: any
    protected distance: any
    protected maxDistance: number = 0
    protected maxDistanceX: number = 0
    protected maxDistanceY: number = 0

    constructor(map: GameMap) {
        this.map = map
        const { vx, vy, vw, vh } = map!.getVirtualBounds()
        this.minX = vx
        this.minY = vy
        this.maxX = vx + vw - 1
        this.maxY = vy + vh - 1
        this.resetVectors()
    }

    protected resetVectors(): void {
        this.queue = []
        this.visited = {}
        this.parent = {}
        this.distance = {}
        this.maxDistance = 0
        this.maxDistanceX = 0
        this.maxDistanceY = 0
    }

    protected makeKey(x: number, y: number): string {
        return `${x},${y}`
    }

    public getPath(x: number, y: number): Array<{ x: number, y: number }> {
        x = Math.floor(x)
        y = Math.floor(y)
        const path: Array<{ x: number, y: number }> = []
        let i: string | null = this.makeKey(x, y)
        while (i !== null) {
            path.push({ x, y })
            const p = this.parent[i]
            if (p) {
                x = p.x
                y = p.y
                i = this.makeKey(x, y)
            } else {
                i = null
            }
        }
        path.reverse()
        return path
    }

    // public getDistance(x: number, y: number): number {
    //     return this.distance[this.makeKey(x, y)]
    // }

    // public getMaxDistance(): number {
    //     return this.maxDistance
    // }

    // public getMaxDistanceX(): number {
    //     return this.maxDistanceX
    // }

    // public getMaxDistanceY(): number {
    //     return this.maxDistanceY
    // }
}

// ----------------------------------------------------------------------------
// A* Algorithm
// ----------------------------------------------------------------------------
// turn the above Dijsktra class into A* by adding a heuristic
// Example use:
// const astar = new AStar(map)
// astar.compute(x, y, tx, ty)
// const path = astar.getPath(x, y)
// console.log('Path:', path)
// console.log('Distance:', astar.getDistance(x, y))
class AStar extends PathFindAlgorithm {
    constructor(map: GameMap) {
        super(map)
    }

    public compute(x: number, y: number, tx: number, ty: number): void {
        this.resetVectors()
        let stepCount = 0

        x = Math.floor(x)
        y = Math.floor(y)
        tx = Math.floor(tx)
        ty = Math.floor(ty)

        this.queue.push({ x, y, d: 0, h: this.heuristic(x, y, tx, ty) })
        while (this.queue.length > 0) {
            ++stepCount
            const { x, y, d } = this.queue.shift()!
            const key = this.makeKey(x, y)
            if (this.visited[key]) {
                continue
            }
            this.visited[key] = { x, y }
            this.distance[key] = d
            if (d > this.maxDistance) {
                this.maxDistance = d
                this.maxDistanceX = x
                this.maxDistanceY = y
            }
            if (x === tx && y === ty) {
                break
            }
            const offsets = [
                { x: 0, y: -1 },
                { x: 0, y: 1 },
                { x: 1, y: 0 },
                { x: -1, y: 0 },
            ]
            for (const offset of offsets) {
                const nx = x + offset.x
                const ny = y + offset.y
                if (nx < this.minX || nx > this.maxX || ny < this.minY || ny > this.maxY) {
                    continue
                }
                // if (getTileInfo(this.map.get(nx, ny)).level !== TileLevel.FLOOR) {
                //     continue
                // }
                if (!isValidPosition(this.map, nx, ny, null)) {
                    continue
                }
                const i = this.makeKey(nx, ny)
                if (this.visited[i]) {
                    continue
                }
                this.queue.push({ x: nx, y: ny, d: d + 1, h: this.heuristic(nx, ny, tx, ty) })
                this.parent[i] = { x, y }
            }
            this.queue.sort((a, b) => a.h - b.h)
        }
        console.log('AStar.compute', stepCount)
    }

    private heuristic(x: number, y: number, tx: number, ty: number): number {
        // Manhattan distance
        return Math.abs(x - tx) + Math.abs(y - ty)
    }
}

// ----------------------------------------------------------------------------
// Dijkstra Algorithm
// ----------------------------------------------------------------------------
// implement Dijkstra's algorithm for floor map give x and y tile coordinates
// Example use to check path between x1,y1 and x2,y2:
// const dijkstra = new Dijkstra(map)
// dijkstra.compute(x1, y1)
// const path = dijkstra.getPath(x2, y2)
// console.log('Path:', path)
// console.log('Distance:', dijkstra.getDistance(x2, y2))
class Dijkstra extends PathFindAlgorithm {
    constructor(map: GameMap) {
        super(map)
    }

    // Same as compute() but stops when the target is reached
    public computeTarget(x: number, y: number, tx: number, ty: number): void {
        this.resetVectors()
        let stepCount = 0

        x = Math.floor(x)
        y = Math.floor(y)
        tx = Math.floor(tx)
        ty = Math.floor(ty)

        this.queue.push({ x, y, d: 0, h: -1 }) // h = not used in Dijkstra
        while (this.queue.length > 0) {
            ++stepCount
            const { x, y, d } = this.queue.shift()!
            const key = this.makeKey(x, y)
            if (this.visited[key]) {
                continue
            }
            this.visited[key] = { x, y }
            this.distance[key] = d
            if (d > this.maxDistance) {
                this.maxDistance = d
                this.maxDistanceX = x
                this.maxDistanceY = y
            }
            // Stop when the destination is reached
            if (x === tx && y === ty) {
                break;
            }
            const offsets = [
                { x: 0, y: -1 },
                { x: 0, y: 1 },
                { x: 1, y: 0 },
                { x: -1, y: 0 },
            ]
            for (const offset of offsets) {
                const nx = x + offset.x
                const ny = y + offset.y
                if (nx < this.minX || nx > this.maxX || ny < this.minY || ny > this.maxY) {
                    continue
                }
                // if (getTileInfo(this.map.get(nx, ny)).level !== TileLevel.FLOOR) {
                //     continue
                // }
                if (!isValidPosition(this.map, nx, ny, null)) {
                    continue
                }
                const i = this.makeKey(nx, ny)
                if (this.visited[i]) {
                    continue
                }
                this.queue.push({ x: nx, y: ny, d: d + 1, h: -1 }) // h = not used in Dijkstra
                this.parent[i] = { x, y }
            }
        }
    }

    public compute(x: number, y: number): void {
        this.resetVectors()

        let stepCount = 0

        x = Math.floor(x)
        y = Math.floor(y)

        this.queue.push({ x, y, d: 0, h: -1 }) // h = not used in Dijkstra
        while (this.queue.length > 0) {
            ++stepCount
            const { x, y, d } = this.queue.shift()!
            const key = this.makeKey(x, y)
            if (this.visited[key]) {
                continue
            }
            this.visited[key] = { x, y }
            this.distance[key] = d
            if (d > this.maxDistance) {
                this.maxDistance = d
                this.maxDistanceX = x
                this.maxDistanceY = y
            }
            const offsets = [
                { x: 0, y: -1 },
                { x: 0, y: 1 },
                { x: 1, y: 0 },
                { x: -1, y: 0 },
            ]
            for (const offset of offsets) {
                const nx = x + offset.x
                const ny = y + offset.y
                if (nx < this.minX || nx > this.maxX || ny < this.minY || ny > this.maxY) {
                    continue
                }
                // if (getTileInfo(this.map.get(nx, ny)).level !== TileLevel.FLOOR) {
                //     continue
                // }
                if (!isValidPosition(this.map, nx, ny, null)) {
                    continue
                }
                const i = this.makeKey(nx, ny)
                if (this.visited[i]) {
                    continue
                }
                this.queue.push({ x: nx, y: ny, d: d + 1, h: -1 }) // h = not used in Dijkstra
                this.parent[i] = { x, y }
            }
        }
    }
}
