import { MapChunk, GameMap } from "./game-map";
import { SimplexNoise2D } from './simplex-noise'
import { Config } from './config';
import { assert } from "./assert";
import { ThingFactory } from "./thing-factory"
import { randomChoice, getRandomInt, Vec2D } from "./core";
import { TileInfo, TileType, getTileInfo, getTileId, TileId } from "./tiles"
import { Character } from "./character"
import { CharacterAI_RandomRoam } from "./ai"
import { gameState } from "./game-state"
import { TextureUtils } from "./textures"
import { TemplateInstructions, TemplateInstructionsInstance, TemplateUtils } from './structure-templates'
import { cloneStructureTemplate } from './structure-templates'
import { FractalNoiseSampler } from './fractal-noise-sampler'

// ----------------------------------------------------------------------------
// World Info
// ----------------------------------------------------------------------------
/**
 * The world info is a data structure that contains all the information about the
 * world that is needed to generate the world map and its contents.
 */
export class WorldInfo {
    // random seed used to generate the world
    randomSeed: string = ''
    fractalNoise: FractalNoiseSampler | null = null

    // * Templates present in the world: structures, decals, Areas of Interest, etc.
    //   is an array of template instructions with an x,y offset attached to it
    // * TODO: At the moment we store them in a linear list, but we should store them
    //   in an optimized quadtree type of structure.
    templateInstances: TemplateInstructionsInstance[] = []

    constructor(seed: string) {
        this.randomSeed = seed
        this.fractalNoise = new FractalNoiseSampler(seed)
    }
}

// ----------------------------------------------------------------------------
// World Generator
// ----------------------------------------------------------------------------
/**
 * The world generator is responsible for generating the world map and its contents,
 * optimize them for gameplay, placing them, run the history simulation, world erosion,
 * etc. and to finally setup the ChunkGenerator with all the necessary information.
 *
 * Once the world generator has setup the ChunkGenerator, the ChunkGenerator is in
 * full control and does not need the help of the world generator anymore.
 *
 * The chunk generator is responsible for loading/unloading/saving/generating chunks
 * and chunk-diffs.
 *
 */
export class WorldGenerator {
    static generate(randomSeed: string): WorldInfo {
        const wi = new WorldInfo(randomSeed)
        const castle1 = cloneStructureTemplate('Castle')
        TemplateUtils.transform(castle1, TemplateUtils.rotateVec270)
        TemplateUtils.transform(castle1, TemplateUtils.mirrorX)
        TemplateUtils.transform(castle1, TemplateUtils.mirrorY)
        TemplateUtils.transform(castle1, TemplateUtils.rotateVec90)
        TemplateUtils.transform(castle1, TemplateUtils.mirrorX)
        TemplateUtils.transform(castle1, TemplateUtils.mirrorY)
        TemplateUtils.transform(castle1, TemplateUtils.rotateVec90)

        const castle2 = cloneStructureTemplate('Castle')
        castle2.placeBlocks.tileMapping['X'] = 'Wall-8-020'
        castle2.placeBlocks.tileMapping['-'] = [ 'Floor-8-035', 'Floor-8-036', 'Floor-8-037' ]
        castle2.placeBlocks.tileMapping['='] = 'Floor-LAVA'
        castle2.placeBlocks.tileMapping['@'] = 'Floor-CAVE'

        WorldGenerator.addTemplate(wi, castle1, 16000-10, 16000-10)
        WorldGenerator.addTemplate(wi, castle2, 16050-10, 16000-10)
        return wi
    }

    static addTemplate(wi: WorldInfo, template: TemplateInstructions, wx: number, wy: number): void {
        assert(template.stats.w !== 0 && template.stats.h !== 0, `Template ${name} invalid stats: ${template.stats.w}x${template.stats.h}`)
        const instance: TemplateInstructionsInstance = {
            instanceName: `${template.name}-${wx}-${wy}`,
            instructions: template,
            wx: wx,
            wy: wy,
            loaded: false,
            characters: [],
        }
        wi.templateInstances.push(instance)
    }
}
