import { assert } from "./assert"
import { Thing } from "./thing"

export class PlayerState {
    static HOTBAR_SLOTS: number = 8
    static INVENTORY_SLOTS: number = 16
    static EQUIPPABLE_SLOTS: number = 8

    // private hotbar: Array<Thing | null> = new Array(PlayerState.HOTBAR_SLOTS).fill(null)
    // private inventory: Array<Thing | null> = new Array(PlayerState.INVENTORY_SLOTS).fill(null)
    // private equippable: Array<Thing | null> = new Array(PlayerState.EQUIPPABLE_SLOTS).fill(null)

    constructor() {
    }

    // getHotbarThing(index: number): Thing | null {
    //     assert(index >= 0 && index < PlayerState.HOTBAR_SLOTS, `Invalid hotbar index ${index}`)
    //     return this.hotbar[index]
    // }

    // setHotbarThing(index: number, thing: Thing | null): void {
    //     assert(index >= 0 && index < PlayerState.HOTBAR_SLOTS, `Invalid hotbar index ${index}`)
    //     this.hotbar[index] = thing
    // }

    // getInventoryThing(index: number): Thing | null {
    //     assert(index >= 0 && index < PlayerState.INVENTORY_SLOTS, `Invalid inventory index ${index}`)
    //     return this.inventory[index]
    // }

    // setInventoryThing(index: number, thing: Thing | null): void {
    //     assert(index >= 0 && index < PlayerState.INVENTORY_SLOTS, `Invalid inventory index ${index}`)
    //     this.inventory[index] = thing
    // }

    // getEquippableThing(index: number): Thing | null {
    //     assert(index >= 0 && index < PlayerState.EQUIPPABLE_SLOTS, `Invalid equippable index ${index}`)
    //     return this.equippable[index]
    // }

    // setWearableThing(index: number, thing: Thing | null): void {
    //     assert(index >= 0 && index < PlayerState.EQUIPPABLE_SLOTS, `Invalid equippable index ${index}`)
    //     this.equippable[index] = thing
    // }
}
