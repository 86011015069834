import { SimplexNoise2D } from './simplex-noise'
import { Config } from './config';

/**
 * WorldUtils
 *
 * Utility functions to build the world
 */
export class WorldUtils {
    static noise: SimplexNoise2D = new SimplexNoise2D(Config.worldMapSeed)

    // definitely assign noise in the constructor
    private constructor() {}

    static buildWorld(options: any): { map: Float32Array, biomes: Array<string> } {
        const self = this;

        const startTime = new Date().getTime()

        const map = new Float32Array(Config.WORLD_TILES_W * Config.WORLD_TILES_H)

        const scale = options.scale || 3
        const persistence = options.persistence || 0.5
        const lacunarity = options.lacunarity || 2.0
        const octaves = options.octaves || 6

        let noiseFrequencies = []
        for (let i = 0; i < octaves; i++) {
            noiseFrequencies.push({
                frequency: Math.pow(lacunarity, i) * scale,
                amplitude: Math.pow(persistence, i)
            })
        }

        let minVal = Infinity
        let maxVal = -Infinity

        const minWolrdSize = Math.min(Config.WORLD_TILES_W, Config.WORLD_TILES_H)

        // fill map with random noise
        for (let y = 0.0; y < Config.WORLD_TILES_H; y++) {
            for (let x = 0.0; x < Config.WORLD_TILES_W; x++) {

                // Use frequency and amplitude to add multiple layers of noise
                let value = 0.0
                for (let i = 0; i < noiseFrequencies.length; i++) {
                    let frequency = noiseFrequencies[i].frequency
                    let amplitude = noiseFrequencies[i].amplitude

                    value += this.noise.get(
                        x / minWolrdSize * frequency,
                        y / minWolrdSize * frequency
                    ) * amplitude
                }

                // -1 to +1 -> 0 to 1
                value = (value + 1.0) / 2.0

                // Compute falloff value
                // See: https://www.youtube.com/watch?v=COmtTyLCd6I
                let dx = Math.abs(x / Config.WORLD_TILES_W * 2 - 1)
                let dy = Math.abs(y / Config.WORLD_TILES_H * 2 - 1)
                let falloff = Math.max(dx, dy)
                const a = 2
                const b = 2
                falloff = Math.pow(falloff, a) / (Math.pow(falloff, a) + Math.pow(b - b * falloff, a))

                value *= (1.0 - falloff)

                // track min and max values
                if (value < minVal) minVal = value
                if (value > maxVal) maxVal = value

                // No need: we'll normalize later
                // clamp val between 0 and 1
                // if (value < 0.0) value = 0.0
                // if (value > 1.0) value = 1.0

                map[x + y * Config.WORLD_TILES_W] = value
            }
        }

        console.log('minVal', minVal.toFixed(6))
        console.log('maxVal', maxVal.toFixed(6))

        console.log('Adjusting contrast...')

        let minValN = Infinity
        let maxValN = -Infinity

        // adjust contrast
        for (let y = 0.0; y < Config.WORLD_TILES_H; y++) {
            for (let x = 0.0; x < Config.WORLD_TILES_W; x++) {
                let value = map[x + y * Config.WORLD_TILES_W]
                value = (value - minVal) * (1.0 / (maxVal - minVal))
                map[x + y * Config.WORLD_TILES_W] = value

                minValN = Math.min(minValN, value)
                maxValN = Math.max(maxValN, value)
            }
        }

        console.log('minVal-N', minValN.toFixed(6))
        console.log('maxVal-N', maxValN.toFixed(6))

        const endTime = new Date().getTime()
        console.log('WorldUtils::buildWorld took', (endTime - startTime) / 1000.0, 'secs')

        const biomes = self.buildBiomesMap({
            scale: 4,
            persistence: 0.5,
            lacunarity: 2,
            octaves: 6,
        })

        return { map, biomes }
    }

    static buildBiomesMap(options: any): Array<string> {
        const self = this;

        const funcName = 'WorldUtils::buildBiomesMap'

        console.log(funcName, 'Generating biomes map...')

        const startTime = new Date().getTime()

        const noiseU = new SimplexNoise2D(Config.worldMapSeed ? Config.worldMapSeed + '1' : Math.random())
        const noiseV = new SimplexNoise2D(Config.worldMapSeed ? Config.worldMapSeed + '2' : Math.random())

        const mapU = new Float32Array(Config.WORLD_TILES_W * Config.WORLD_TILES_H)
        const mapV = new Float32Array(Config.WORLD_TILES_W * Config.WORLD_TILES_H)
        const map: Array<string> = new Array<string>(Config.WORLD_TILES_W * Config.WORLD_TILES_H)

        const scale = options.scale || 3
        const persistence = options.persistence || 0.5
        const lacunarity = options.lacunarity || 2.0
        const octaves = options.octaves || 6

        let noiseFrequencies = []
        for (let i = 0; i < octaves; i++) {
            noiseFrequencies.push({
                frequency: Math.pow(lacunarity, i) * scale,
                amplitude: Math.pow(persistence, i)
            })
        }

        let minValU = Infinity
        let maxValU = -Infinity
        let minValV = Infinity
        let maxValV = -Infinity

        const minWolrdSize = Math.min(Config.WORLD_TILES_W, Config.WORLD_TILES_H)

        // Fill map
        for (let y = 0.0; y < Config.WORLD_TILES_H; y++) {
            for (let x = 0.0; x < Config.WORLD_TILES_W; x++) {
                const dx = x / minWolrdSize
                const dy = y / minWolrdSize
                // FBM: Fractal Brownian Motion
                // https://thebookofshaders.com/13/
                // Use frequency and amplitude to add multiple layers of noise
                let u = 0.0, v = 0.0
                for (let i = 0; i < noiseFrequencies.length; i++) {
                    let frequency = noiseFrequencies[i].frequency
                    let amplitude = noiseFrequencies[i].amplitude

                    u += noiseU.get(
                        dx * frequency,
                        dy * frequency
                    ) * amplitude

                    v += noiseV.get(
                        dx * frequency,
                        dy * frequency
                    ) * amplitude
                }

                // track min and max values
                if (u < minValU) minValU = u
                if (u > maxValU) maxValU = u
                if (v < minValV) minValV = v
                if (v > maxValV) maxValV = v

                mapU[x + y * Config.WORLD_TILES_W] = u
                mapV[x + y * Config.WORLD_TILES_W] = v
            }
        }

        console.log('minValU', minValU.toFixed(6))
        console.log('maxValU', maxValU.toFixed(6))
        console.log('minValV', minValV.toFixed(6))
        console.log('maxValV', maxValV.toFixed(6))

        console.log('Adjusting contrast...')

        let minValNU = Infinity
        let maxValNU = -Infinity
        let minValNV = Infinity
        let maxValNV = -Infinity

        const biomeSize = 5

        // const biomes = [
        //     ['red', 'blue', 'green', 'gold'],
        //     ['orange', 'white', 'black', 'yellow'],
        //     ['purple', 'cyan', 'magenta', 'gray'],
        //     ['brown', 'pink', 'lime', 'olive'],
        // ]

        const biomes = [
            ['gold', 'gold', 'white', 'black', 'black'],
            ['gold', 'white', 'black', 'black', 'black'],
            ['white', 'black', 'black', 'black', 'red'],
            ['black', 'black', 'black', 'red', 'fuchsia'],
            ['black', 'black', 'red', 'fuchsia', 'fuchsia'],
        ]

        // adjust contrast
        for (let y = 0.0; y < Config.WORLD_TILES_H; y++) {
            for (let x = 0.0; x < Config.WORLD_TILES_W; x++) {

                let u = mapU[x + y * Config.WORLD_TILES_W]
                u = (u - minValU) * (1.0 / (maxValU - minValU))
                mapU[x + y * Config.WORLD_TILES_W] = u

                let v = mapV[x + y * Config.WORLD_TILES_W]
                v = (v - minValV) * (1.0 / (maxValV - minValV))
                mapV[x + y * Config.WORLD_TILES_W] = v

                minValNU = Math.min(minValNU, u)
                maxValNU = Math.max(maxValNU, u)
                minValNV = Math.min(minValNV, v)
                maxValNV = Math.max(maxValNV, v)

                u = Math.max(u, 0)
                v = Math.max(v, 0)
                u = Math.floor(u * 0.99 * biomeSize)
                v = Math.floor(v * 0.99 * biomeSize)

                map[x + y * Config.WORLD_TILES_W] = biomes[u][v]

                // map[x + y * Config.WORLD_TILES_W] = `rgb(${u * 256 / biomeSize}, ${v * 256 / biomeSize}, 0)`

                // map[x + y * Config.WORLD_TILES_W] = 'black'
                // if (u > v) {
                //     if (u > 0.75)
                //         map[x + y * Config.WORLD_TILES_W] = 'red'
                // } else {
                //     if (v > 0.75)
                //         map[x + y * Config.WORLD_TILES_W] = 'blue'
                // }
            }
        }

        console.log('minVal-N-U', minValNU.toFixed(6))
        console.log('maxVal-N-U', maxValNU.toFixed(6))
        console.log('minVal-N-V', minValNV.toFixed(6))
        console.log('maxVal-N-V', maxValNV.toFixed(6))

        const endTime = new Date().getTime()
        console.log(funcName, (endTime - startTime) / 1000.0, 'secs')

        return map
    }

}
