import { Graph } from "./graph"
import { shuffleArray, randomChoice, getRandomInt } from "./core"
import { assert } from "./assert"
import { Config } from './config';
import { GameMap, MapChunk } from './game-map';
import { TileType, TileId, getTileInfo } from "./tiles";
import { ThingFactory } from "./thing-factory";
import { TextureUtils } from "./textures";
import { TileCategory } from "./enums";

// -----------------------------------------------------------------------------
// Tile utils moved out of tiles.ts
// -----------------------------------------------------------------------------

export function isTileWall(tileId: number): boolean {
    if (tileId === TileId.EMPTY) {
        return false
    }
    const info = getTileInfo(tileId)
    return info.canSeeThrough === false && info.canWalkThrough === false
}

export function isTileFloor(tileId: number): boolean {
    if (tileId === TileId.EMPTY) {
        return false
    }
    const info = getTileInfo(tileId)
    return info.canSeeThrough === true && info.canWalkThrough === true
}

export function getTileCategory(tileId: number): TileCategory {
    if (isTileWall(tileId)) {
        return TileCategory.WALL
    } else
    if (isTileFloor(tileId)) {
        return TileCategory.FLOOR
    } else
    return TileCategory.NONE
}

/**
 * FortressUtils
 *
 * Utility functions to generate: castles, towers, keeps, fortresses, mansions, manors,
 * bastions, lairs, churches, keeps, citadels, chateaus, barracks, sanctums, garrisons,
 * dungeons, caves, cemeteries etc.
 */
export class FortressUtils {

    private constructor() {}

    init() {
        const self = this;

    }

    // render given room array
    static renderRooms(rooms: any, map: GameMap, mergeWall: boolean) {
        const self = this;
        rooms.forEach(function (room: any, i: any) {
            self.renderRoom(
                room.x,
                room.y,
                room.w,
                room.h,
                TileId.FLOOR_STONE,
                map,
                mergeWall
            )
        })
    }

    static drawRoom(map: GameMap, x: number, y: number, w: number, h: number, floorTile: TileType, wallTile: TileType) {
        const self = this;
        // const mapW = map.getW()

        // console.log('drawRoom', x, y, w, h, floorTile, wallTile)

        // fill with background without borders
        for (let y0 = 0; y0 < h; ++y0) {
            for (let x0 = 0; x0 < w; ++x0) {
                map.setSafe_(x0 + x, y0 + y, floorTile)
            }
        }

        // top wall
        for (let x0 = 0; x0 < w; ++x0) {
            map.setSafe_(x0 + x, y, wallTile)
        }
        // bottom wall
        for (let x0 = 0; x0 < w; ++x0) {
            map.setSafe_(x0 + x, y + h - 1, wallTile)
        }
        // left wall
        for (let y0 = 1; y0 < h - 1; ++y0) {
            map.setSafe_(x, y0 + y, wallTile)
        }
        // right wall
        for (let y0 = 1; y0 < h - 1; ++y0) {
            map.setSafe_(x + w - 1, y0 + y, wallTile)
        }
    }

    static renderRoom(x: number, y: number, w: number, h: number, floorTile: TileType, map: GameMap, mergeWall: boolean) {
        x = Math.floor(x)
        y = Math.floor(y)
        w = Math.floor(w)
        h = Math.floor(h)
        // const mapW = map.getW()
        // FIXME: pass as parameter
        const wallTile = TileId.WALL_STONE_EXT

        // fill with background without borders
        for (let y0 = 0; y0 < h; ++y0) {
            for (let x0 = 0; x0 < w; ++x0) {
                map.setSafe_(x0 + x, y0 + y, floorTile)
            }
        }

        for (let y0 = 0; y0 < h; ++y0) {
            for (let x0 = 0; x0 < w; ++x0) {

                if (!(y0 == 0 || y0 == h - 1 || x0 == 0 || x0 == w - 1)) {
                    continue
                }

                if (mergeWall === true) {
                    map.setSafe_(x0 + x, y0 + y, wallTile)
                } else {
                    const a = map.getSafe_(x0 + x - 1, y0 + y)
                    const b = map.getSafe_(x0 + x + 1, y0 + y)
                    const c = map.getSafe_(x0 + x, y0 + y - 1)
                    const d = map.getSafe_(x0 + x, y0 + y + 1)
                    if (a === TileId.EMPTY || b === TileId.EMPTY || c === TileId.EMPTY || d === TileId.EMPTY) {
                        map.setSafe_(x0 + x, y0 + y, wallTile)
                    }
                }
            }
        }
    }

    static roomDistance(r1: any, r2: any) {
        // Euclidean distance
        // const dx = Math.max(r1.x, r2.x) - Math.min(r1.x + r1.w, r2.x + r2.w);
        // const dy = Math.max(r1.y, r2.y) - Math.min(r1.y + r1.h, r2.y + r2.h);
        // return Math.sqrt(dx * dx + dy * dy);

        // Manhattan distance
        return Math.abs(r1.x - r2.x) + Math.abs(r1.y - r2.y)
    }

    static roomIntersect(r1: any, r2: any, border: number) {
        const self = this;
        if (r1.x >= r2.x + r2.w + border)
            return false
        if (r2.x >= r1.x + r1.w + border)
            return false
        if (r1.y >= r2.y + r2.h + border)
            return false
        if (r2.y >= r1.y + r1.h + border)
            return false
        return true
    }

    static roomIntersectAny(room: any, rooms: any, ghostRooms: any, border: any) {
        const self = this;
        for (let r = 0; r < ghostRooms.length; ++r) {
            if (self.roomIntersect(room, ghostRooms[r], 0)) {
                return true
            }
        }

        for (let r = 0; r < rooms.length; ++r) {
            if (self.roomIntersect(room, rooms[r], border)) {
                return true
            }
        }
        return false;
    }

    static roomNextTo(r1: any, r2: any, border: number) {
        const self = this;

        if (r1.x >= r2.x + r2.w + 1 + border)
            return false
        if (r2.x >= r1.x + r1.w + 1 + border)
            return false
        if (r1.y >= r2.y + r2.h + 1 + border)
            return false
        if (r2.y >= r1.y + r1.h + 1 + border)
            return false

        return true
    }

    static roomNextToAny(room: any, rooms: any, border: number) {
        const self = this;
        for (let r = 0; r < rooms.length; ++r) {
            if (self.roomNextTo(room, rooms[r], border)) {
                return true
            }
        }
        return false;
    }

    /**
     * @param {*} neat Use false to connect rooms in random order, more organic map looking like
     */
    static connectRooms(rooms: any, map: GameMap, neat: any, types: any) {
        const self = this;

        const graph = new Graph()

        // Pass 1: connect the closest room pairs
        for (let i = 0; i < rooms.length; ++i) {
            let closest = null;
            let closestDist = Infinity;

            for (let j = 0; j < rooms.length; ++j) {
                if (i == j) {
                    continue
                }

                const dist = self.roomDistance(rooms[i], rooms[j]);
                if (dist < closestDist) {
                    closest = j;
                    closestDist = dist;
                }
            }

            if (closest !== null && graph.hasEdge(i, closest) !== true) {
                self.connectTwoRooms(rooms[i], rooms[closest], map, types);
                graph.addEdge(i, closest)
            }
        }

        // // traverse graph from given node
        // function traverse(graph: Graph, node: any, visited: any, subgraph: any) {
        //     if (visited[node]) {
        //         return
        //     }
        //     visited[node] = true
        //     subgraph.push(node)
        //     for (let i = 0; i < graph.nodes[node].length; ++i) {
        //         traverse(graph, graph.nodes[node][i], visited, subgraph)
        //     }
        // }

        const subgraphs: any = graph.getConnectedComponents()

        do {
            // for each subgraph and room find the closest subgraph and room
            for (let i = 0; i < subgraphs.length - 1; ++i) {
                let closest1 = null;
                let closest2 = null;
                let closestRoom1 = null;
                let closestRoom2 = null;
                let closestRoomDist = Infinity

                // now find the closest room
                for (let j = i + 1; j < subgraphs.length; ++j) {
                    for (let k1 = 0; k1 < subgraphs[i].length; ++k1) {
                        for (let k2 = 0; k2 < subgraphs[j].length; ++k2) {
                            const dist = self.roomDistance(rooms[subgraphs[i][k1]], rooms[subgraphs[j][k2]]);
                            if (dist < closestRoomDist) {
                                closest1 = i
                                closest2 = j
                                closestRoom1 = subgraphs[i][k1]
                                closestRoom2 = subgraphs[j][k2]
                                closestRoomDist = dist;
                            }
                        }
                    }
                }

                self.connectTwoRooms(rooms[closestRoom1], rooms[closestRoom2], map, types)


                // merge subgraphs and remove the second one
                assert(closest1 !== null, 'closest1 !== null')
                assert(closest2 !== null, 'closest2 !== null')
                subgraphs[closest1!] = subgraphs[closest1!].concat(subgraphs[closest2!])
                subgraphs.splice(closest2, 1)
                break
            }
        } while (subgraphs.length > 1)
    }

    /**
     * @param {*} neat Use false to connect rooms in random order, more organic map looking like
     */
    static connectRoomsClassic(rooms: any, map: GameMap, neat: any, types: any) {
        const self = this;

        if (neat) {
            rooms.sort(function (a: any, b: any) {
                // sort by manhattan distance from origin
                return (Math.abs(a.x) + Math.abs(a.y)) - (Math.abs(b.x) + Math.abs(b.y))
            })
        } else {
            // shuffle rooms
            rooms = shuffleArray(rooms)
        }

        const connected: any = {}
        // connect the rooms with corridors by finding the closest pair
        for (let i = 0; i < rooms.length; ++i) {
            let closest = null;
            let closestDist = Infinity;
            let key = ''
            for (let j = i + 1; j < rooms.length; ++j) {
                const k1 = Math.min(i, j)
                const k2 = Math.max(i, j)
                const dist = self.roomDistance(rooms[i], rooms[j]);
                if (dist < closestDist) {
                    closest = j;
                    closestDist = dist;
                    key = '' + k1 + k2
                }
            }

            if (closest !== null && connected[key] !== true) {
                self.connectTwoRooms(rooms[i], rooms[closest], map, types);
                connected[key] = true
            }
        }
    }

    static connectTwoRooms(r1: any, r2: any, map: GameMap, types: any) {
        const self = this;
        // const mapW = map.getW()
        // const mapH = map.getH()

        let x1 = Math.floor(r1.x + r1.w / 2);
        let y1 = Math.floor(r1.y + r1.h / 2);
        let x2 = Math.floor(r2.x + r2.w / 2);
        let y2 = Math.floor(r2.y + r2.h / 2);
        const fillTile = TileId.FLOOR_ROAD
        const type = randomChoice(types)
        // const dx = Math.abs(x1 - x2);
        // const dy = Math.abs(y1 - y2);

        while (x1 !== x2 || y1 !== y2) {
            if (type === 0) {
                if (x1 < x2) {
                    x1++;
                } else
                    if (x1 > x2) {
                        x1--;
                    } else
                        if (y1 < y2) {
                            y1++;
                        } else
                            if (y1 > y2) {
                                y1--;
                            }
            } else
                if (type === 1) {
                    if (y1 < y2) {
                        y1++;
                    } else
                        if (y1 > y2) {
                            y1--;
                        } else
                            if (x1 < x2) {
                                x1++;
                            } else
                                if (x1 > x2) {
                                    x1--;
                                }
                }
                else {
                    if (y1 < y2) {
                        y1++;
                    }
                    if (y1 > y2) {
                        y1--;
                    }
                    if (x1 < x2) {
                        x1++;
                    }
                    if (x1 > x2) {
                        x1--;
                    }
                }

            const isWall = isTileWall(map.getSafe_(x1, y1))
            if (map.getSafe_(x1, y1) === TileId.EMPTY || isWall) {
                map.setSafe_(x1, y1, fillTile)
            }
        }
    }

    static makeDoors(map: GameMap) {
        const self = this;
        const mapW = map.getVirtualChunkW() * MapChunk.CHUNK_SIZE
        const mapH = map.getVirtualChunkH() * MapChunk.CHUNK_SIZE

        if (!map.hasThingMap()) {
            map.initializeThingMap()
        }

        // Fill doors
        for (let y = 1; y < mapH - 1; ++y) {
            for (let x = 1; x < mapW - 1; ++x) {
                const c = getTileCategory(map.getSafe_(x, y))
                if (c === TileCategory.FLOOR) {
                    const c1 = getTileCategory(map.getSafe_(x + 1, y))
                    const c2 = getTileCategory(map.getSafe_(x - 1, y))
                    const c3 = getTileCategory(map.getSafe_(x, y + 1))
                    const c4 = getTileCategory(map.getSafe_(x, y - 1))
                    // check if c3 is FLOOR
                    if (c1 === TileCategory.WALL && c2 === TileCategory.WALL && (c3 === TileCategory.FLOOR) && (c4 === TileCategory.FLOOR)) {
                        // mapTiles[x + y * mapW] = TileId.DOOR
                        const thing = ThingFactory.getThingInstance('DoorClosed')
                        map.placeThing(thing, x, y)
                    }
                    else
                        if (c3 === TileCategory.WALL && c4 === TileCategory.WALL && (c1 === TileCategory.FLOOR) && (c2 === TileCategory.FLOOR)) {
                            // mapTiles[x + y * mapW] = TileId.DOOR
                            const thing = ThingFactory.getThingInstance('DoorClosed')
                            map.placeThing(thing, x, y)
                            }
                    // else
                    // if (c3 === TileId.WALL && c4 === TileId.WALL && c1 === TileId.WAY && c2 === TileId.WAY) {
                    //   mapTiles[x + y * mapW] = '@'
                    // }
                }
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////////
    // Bin Packing Algorithm - Based on Random Walk
    ///////////////////////////////////////////////////////////////////////////////
    static binPacking(options: any, rooms: any, roomsPlaced: any) {
        const self = this;

        if (rooms.length === 0) {
            return
        }

        if (roomsPlaced.length === 0) {
            // place first room in the middle of the map
            const firstRoom = rooms.shift()
            roomsPlaced.push(firstRoom)
            firstRoom.x = Math.floor(Config.MAP_TILES_W / 2) - Math.floor(firstRoom.w / 2)
            firstRoom.y = Math.floor(Config.MAP_TILES_H / 2) - Math.floor(firstRoom.h / 2)
        } else {
            // place next room
            const room = rooms.shift()

            // center room in the middle of the map
            const cx = room.x = Math.floor(Config.MAP_TILES_W / 2) - Math.floor(room.w / 2)
            const cy = room.y = Math.floor(Config.MAP_TILES_H / 2) - Math.floor(room.h / 2)
            const maxIter = 10000
            const directions = [
                { x: 0, y: -1 }, // up
                { x: 0, y: 1 }, // down
                { x: -1, y: 0 }, // left
                { x: 1, y: 0 }, // right
            ]

            let i = 0
            for (i = 0; i < maxIter; ++i) {
                // chose random direction
                const dir = randomChoice(directions)
                room.x += dir.x
                room.y += dir.y
                // if room outside of map then reposition to the center
                if (room.x < 0 || room.x + room.w > Config.MAP_TILES_W || room.y < 0 || room.y + room.h > Config.MAP_TILES_H) {
                    room.x = cx
                    room.y = cy
                    continue
                }

                if (!self.roomIntersectAny(room, roomsPlaced, [], -1)) {
                    roomsPlaced.push(room)
                    break
                }
            }

            // console.log('Iterations:', i)
        }

    }

    ///////////////////////////////////////////////////////////////////////////////
    // Drunk Walker
    ///////////////////////////////////////////////////////////////////////////////
    static drunkCaves(options: any): GameMap {
        const self = this

        const minPercent = options.minPercent || 33
        const maxClipping = options.maxClipping || 10
        const caveNum = options.caveNum || 2
        const totalTiles = options.totalTiles || 10000
        const maxTilesPerCave = totalTiles / caveNum
        const maxTries = options.maxTries || 1000
        const wallBorder = options.wallBorder || []
        const border = options.border || wallBorder.length

        let bestMap: GameMap | null = null
        let bestPercent = 0
        let tries = 0
        const startTime = Date.now()
        do {
            // map = self.app.mapTemplate.slice()
            const mapW = Config.MAP_TILES_W
            const mapH = Config.MAP_TILES_H
            // TODO: too slow now to use the full game map for this - use a simple array instead
            // const map = new GameMap(mapW, mapH)
            const map = new GameMap()
            // const map = new Uint32Array(mapW * mapH).fill(TileId.EMPTY)

            if (++tries > maxTries) {
                break
            }

            let tiles = 0
            let clippings = 0
            // start in a random spot on the map within the middle center of the map
            let x = getRandomInt(Math.floor(mapW / 2) - 50, Math.floor(mapW / 2) + 50)
            let y = getRandomInt(Math.floor(mapH / 2) - 50, Math.floor(mapH / 2) + 50)
            const nextStart = { x, y }

            const offsets = [
                { x: 0, y: -1 }, // up
                { x: 0, y: 1 }, // down
                { x: -1, y: 0 }, // left
                { x: 1, y: 0 }, // right
            ]

            for (let c = 0; c < caveNum; ++c) {
                for (let t = 0; t < maxTilesPerCave; ++t) {
                    // dir = t % 3 === 0 ? getRandomInt(0,3) : dir
                    // dir = getRandomInt(0,100) < 75 ? getRandomInt(0,3) : dir
                    const dir: number = getRandomInt(0, 3)
                    x += offsets[dir].x
                    y += offsets[dir].y

                    if (x < 1 + border || x >= mapW - 1 - border || y < 1 + border || y >= mapH - 1 - border) {
                        // pick last point as the next start
                        x = nextStart.x
                        y = nextStart.y
                        clippings += 1
                        if (clippings > maxClipping) {
                            break
                        }
                    }

                    // count valid tiles effectively drawn
                    if (map.getSafe_(x, y) === TileId.EMPTY) {
                        ++tiles
                    }

                    map.setSafe_(x, y, TileId.FLOOR_STONE)
                }

                if (clippings > maxClipping) {
                    break
                }
            }

            const percent = (100.0 * tiles / (maxTilesPerCave * caveNum))
            // console.log('Tiles:', tiles, 'Tiles %:', percent.toFixed(2), 'Clippings:', clippings)

            if (clippings < maxClipping && percent > bestPercent) {
                bestPercent = percent
                bestMap = map
            }

            if (clippings <= maxClipping && percent >= minPercent) {
                break
            }

        } while (true)

        // print best clipping and percent
        console.log('Best Percent:', bestPercent.toFixed(2), 'Tries:', tries)
        assert(bestMap !== null, 'bestMap !== null')

        const finisTime = Date.now()
        console.log('Time:', finisTime - startTime)

        wallBorder.forEach(function (filler: any) {
            self.erode(bestMap!, { filler })
        })

        return bestMap!
    }

    /**
     * This function erodes the map by replacing with the filler all null cells near to a non-null one
     * @param {*} inputMap
     * @param {*} options
     * @returns
     */
    static erode(inputMap: GameMap, options: any) {
        const self = this
        const filler = options.filler || '?'
        // const map = inputMap.slice()
        // const map = new GameMap(inputMap.getW(), inputMap.getH(), inputMap)
        const map = new GameMap(inputMap)
        const mapW = map.getVirtualChunkW() * MapChunk.CHUNK_SIZE
        const mapH = map.getVirtualChunkH() * MapChunk.CHUNK_SIZE

        // iterate every pixel of the map
        for (let y = 1; y < mapH - 1; ++y) {
            for (let x = 1; x < mapW - 1; ++x) {
                const c = map.getSafe_(x, y)
                if (c === TileId.EMPTY) {
                    const c1 = map.getSafe_(x + 1, y)
                    const c2 = map.getSafe_(x - 1, y)
                    const c3 = map.getSafe_(x, y + 1)
                    const c4 = map.getSafe_(x, y - 1)

                    if (c1 !== TileId.EMPTY || c2 !== TileId.EMPTY || c3 !== TileId.EMPTY || c4 !== TileId.EMPTY) {
                        inputMap.setSafe_(x, y, filler)
                    }
                }
            }
        }

        return inputMap
    }

    ///////////////////////////////////////////////////////////////////////////////
    // Cellular Automata Caves
    ///////////////////////////////////////////////////////////////////////////////
    static cellularAutomataCaves(options: any): GameMap {
        const self = this

        const maxPasses = options.maxPasses || 5
        const threshold = options.threshold || 4
        const caveProbability = options.caveProbability || 53
        const circleRadius = options.circleRadius // it can be 0
        const border = options.border || 5
        const emptyCell = options.emptyCell || TileId.EMPTY
        const fillCell = options.fillCell || TileId.WALL_ROCK
        const dimensions = options.dimensions || { w: Config.MAP_TILES_W, h: Config.MAP_TILES_H }
        console.log('Dimensions:', dimensions)

        const mapW = dimensions.w
        const mapH = dimensions.h

        // Fill generation1 with walls
        // let generation1 = Array(mapW * mapH).fill(fillCell)
        let generation1 = new Uint32Array(mapW * mapH).fill(fillCell)
        // Fill generation2 with nothing
        // let generation2 = Array(mapW * mapH).fill(TileId.EMPTY)
        let generation2 = new Uint32Array(mapW * mapH).fill(emptyCell)

        // Randomly initialize generation 1
        for (let y = border; y < mapH - border; ++y) {
            for (let x = border; x < mapW - border; ++x) {
                // distance calculation
                const dx1 = Math.abs(x - mapW / 4 * 2.8)
                const dx2 = Math.abs(x - mapW / 4 * 1.2)
                const dy = Math.abs(y - mapH / 2)
                const dist1 = Math.sqrt(dx1 * dx1 + dy * dy)
                const dist2 = Math.sqrt(dx2 * dx2 + dy * dy)
                if (circleRadius === 0 || dist1 < circleRadius || dist2 < circleRadius) {
                    generation1[x + y * mapW] = getRandomInt(0, 100) < caveProbability ? emptyCell : fillCell
                }
            }
        }

        // Iterate passes
        for (let p = 0; p < maxPasses; ++p) {

            console.log('Pass:', p)

            // Iterate map
            for (let y = 1; y < mapH - 1; ++y) {
                for (let x = 1; x < mapW - 1; ++x) {
                    // find value of 9 tiles around x y
                    let wallCount = 0

                    // find value at x y
                    const c = generation1[x + y * mapW]

                    // find neighbors
                    for (let y0 = -1; y0 <= 1; ++y0) {
                        for (let x0 = -1; x0 <= 1; ++x0) {
                            if (x0 === 0 && y0 === 0) {
                                continue
                            }
                            if (generation1[(x + x0) + (y + y0) * mapW] === fillCell) {
                                ++wallCount
                            }
                        }
                    }

                    // apply rules
                    if ((c === fillCell && wallCount >= threshold) || (c !== fillCell && wallCount > threshold)) {
                        generation2[x + y * mapW] = fillCell
                    } else {
                        generation2[x + y * mapW] = emptyCell
                    }
                }
            }

            // swap generations
            const temp = generation1
            generation1 = generation2
            generation2 = temp
        }

        // const newMap = new GameMap(mapW, mapH)
        const newMap = new GameMap()
        newMap.copyTileMap(generation1, mapW, mapH)
        return newMap
    }

    static findAllCaves(map: GameMap, emptyCell: any, fillCell: any): any {
        const self = this

        // find the largest cave
        let largestCave = null
        let largestCaveSize = 0
        const caves = []
        // fillMap will be used to find caves and will be filled with walls as we progress
        const fillMap : GameMap = new GameMap(map)
        const mapW = fillMap.getVirtualChunkW() * MapChunk.CHUNK_SIZE
        const mapH = fillMap.getVirtualChunkH() * MapChunk.CHUNK_SIZE

        for (let y = 1; y < mapH - 1; ++y) {
            for (let x = 1; x < mapW - 1; ++x) {
                // find value at x y
                const c = fillMap.getSafe_(x, y)
                if (c === emptyCell) {
                    const cave: {tiles: []} = self.findCave(x, y, fillMap, emptyCell, fillCell)
                    if (cave) {
                        caves.push(cave)
                        if (cave.tiles.length > largestCaveSize) {
                            largestCaveSize = cave.tiles.length
                            largestCave = cave
                        }
                    }
                }
            }
        }

        // sort caves by tiles count descending
        caves.sort(function (a, b) {
            return b.tiles.length - a.tiles.length
        })

        return {
            caves,
            largestCave,
            largestCaveSize
        }
    }

    static findCave(x: number, y: number, fillMap: GameMap, emptyCell: any, fillCell: any): any {
        const self = this
        // implementation using flood fill
        // const mapW = fillMap.getW()
        // const mapH = fillMap.getH()

        const cave: any = {
            tiles: []
        }

        const queue = []
        queue.push({ x, y })

        while (queue.length > 0) {
            const p: any = queue.shift()
            const x = p.x
            const y = p.y
            const c = fillMap.getSafe_(x, y)
            if (c === emptyCell) {
                cave.tiles.push({ x, y })
                fillMap.setSafe_(x, y, fillCell)
                queue.push({ x: x + 1, y })
                queue.push({ x: x - 1, y })
                queue.push({ x, y: y + 1 })
                queue.push({ x, y: y - 1 })
            }
        }

        return cave.tiles.length > 0 ? cave : null
    }

    ///////////////////////////////////////////////////////////////////////////////
    // Generates doors between rooms by connecting the closest rooms
    ///////////////////////////////////////////////////////////////////////////////
    static generateRoomDoors(rooms: any, map: GameMap, blowUpDoors: boolean): GameMap {
        const self = this
        // const mapW = map.getW()

        if (!map.hasThingMap()) {
            map.initializeThingMap()
        }

        // generate doors
        const graph = new Graph()

        const doorGraph = []
        for (let i = 0; i < rooms.length; ++i) {
            for (let j = 0; j < rooms.length; ++j) {
                if (i === j) {
                    continue
                }

                // skip if this room is already connected to anything
                // if (graph.nodes[i] && graph.nodes[i].length >= 2 ) {
                //   continue
                // }

                const r1 = rooms[i]
                const r2 = rooms[j]
                // search adjacent rooms

                // connect to the right
                const y1top = r1.y + r1.h - 1
                const y1bottom = r1.y
                const y2top = r2.y + r2.h - 1
                const y2bottom = r2.y
                if (r1.x + r1.w - 1 === r2.x && y1top >= y2bottom + 2 && y1bottom <= y2top - 2) {
                    // draw door in the middle where the two walls overlap
                    const x = r1.x + r1.w - 1
                    const y1 = Math.max(r1.y, r2.y)
                    const y2 = Math.min(r1.y + r1.h - 1, r2.y + r2.h - 1)
                    const y = Math.floor((y1 + y2) / 2)

                    // can add extra doors here
                    // map[x + y * mapW] = TileId.DOOR // door
                    graph.addEdge(i, j)

                    // add door to doorgraph
                    doorGraph.push({ i, j, x, y })
                }

                // connect to the top
                const x1left = r1.x
                const x1right = r1.x + r1.w - 1
                const x2left = r2.x
                const x2right = r2.x + r2.w - 1
                if (r1.y + r1.h - 1 === r2.y && x1right >= x2left + 2 && x1left <= x2right - 2) {
                    // draw door in the middle where the two walls overlap
                    const y = r1.y + r1.h - 1
                    const x1 = Math.max(r1.x, r2.x)
                    const x2 = Math.min(r1.x + r1.w - 1, r2.x + r2.w - 1)
                    const x = Math.floor((x1 + x2) / 2)

                    // can add extra doors here
                    // map[x + y * mapW] = TileId.DOOR // door
                    graph.addEdge(i, j)

                    // add door to doorgraph
                    doorGraph.push({ i, j, x, y })
                }

            }
        }

        // traverse graph to discover minimum spanning tree
        function traverse(graph: Graph, parent: any, node: any, visited: any, path: any) {
            if (visited[node]) {
                return
            }
            visited[node] = true
            path.push([parent, node])
            for (let i = 0; i < graph.nodes[node].length; ++i) {
                traverse(graph, node, graph.nodes[node][i], visited, path)
            }
        }

        const visited = {}
        const path: any = []
        traverse(graph, -1, 0, visited, path)

        console.log('Path nodes:', path.length)
        console.log('Rooms:', rooms.length)

        // In this for loop we draw the doors
        for (let k = 1; k < path.length; ++k) {
            const i = path[k][0]
            const j = path[k][1]
            // console.log(i, '->', j)

            // search for the door with the corresponding room
            let door = null
            for (let k = 0; k < doorGraph.length; ++k) {
                if (doorGraph[k].i === i && doorGraph[k].j === j || doorGraph[k].i === j && doorGraph[k].j === i) {
                    door = doorGraph[k]
                    // console.log('door', door)
                    break
                }
            }

            if (door) {
                let x = door.x
                let y = door.y
                // mapTiles[x + y * mapW] = TileId.DOOR // door
                const thing = ThingFactory.getThingInstance('DoorClosed')
                map.placeThing(thing, x, y)

                // check direction of the wall at the position x y where the door is
                // and draw the door accordingly
                const c1 = getTileCategory(map.getSafe_(x + 1, y))
                const c2 = getTileCategory(map.getSafe_(x - 1, y))
                const c3 = getTileCategory(map.getSafe_(x, y + 1))
                const c4 = getTileCategory(map.getSafe_(x, y - 1))
                const x0 = x
                const y0 = y
                const blowUpCandidates = [{ x, y }] // initialize with the door position
                if (c1 === TileCategory.WALL && c2 === TileCategory.WALL && (c3 === TileCategory.FLOOR) && (c4 === TileCategory.FLOOR)) {
                    do {
                        x += 1
                        const c3 = getTileCategory(map.getSafe_(x, y + 1))
                        const c4 = getTileCategory(map.getSafe_(x, y - 1))
                        if (c3 === TileCategory.FLOOR && c4 === TileCategory.FLOOR) {
                            blowUpCandidates.push({ x, y })
                        }
                        else {
                            break
                        }
                    } while (true)
                    x = x0
                    do {
                        x -= 1
                        const c3 = getTileCategory(map.getSafe_(x, y + 1))
                        const c4 = getTileCategory(map.getSafe_(x, y - 1))
                        if (c3 === TileCategory.FLOOR && c4 === TileCategory.FLOOR) {
                            blowUpCandidates.push({ x, y })
                        }
                        else {
                            break
                        }
                    } while (true)

                    // discard the last one
                    blowUpCandidates.pop()
                }
                else
                    if (c3 === TileCategory.WALL && c4 === TileCategory.WALL && (c1 === TileCategory.FLOOR) && (c2 === TileCategory.FLOOR)) {
                        do {
                            y += 1
                            const c1 = getTileCategory(map.getSafe_(x + 1, y))
                            const c2 = getTileCategory(map.getSafe_(x - 1, y))
                            if (c1 === TileCategory.FLOOR && c2 === TileCategory.FLOOR) {
                                blowUpCandidates.push({ x, y })
                            }
                            else {
                                break
                            }
                        } while (true)
                        y = y0
                        do {
                            y -= 1
                            const c1 = getTileCategory(map.getSafe_(x + 1, y))
                            const c2 = getTileCategory(map.getSafe_(x - 1, y))
                            if (c1 === TileCategory.FLOOR && c2 === TileCategory.FLOOR) {
                                blowUpCandidates.push({ x, y })
                            }
                            else {
                                break
                            }
                        } while (true)

                        // discard the last one
                        blowUpCandidates.pop()
                    }

                if (blowUpDoors) {
                    // sort blow up candidates by distance to the door x0 and y0
                    blowUpCandidates.sort(function (a: any, b: any) {
                        const distA = Math.abs(a[0] - x0) + Math.abs(a[1] - y0)
                        const distB = Math.abs(b[0] - x0) + Math.abs(b[1] - y0)
                        return distA - distB
                    })

                    // shrink blowUpCandidates by a random factor
                    blowUpCandidates.length = getRandomInt(0, blowUpCandidates.length)

                    // blow everything up
                    blowUpCandidates.forEach(function (c, i) {
                        map.setSafe_(c.x, c.y, TileId.FLOOR_DIRT)
                        map.removeThingXY(c.x, c.y)
                    })
                }
            }
        }

        return map
    }

    ///////////////////////////////////////////////////////////////////////////////
    //
    ///////////////////////////////////////////////////////////////////////////////
    static genManorCompositeBSP(wrapRooms: any, rooms: any, blowUpDoors: boolean): GameMap {
        const self = this

        // const map = self.app.mapTemplate.slice()
        const map: GameMap = new GameMap()

        let allRooms = rooms

        // Generate rooms
        wrapRooms.forEach(function (wrapRoom: any) {
            const rooms = self.generateRoomsBSP({
                maxDepth: 5,
                wrapRoom: wrapRoom
            })

            // append rooms to allRooms
            allRooms = allRooms.concat(rooms)
        })

        // compute the bounding box of all room +1 tile border
        let minX = Infinity
        let minY = Infinity
        let maxX = -Infinity
        let maxY = -Infinity

        allRooms.forEach(function (room: any) {
            minX = Math.min(minX, room.x)
            minY = Math.min(minY, room.y)
            maxX = Math.max(maxX, room.x + room.w - 1)
            maxY = Math.max(maxY, room.y + room.h - 1)
        })

        const wrapper = {
            x: minX - 2,
            y: minY - 2,
            w: maxX - minX + 5,
            h: maxY - minY + 5,
        }

        // Render rooms
        self.renderRooms([wrapper], map, true)
        self.renderRooms(allRooms, map, true)

        // Generate doors
        self.generateRoomDoors(allRooms, map, blowUpDoors)

        return map
    }

    ///////////////////////////////////////////////////////////////////////////////
    // BSP Generation
    ///////////////////////////////////////////////////////////////////////////////
    static generateRoomsBSP(options: any) {
        const self = this

        // Clone wrap room
        // Copy map array
        // const map = self.app.mapTemplate.slice()
        const wrapRoom = Object.assign({}, options.wrapRoom)
        const maxDepth = options.maxDepth
        const minRoomSize = 5
        const percSplit = 0.25
        const skipSplitChance = 5 // percent

        wrapRoom.x = Math.floor(wrapRoom.x)
        wrapRoom.y = Math.floor(wrapRoom.y)
        wrapRoom.w = Math.floor(wrapRoom.w)
        wrapRoom.h = Math.floor(wrapRoom.h)

        // const sizeM = options.sizeM || 2
        // const sizeN = options.sizeN || 5

        let rooms: any = [wrapRoom]
        let depth = 1
        let splitRooms: any = []
        do {
            splitRooms = []
            rooms.forEach(function (room: any) {
                if (room.w > room.h) {
                    if (room.w <= minRoomSize * 2 || getRandomInt(1, 100) < skipSplitChance) {
                        splitRooms.push(room)
                        return
                    }

                    const minSize = Math.max(minRoomSize, Math.floor(room.w * percSplit))

                    // split across w
                    const w1 = getRandomInt(minSize, room.w - minSize)
                    const w2 = room.w - w1

                    const room1 = {
                        x: room.x,
                        y: room.y,
                        w: w1,
                        h: room.h,
                    }
                    const room2 = {
                        x: room.x + w1,
                        y: room.y,
                        w: w2,
                        h: room.h,
                    }

                    if (w1 < w2) {
                        room1.w += 1
                    } else
                        if (w1 >= w2) {
                            room2.w += 1
                            room2.x -= 1
                        }

                    splitRooms.push(room1)
                    splitRooms.push(room2)

                } else {
                    if (room.h <= minRoomSize * 2 || getRandomInt(1, 100) < skipSplitChance) {
                        splitRooms.push(room)
                        return
                    }

                    const minSize = Math.max(minRoomSize, Math.floor(room.h * percSplit))

                    // split across h
                    const h1 = getRandomInt(minSize, room.h - minSize)
                    const h2 = room.h - h1
                    const room1 = {
                        x: room.x,
                        y: room.y,
                        w: room.w,
                        h: h1,
                    }
                    const room2 = {
                        x: room.x,
                        y: room.y + h1,
                        w: room.w,
                        h: h2,
                    }

                    if (h1 < h2) {
                        room1.h += 1
                    } else
                        if (h1 >= h2) {
                            room2.h += 1
                            room2.y -= 1
                        }

                    splitRooms.push(room1)
                    splitRooms.push(room2)
                }
            })

            rooms = splitRooms

            // console.log(`Depth: ${depth}, Rooms: ${rooms.length}`)

            ++depth
        } while (depth <= maxDepth)

        // console.log(`Rooms: ${rooms.length}`)

        // // Render rooms
        // self.renderRooms(rooms, map, true)

        // // Generate doors
        // this.generateRoomDoors(rooms, map)

        // return map;

        return rooms
    }

    ///////////////////////////////////////////////////////////////////////////////
    // Random Generation Method #2
    //
    // TODO:
    // - Implement precise room connection exactly where the border with the closest room is
    //
    ///////////////////////////////////////////////////////////////////////////////
    static generateRoomsAdjacent(options: any): GameMap {
        const self = this;

        const roomNum = options.roomNum || 100
        const border = options.border
        const connect = options.connect
        const connectNeat = options.connectNeat
        const connectTypes = options.connectTypes || [0, 1, 2]
        const makeDoors = options.makeDoors
        const sizeM = options.sizeM || 2
        const sizeN = options.sizeN || 5
        const seedRoomNum = options.seedRoomNum || 1
        const ghostRooms = options.ghostRooms || []

        // Copy map array
        // const map = self.app.mapTemplate.slice()
        const map: GameMap = new GameMap()

        const rooms = []

        for (let q = 0; q < seedRoomNum; ++q) {
            const w = Math.max(5, getRandomInt(1, sizeM) * sizeN)
            const h = Math.max(5, getRandomInt(1, sizeM) * sizeN)

            let room = null
            for (let t = 0; t < 100; ++t) {
                room = {
                    x: getRandomInt(1, Config.MAP_TILES_W - w - 1),
                    y: getRandomInt(1, Config.MAP_TILES_H - h - 1),
                    w,
                    h,
                }

                const border = 5
                if (self.roomIntersectAny(room, rooms, ghostRooms, border)) {
                    continue
                } else {
                    rooms.push(room)
                    break
                }
            }
        }

        for (let q = 0; q < roomNum; ++q) {
            const w = Math.max(5, getRandomInt(1, sizeM) * sizeN)
            const h = Math.max(5, getRandomInt(1, sizeM) * sizeN)

            let room = null
            for (let t = 0; t < 100; ++t) {
                room = {
                    x: getRandomInt(1, Config.MAP_TILES_W - w - 1),
                    y: getRandomInt(1, Config.MAP_TILES_H - h - 1),
                    w,
                    h,
                }

                if (rooms.length === 0) {
                    rooms.push(room)
                    continue
                }

                if (self.roomIntersectAny(room, rooms, ghostRooms, border)) {
                    continue
                } else
                    if (self.roomNextToAny(room, rooms, border)) {
                        rooms.push(room)
                        break
                    } else {
                        continue
                    }
            }
        }

        rooms.forEach(function (room: any) {
            self.renderRoom(
                room.x,
                room.y,
                room.w,
                room.h,
                TileId.FLOOR_STONE,
                map,
                false // mergeWall
            )
        })

        if (connect) {
            self.connectRooms(rooms, map, connectNeat, connectTypes)
        }

        if (connect && makeDoors) {
            self.makeDoors(map)
        }

        console.log(`Rooms: ${rooms.length}`)

        return map
    }

    ///////////////////////////////////////////////////////////////////////////////
    // Awesome Castle Generator
    ///////////////////////////////////////////////////////////////////////////////
    static generateCastle(options: any): GameMap {
        const self = this;

        const roomNum = options.roomNum
        const sizeM = options.sizeM
        const sizeN = options.sizeN

        // Copy map array
        // const map = self.app.mapTemplate.slice()
        const map: GameMap = new GameMap()
        const mapW = map.getVirtualChunkW() * MapChunk.CHUNK_SIZE
        const mapH = map.getVirtualChunkH() * MapChunk.CHUNK_SIZE

        const rooms = [
            // first room is as maximum size
            {
                // center x and y
                x: Math.floor(mapW / 2) - Math.floor(sizeM * sizeN / 2),
                y: Math.floor(mapH / 2) - Math.floor(sizeM * sizeN / 2),
                w: sizeM * sizeN,
                h: sizeM * sizeN,
            }
        ]

        // compute first room center as cx and cy
        const firstRoom = rooms[0]
        const cx = firstRoom.x + Math.floor(firstRoom.w / 2)
        const cy = firstRoom.y + Math.floor(firstRoom.h / 2)

        const angles = shuffleArray([0, 45, 90, 135, 180, 225, 270, 315])

        for (let q = 0; q < roomNum; ++q) {
            const w = Math.max(5, getRandomInt(Math.floor(sizeM / 2), sizeM) * sizeN) + sizeN
            const h = Math.max(5, getRandomInt(Math.floor(sizeM / 2), sizeM) * sizeN) + sizeN

            let ox = cx + firstRoom.w / 2
            let oy = cy // - h / 2
            // rotate ox and oy around cx and cy by 45 degrees
            const angle = angles[q]
            const rad = angle * Math.PI / 180
            const cos = Math.cos(rad)
            const sin = Math.sin(rad)
            const rx = Math.floor(cos * (ox - cx) - sin * (oy - cy) + cx)
            const ry = Math.floor(sin * (ox - cx) + cos * (oy - cy) + cy)

            const room = {
                x: rx - w / 2,
                y: ry - h / 2,
                w,
                h,
            }

            rooms.push(room)
        }

        rooms.forEach(function (room) {
            self.renderRoom(
                room.x,
                room.y,
                room.w,
                room.h,
                TileId.FLOOR_STONE,
                map,
                false // mergeWall
            )
        })

        // detect castle corners
        const corners = []
        const cornerTemplate = [
            [1,1,0,0].join(','), // '##  '
            [0,1,1,0].join(','), // ' ## '
            [0,0,1,1].join(','), // '  ##'
            [1,0,0,1].join(',')  // '#  #'
        ]

        for (let y = 1; y < mapH - 1; ++y) {
            for (let x = 1; x < mapW - 1; ++x) {
                const isWall = isTileWall(map.getSafe_(x, y))
                if (isWall) {
                    const a = getTileCategory(map.getSafe_(x + 1, y))
                    const b = getTileCategory(map.getSafe_(x, y + 1))
                    const c = getTileCategory(map.getSafe_(x - 1, y))
                    const d = getTileCategory(map.getSafe_(x, y - 1))
                    const corner = [a, b, c, d].join(',')
                    if (cornerTemplate.includes(corner)) {
                        corners.push([x, y])
                    }
                }
            }
        }

        // create rooms centered in corners and append to a new list
        const towerRooms: any = []
        corners.forEach(function (corner) {
            const towerSize = randomChoice([5, 7, 9, 11, 13])
            const x = corner[0]
            const y = corner[1]
            const w = towerSize
            const h = towerSize
            const room = {
                x: x - Math.floor(w / 2),
                y: y - Math.floor(h / 2),
                w,
                h,
            }

            if (self.roomIntersectAny(room, towerRooms, [], 0)) {
                return
            }
            towerRooms.push(room)
        })

        const doorLocations: {x: number, y: number}[] = []

        towerRooms.forEach(function (room: any) {
            const solid = getRandomInt(1, 100) > 33
            self.renderRoom(
                room.x,
                room.y,
                room.w,
                room.h,
                TileId.FLOOR_STONE,
                map,
                solid // mergeWall
            )

            // make tower doors
            if (solid) {
                const cornerTemplate = [
                    [1,1,2,2].join(','), // '##..'
                    [2,2,1,1].join(',')  // '..##'
                ]
                const doors = []
                for (let y = room.y; y < room.y + room.w; ++y) {
                    for (let x = room.x; x < room.x + room.w; ++x) {
                        const isWall = isTileWall(map.getSafe_(x, y))
                        if (isWall) {
                            const a = getTileCategory(map.getSafe_(x + 1, y))
                            const b = getTileCategory(map.getSafe_(x - 1, y))
                            const c = getTileCategory(map.getSafe_(x, y + 1))
                            const d = getTileCategory(map.getSafe_(x, y - 1))
                            const corner = [a, b, c, d].join(',')
                            // collect possible locations for doors
                            if (cornerTemplate.includes(corner)) {
                                doors.push([x, y])
                            }
                        }
                    }
                }
                // select random location for door
                const door = randomChoice(doors)
                // mapTiles[door[0] + door[1] * mapW] = TileId.DOOR
                // add door to doorLocations
                doorLocations.push({ x: door[0], y: door[1] })
            }
        })

        console.log(`Rooms: ${rooms.length}`)

        const layerMaps = self.layerMaps(
            map, // castle map

            self.drunkCaves({
                minPercent: 28,
                maxClipping: 10,
                caveNum: 20,
                totalTiles: 5000,
                maxTries: 100,
                wallBorder: [TileId.WALL_CAVE],
            }),

            [TileId.FLOOR_STONE]
        )
        layerMaps.initializeThingMap()
        doorLocations.forEach(function (door) {
            const thing = ThingFactory.getThingInstance('DoorClosed')
            layerMaps.placeThing(thing, door.x, door.y)
        })

        // Layer castle map over caves map
        return layerMaps
    }

    ///////////////////////////////////////////////////////////////////////////////
    // Random Generation Method #1
    ///////////////////////////////////////////////////////////////////////////////
    static generateRooms(options: any): GameMap {
        const self = this

        const roomNum = options.roomNum || 100
        const ghostRooms = options.ghostRooms || []
        const sizeM = options.sizeM || 2
        const sizeN = options.sizeN || 5
        const border = options.border
        const connect = options.connect
        const connectNeat = options.connectNeat
        const connectTypes = options.connectTypes || [0, 1, 2]

        // Copy map array
        // const map = self.app.mapTemplate.slice()
        const map: GameMap = new GameMap()

        const rooms = []
        for (let q = 0; q < roomNum; ++q) {
            const w = Math.max(5, getRandomInt(1, sizeM) * sizeN)
            const h = Math.max(5, getRandomInt(1, sizeM) * sizeN)

            let room = null
            for (let t = 0; t < 100; ++t) {
                room = {
                    x: getRandomInt(1, Config.MAP_TILES_W - w - 1),
                    y: getRandomInt(1, Config.MAP_TILES_H - h - 1),
                    w,
                    h,
                }

                if (self.roomIntersectAny(room, rooms, ghostRooms, border)) {
                    continue
                } else {
                    rooms.push(room)
                    break
                }
            }
        }

        rooms.forEach(function (room: any) {
            self.renderRoom(
                room.x,
                room.y,
                room.w,
                room.h,
                TileId.FLOOR_STONE,
                map,
                false // mergeWall
            )
        })

        if (connect) {
            self.connectRooms(rooms, map, connectNeat, connectTypes)
        }

        return map
    }

    /**
     * This function layers map2 over map1
     * @param {*} map1
     * @param {*} map2
     * @param {*} filter only allows this character to be drawn from map2 onto map1 on non-empty cells
     * @returns
     */
    static layerMaps(map1: GameMap, map2: GameMap, filter: Array<TileType>): GameMap {
        const self = this

        // const map = map1.slice()
        const map = new GameMap()
        const mapW = map1.getVirtualChunkW() * MapChunk.CHUNK_SIZE
        const mapH = map1.getVirtualChunkH() * MapChunk.CHUNK_SIZE

        for (let y = 0; y < mapH; ++y) {
            for (let x = 0; x < mapW; ++x) {
                const c1 = map1.getSafe_(x, y)
                const c2 = map2.getSafe_(x, y)
                if (c2 !== TileId.EMPTY) {
                    if (c1 === TileId.EMPTY || filter.includes(c2)) {
                        map.setSafe_(x, y, c2)
                    }
                }
            }
        }

        return map
    }

}