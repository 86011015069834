///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

import { Config } from './config'

function getPrngSeed() {
    if (Config.prngSeed !== 0) {
        console.log('Seed: ' + Config.prngSeed)
        return Config.prngSeed
    } else {
        const seed = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        console.log('Seed: ' + seed)
        return seed
    }
}

export const PRNG = new isaacCSPRNG(String(getPrngSeed()))

export function addJS(path: string) {
    const e = document.createElement('script')
    e.type = 'text/javascript'
    e.src = path + '?v=' + PRNG.random()
    $("head").append(e)
}

export function addCSS(path: string) {
    const e = document.createElement('link')
    e.rel = 'stylesheet'
    e.href = path + '?v=' + PRNG.random()
    $("head").append(e)
}

export function addScripts(scripts: Array<string>) {
    scripts.forEach((s: string) => {
        console.log('Loading ' + s)
        if (s.endsWith('.css')) {
            addCSS(s)
        } else
            if (s.endsWith('.js')) {
                addJS(s)
            }
    })
}

export function shuffleArray(array: Array<any>) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {
        randomIndex = Math.floor(PRNG.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

export function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(PRNG.random() * (max - min + 1)) + min;
}

export function delayReload(ms: number) {
    setTimeout(function () { document.location.reload() }, ms);
}

// Array.prototype.randomChoice = function () {
//     return this[Math.floor(PRNG.random() * this.length)];
// }

export function randomChoice(array: Array<any>) {
    return array[Math.floor(PRNG.random() * array.length)];
}

export function isNumber(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export class Circle {
    x: number = 0;
    y: number = 0;
    radius: number = 0;
}

export class Box2D {
    x: number = 0
    y: number = 0
    w: number = -1
    h: number = -1

    constructor(x: number, y: number, w: number, h: number) {
        this.set(x, y, w, h)
    }

    set(x: number, y: number, w: number, h: number): Box2D {
        this.x = x
        this.y = y
        this.w = w
        this.h = h
        return this
    }

    union(b: Box2D): Box2D {
        if (this.isNull()) {
            this.set(b.x, b.y, b.w, b.h)
            return this
        } else if (b.isNull()) {
            return this
        } else {
            const x1 = Math.min(this.x, b.x)
            const y1 = Math.min(this.y, b.y)
            const x2 = Math.max(this.x + this.w, b.x + b.w)
            const y2 = Math.max(this.y + this.h, b.y + b.h)
            this.set(x1, y1, x2 - x1, y2 - y1)
            return this
        }
    }

    isNull(): boolean {
        return this.w === -1 || this.h === -1
    }
}

///////////////////////////////////////////////////////////////////////////////
// Vec2D
///////////////////////////////////////////////////////////////////////////////
/**
 * @class Vec2D
 */
export class Vec2D {
    constructor(public x: number, public y: number) {
    }

    set(x: number, y: number): Vec2D {
        this.x = x
        this.y = y
        return this
    }

    dot(v: Vec2D): number {
        return this.x * v.x + this.y * v.y
    }

    scale(factor: number): Vec2D {
        this.x *= factor
        this.y *= factor
        return this
    }

    // equal operator
    eq(v: Vec2D): boolean {
        return this.x === v.x && this.y === v.y
    }

    diff(v: Vec2D): Vec2D {
        return new Vec2D(this.x - v.x, this.y - v.y)
    }

    distance(v: Vec2D): number {
        return Math.sqrt(Math.pow(this.x - v.x, 2) + Math.pow(this.y - v.y, 2))
    }

    isNull(): boolean {
        return this.x === 0 && this.y === 0
    }

    normalize(): Vec2D {
        const length = Math.sqrt(this.x * this.x + this.y * this.y)
        if (length !== 0) {
            this.x /= length
            this.y /= length
        }
        return this
    }
}

///////////////////////////////////////////////////////////////////////////////
// RGBA
///////////////////////////////////////////////////////////////////////////////
/**
 * RGBA
 */
export class RGBA {
    constructor(public r: number, public g: number, public b: number, public a: number) {
    }

    mulRGB(factor: number): RGBA {
        // multiply each component by factor and clamp to 0-255
        const r = Math.min(255, Math.max(0, Math.round(this.r * factor)))
        const g = Math.min(255, Math.max(0, Math.round(this.g * factor)))
        const b = Math.min(255, Math.max(0, Math.round(this.b * factor)))
        return new RGBA(r, g, b, this.a)
    }

    avgRGB(): number {
        return Math.round((this.r + this.g + this.b) / 3)
    }

    toHSL(): HSL {
        return HSL.fromRGBA(this)
    }

    toString() {
        return `${this.r},${this.g},${this.b},${this.a}`
    }

    toCSS() {
        return `rgba(${this.r},${this.g},${this.b},${this.a/255.0})`
    }
}

// Example: hexToRGBA('#ff0000')
export function hexToRGBA(hex: string): RGBA {
    const r = parseInt(hex.substring(1, 3), 16)
    const g = parseInt(hex.substring(3, 5), 16)
    const b = parseInt(hex.substring(5, 7), 16)
    const a = 255
    return new RGBA(r, g, b, a)
}

/**
 * @class HSL
 * @description Hue, Saturation, Lightness
 * @see https://stackoverflow.com/questions/2353211/hsl-to-rgb-color-conversion
 * @see https://en.wikipedia.org/wiki/HSL_and_HSV
 *
 * HSL stands for hue, saturation, and lightness
 * Hue range: [0, 1] where 0 is red, 1/3 is green, 2/3 is blue, and 1 is red again
 * Saturation range: [0, 1] where 0 is grayscale and 1 is full color
 * Lightness range: [0, 1] where 0 is black, 0.5 is normal, and 1 is white
 */
export class HSL {
    constructor(public hue: number, public saturation: number, public lightness: number) {
    }

    rotateHue(delta: number): HSL {
        // rotate hue by hue amount and account for negative values
        this.hue += delta
        this.hue %= 1
        if (this.hue < 0) {
            this.hue += 1
        }
        return this
    }

    setLightness(lightness: number): HSL {
        this.lightness = lightness
        this.lightness = Math.min(1, Math.max(0, this.lightness))
        return this
    }

    setSaturation(saturation: number): HSL {
        this.saturation = Math.min(1, Math.max(0, saturation))
        return this
    }

    addLightness(lightness: number): HSL {
        this.lightness += lightness
        this.lightness = Math.min(1, Math.max(0, this.lightness))
        return this
    }

    mulLightness(lightness: number): HSL {
        this.lightness *= lightness
        // this.lightness = Math.min(1, Math.max(0, this.lightness))
        return this
    }

    mulSaturation(saturation: number): HSL {
        this.saturation *= saturation
        this.saturation = Math.min(1, Math.max(0, this.saturation))
        return this
    }

    toRGBA(): RGBA {
        const h = this.hue
        const s = this.saturation
        const l = this.lightness
        let r, g, b

        if (s == 0) {
            r = g = b = l; // achromatic
        } else {
            const hue2rgb = function hue2rgb(p: number, q: number, t: number) {
                if (t < 0) t += 1
                if (t > 1) t -= 1
                if (t < 1.0 / 6) return p + (q - p) * 6 * t
                if (t < 1.0 / 2) return q
                if (t < 2.0 / 3) return p + (q - p) * (2.0 / 3 - t) * 6
                return p
            }

            const q = l < 0.5 ? l * (1 + s) : l + s - l * s
            const p = 2 * l - q
            r = hue2rgb(p, q, h + 1.0 / 3.0)
            g = hue2rgb(p, q, h)
            b = hue2rgb(p, q, h - 1.0 / 3.0)
        }

        // I'd like to point out that using Math.round introduces small inaccuracies at the low and high ends (values of 0 and 255) of the scale. Values that arent on the ends of the range can round either up or down to reach their value, but values can only be rounded down to 0, or up to 255.
        // This means that the range of values that map to 0 and 255 are exactly half of those for other values.
        // To fix this we use min(floor(val*256),255)
        // return new RGBA(Math.round(r * 255), Math.round(g * 255), Math.round(b * 255), 255)
        return new RGBA(Math.min(Math.floor(r * 256), 255), Math.min(Math.floor(g * 256), 255), Math.min(Math.floor(b * 256), 255), 255)
    }

    static fromRGB(r: number, g: number, b: number): HSL {
        return this.fromRGBA(new RGBA(r, g, b, 255))
    }

    static fromRGBA(rgba: RGBA): HSL {
        const r = rgba.r / 255.0
        const g = rgba.g / 255.0
        const b = rgba.b / 255.0

        const max = Math.max(r, g, b)
        const min = Math.min(r, g, b)

        let h = 0
        let s = 0
        const l = (max + min) / 2.0

        if (max == min) {
            h = s = 0 // achromatic
        } else {
            const d = max - min
            s = l > 0.5 ? d / (2.0 - max - min) : d / (max + min)
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break
                case g: h = (b - r) / d + 2; break
                case b: h = (r - g) / d + 4; break
            }
            h /= 6.0
        }

        return new HSL(h, s, l)
    }
}

// console.log('=============================')
// for(let i = 0; i < 10; ++i) {
//     // create ranom rgb values and convert to hsl
//     const r = getRandomInt(0, 255)
//     const g = getRandomInt(0, 255)
//     const b = getRandomInt(0, 255)
//     const rgba = HSL.fromRGB(r,g,b).toRGBA()
//     console.log(`${r} ${g} ${b} -> ${rgba.r}, ${rgba.g}, ${rgba.b}`)
// }
// console.log('=============================')
