import { Direction } from "./enums"
import { assert } from "./assert"
import { TextureUtils, Texture } from "./textures"
import { EquipmentType } from "./enums"
import { Vec2D } from "./core"
import { Config } from "./config"
import { ImageTransform } from "./image-transform"

// Thing attributes for tools & weapons
export class ThingAttribToolWeapon {
    constructor() {
    }
}

// armor, shield, ring, amulet, emblem, etc.
export class ThingAttribWearable {
    constructor() {
    }
}

// scroll, potion, food, etc.
export class ThingAttribConsumable {
    constructor() {
    }
}

// torch, lamp, etc.
export class ThingAttribLightSource {
    constructor() {
    }
}

// key, etc.
export class ThingAttribKey {
    constructor() {
    }
}

// book, etc.
export class ThingAttribReadable {
    constructor() {
    }
}

// gold, etc.
export class ThingAttribValuable {
    constructor() {
    }
}

// arrow, etc.
export class ThingAttribAmmo {
    constructor() {
    }
}

// bag, etc.
export class ThingAttribContainer {
    constructor() {
    }
}

/**
 * Note:
 *
 * Things can be a list of Things. This is used to place multiple entities on a single map tile that might be stacked on top of each other
 * or have combined effects. For example, fire, peeking (hole, crack, slit, key-hole, window).
 *
 * It can also be blocks when not part of the map, floating or in inventory.
 */

/**
 * @class Thing
 * @description A game object that can be placed on a map tile, has a position and direction and is 1x1 tile in size
 */
export class Thing {
    // Generate unique id
    private static _id: number = 0
    private id: number = Thing._id++

    // Things are linked together in a doubly linked list
    private next: Thing | null = null
    private prev: Thing | null = null

    // Type of thing. Basically is the class and determines which class is instantiated by the Thing factory
    public type: string

    public name: string

    // Tile world position
    public x: number = -1 // tile number
    public y: number = -1 // tile number

    public direction: Direction = Direction.UP

    // Name of default texture for templates
    public textureName: string = ''

    // The position in the texture that should be placed at wx/wy - The texture origin
    public texturePivot: Vec2D = new Vec2D(0, 0)

    // Active texture
    // public texture: HTMLImageElement | null = null
    public texture: Texture | null = null

    // Actife icon texture
    // public textureIcon: HTMLImageElement | null = null // used when floating or in inventory
    public textureIcon: Texture | null = null // used when floating or in inventory

    public canSeeThrough: boolean = true
    public canWalkThrough: boolean = true
    public canWalkBridge: boolean = false // allows users to walk on non-walkable tiles
    public isEquipment: boolean = false // can be equipped
    public equipmentType: string = EquipmentType.NONE

    // false = part of the map | true = part of inventory or floating on the map
    // Anything that is not placed can by definition be walked through
    public isFloating = false

    // Floating animation offset
    public floatOffset = Math.random() * Math.PI

    // 0 = cannot be piled | > 0 = can be piled
    public pileCount = 1

    constructor(type: string) {
        this.type = type
        this.name = `${type}:${this.id}`
    }

    shallowClone(): Thing {
        const exclude = ['id', 'next', 'prev']
        const allowedComplexAssign: string[] = [
            // 'texture', 'textureIcon'
        ]
        // use introspection
        const thing = new (this.constructor as any)()
        // iterate over all properties
        for (const key in this) {
            const oldProp = this[key] as any
            // copy property except for next and prev and id
            if (exclude.indexOf(key) === -1) {
                if (oldProp instanceof HTMLImageElement) {
                    // handles also HTMLImageElement in subclasses
                    thing[key] = oldProp
                } else
                if (oldProp instanceof Vec2D) {
                    const val = this[key] as Vec2D
                    thing[key] = new Vec2D(val.x, val.y)
                } else
                // check if property is a class with a clone() method
                if (typeof oldProp === 'object' && oldProp !== null && typeof oldProp.clone === 'function') {
                    thing[key] = oldProp.clone()
                } else {
                    const isSimpleType = typeof oldProp === 'string' || typeof oldProp === 'number' || typeof oldProp === 'boolean' || oldProp === null
                    if (allowedComplexAssign.indexOf(key) !== -1 || isSimpleType) {
                        thing[key] = oldProp
                    } else {
                        // console.log(key, oldProp)
                        assert(typeof oldProp !== 'object', `Thing shallowClone() property ${key} is of type '${typeof oldProp}' not a scalar type`)
                    }
                }
            }
        }

        return thing
    }

    init(): void {
        const self = this
        self.texture = TextureUtils.getTexture(self.textureName)
        self.textureIcon = TextureUtils.getTexture(self.textureName + ':Icon')
    }

    handleUserAction(action: any): void {
        console.log('Thing.handleUserAction()', action)
    }

    setXY(x: number, y: number): Thing {
        this.x = x
        this.y = y
        return this
    }

    // Thing id

    getId(): number {
        return this.id
    }

    // List functions

    appendToList(thing: Thing): void {
        assert( this.next === null || this.next.prev === this, `Thing ${this.name} next.prev is not this`)

        if (this.next === null) {
            this.next = thing
            thing.prev = this
        } else {
            this.next.appendToList(thing)
        }
    }

    removeFromList(): void {
        if (this.prev) {
            this.prev.next = this.next
        }
        if (this.next) {
            this.next.prev = this.prev
        }
    }

    getNextInList(): Thing | null {
        return this.next
    }

    getPrevInList(): Thing | null {
        return this.prev
    }

    forEachInList(callback: (thing: Thing) => boolean): void {
        if (callback(this)) {
            return
        } else
        if (this.next) {
            this.next.forEachInList(callback)
        }
    }

    findInList(callback: (thing: Thing) => boolean): Thing | null {
        if (callback(this)) {
            return this
        }
        if (this.next) {
            return this.next.findInList(callback)
        }
        return null
    }

    getListLength(): number {
        if (this.next) {
            return this.next.getListLength() + 1
        }
        return 1
    }
}

//---------------------------------------------------------
// ThingDoor
//---------------------------------------------------------
export class ThingDoor extends Thing {
    isOpen: boolean = true
    textureOpen: HTMLImageElement | null = null
    textureClosed: HTMLImageElement | null = null

    constructor(type: string) {
        super(type)
    }

    init(): void {
        const self = this
        self.texture = TextureUtils.getTexture(self.isOpen ? 'DoorOpen' : 'DoorClosed')
        self.textureIcon = TextureUtils.getTexture('DoorClosed:Icon')
        self.canSeeThrough = self.isOpen
        self.canWalkThrough = self.isOpen
    }

    handleUserAction(action: any): void {
        const self = this
        self.isOpen = !self.isOpen

        self.texture = TextureUtils.getTexture(self.isOpen ? 'DoorOpen' : 'DoorClosed')
        self.canSeeThrough = self.isOpen
        self.canWalkThrough = self.isOpen
    }
}
