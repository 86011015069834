export enum Direction {
    DOWN = 0,
    LEFT = 1,
    RIGHT = 2,
    UP = 3,
}

export enum TileLevel {
    SUBSTRATUM = 0,
    FLOOR = 1,
    WALL = 2,
    ROOF = 3,
    COUNT = 4,
    // EMPTY = 5,
}

export enum FogOfWarType {
    BRIGHT,
    MEDIUM,
    DARK,
    NONE
}

export enum CameraFollowMode {
    NONE,
    CENTER,
    BORDER
}

export enum TileCategory {
    NONE,
    WALL,
    FLOOR
}

export enum MouseButton {
    NONE,
    LEFT = 0,
    MIDDLE = 1,
    RIGHT = 2
}

export enum MouseEventType {
    NONE,

    DOWN_LEFT,
    DOWN_MIDDLE,
    DOWN_RIGHT,

    UP_LEFT,
    UP_MIDDLE,
    UP_RIGHT,

    CLICK_LEFT,
    CLICK_MIDDLE,
    CLICK_RIGHT,

    MOVE,
    DRAG_BEGIN

    // DOUBLE_CLICK,
    // ENTER,
    // LEAVE,
    // WHEEL
}

export class EquipmentType {
    static NONE = 'NONE'

    static SHIELD = 'SHIELD'

    static MASK = 'MASK'
    static HELMET = 'HELMET'
    static CHESTPLATE = 'CHESTPLATE'
    static BRACERS = 'BRACERS' // arm armor
    static GLOVES = 'GLOVES' // gauntlets
    static PAULDRONS = 'PAULDRONS' // shoulder armor
    static BELT = 'BELT'
    static GREAVES = 'GREAVES' // leg armor
    static BOOTS = 'BOOTS'

    static CLOAK = 'CLOAK' // CLOACK = heavier, longer, has a hood | CAPE = lighter, shorter, no hood

    static RING = 'RING'
    static NECKLACE = 'NECKLACE'
    static AMULET = 'AMULET'
    static EMBLEM = 'EMBLEM'

    static LEGGINGS = 'LEGGINGS' // under armor
    static BRACELET = 'BRACELET'
    static HAT = 'HAT'
    static EARRINGS = 'EARRINGS'
    static CROWN = 'CROWN'

    static UNDERARMOR = 'UNDERARMOR' // Example: chainmail, gambeson, leather armor, etc.

    // diadem, arm bandage,
}

/////////////////////////////////////////////////////////////////////////////// + ///////////////////////////////////////////////////////////////////////////////


// The 13 elemental forces
export enum ElementalForces {
    CHAOS,
    ORDER,
    LIFE,
    DEATH,
    GOOD,
    EVIL,
    CELESTIAL,
    BESTIAL,
    LIGHT,
    DARKNESS,

    NEUTRAL,
    REVERSE,
    VOID
}

// The 13 elements
export enum Elements {
    FIRE, // Lava, volcano, super nova, sun heaat, explosion, fireball, firestorm, fire elemental, fire giant, fire snake
    WATER,
    EARTH, // Mud
    AIR,
    LIGHTNING,
    ICE,
    STONE, // Wall, rock, boulder, mountain, hill, cliff, cave, cavern, dungeon, fortress, castle, tower, keep, citadel, palace, temple, tomb, crypt, catacombs, ruins, mine, quarry, pit, well, tunnel, bridge, aqueduct, sewer, canal, moat, trench, ditch, alley,
    METAL,
    WOOD,
    CRYSTAL,
    GRAVITY,
    RADIOACTIVITY,
    MAGNETISM,
}

// The 13 holy constellations
// Time affects how the constellations affect the world based on the time of the year and the time of the day.
// Holy constellations are more powerful during the day, while unholy constellations are more powerful during the night.
//
// People born during the day are more likely to be affected by holy constellations, while people born during the night are more likely to be affected by unholy constellations.
// People born during the day are thougth to be blessed while people born during the night tend to carry amulets and talismans to protect themselves and keep away evil spirits and ominous auras.
export enum HolyConstellations {
    STAG,
    PHOENIX,
    BEAR,
    GRIFFIN,
    LION,
    KRACKEN,
    SERPENT,
    HYDRA,
    EAGLE,
    CENTAUR,
    WOLF,
    DRAGON,
    SPIDER,
}

// The 13 unholy constellations
// Time affects how the constellations affect the world based on the time of the year and the time of the day.
// Holy constellations are more powerful during the day, while unholy constellations are more powerful during the night.
export enum UnholyConstellations {
    DEMON,
    DEVIL,
    VIPERA,
    GHOST,
    WRAITH,
    SPECTRE,
    ZOMBIE,
    SKELETON,
    WEREWOLF,
    WITCH,
    WARLOCK,
    NECROMANCER,
    LICH,
}

// 13 + 13 Holy Orders
//
// This is how each order is organized, ie the hierarchy of the order:
//
// For each order, there are 13 ranks. Each order might call its 13 ranks differently, but the hierarchy is the same.
// Each rank has the following number of members:
// 1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233 = 609 total official members + unlimited number of associates, recruits, applicants, etc.
//
// The top leaders of the order are proper characters that can be interacted with in the game.
//
// Each rank has the following attributes:
// - Name
// - Description
// - Symbol
// - Color
// - Rank
// - Title
// - Abilities
// - Powers
// - Privileges
// - Duties
// - Responsibilities
// - Requirements
// - Restrictions
// - Benefits
// - Rewards
// - Penalties
// - Punishments
// - Oaths
// - Vows
// - Pledges
// - Promises
// - Allegiance
// - Loyalty
// - Honor
// - Reputation
// - Status
// - Prestige
// - Fame
// - Glory
// - Renown
// - Influence
//
// - Grandmaster of the order
// - Captain of the order
// - General of the order
// - High priest of the order
// - High priestess of the order
// - High wizard of the order
// - High witch of the order
// - High warlock of the order
// - High necromancer of the order
// - High lich of the order
// - Grand commander of the order
// - Grand officer of the order
// - Grand knight of the order
// - Commander of the order
// - Officer of the order
// - Knight of the order
// - Squire of the order
// - Initiate of the order
// - Novice of the order
// - Acolyte of the order
// - Neophyte of the order
// - Disciple of the order
// - Follower of the order
// - Member of the order
// - Associate of the order
// - Recruit of the order
// - Applicant of the order
// - Outsider of the order
// - Enemy of the order
// - Traitor of the order
// - Heretic of the order
// - Apostate of the order
// - Defector of the order
// - Deserter of the order
// - Renegade of the order
// - Outcast of the order
// - Exile of the order
// - Pariah of the order
// - Banished of the order
// - Ostracized of the order
// - Forsaken of the order
// - Disgraced of the order
// - Fallen of the order
// - Disowned of the order
// - Shunned of the order
// - Abandoned of the order
// - Excommunicated of the order
// - Expelled of the order
// - Rejected of the order
// - Disavowed of the order
// - Discredited of the order
// - Disqualified of the order
export enum HolyOrders {
    // Order of the Noble Crest
    // Represents high-mindedness or noble quality.
    NOBILITY,

    // Order of the Blessed Guardians
    // Represents all that is good, right, and true.
    BLESSED,

    // Order of the Pure Heart
    // Symbolizes freedom from anything that debases or contaminates.
    PURITY,

    // Order of the Guiding Light
    // Emblems hope and purity in the darkest of times. They bring enlightenment. Light bringer.
    LIGHT,

    // Order of the True Balance
    // Embodies balance and the fight against injustice.
    JUSTICE,

    // Order of the Unclouded Eye
    // Represents clarity in a world of deceit and illusions.
    TRUTH,

    // Order of the Peaceful Confluence
    // Encourages balance and mutual understanding.
    HARMONY,

    // Order of the New Dawn
    // Symbolizes regeneration and new beginnings.
    REBIRTH,

    // Order of the Restorative Hand
    // Emphasizes the power of restoration and well-being.
    HEALING,

    // Order of the Second Chance
    // Offers a path for characters to atone for past misdeeds.
    REDEMPTION,

    // Order of the Noble Sacrifice
    // Highlights the cost of great power or the common good.
    SACRIFICE,

    // Order of the Unyielding Mountain
    // Embodies physical might and determination.
    STRENGTH,

    // Order of the Guardian Shield
    // Evokes guardianship and shielding the weak.
    PROTECTION,

    // Order of the Honorable Blade
    // Focuses on the code by which knights and warriors live.
    HONOR,

    // Order of the Valiant Heart
    // Inspires bravery in the face of fearsome challenges.
    COURAGE,

    // Order of the Guiding Star
    // Prioritizes learning from experience and guiding others.
    WISDOM,

    // Order of the Ancient Scroll
    // Seeks ancient lore and hidden truths.
    KNOWLEDGE,

    // Order of the Hidden Realms
    // Encourages exploration of the unknown.
    MYSTERY,

    // Order of the Arcane Circle
    // Represents the arcane and supernatural forces.
    MAGIC,

    // Order of the Unwavering Belief
    // Stresses unwavering belief, hope and faith even in the face of doubt and adversity.
    // Focuses on the power of belief and the triumph of good gods.
    FAITH,

    // Order of the Kind Heart
    // Denotes selfless acts and benevolence.
    // Highlights compassion and leniency.
    // Embodies the power of forgiveness and absolution.
    CHARITY_N_MERCY,

    // Order of the Fated Ones
    // The chosen ones who are destined to save the world.
    CHOSEN,

    // Order of the Steadfast Path
    // Represents persistent effort and commitment.
    DILIGENCE,

    // Order of the Resilient Oak
    // Advocates for resilience against adversities.
    RESILIENCE,

    // Order of the Bloodline
    // Focuses on the importance of lineage, family ties and ancestral bonds.
    BLOOD,

    // Order of the Triumphant Banner
    // Emphasizes conquests and triumphs over challenges and victories in battle.
    VICTORY,
}

// 13 + 13 Unholy Orders
export enum UnholyOrders {
    // THEFT,
    // DISRESPECT,
    // DERISION,

    // Sect of the Broken Oath
    // Embraces treachery and the lack of noble quality.
    TREACHERY,

    // Sect of the Tainted Will
    // Denotes wrongness of principle or conduct; moral vice.
    CORRUPTION,

    // Order of the Unholy Taint
    // Represents all that contaminates or degrades.
    CONTAMINATION,

    // Cult of the Evernight
    // Symbolizes darkness and despair.
    DARKNESS,

    // Sect of the Iron Fist
    // Embodies chaos and promotes injustice.
    TYRANNY,

    // Order of the Mirage
    // Denotes deception and illusion.
    DECEIT,

    // Cult of the Endless Strife
    // Advocates for conflict, discord and confusion.
    DISCORD,

    // Sect of the Withering
    // Symbolizes degeneration and endings.
    DECAY,

    // Cult of the Tormentor
    // Represents harm and degradation.
    HARM,

    // Sect of the Shadowed Past
    // Promotes past sins and misdeeds.
    CORRUPTOR,

    // Order of the Leech
    // Takes advantage without any regard for the cost.
    EXPLOITATION,

    // Cult of the Fading Flame
    // Embodies physical weakness and vulnerability.
    WEAKNESS,

    // Sect of the Silent Threat
    // Encourages threats and endangerment.
    PERIL,

    // Order of the Black Mark
    // Denotes betrayal and lack of integrity.
    DISHONOR,

    // Cult of the Fleeing Shadow
    // Encourages fear and hesitancy.
    COWARDICE,

    // Sect of the Closed Eye
    // Prioritizes ignorance and the suppression of truth.
    IGNORANCE,

    // Order of the Hidden Scar
    // Suppresses truth and promotes secrets and confusion.
    OBSCURITY,

    // Sect of the Earthbound
    // Represents the known, mundane, and profane.
    PROFANITY,

    // Order of the Abyssal Circle
    // Represents the misuse of arcane and supernatural forces.
    DARK_ARTS,

    // Cult of the Broken Spirit
    // Encourages doubt, despair, and hopelessness.
    DESPAIR,

    // Sect of the Gilded Claw
    // Represents selfish acts and malevolence.
    GREED,

    // Order of the Barbed Whip
    // Denotes cruelty and harshness.
    CRUELTY,

    // Cult of the Slumbering Stone
    // Advocates for lethargy and avoidance of effort.
    SLOTH,

    // Sect of the Fallen Knees
    // Encourages fragility and surrender to adversities.
    SUBMISSION,

    // Order of the Forsaken Oath
    // Focuses on betrayal, treachery, and severing ties.
    BETRAYAL,

    // Cult of the Fallen Banner
    // Emphasizes defeats and failures in challenges.
    DEFEAT,
}

export enum Gods {
    VOID,
    ARCANE,
    CHANGE,
    VIOLATION,
    HOLY,
    UNHOLY,
    SHADOW,
    MAGIC,
    ELEMENTAL,
    NATURE,
    WISDOM,
    KNOWLEDGE,
    WAR,
    DESTRUCTION,
    CREATION,
    DEATH,
    LIFE,
    CHAOS,
    ORDER,
    GOOD,
    EVIL,
    LAW,
    TRICKERY,
    LUCK,
    FATE,
    TIME,
    SPACE,
    MIND,
    BODY,
    SOUL,
    SPIRIT,
    LIGHT,
    DARKNESS,
    DECAY,
    DECEIT,
    PAIN,
    PLEASURE,
    FEAR,
    HATE,
    LOVE,
    BEAUTY,
    FORTUNE,
    JUSTICE,
    MERCY,
    REDEMPTION,
    REVENGE,
    VENGEANCE,
    SUFFERING,
    SORROW,
    PITY,
    ENVY,
    GLUTTONY,
    GREED,
    LUST,
    PRIDE,
    SLOTH,
    WRATH,
    HOPE,
    DESPAIR,
    DREAMS,
    NIGHTMARES,
    INSANITY,
    MADNESS,
    INSPIRATION,
    CREATIVITY,
    ART,
    MUSIC,
    POETRY,
    WRITING,
    SONG,
    DANCE,
    DRAMA,
    THEATER,
    COMEDY,
    TRAGEDY,
    HISTORY,
    PAST,
    PRESENT,
    FUTURE,
    DESTINY,
    PROPHECY,
    OMENS,
    SIGNS,
    VISIONS,
    ILLUSIONS,
    LIES,
    TRUTH,
    ANIMALS,
    PLANTS,
    TREES,
    FORESTS,
    OCEANS,
    SEAS,
    RIVERS,
    LAKES,
    MOUNTAINS,
    HILLS,
    DESERTS,
    WASTELANDS,
    CAVES,
    UNDERGROUND,
    SKY,
    STARS,
    SUN,
    MOON,
    PLANETS,
    COMETS,
    METEORS,
    ASTEROIDS,
    METEORITES,
    SPELLS,
    WIZARDRY,
    WITCHCRAFT,
    SORCERY,
    NECROMANCY,
    ENCHANTMENTS,
    CHARMS,
    HEXES,
    CURSES,
    BLESSINGS,
    PRAYERS,
    RITUALS,
    SACRIFICES,
    OFFERINGS,

    AFTERLIFE,
    UNDERWORLD,
    UNDERSEA,
    SEABED,
    OCEAN,
    REEF,
    TRENCH,
    DEPTHS,
    SURFACE,
    HEAVENS,
    SAND,
    DUST,
    DIRT,
    MUD,
    CLAY,
    ROCK,
    HEAVEN,
    HELL,
    PURGATORY,
    LIMBO,
    PARADISE,
    NIRVANA,
    ENLIGHTENMENT,
    REINCARNATION,
    RESURRECTION,
    IMMORTALITY,
    MORTALITY,
    BIRTH,
    CONCEPTION,

    // ALTARS,
    // SHRINES,
    // TEMPLES,
    // CHURCHES,
    // CATHEDRALS,
    // MONASTERIES,
    // MONKS,
    // NUNS,
    // PRIESTS,
    // PRIESTESSES,
    // CLERGY,
    // CULTS,
    // SECTS,
    // RELIGIONS,
    // GODS,
    // GODDESSES,
    // ANGELS,
    // DEMONS,
    // DEVILS,
    // SPIRITS,
    // GHOSTS,
    // SOULS,

    GALAXIES,
    UNIVERSE,
    COSMOS,
    DIMENSIONS,
    REALITY,
    FIRE,
    WATER,
    EARTH,
    AIR,
    LIGHTNING,
    ICE,
    STONE,
    IRON,
    STEEL,
    GOLD,
    SILVER,
    COPPER,
    BRONZE,
    PLATINUM,
    TIN,
    LEAD,
    METAL,
    WOOD,
    CRYSTAL,
    GRAVITY,
    RADIOACTIVE,
    MAGNETIC,
    POISON,
    DISEASE,
    PLAGUE,
    HEALTH,
    HEALING,
    JOY,
    HAPPINESS,
    SADNESS,
    GRIEF,
    MISERY,
    ANGER,
    RAGE,
    JEALOUSY,
    RAIN,
    RAINFALL,
    SNOW,
    HAIL,
    SLEET,
    WIND,
    STORM,
    THUNDER,
    CLOUDS,
    FOG,
    MIST,
    SUNSHINE,
    DAWN,
    DUSK,
    DAY,
    NIGHT,
    MOONLIGHT,
    STARLIGHT,
    TWILIGHT,
    SHADOWS,
    SLIPPERY,
    FISHY,
    FISH,
    BIRDS,
    INSECTS,
    REPTILES,
    AMPHIBIANS,
    MAMMALS,
    RODENTS,
    PREDATORS,
    PREY,
    HUNTING,
    FISHING,
    TRAPPING,
    TRACKING,
    SURVIVAL,
    FORAGING,
    GATHERING,
    FARMING,
    AGRICULTURE,
    CROPS,
    HARVEST,
    SEEDS,
    PLANTING,
    GROWING,
    NATURAL,
    SUPERNATURAL,
    MYSTICAL,
    MYSTERY,
    MYTH,
    LEGEND,
    FOLKLORE,
    FAIRYTALE,
    FAIRIES,

    BALANCE,

    SEVEN_HEADED,
    BIFRONTE,

    LAST // the last god
}
