import { Vec2D } from './core'
import { EquipmentType } from './enums'
import { Thing, ThingDoor } from './thing'
import { assert } from './assert'

//---------------------------------------------------------
// Thing Templates
//---------------------------------------------------------
type ThingTemplateConfig = {
    type: string
    textureName: string
    canSeeThrough: boolean
    canWalkThrough: boolean
    canWalkBridge: boolean
    pileCount: number
    pivot: Vec2D
    equipment: boolean
    equipmentType: string
}

//---------------------------------------------------------
// ThingTemplateConfigDefault
//---------------------------------------------------------
const ThingTemplateConfigDefault = {
    type: '',
    textureName: '',
    canSeeThrough: true,
    canWalkThrough: true,
    canWalkBridge: false,
    pileCount: 1,
    pivot: new Vec2D(0, 0),
    equipment: false,
    equipmentType: EquipmentType.NONE,
}

//---------------------------------------------------------
// ThingFactory
//---------------------------------------------------------
export class ThingFactory {

    // --- Thing Templates ---

    private static thingTypes: ThingTemplateConfig[] = [
        { ...ThingTemplateConfigDefault, type: 'Bridge',     textureName: 'Bridge',     canWalkThrough: true,  canSeeThrough: true, canWalkBridge: true, pileCount: 1 },
        { ...ThingTemplateConfigDefault, type: 'Door', textureName: 'Door', canWalkThrough: false, canSeeThrough: false, pileCount: 1 },
        { ...ThingTemplateConfigDefault, type: 'StairsDown', textureName: 'StairsDown', canWalkThrough: true,  canSeeThrough: true, pileCount: 1 },
        { ...ThingTemplateConfigDefault, type: 'StairsUp',   textureName: 'StairsUp',   canWalkThrough: true,  canSeeThrough: true, pileCount: 1 },
        { ...ThingTemplateConfigDefault, type: 'Treasure',   textureName: 'Treasure',   canWalkThrough: false, canSeeThrough: true, pileCount: 1 },
        { ...ThingTemplateConfigDefault, type: 'Armery',     textureName: 'Armery',     canWalkThrough: false, canSeeThrough: true, pileCount: 1 },

        { ...ThingTemplateConfigDefault, type: 'Tree-Pink-1',   textureName: 'Thing-Tree-Pink-1', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*2, 16*3.5) },
        { ...ThingTemplateConfigDefault, type: 'Tree-Pink-2',   textureName: 'Thing-Tree-Pink-2', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*2, 16*3.5) },
        { ...ThingTemplateConfigDefault, type: 'Tree-Pink-3',   textureName: 'Thing-Tree-Pink-3', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1, 16*3.5) },
        { ...ThingTemplateConfigDefault, type: 'Tree-Pink-4',   textureName: 'Thing-Tree-Pink-4', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1, 16*3.5) },

        { ...ThingTemplateConfigDefault, type: 'Tree-Green1-1',   textureName: 'Thing-Tree-Green1-1', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1.5, 16*3.5) },
        { ...ThingTemplateConfigDefault, type: 'Tree-Green1-2',   textureName: 'Thing-Tree-Green1-2', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1.5, 16*3.5) },
        { ...ThingTemplateConfigDefault, type: 'Tree-Green1-3',   textureName: 'Thing-Tree-Green1-3', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1, 16*3.5) },
        { ...ThingTemplateConfigDefault, type: 'Tree-Green1-4',   textureName: 'Thing-Tree-Green1-4', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1, 16*3.5) },

        { ...ThingTemplateConfigDefault, type: 'Tree-Green2-1',   textureName: 'Thing-Tree-Green2-1', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1.5, 16*3.5) },
        { ...ThingTemplateConfigDefault, type: 'Tree-Green2-2',   textureName: 'Thing-Tree-Green2-2', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1.5, 16*3.5) },
        { ...ThingTemplateConfigDefault, type: 'Tree-Green2-3',   textureName: 'Thing-Tree-Green2-3', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1, 16*3.5) },
        { ...ThingTemplateConfigDefault, type: 'Tree-Green2-4',   textureName: 'Thing-Tree-Green2-4', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1, 16*3.5) },

        { ...ThingTemplateConfigDefault, type: 'Tree-Green3-1',   textureName: 'Thing-Tree-Green3-1', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1.5, 16*3.5) },
        { ...ThingTemplateConfigDefault, type: 'Tree-Green3-2',   textureName: 'Thing-Tree-Green3-2', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1.5, 16*3.5) },
        { ...ThingTemplateConfigDefault, type: 'Tree-Green3-3',   textureName: 'Thing-Tree-Green3-3', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1, 16*3.5) },
        { ...ThingTemplateConfigDefault, type: 'Tree-Green3-4',   textureName: 'Thing-Tree-Green3-4', canWalkThrough: false, canSeeThrough: false, pileCount: 1, pivot: new Vec2D(16*1, 16*3.5) },

        // --- These objects should be marked as isPlaced = false
        { ...ThingTemplateConfigDefault, type: 'Pickaxe | Iron', textureName: 'Item-129', pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Sword | Iron',    textureName: 'Item-130', pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Shovel | Iron',   textureName: 'Item-131', pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Axe | Iron',      textureName: 'Item-132', pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Hoe | Iron',      textureName: 'Item-133', pileCount: 0 },
        // ---
        { ...ThingTemplateConfigDefault, type: 'Pickaxe | Gold', textureName: 'Item-145', pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Sword | Gold',    textureName: 'Item-146', pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Shovel | Gold',   textureName: 'Item-147', pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Axe | Gold',      textureName: 'Item-148', pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Hoe | Gold',      textureName: 'Item-149', pileCount: 0 },
        // --
        { ...ThingTemplateConfigDefault, type: 'Bow 1', textureName: 'Item-005', pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Bow 2', textureName: 'Item-029', pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Bow 3', textureName: 'Item-030', pileCount: 0 },
        // --
        { ...ThingTemplateConfigDefault, type: 'Arrow 1', textureName: 'Item-057' },
        // { ...ThingTemplateConfigDefault, type: 'Arrow 2', textureName: 'Item-058' },
        // { ...ThingTemplateConfigDefault, type: 'Arrow 3', textureName: 'Item-059' },
        // { ...ThingTemplateConfigDefault, type: 'Arrow 4', textureName: 'Item-060' },
        // { ...ThingTemplateConfigDefault, type: 'Arrow 5', textureName: 'Item-061' },
        // --
        { ...ThingTemplateConfigDefault, type: 'Shield 1', textureName: 'Item-062', equipment: true, equipmentType: EquipmentType.SHIELD, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Shield 2', textureName: 'Item-063', equipment: true, equipmentType: EquipmentType.SHIELD, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Shield 3', textureName: 'Item-064', equipment: true, equipmentType: EquipmentType.SHIELD, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Shield 4', textureName: 'Item-065', equipment: true, equipmentType: EquipmentType.SHIELD, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Shield 5', textureName: 'Item-066', equipment: true, equipmentType: EquipmentType.SHIELD, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Shield 6', textureName: 'Item-067', equipment: true, equipmentType: EquipmentType.SHIELD, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Shield 7', textureName: 'Item-068', equipment: true, equipmentType: EquipmentType.SHIELD, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Shield 8', textureName: 'Item-069', equipment: true, equipmentType: EquipmentType.SHIELD, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Shield 9', textureName: 'Item-070', equipment: true, equipmentType: EquipmentType.SHIELD, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Shield 10', textureName: 'Item-073', equipment: true, equipmentType: EquipmentType.SHIELD, pileCount: 0 },
        // // --
        { ...ThingTemplateConfigDefault, type: 'Chestplate 1', textureName: 'Item-074', equipment: true, equipmentType: EquipmentType.CHESTPLATE, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Chestplate 2', textureName: 'Item-075', equipment: true, equipmentType: EquipmentType.CHESTPLATE, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Chestplate 3', textureName: 'Item-076', equipment: true, equipmentType: EquipmentType.CHESTPLATE, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Chestplate 4', textureName: 'Item-077', equipment: true, equipmentType: EquipmentType.CHESTPLATE, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Chestplate 5', textureName: 'Item-078', equipment: true, equipmentType: EquipmentType.CHESTPLATE, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Chestplate 5', textureName: 'Item-083', equipment: true, equipmentType: EquipmentType.CHESTPLATE, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Chestplate 6', textureName: 'Item-084', equipment: true, equipmentType: EquipmentType.CHESTPLATE, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Chestplate 7', textureName: 'Item-085', equipment: true, equipmentType: EquipmentType.CHESTPLATE, pileCount: 0 },
        // // --
        { ...ThingTemplateConfigDefault, type: 'Helmet 1',  textureName: 'Item-088', equipment: true, equipmentType: EquipmentType.HELMET, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Helmet 2',  textureName: 'Item-089', equipment: true, equipmentType: EquipmentType.HELMET, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Helmet 3',  textureName: 'Item-090', equipment: true, equipmentType: EquipmentType.HELMET, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Helmet 4',  textureName: 'Item-091', equipment: true, equipmentType: EquipmentType.HELMET, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Helmet 5',  textureName: 'Item-092', equipment: true, equipmentType: EquipmentType.HELMET, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Helmet 6',  textureName: 'Item-093', equipment: true, equipmentType: EquipmentType.HELMET, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Helmet 7',  textureName: 'Item-094', equipment: true, equipmentType: EquipmentType.HELMET, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Helmet 8',  textureName: 'Item-095', equipment: true, equipmentType: EquipmentType.HELMET, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Helmet 9',  textureName: 'Item-096', equipment: true, equipmentType: EquipmentType.HELMET, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Helmet 10', textureName: 'Item-097', equipment: true, equipmentType: EquipmentType.HELMET, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Helmet 11', textureName: 'Item-098', equipment: true, equipmentType: EquipmentType.HELMET, pileCount: 0 },
        // // --
        { ...ThingTemplateConfigDefault, type: 'Boots 1', textureName: 'Item-109', equipment: true, equipmentType: EquipmentType.BOOTS, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Boots 2', textureName: 'Item-110', equipment: true, equipmentType: EquipmentType.BOOTS, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Boots 3', textureName: 'Item-111', equipment: true, equipmentType: EquipmentType.BOOTS, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Boots 4', textureName: 'Item-112', equipment: true, equipmentType: EquipmentType.BOOTS, pileCount: 0 },
        // // --
        { ...ThingTemplateConfigDefault, type: 'Ring 1', textureName: 'Item-099', equipment: true, equipmentType: EquipmentType.RING, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Ring 2', textureName: 'Item-100', equipment: true, equipmentType: EquipmentType.RING, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Ring 3', textureName: 'Item-101', equipment: true, equipmentType: EquipmentType.RING, pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Ring 4', textureName: 'Item-102', equipment: true, equipmentType: EquipmentType.RING, pileCount: 0 },
        // --
        { ...ThingTemplateConfigDefault, type: 'Neclace 1', textureName: 'Item-103', equipment: true, equipmentType: EquipmentType.NECKLACE, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Neclace 2', textureName: 'Item-104', equipment: true, equipmentType: EquipmentType.NECKLACE, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Neclace 3', textureName: 'Item-105', equipment: true, equipmentType: EquipmentType.NECKLACE, pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Neclace 4', textureName: 'Item-106', equipment: true, equipmentType: EquipmentType.NECKLACE, pileCount: 0 },
        // --
        { ...ThingTemplateConfigDefault, type: 'Staff 1', textureName: 'Item-006', pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Staff 2', textureName: 'Item-038', pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Staff 3', textureName: 'Item-031', pileCount: 0 },
        // { ...ThingTemplateConfigDefault, type: 'Staff 4', textureName: 'Item-032', pileCount: 0 },
        // --
        { ...ThingTemplateConfigDefault, type: 'Watering Can', textureName: 'Item-153', pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Fishing Rod',  textureName: 'Item-162', pileCount: 0 },
        { ...ThingTemplateConfigDefault, type: 'Scissors',     textureName: 'Item-163', pileCount: 0 },

        { ...ThingTemplateConfigDefault, type: 'Ore | Iron', textureName: 'Item-164' },
        { ...ThingTemplateConfigDefault, type: 'Ingott | Iron', textureName: 'Item-167' },
        { ...ThingTemplateConfigDefault, type: 'Ore | Gold', textureName: 'Item-168' },
        { ...ThingTemplateConfigDefault, type: 'Ingott | Gold', textureName: 'Item-177' },
        // --
        { ...ThingTemplateConfigDefault, type: 'Wood Sticks 1', textureName: 'Item-193' },
        { ...ThingTemplateConfigDefault, type: 'Wood Sticks 2', textureName: 'Item-194' },
        // { ...ThingTemplateConfigDefault, type: 'Wood Sticks 3', textureName: 'Item-195' },
        // { ...ThingTemplateConfigDefault, type: 'Wood Sticks 4', textureName: 'Item-196' },
        // --
        // /*{ ...ThingTemplateConfigDefault, type: 'Thing Shadow', textureName: 'Item-197' },*/
        // --
        { ...ThingTemplateConfigDefault, type: 'Potion 1', textureName: 'Item-305' },
        { ...ThingTemplateConfigDefault, type: 'Potion 2', textureName: 'Item-306' },
        // { ...ThingTemplateConfigDefault, type: 'Potion 3', textureName: 'Item-307' },
        // { ...ThingTemplateConfigDefault, type: 'Potion 4', textureName: 'Item-313' },
        // --
        { ...ThingTemplateConfigDefault, type: 'Wall-8-001', textureName: 'Wall-8-001' },
        { ...ThingTemplateConfigDefault, type: 'Wall-8-002', textureName: 'Wall-8-002' },
        // { ...ThingTemplateConfigDefault, type: 'Wall-8-003', textureName: 'Wall-8-003' },
        // { ...ThingTemplateConfigDefault, type: 'Wall-8-004', textureName: 'Wall-8-004' },
        { ...ThingTemplateConfigDefault, type: 'Floor-8-001', textureName: 'Floor-8-001' },
        { ...ThingTemplateConfigDefault, type: 'Floor-8-002', textureName: 'Floor-8-002' },
        // { ...ThingTemplateConfigDefault, type: 'Floor-8-003', textureName: 'Floor-8-003' },
        // { ...ThingTemplateConfigDefault, type: 'Floor-8-004', textureName: 'Floor-8-004' },
    ]

    // Map: name -> Thing
    private static thingTemplates: { [key: string]: Thing } = {}

    static newThing(type: string): Thing {
        switch (type) {
            case 'Door': return new ThingDoor(type);
            default:
                return new Thing(type);
        }
    }

    static initThingTemplates() {
        // do as above but in a for loop
        for (const thingType of this.thingTypes) {
            const thing = this.newThing(thingType.type) // new Thing(thingType.type)

            // thing.type = thingType.type
            // thing.name = thingType.type
            thing.textureName = thingType.textureName
            thing.canSeeThrough = thingType.canSeeThrough !== false // default is true
            thing.canWalkThrough = thingType.canWalkThrough !== false // default is true
            thing.canWalkBridge = thingType.canWalkBridge === true // default is false
            thing.isEquipment = thingType.equipment === true // default is false
            thing.equipmentType = thingType.equipmentType || EquipmentType.NONE
            thing.isFloating = false
            // if pileCount defined then use it else 1
            thing.pileCount = thingType.pileCount === 0 ? 0 : 1
            thing.texture = null
            thing.texturePivot = thingType.pivot || thing.texturePivot
            this.thingTemplates[thingType.type] = thing

            // Initialize textures
            thing.init()
        }
    }

    private static getThingTemplate(type: string): Thing {
        const thing = ThingFactory.thingTemplates[type]
        assert(thing !== undefined, `ThingFactory.getThingTemplate(): unknown type '${type}'`)
        return thing
    }

    static getThingInstance(type: string): Thing {
        const thing = this.getThingTemplate(type).shallowClone()
        assert(thing.texture !== null, `ThingFactory.getThingInstance('${type}') texture is null`)
        assert(thing.type === type, `ThingFactory.getThingInstance('${type}') type is not '${type}'`)
        return thing
    }

    static getThingNames(): string[] {
        return Object.keys(this.thingTemplates)
    }
}
