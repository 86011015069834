// Tile Meta View: do we need it?
//
// Complete basic version of standard base tiles
// - [ ] 10x brick walls
// - [ ] 10x rocky walls
// - [ ] 10x stone walls
// - [ ] 10x smooth / verly low noise colourful tiles for fancy colorful dungeons and buildings (check minecraft)
//
// - 10-20x basic grass, dirt, dead grass, sand, water, lava, black, brown, gray, red demon etc. tiles noise + accent colors optional
//
// === Texture Testing Demo ===
//
// > test peppering levels with shades -> generate tiles ids procedurally
// > walls: randomly assign variations - variables are: coverage, variation type and strength
//   - effects on building walls: moss, cracks, etc can be different from cave walls
//   - procedurally generate moss/rampicanti of different colors, cracks, add noise, etc
// > make larger non repeating textures for grass, dirt, etc noise based 8 x 8 tiles
// > each 8x8 megatile with different hue, lightness, saturation - dead grass has lower saturation, etc
// > make grass and dirt have patches based on simplex (or life game)
// > make grass and dirt be different close to walls and water

import { RGBA, HSL, randomChoice, hexToRGBA } from './core'
import { assert } from './assert'
import { Config } from './config'
import { Events } from './events'
import { sprintf } from 'sprintf-js';
import { ImageTransform } from "./image-transform"

const SPRITE_SIZE = Config.TILE_PIXEL_SIZE

//-----------------------------------------------------------------------------
// Textures Unility Class
//-----------------------------------------------------------------------------
export class Texture {
    handle: HTMLImageElement | null = null

    // tile coordinates within original texture
    tx: number = 0
    ty: number = 0
    tw: number = 0
    th: number = 0

    // full width and height of original texture
    fullW: number = 0
    fullH: number = 0

    constructor(handle: HTMLImageElement | null, tx: number = 0, ty: number = 0, tw: number = -1, th: number = -1) {
        this.handle = handle
        this.fullW = handle ? handle.width : 0
        this.fullH = handle ? handle.height : 0
        this.tx = tx
        this.ty = ty
        this.tw = tw >= 0 ? tw : this.fullW
        this.th = th >= 0 ? th : this.fullH
    }

    clone(): Texture {
        const texture = new Texture(this.handle)
        texture.tx = this.tx
        texture.ty = this.ty
        texture.tw = this.tw
        texture.th = this.th
        texture.fullW = this.fullW
        texture.fullH = this.fullH
        return texture
    }
}

//-----------------------------------------------------------------------------
// Textures Unility Class
//-----------------------------------------------------------------------------
export class TextureUtils {
    private static textures: { [key: string]: Texture } = {}

    private constructor() { }

    static getTexture(name: string): Texture {
        assert(this.textures[name] !== undefined, `Texture "${name}" not found`)
        return this.textures[name] || this.textures['Undefined']
    }

    static getTextureNames(): string[] {
        return Object.keys(this.textures)
    }

    static setTexture(name: string, texture: Texture): Texture {
        this.textures[name] = texture
        return texture
    }

    static generateNoiseTexture(wh: number, color: RGBA): Texture {
        const imageData: ImageData = ImageTransform.createImageData(wh, wh, new RGBA(255, 255, 255, 255))
        ImageTransform.run(imageData, [
            {
                tr: ImageTransform.mapColors,
                args: {
                    colorMap: {
                        '255,255,255,255': color,
                    }
                }
            },
            {
                tr: ImageTransform.addNoise,
                args: {
                    noiseStrength: 0.115,
                    noiseCoverage: 0.250
                }
            },
        ])

        const canvas = document.createElement('canvas')
        canvas.width = wh
        canvas.height = wh
        const ctx = canvas.getContext('2d')!
        ctx.putImageData(imageData, 0, 0)

        // create a texture from the image
        const image = new Image()
        image.src = canvas.toDataURL()
        const texture = new Texture(image)
        // explicit setter due to html image might not be ready just yet
        texture.tx = 0
        texture.ty = 0
        texture.tw = wh
        texture.th = wh
        texture.fullW = wh
        texture.fullH = wh
        return texture
    }

    static async initializeTextures() {
        // if already initialized then return
        if (Object.keys(this.textures).length > 0) {
            return
        }

        // Async - Load textures from texture atlas (tileset) images
        await this.loadTextureAtlases()

        // Async - Generate noise textures
        await this.generateNoiseTextures()

        // Loads trees
        await this.loadTextureTrees()

        // Generate icons
        await this.generateIconTextures()

        this.generateShadowTextures('Shadow', new RGBA(0, 0, 0, 192), 4, 4)
        this.generateShadowTextures('Shadow-FoW', new RGBA(0,0,0,255), 6, 6)

        await this.loadSpriteSheet('/img/sprite-sheets/chara6.png', 12, 8, 'Chara6-')
        // await this.loadSpriteSheet('/img/sprite-sheets/chara7.png', 12, 8, 'Chara7-')
        // await this.loadSpriteSheet('/img/sprite-sheets/chara8.png', 12, 8, 'Chara8-')

        // await this.loadSpriteSheet('/img/sprite-sheets/npc5.png', 12, 8, 'Npc5-')
        // await this.loadSpriteSheet('/img/sprite-sheets/npc6.png', 12, 8, 'Npc6-')

        await this.loadSpriteSheet('/img/sprite-sheets/orc1.png', 12, 8, 'Orc1-')
        // await this.loadSpriteSheet('/img/sprite-sheets/orc2.png', 12, 8, 'Orc2-')

        await this.loadSpriteSheet('/img/sprite-sheets/monster1.png', 12, 8, 'Monster1-')
        await this.loadSpriteSheet('/img/sprite-sheets/monster2.png', 12, 8, 'Monster2-')
        await this.loadSpriteSheet('/img/sprite-sheets/monster3.png', 12, 8, 'Monster3-')
        await this.loadSpriteSheet('/img/sprite-sheets/monster4.png', 12, 8, 'Monster4-')

        // await this.loadSpriteSheet('/img/sprite-sheets/horse1.png', 12, 8, 'Horse-')
        // await this.loadSpriteSheet('/img/sprite-sheets/mount1.png', 12, 8, 'Mount1-')
        // await this.loadSpriteSheet('/img/sprite-sheets/mount2.png', 12, 8, 'Mount2-')

        // await this.loadSpriteSheet('/img/sprite-sheets/elemental.png', 12, 8, 'Elemental-')
        await this.loadSpriteSheet('/img/sprite-sheets/wizard.png', 12, 8, 'Wizard-')

        const bosses = [
            // { path: '/img/sprite-sheets/monster_dknight1.png', name: 'Monster-DNight1-' },
            // { path: '/img/sprite-sheets/monster_dknight2.png', name: 'Monster-DNight2-' },
            // { path: '/img/sprite-sheets/monster_bird1.png', name: 'Monster-Bird1-' },
            // { path: '/img/sprite-sheets/monster_bird2.png', name: 'Monster-Bird2-' },
            // { path: '/img/sprite-sheets/monster_bird3.png', name: 'Monster-Bird3-' },
            // { path: '/img/sprite-sheets/monster_boar.png', name: 'Monster-Boar-' },
            // { path: '/img/sprite-sheets/monster_cacto.png', name: 'Monster-Cacto-' },
            // { path: '/img/sprite-sheets/monster_elk.png', name: 'Monster-Elk-' },
            // { path: '/img/sprite-sheets/monster_golem1.png', name: 'Monster-Golem1-' },
            // { path: '/img/sprite-sheets/monster_golem2.png', name: 'Monster-Golem2-' },
            { path: '/img/sprite-sheets/monster_lich.png', name: 'Monster-Lich-' },
            // { path: '/img/sprite-sheets/monster_lizardman1.png', name: 'Monster-Lizardman1-' },
            // { path: '/img/sprite-sheets/monster_lizardman2.png', name: 'Monster-Lizardman2-' },
            // { path: '/img/sprite-sheets/monster_minotaur.png', name: 'Monster-Minotaur-' },
            // { path: '/img/sprite-sheets/monster_phoenix.png', name: 'Monster-Phoenix-' },
            // { path: '/img/sprite-sheets/monster_raptor1.png', name: 'Monster-Raptor1-' },
            // { path: '/img/sprite-sheets/monster_raptor2.png', name: 'Monster-Raptor2-' },
            // { path: '/img/sprite-sheets/monster_treant.png', name: 'Monster-Treant-' },
            // { path: '/img/sprite-sheets/monster_wolf1.png', name: 'Monster-Wolf1-' },
            // { path: '/img/sprite-sheets/monster_wolf2.png', name: 'Monster-Wolf2-' },
        ]

        for (const boss of bosses) {
            await this.loadSpriteSheet(boss.path, 3, 4, boss.name)
        }

        // await this.waitAllTexturesLoaded()

        this.createTextureNameAliases()
        console.log('Texture initialization complete.')
    }


    static generateNoiseTextures() {
        const wh = Config.TILE_PIXEL_SIZE * 5

        TextureUtils.setTexture('ERROR', TextureUtils.generateNoiseTexture(wh, new RGBA(255, 0, 0, 255)))
        TextureUtils.setTexture('EMPTY', TextureUtils.generateNoiseTexture(wh, new RGBA(0, 0, 0, 255)))
        TextureUtils.setTexture('Substratum-ROCK', TextureUtils.generateNoiseTexture(wh, new RGBA(40, 40, 40, 255)))
        TextureUtils.setTexture('Floor-CAVE', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#290202'))) // dark red
        TextureUtils.setTexture('Floor-GRASS', TextureUtils.generateNoiseTexture(wh, new RGBA(0, 128, 0, 255))) // green
        TextureUtils.setTexture('Floor-DIRT', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#9C6E00'))) // brown
        TextureUtils.setTexture('Floor-ROAD', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#FFC600'))) // yellowish
        TextureUtils.setTexture('Floor-SAND', TextureUtils.generateNoiseTexture(wh, new RGBA(255, 255, 0, 255))) // yellow
        TextureUtils.setTexture('Floor-SNOW', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#DDF4FF'))) // white blue
        TextureUtils.setTexture('Floor-ICE', TextureUtils.generateNoiseTexture(wh, new RGBA(0, 255, 255, 255))) // cyan
        TextureUtils.setTexture('Floor-SWAMP', TextureUtils.generateNoiseTexture(wh, new RGBA(0, 128, 128, 255))) // teal
        TextureUtils.setTexture('Floor-STONE', TextureUtils.generateNoiseTexture(wh, new RGBA(128, 128, 128, 255))) // gray
        TextureUtils.setTexture('Floor-WOOD', TextureUtils.generateNoiseTexture(wh, new RGBA(139, 69, 19, 255))) // darksalmon
        TextureUtils.setTexture('Floor-WATER_SHALLOW', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#56A7CF'))) // blue
        TextureUtils.setTexture('Floor-WATER_RIVER', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#5687FF'))) // blue
        TextureUtils.setTexture('Floor-WATER_SEA', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#0030A5'))) // blue
        TextureUtils.setTexture('Floor-LAVA', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#EC4141'))) // red
        TextureUtils.setTexture('Wall-CAVE', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#8C6DB3'))) // sort of purple
        TextureUtils.setTexture('Wall-ROCK', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#837A5A'))) // gray
        TextureUtils.setTexture('Wall-STONE_EXT', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#829183'))) // gray
        TextureUtils.setTexture('Wall-BRICK_EXT', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#8C5656'))) // darksalmon
        TextureUtils.setTexture('Wall-STONE_INT', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#779C79'))) // gray
        TextureUtils.setTexture('Wall-BRICK_INT', TextureUtils.generateNoiseTexture(wh, hexToRGBA('#C17474'))) // darksalmon
    }

    static createTextureNameAliases() {
        const spriteMapThings: any = {
            "StairsDown": "Thing-8-001",
            "StairsUp": "Thing-8-002",
            "Tree": "Thing-8-003",
            "Bridge": "Thing-8-004",
            "Treasure": "Thing-8-005",
            "DoorClosed": "Thing-8-006",
            "DoorOpen": "Thing-8-007",
            "Armery": "Thing-8-008",
            "Potion1": "Thing-8-009",
            "Potion2": "Thing-8-010",
            "Potion3": "Thing-8-011"
        }
        Object.keys(spriteMapThings).forEach((spriteName, i) => {
            this.textures[spriteName] = this.getTexture(spriteMapThings[spriteName])
            this.textures[spriteName+':Icon'] = this.getTexture(spriteMapThings[spriteName] + ':Icon')
        })

        // const spriteMapCharacters: any =
        // {
        //     "Player": "Chars8-001",
        //     "Knight1": "Chars8-002",
        //     "DarkKnight5": "Chars8-003",
        //     "DarkKnight4": "Chars8-004",
        //     "DarkKnight3": "Chars8-005",
        //     "DarkKnight2": "Chars8-006",
        //     "DarkKnight1": "Chars8-007",
        //     "Snake": "Chars8-008",
        //     "Bat": "Chars8-009",
        //     "GruesomeEye": "Chars8-010",
        //     "Ghost": "Chars8-011",
        //     "Zombie": "Chars8-012",
        //     "Goblin": "Chars8-013",
        //     "Orc": "Chars8-014",
        //     "Vampire": "Chars8-015",
        //     "Skeleton": "Chars8-016",
        //     "Spider": "Chars8-017"
        // }
        // Object.keys(spriteMapCharacters).forEach((spriteName, i) => {
        //     this.textures[spriteName] = this.getTexture(spriteMapCharacters[spriteName])
        // })
    }

    // Loads tree sprites from atlases
    static async loadTextureTrees() {
        const configs = [
            {
                path: '/img/sprite-sheets/trees1.png',
                sprites: [
                    { x: 0,    y: 0, w: 16*3, h: 16*4, name: 'Thing-Tree-Green1-1' },
                    { x: 16*3, y: 0, w: 16*3, h: 16*4, name: 'Thing-Tree-Green1-2' },
                    { x: 16*6, y: 0, w: 16*2, h: 16*4, name: 'Thing-Tree-Green1-3' },
                    { x: 16*8, y: 0, w: 16*2, h: 16*4, name: 'Thing-Tree-Green1-4' },

                    { x: 0,    y: 16*4, w: 16*3, h: 16*4, name: 'Thing-Tree-Green2-1' },
                    { x: 16*3, y: 16*4, w: 16*3, h: 16*4, name: 'Thing-Tree-Green2-2' },
                    { x: 16*6, y: 16*4, w: 16*2, h: 16*4, name: 'Thing-Tree-Green2-3' },
                    { x: 16*8, y: 16*4, w: 16*2, h: 16*4, name: 'Thing-Tree-Green2-4' },

                    { x: 0,    y: 16*8, w: 16*3, h: 16*4, name: 'Thing-Tree-Green3-1' },
                    { x: 16*3, y: 16*8, w: 16*3, h: 16*4, name: 'Thing-Tree-Green3-2' },
                    { x: 16*6, y: 16*8, w: 16*2, h: 16*4, name: 'Thing-Tree-Green3-3' },
                    { x: 16*8, y: 16*8, w: 16*2, h: 16*4, name: 'Thing-Tree-Green3-4' },
                ]
            },
            {
                path: '/img/sprite-sheets/trees2.png',
                sprites: [
                    { x: 0,    y: 0, w: 16*4, h: 16*4, name:  'Thing-Tree-Pink-1' },
                    { x: 16*4, y: 0, w: 16*4, h: 16*4, name:  'Thing-Tree-Pink-2' },
                    { x: 16*8, y: 0, w: 16*2, h: 16*4, name:  'Thing-Tree-Pink-3' },
                    { x: 16*10, y: 0, w: 16*2, h: 16*4, name: 'Thing-Tree-Pink-4' },
                ]
            },
        ]

        for( const config of configs ) {
            // const promises: Promise<HTMLImageElement>[] = []

            const atlas = new Image()
            atlas.src = config.path
            atlas.onload = () => {
                for (const sprite of config.sprites) {
                    const texture = new Texture(atlas, sprite.x, sprite.y, sprite.w, sprite.h)
                    assert(texture.fullW === atlas.width && texture.fullH === atlas.height, `Invalid texture size: ${texture.fullW}x${texture.fullH}`)
                    this.setTexture(sprite.name, texture)
                    // const canvas = document.createElement('canvas')
                    // canvas.width = sprite.w
                    // canvas.height = sprite.h
                    // const ctx = canvas.getContext('2d')!
                    // ctx.drawImage(atlas, sprite.x, sprite.y, sprite.w, sprite.h, 0, 0, sprite.w, sprite.h)
                    // const image = new Image()
                    // image.src = canvas.toDataURL()

                    // // add to promises
                    // promises.push(new Promise((resolve, reject) => {
                    //     image.onload = () => {
                    //         console.log('Tree loaded', sprite.name)
                    //         this.setTexture(sprite.name, new Texture(image))
                    //         resolve(image)
                    //     }
                    // }))
                }
            }

            // wait for tree1 to load
            await new Promise((resolve, reject) => {
                const onloadOriginal: any = atlas.onload
                atlas.onload = () => {
                    console.log('Tree loaded', config.path)
                    if (onloadOriginal)
                        onloadOriginal()
                    resolve(atlas)
                }
            })

            console.log('Waiting for all tree textures to load...')
            // await Promise.all(promises)
            console.log('[v] Tree textures loaded.')
        }
    }

    static async loadTextureAtlases() {
            // we assume these don't need icons for now
            const textureAtlases = [
            {
                path: '/img/tiles/items-16x16.png',
                prefix: 'Item-',
                tileSize: 16,
                count: 16*20,
            },
            // mic fixme: remove this
            // {
            //     path: '/img/tiles/chars.png',
            //     prefix: 'Chars8-',
            //     tileSize: 16,
            //     count: 17,
            // },
            // mix fixme: remove or generate icons for this
            // legacy 8-pixel objects
            {
                path: '/img/tiles/objs.png',
                prefix: 'Thing-8-',
                tileSize: 16,
                count: 11,
            },
            // mix fixme: generate icons for this
            {
                path: '/img/tiles/floor-16x16.png',
                prefix: 'Floor-8-',
                tileSize: 16,
                isFloor: true,
                count: 145,
            },
            // mix fixme: generate icons for this
            {
                path: '/img/tiles/wall-16x16.png',
                prefix: 'Wall-8-',
                tileSize: 16,
                isWall: true,
                count: 87
            },
        ]

        const tileSetImages: HTMLImageElement[] = []
        for (const atlasInfo of textureAtlases) {
            const atlasImage: HTMLImageElement = new Image()
            tileSetImages.push(atlasImage)
            atlasImage.src = atlasInfo.path
            atlasImage.onload = () => {
                /*
                // append a copy of this image to the body
                const $img: any = $(atlasImage).clone()
                $img.addClass('tile-set')
                // 100% width and height
                $img.css('width', '50%')
                $img.css('height', '50%')
                $img.css('display', 'block')
                // pixelated
                $img.css('image-rendering', 'pixelated')
                $('body').append($img)
                */

                const imageWidth = atlasImage.width
                const imageHeight = atlasImage.height
                const tileSize = atlasInfo.tileSize
                const tileSetW = imageWidth / tileSize
                const tileSetH = imageHeight / tileSize
                console.log(`tileSetW: ${tileSetW}, tileSetH: ${tileSetH}`)
                let tileIndex = 1
                for (let y = 0; y < tileSetH && tileIndex <= atlasInfo.count; ++y) {
                    for (let x = 0; x < tileSetW && tileIndex <= atlasInfo.count; ++x, ++tileIndex) {
                        // register name -> texture
                        const name = atlasInfo.prefix + sprintf('%03d', tileIndex)

                        // const image = this.getTileFromAtlas(atlasImage, x * tileSize, y * tileSize, tileSize, tileSize, Config.TILE_PIXEL_SIZE, Config.TILE_PIXEL_SIZE)
                        // // mic fixme: prime place to implement and test texture atlases
                        // const texture = new Texture(image)
                        // // explicit setter due to html image might not be ready just yet
                        // texture.tx = 0
                        // texture.ty = 0
                        // texture.tw = Config.TILE_PIXEL_SIZE
                        // texture.th = Config.TILE_PIXEL_SIZE
                        // texture.fullW = Config.TILE_PIXEL_SIZE
                        // texture.fullH = Config.TILE_PIXEL_SIZE
                        // this.setTexture(name, texture)

                        // mic fixme: prime place to implement and test texture atlases
                        const texture = new Texture(atlasImage)
                        // explicit setter due to html image might not be ready just yet
                        texture.tx = x * tileSize
                        texture.ty = y * tileSize
                        texture.tw = tileSize
                        texture.th = tileSize
                        texture.fullW = atlasImage.width
                        texture.fullH = atlasImage.height
                        this.setTexture(name, texture)
                    }
                }

                // emit event
                console.log(`Tile textures loaded: ${atlasInfo.path}`)
            }
        }

        // use promises to emit signal when all tile textures are loaded
        const promises: any = tileSetImages.map((atlasImage) => {
            return new Promise((resolve, reject) => {
                const onloadOriginal: any = atlasImage.onload
                atlasImage.onload = () => {
                    onloadOriginal()
                    resolve(atlasImage)
                }
            })
        })

        // Promise.all(promises).then(() => {
        //     // emit event
        //     console.log('All tile textures loaded (1):', Events.TileTextureNamesRegistered)
        //     Events.signals[Events.TileTextureNamesRegistered].dispatch()
        // })

        // await that all tile textures are loaded
        console.log('Waiting for all tile textures to load...')
        await Promise.all(promises)
    }

    // generate 16x16 icons for all textures that need them
    static async generateIconTextures() {
        const promises: any[] = []

        // iterate over Floor8 and Wall8 textures and print the name
        Object.keys(this.textures).forEach((textureName) => {
            const startWith = ['Wall-', 'Floor-', 'Item-', 'Thing-']
            const needsIcon = startWith.some((prefix) => { return textureName.startsWith(prefix) })
            const isThing = textureName.startsWith('Thing-')
            if (needsIcon) {
                const iconName = textureName + ':Icon'
                console.log('Generate Icon:', textureName, '->', iconName)
                const texture = TextureUtils.getTexture(textureName)
                let textureIcon: Texture | null = null
                if (texture.tw === Config.TILE_PIXEL_SIZE && texture.th === Config.TILE_PIXEL_SIZE) {
                    textureIcon = texture
                } else {
                    if (isThing) {
                        // generate 16x16 icon by scaling down
                        textureIcon = ImageTransform.makeIconScale(texture)
                    } else {
                        // generate 16x16 icon by cropping top left
                        textureIcon = ImageTransform.makeIconTopLeft(texture)
                    }
                    // add promise to wait for texture to load
                    promises.push(new Promise((resolve, reject) => {
                        textureIcon!.handle!.addEventListener('load', () => {
                            resolve(textureIcon)
                        })
                    }))
                }
                // register texture
                this.textures[iconName] = textureIcon!
            }
        })

        // wait for all textures to load
        await Promise.all(promises)
    }

    static getTileFromAtlas(atlasImage: HTMLImageElement, x: number, y: number, sw: number, sh: number, dw: number, dh: number): HTMLImageElement {
        const canvas = document.createElement('canvas')
        canvas.width = dw
        canvas.height = dh
        const ctx = canvas.getContext('2d')!
        // pixelated = true
        ctx.imageSmoothingEnabled = false
        ctx.drawImage(atlasImage, x, y, sw, sh, 0, 0, dw, dh)
        const texture = new Image()
        texture.src = canvas.toDataURL()
        return texture
    }

    static async generateShadowTextures(name: string, shadowColor: RGBA, thickness: number, blurRadius: number) {
        // shadow fragments
        const shadows = [
            // top
            {
                x: 0,
                y: 0,
                w: Config.TILE_PIXEL_SIZE,
                h: thickness,
                name: 0
            },
            // right
            {
                x: Config.TILE_PIXEL_SIZE - thickness,
                y: 0,
                w: thickness,
                h: Config.TILE_PIXEL_SIZE,
                name: 1
            },
            // bottom
            {
                x: 0,
                y: Config.TILE_PIXEL_SIZE - thickness,
                w: Config.TILE_PIXEL_SIZE,
                h: thickness,
                name: 2
            },
            // left
            {
                x: 0,
                y: 0,
                w: thickness,
                h: Config.TILE_PIXEL_SIZE,
                name: 3
            },
            // now the four corners
            // top left
            {
                x: 0,
                y: 0,
                w: thickness,
                h: thickness,
                name: 4
            },
            // top right
            {
                x: Config.TILE_PIXEL_SIZE - thickness,
                y: 0,
                w: thickness,
                h: thickness,
                name: 5
            },
            // bottom left
            {
                x: 0,
                y: Config.TILE_PIXEL_SIZE - thickness,
                w: thickness,
                h: thickness,
                name: 6
            },
            //
            {
                x: Config.TILE_PIXEL_SIZE - thickness,
                y: Config.TILE_PIXEL_SIZE - thickness,
                w: thickness,
                h: thickness,
                name: 7
            },
        ]

        for (const shadow of shadows) {
            const canvas = document.createElement('canvas')
            canvas.width = Config.TILE_PIXEL_SIZE
            canvas.height = Config.TILE_PIXEL_SIZE
            const ctx = canvas.getContext('2d')!

            // initialize rect with RGB coming from shadowColor
            const r = shadowColor.r
            const g = shadowColor.g
            const b = shadowColor.b
            ctx.fillStyle = `rgba(${r},${g},${b},0)`
            ctx.fillRect(0, 0, Config.TILE_PIXEL_SIZE, Config.TILE_PIXEL_SIZE)

            ctx.fillStyle = shadowColor.toCSS()
            console.log(ctx.fillStyle)
            ctx.imageSmoothingEnabled = true

            ctx.fillRect(shadow.x, shadow.y, shadow.w, shadow.h)
            const imageData = ctx.getImageData(0, 0, Config.TILE_PIXEL_SIZE, Config.TILE_PIXEL_SIZE)

            ImageTransform.run(imageData, [
                {
                    tr: ImageTransform.blur,
                    args: {
                        radius: blurRadius
                    }
                },
            ])

            // write image data back to canvas
            ctx.putImageData(imageData, 0, 0)

            // create a texture from the image
            const image = new Image()
            image.src = canvas.toDataURL()
            // mic fixme: turn this into a texture atlas down the line
            // register texture
            const texture = new Texture(image)
            // explicit setter due to html image might not be ready just yet
            texture.tx = 0
            texture.ty = 0
            texture.tw = Config.TILE_PIXEL_SIZE
            texture.th = Config.TILE_PIXEL_SIZE
            texture.fullW = Config.TILE_PIXEL_SIZE
            texture.fullH = Config.TILE_PIXEL_SIZE
            this.setTexture(`${name}-${shadow.name}`, texture)
        }
    }

    static async waitAllTexturesLoaded() {
        // use promises to emit signal when all tile textures are loaded
        const promises: any = Object.keys(this.textures)
        .filter((textureName) => {
            // skipe textures that are already loaded
            return !this.textures[textureName].handle!.complete
        })
        .map((textureName) => {
            return new Promise((resolve, reject) => {
                const texture = this.textures[textureName]
                const onloadOriginal: any = texture.handle!.onload
                texture.handle!.onload = () => {
                    if (onloadOriginal)
                        onloadOriginal()
                    resolve(texture)
                }
            })
        })
        Promise.all(promises).then(() => {
            console.log('All tile textures loaded.')
        })

        // await that all tile textures are loaded
        console.log('Waiting for all tile textures to load...')
        await Promise.all(promises)
    }

    static async loadSpriteSheet(path: string, spritesX: number, spritesY: number, prefix: string) {
        let promises: any = []
        const spriteSheet: HTMLImageElement = new Image()
        spriteSheet.src = path
        spriteSheet.onload = () => {
            const imageWidth = spriteSheet.width
            const imageHeight = spriteSheet.height
            const charNum = (spritesX / 3) * (spritesY / 4)
            const spriteWidth = imageWidth / spritesX
            const spriteHeight = imageHeight / spritesY
            console.log(path, imageHeight, imageHeight)
            console.log(`charNum: ${charNum}, spriteWidth: ${spriteWidth}, spriteHeight: ${spriteHeight}`)

            const images: HTMLImageElement[] = []
            for( let iChar=0; iChar < charNum; ++iChar) {
                const charXOrigin = (iChar * spriteWidth * 3) % imageWidth
                const charYOrigin = Math.floor((iChar * spriteWidth * 3) / imageWidth) * spriteHeight * 4
                for( let iDir=0; iDir < 4; ++iDir) {
                    for( let iFrame=0; iFrame < 3; ++iFrame) {
                        const name = prefix + sprintf('%d-%d-%d', iChar, iDir, iFrame)

                        // const image = this.getTileFromAtlas(
                        //     spriteSheet,
                        //     charXOrigin + iFrame * spriteWidth,
                        //     charYOrigin + iDir * spriteHeight,
                        //     spriteWidth,
                        //     spriteHeight,
                        //     spriteWidth,
                        //     spriteHeight
                        // )
                        // // mic fixme: turn this into proper texture atlas
                        // const texture = new Texture(image)
                        // // explicit setter due to html image might not be ready just yet
                        // texture.tx = 0
                        // texture.ty = 0
                        // texture.tw = spriteWidth
                        // texture.th = spriteHeight
                        // texture.fullW = spriteWidth
                        // texture.fullH = spriteHeight
                        // this.setTexture(name, texture)
                        // images.push(image)
                        // // image.name = name
                        // // console.log(name)

                        const texture = new Texture(spriteSheet)
                        // explicit setter due to html image might not be ready just yet
                        texture.tx = charXOrigin + iFrame * spriteWidth
                        texture.ty = charYOrigin + iDir * spriteHeight
                        texture.tw = spriteWidth
                        texture.th = spriteHeight
                        texture.fullW = imageWidth
                        texture.fullH = imageHeight
                        this.setTexture(name, texture)
                    }
                }
            }

            // wait for all images to load
            promises = images.map((image) => {
                return new Promise((resolve, reject) => {
                    const onloadOriginal: any = image.onload
                    image.onload = () => {
                        if (onloadOriginal)
                            onloadOriginal()
                        resolve(image)
                    }
                })
            })
        }

        // create promise to emit signal when spriteSheet is loaded
        await new Promise((resolve, reject) => {
            const onloadOriginal: any = spriteSheet.onload
            spriteSheet.onload = () => {
                if (onloadOriginal)
                    onloadOriginal()
                resolve(spriteSheet)
            }
        })

        console.log('Waiting for all sprites to load...')
        await Promise.all(promises)
        console.log('Sprites loaded')
    }
}
