import { assert } from "./assert"
import { Vec2D, Box2D, RGBA, shuffleArray } from "./core"
import { Config } from "./config"
import { gameState } from "./game-state"
import { isValidPosition } from "./character"
import { Thing } from "./thing"
import { findPath } from './path-find';
import { MouseEventType, EquipmentType, Direction, TileLevel } from './enums';
import { getTileInfo, getTileId } from './tiles';
import { GameTime } from "./game-time"
import { GameMap } from "./game-map"
import { GuiMouseEvent, computeMousePosition, GuiWidget } from "./gui-widget"
import { Character } from "./character"
import { TextureUtils, Texture } from "./textures"

// -----------------------------------------------------------------------------
// GuiWidgetMap
// -----------------------------------------------------------------------------
export class GuiWidgetMap extends GuiWidget {
    startX: number = 0
    startY: number = 0
    startOffsetX: number = 0
    startOffsetY: number = 0
    isDragging: boolean = false

    constructor() {
        super()
        this.name = 'GuiWidgetMap'
    }

    onMouseDown(e: GuiMouseEvent): boolean {
        // TODO: adjust speedScale based on tool and streght
        const speedScale = 20
        const hitSpeed = 1.0 / (1.0 + speedScale * 0.25)
        // Play action animation
        const isControlPressed = gameState().userInput?.keyDown['Control']
        if (!isControlPressed && e.type === MouseEventType.DOWN_LEFT) {
            const thePlayer = gameState().thePlayer!
            if (thePlayer.hitAnimation.timeStart === 0 || (GameTime.now() > thePlayer.hitAnimation.timeStart + hitSpeed)) {

                const targetTileX = e.tx
                const targetTileY = e.ty
                const targetDst = getPlayerTileDistance(targetTileX, targetTileY)
                if (targetDst === 0) {
                    return true
                }

                const targetVec = new Vec2D(targetTileX-Math.floor(thePlayer.x), targetTileY-Math.floor(thePlayer.y))
                const mostlyHorizontal = Math.abs(targetVec.x) >= Math.abs(targetVec.y)

                // Turn player in the direction of target
                if(mostlyHorizontal) {
                    if(targetVec.x > 0) {
                        thePlayer.direction = Direction.RIGHT
                    } else {
                        thePlayer.direction = Direction.LEFT
                    }
                }
                else {
                    if(targetVec.y > 0) {
                        thePlayer.direction = Direction.DOWN
                    } else {
                        thePlayer.direction = Direction.UP
                    }
                }

                // TODO: Hit animation
                thePlayer.hitAnimation.timeStart = GameTime.now()
                thePlayer.hitAnimation.duration = 0.25
                switch(thePlayer.direction) {
                    case Direction.DOWN:
                        thePlayer.hitAnimation.dx = 0
                        thePlayer.hitAnimation.dy = +4 // +1
                        break
                    case Direction.UP:
                        thePlayer.hitAnimation.dx = 0
                        thePlayer.hitAnimation.dy = -4 // -1
                        break
                    case Direction.LEFT:
                        thePlayer.hitAnimation.dx = -10
                        thePlayer.hitAnimation.dy = 0
                        break
                    case Direction.RIGHT:
                        thePlayer.hitAnimation.dx = +7
                        thePlayer.hitAnimation.dy = 0
                        break
                }

                // (1) TODO:
                // ...

                // (2) TODO: Hit mechanics
                // - active hit block or thing mechanics
                // - damage points for block or thing
                // - damage effectivenes based on tool
                //   - trees -> axe, saw
                //   - objects -> hammer
                //   - dirt/grass -> shovel
                //   - rocks/walls -> pickaxe
                //   - water/lava -> bucket

                // (3) TODO: Extensible system combining tool type vs tile or object type
                // ...

                const dig = checkCanDigOrPickup(e)

                const map = gameState().theFloor!
                if (dig.ok) {
                    // Dig a thing: pickup or break it
                    if (dig.thing) {
                        if (dig.thing.isFloating) {
                            // pickup the thing
                            const activeThing = gameState().getActiveHotbarSlotThing()!
                            assert(activeThing === null || activeThing.type === dig.thing.type, 'activeThing === null || activeThing.type === dig.thing.type')
                            if (activeThing === null) {
                                gameState().setActiveHotbarSlotThing(dig.thing)
                            } else {
                                activeThing.pileCount += 1
                            }
                            map.removeThingXY(dig.x, dig.y)
                        } else {
                            // dig the thing and make it floating
                            dig.thing.isFloating = true
                        }
                    } else {
                        // Dig - Walls, Floors, Things
                        const tileInfo = getTileInfo(map.get(dig.x, dig.y))
                        console.log('tileInfo', JSON.stringify(tileInfo, null, 2))

                        if (tileInfo.level === TileLevel.WALL) {
                            map.removeWall(dig.x, dig.y)
                        } else
                        if (tileInfo.level === TileLevel.FLOOR) {
                            map.removeFloor(dig.x, dig.y)
                        }

                        const floatingBlock = createFloatingBlock(tileInfo.name)
                        // if there's already a floating thing there then move it to a free spot around if possible
                        moveFloatingThing(dig.x, dig.y)
                        // place dug floating block
                        map.placeThing(floatingBlock, dig.x, dig.y, true)
                    }

                    gameState().theMapCameraRenderer!.scheduleUpdateFogOfWar = true
                } else {
                    // Build - Walls, Floors, Things
                    const build = checkCanBuild(e)
                    if (build.ok) {
                        const activeThing = gameState().getActiveHotbarSlotThing()!
                        if (build.thing) {
                            // Build Thing
                            moveFloatingThing(build.x, build.y)
                            map.placeThing(build.thing, build.x, build.y)
                        } else {
                            const tileId = getTileId(activeThing.textureName)
                            map.set(targetTileX, targetTileY, tileId)
                            // if it's a wall then destroy thing
                            const isWall = getTileInfo(tileId).level === TileLevel.WALL
                            if (isWall) {
                                moveFloatingThing(build.x, build.y)
                            }
                        }

                        // 1) build floor -> keep thing where it is
                        // 2) build wall -> remove thing

                        // use up material form hotbar
                        if (activeThing.pileCount > 0) {
                            activeThing.pileCount -= 1
                        }
                        if (activeThing.pileCount === 0) {
                            gameState().setActiveHotbarSlotThing(null)
                        }

                        // schedule fog of war update
                        gameState().theMapCameraRenderer!.scheduleUpdateFogOfWar = true
                    }
                }
            }
        }

        return true
    }

    onMouseDragBegin(e: GuiMouseEvent): boolean {
        if (!e.isDraggingR) {
            return false
        }

        // console.log('MAP DRAG BEGIN')
        // Map dragging logic
        const camera = gameState().theCamera
        this.startX = e.clientX
        this.startY = e.clientY
        this.startOffsetX = camera.viewLeft
        this.startOffsetY = camera.viewTop
        this.isDragging = true
        // capture mouse
        gameState().theGui!.captureMouse(this)
        return true
    }

    onMouseUp(e: GuiMouseEvent): boolean {
        if (this.isDragging) {
            this.isDragging = false
            // capture mouse
            gameState().theGui!.releaseMouse(this)
            return true
        } else {
            return false
        }
    }

    onMouseMove(e: GuiMouseEvent): boolean {
        /* === Print info of what's under the mouse ===
        // get floor
        assert(self.theFloor !== null, 'theFloor is null')
        const theFloor = self.theFloor!
        const map = theFloor.map!
        assert(map !== null, 'Map is null')
        // print tile info
        const tile = getTileInfo(map.get(coords.tx, coords.ty))
        console.log('Tile:', tile.name)
        // print thing name
        const thing = map.getThing(coords.tx, coords.ty)
        if (thing) {
          console.log('Thing:', thing.type)
        }
        // print character name
        const character = theFloor.characters.find((character) => character.x === coords.tx && character.y === coords.ty)
        if (character) {
          console.log('Character:', character.type)
        }
        */

        // Map dragging logic
        if (gameState().theFloor === null) {
            return false
        }
        assert (gameState().theFloor !== null, 'theFloor is null')

        if (!gameState().theFloor!.hasTileMap()) {
            return false
        }

        const canvas = gameState().theMapCanvas.getCanvasElement()
        const camera = gameState().theCamera

        // dragging the map
        if (this.isDragging) {
            const tilePixelSize = Config.TILE_PIXEL_SIZE

            // move the map computing screen pixel size vs canvas pixel size
            camera.viewLeft = this.startOffsetX + (this.startX - e.clientX) * (canvas.width / canvas.clientWidth)
            camera.viewTop = this.startOffsetY + (this.startY - e.clientY) * (canvas.height / canvas.clientHeight)

            // TODO: either remove or reintroduce once chunk rendering works
            // make sure we don't pan outside the map
            // camera.viewLeft = Math.max(0, camera.viewLeft)
            // camera.viewTop = Math.max(0, camera.viewTop)
            // camera.viewLeft = Math.min(camera.viewLeft, mapSize.x * tilePixelSize - canvas.width)
            // camera.viewTop = Math.min(camera.viewTop, mapSize.y * tilePixelSize - canvas.height)

            // round to nearest pixel - needed for crisp rendering
            camera.viewLeft = Math.floor(camera.viewLeft)
            camera.viewTop = Math.floor(camera.viewTop)

            camera.changed = true
        }

        return false
    }

    onMouseClickLeft(e: GuiMouseEvent): boolean {
        // Map click logic: path finding
        const map = gameState().theFloor!
        // check if e.tx/e.ty position is valid
        const isControlPressed = gameState().userInput?.keyDown['Control']
        const isValid = isControlPressed && isValidPosition(map, e.tx, e.ty, gameState().thePlayer)
        if (isValid) {
            const path = findPath(map, gameState().thePlayer, e.tx, e.ty)
            if (path) {
                gameState().theMapCameraRenderer!.playerPath = path
            } else {
                return true
            }
        } else {
            // Check for near actions
            // const map = floor.map!
            const player = gameState().thePlayer!
            const px = Math.floor(player.x)
            const py = Math.floor(player.y)
            const isNear = isTileNearPlayer(e.tx, e.ty, Config.DIG_BUILD_MAX_RANGE)
            if ((px !== e.tx || py !== e.ty) && isNear) {
                const thing = map.getThing(e.tx, e.ty)
                if (thing) {
                    console.log('Thing:', thing.type)
                    // handle thing action
                    switch (thing.type) {
                        default:
                            console.log('Click Left: No DIG action for thing', thing.type)
                    }
                } else {
                    // handle tile action
                    const tile = getTileInfo(map.get(e.tx, e.ty))
                    switch (tile.name) {
                        default:
                            console.log('Click Left: No DIG action for tile', tile.name)
                    }
                }
            }
        }
        return true
    }

    onMouseClickRight(e: GuiMouseEvent): boolean {
        console.log('Click Right', e.tx, e.ty)
        const player = gameState().thePlayer!
        const px = Math.floor(player.x)
        const py = Math.floor(player.y)

        if (px === e.tx && py === e.ty) {
            return true
        }

        const map = gameState().theFloor!
        const canReach = canPlayerReachXY(e.tx, e.ty, map)

        if (!canReach) {
            return true
        }

        // print tile info and thing info
        const tile = getTileInfo(map.get(e.tx, e.ty))
        console.log('Tile:', tile.name)
        const thing = map.getThing(e.tx, e.ty)
        if (thing && !thing.isFloating) {
            console.log('Thing:', thing.type)
            // handle thing action
            switch (thing.type) {
                case 'Door':
                    thing.handleUserAction({})
                    // schedule fog of war update
                    gameState().theMapCameraRenderer!.scheduleUpdateFogOfWar = true
                    break
                default:
                    console.log('Click Right: No USE action for thing', thing.type)
            }
        } else {
            // handle tile action
            switch (tile.name) {
                default:
                    console.log('Click Right: No USE action for tile', tile.name)
            }
        }
        return true
    }
}

/**
 * Move thing to a free spot around tx, ty
 * @param tx
 * @param ty
 * @returns
 */
function moveFloatingThing(tx: number, ty: number) {
    const map = gameState().theFloor!
    const thing = map.getThing(tx, ty)
    if (thing === null || !thing.isFloating) {
        return
    }

    const offsets = [
        {dx:-1, dy:-1},
        {dx:-1, dy: 0},
        {dx:-1, dy:+1},
        {dx: 0, dy:-1},
        {dx: 0, dy:+1},
        {dx:+1, dy:-1},
        {dx:+1, dy: 0},
        {dx:+1, dy:+1},
    ]

    // shuffle offsets
    shuffleArray(offsets)

    // remove thing first and then try to move it to a suitable spot or lose it
    map.removeThingXY(tx, ty)

    // check around tx, ty for a free spot
    for (const offset of offsets) {
        const x = tx + offset.dx
        const y = ty + offset.dy
        if (isValidPosition(map, x, y, null, false) && map.getThing(x, y) === null) {
            map.placeThing(thing, x, y, true)
            return
        }
    }
}

// TODO: move these into appropriate module together with code in mouse event handlers

class CheckDigFlags {
    ok: boolean = false
    x: number = -1
    y: number = -1
    thing: Thing | null = null
    damage: number = 0 // 1 - 10
}

function checkCanDigOrPickup(e: GuiMouseEvent): CheckDigFlags {
    const res: CheckDigFlags = {
        ok: false,
        x: -1,
        y: -1,
        thing: null,
        damage: 0, // 1 - 10
    }

    const thePlayer = gameState().thePlayer!

    const targetTileX = e.tx
    const targetTileY = e.ty

    if (getPlayerTileDistance(targetTileX, targetTileY) === 0) {
        return res
    }

    let facingTileX = 0
    let facingTileY = 0
    switch(thePlayer.direction) {
        case Direction.DOWN:
            facingTileY = +1
            break
        case Direction.UP:
            facingTileY = -1
            break
        case Direction.LEFT:
            facingTileX = -1
            break
        case Direction.RIGHT:
            facingTileX = +1
            break
    }

    const activeThing = gameState().getActiveHotbarSlotThing()

    // target tile
    facingTileX += Math.floor(thePlayer.x)
    facingTileY += Math.floor(thePlayer.y)

    const map = gameState().theFloor!
    let finalDigTile = null
    const targetTileInfo = getTileInfo(map!.get(targetTileX, targetTileY))
    const facingTileInfo = getTileInfo(map!.get(facingTileX, facingTileY))

    const isFacingWall = facingTileInfo.level === TileLevel.WALL

    const isActivePickaxe: boolean = activeThing !== null && activeThing.type.startsWith('Pickaxe')
    const isActiveShovel: boolean = activeThing !== null && activeThing.type.startsWith('Shovel')
    const isActiveAxe: boolean = activeThing !== null && activeThing.type.startsWith('Axe')

    // 1 - check if it's reachable
    // 2 - if axe then prioritize facing tile if it's wall
    // 3 - compute damage based on tool vs tile

    // Promote to facing tile
    if (isActivePickaxe && isFacingWall) {
        res.x = facingTileX
        res.y = facingTileY
        finalDigTile = facingTileInfo
        res.ok = true
    } else {
        res.x = targetTileX
        res.y = targetTileY
        finalDigTile = targetTileInfo
        res.ok = canPlayerReachXY(targetTileX, targetTileY, map)
    }

    if (!res.ok) {
        return res
    }

    // compute damage based on tool vs tile

    const digThing = map.getThing(res.x, res.y)
    const isDigTileWall = finalDigTile.level === TileLevel.WALL
    const isDigTileFloor = finalDigTile.level === TileLevel.FLOOR
    const isFloatingThing = digThing !== null && digThing.isFloating
    const isPlacedThing = digThing !== null && !digThing.isFloating
    const canPickup = isFloatingThing && (activeThing === null || activeThing.type === digThing.type)

    // Pickup
    if(canPickup) {
        // grab object if it's floating and it's next to player and player has empty slot
        res.thing = digThing
        res.damage = 0
    } else
    // Break thing
    if (isPlacedThing) {
        if (isActiveAxe) {
            res.thing = digThing
            res.damage = 1
        } else {
            // no any kind of digging allowed when a placed thing is present and we're not using an axe to dig the very thing
            res.ok = false
            res.damage = 0
        }
    } else
    // Dig wall
    if (isDigTileWall && isActivePickaxe && finalDigTile.canDig) {
        res.damage = 1
    } else
    // Dig floor
    if (isDigTileFloor && isActiveShovel && finalDigTile.canDig) {
        res.damage = 1
    }
    // Dig nothing
    else {
        // important: unrecognized combinations don't work
        res.ok = false
        res.damage = 0
    }

    return res
}

class CheckBuildFlags {
    ok: boolean = false
    x: number = -1
    y: number = -1
    thing: Thing | null = null
}

function checkCanBuild(e: GuiMouseEvent): CheckBuildFlags {
    const res: CheckBuildFlags = {
        ok: false,
        x: -1,
        y: -1,
        thing: null,
    }

    const activeThing = gameState().getActiveHotbarSlotThing()
    if (activeThing === null) {
        return res
    }

    const targetTileX = e.tx
    const targetTileY = e.ty

    if (getPlayerTileDistance(targetTileX, targetTileY) === 0) {
        return res
    }

    const map = gameState().theFloor!
    if (!canPlayerReachXY(targetTileX, targetTileY, map)) {
        return res
    }

    const targetTileInfo = getTileInfo(map!.get(targetTileX, targetTileY))

    const isActiveWall = activeThing.type.startsWith('Wall')
    const isActiveFloor = activeThing.type.startsWith('Floor')

    // TODO: turn this into a flag in the thing config
    const isActiveBuildableThing =
        activeThing.type.startsWith('Door') ||
        activeThing.type.startsWith('Bridge') ||
        activeThing.type.startsWith('Tree') ||
        activeThing.type.startsWith('Armery') ||
        activeThing.type.startsWith('Treasure')
    const isActiveBridgeThing = activeThing.canWalkBridge

    console.log('activeThing', JSON.stringify(activeThing, null, 2))

    // Promote to facing tile
    res.x = targetTileX
    res.y = targetTileY

    const isTargetTileFloor = targetTileInfo.level === TileLevel.FLOOR
    const isTargetTileSubstratum = targetTileInfo.level === TileLevel.SUBSTRATUM

    // const isWalkableGround = [TileLevel.FLOOR, TileLevel.SUBSTRATUM].indexOf(targetTileInfo.level) !== -1 && targetTileInfo.canWalkThrough

    // You need a floor to place objects on it - including bridges, or fill it with water

    if (isActiveWall && (isTargetTileFloor || isTargetTileSubstratum) ) {
        const thing = map.getThing(targetTileX, targetTileY)
        const targetTileChar = findCharacterAt(targetTileX, targetTileY)
        // can build on top of floating things - but not on top of characters
        res.ok = (thing === null || thing.isFloating === true) && targetTileChar === null
    } else
    if (isActiveFloor && isTargetTileSubstratum) {
        res.ok = true
    } else
    if (isActiveBuildableThing && isTargetTileFloor && targetTileInfo.canWalkThrough || isActiveBridgeThing && isTargetTileFloor && !targetTileInfo.canWalkThrough) {
        const thing = map.getThing(targetTileX, targetTileY)
        res.ok = thing === null || thing.isFloating === true
        if (res.ok) {
            // clone thing
            res.thing = activeThing.shallowClone()
            res.thing.isFloating = false
            res.thing.pileCount = 1
        }
    } else {
        res.ok = false
    }

    return res
}

function createFloatingBlock(typeAndTileTextureName: string): Thing {
    const thing = new Thing(typeAndTileTextureName)
    // thing.name = textureName
    // thing.type = textureName
    thing.textureName = typeAndTileTextureName
    thing.canSeeThrough = true
    thing.canWalkThrough = true
    thing.canWalkBridge = false
    thing.isEquipment = false
    thing.equipmentType = EquipmentType.NONE
    thing.isFloating = true
    // if pileCount defined then use it else 1
    thing.pileCount = 1
    thing.texture = TextureUtils.getTexture(typeAndTileTextureName)
    thing.textureIcon = TextureUtils.getTexture(typeAndTileTextureName + ':Icon')
    return thing
}

function canPlayerReachXY(tx: number, ty: number, map: GameMap): boolean {
    const targetDst = getPlayerTileDistance(tx, ty)

    if (targetDst > Config.DIG_BUILD_MAX_RANGE) {
        console.log(`>>> can-reach-xy: TOO FAR: ${targetDst} > ${Config.DIG_BUILD_MAX_RANGE}`)
        return false
    }

    const player = gameState().thePlayer!

    const px = Math.floor(player.x)
    const py = Math.floor(player.y)

    // max distance including diagonal
    const maxDst = Math.max(Math.abs(px - tx), Math.abs(py - ty))
    if (maxDst === 1) {
        return true
    }

    // find bounding box of px/py and tx/ty
    const topLeft = new Vec2D(
        Math.min(px, tx),
        Math.min(py, ty)
    )
    const bottomRight = new Vec2D(
        Math.max(px, tx),
        Math.max(py, ty)
    )

    // Method (2): floodfill or path find algo
    // use floodfill algorithm to find a path between px/py and tx/ty within the top-left and bottom-right bounding box
    let x = px
    let y = py
    const nodes: Vec2D[] = []
    const visited: { [key: string]: boolean } = {}
    const offsets = [
        {dx:-1, dy: 0},
        {dx:+1, dy: 0},
        {dx: 0, dy:-1},
        {dx: 0, dy:+1},
    ]
    let done = false
    let steps = 0
    while (!done) {
        for (const offset of offsets) {
            steps += 1
            const nx = x + offset.dx
            const ny = y + offset.dy
            if (nx === tx && ny === ty) {
                console.log('>>> can-reach-xy: FOUND PATH, steps =', steps)
                return true
            }
            if (nx < topLeft.x || nx > bottomRight.x || ny < topLeft.y || ny > bottomRight.y) {
                continue
            }
            // check if already visited
            const key = `${nx},${ny}`
            if (visited[key] === true) {
                continue
            }
            // mark as visited
            visited[key] = true

            // check if tile is floor
            const tileInfo = getTileInfo(map.get(nx, ny))
            const thing = map.getThing(nx, ny)
            const canWalkThroughThing = thing !== null && thing.canWalkThrough && !thing.isFloating
            const blockingThing = thing !== null && !thing.canWalkThrough && !thing.isFloating
            if (tileInfo.canWalkThrough && !blockingThing || canWalkThroughThing) {
                nodes.push(new Vec2D(nx, ny))
            }
        }
        if (nodes.length === 0) {
            done = true
        } else {
            const node = nodes.pop()!
            x = node.x
            y = node.y
        }
    }

    console.log('>>> can-reach-xy: NO PATH, steps =', steps)

    /*
    // Method (1): check if around thing there is a floor tile that is next to the player
    const offsets = [
        {dx:-1, dy:-1},
        {dx:-1, dy: 0},
        {dx:-1, dy:+1},
        {dx: 0, dy:-1},
        {dx: 0, dy:+1},
        {dx:+1, dy:-1},
        {dx:+1, dy: 0},
        {dx:+1, dy:+1},
    ]

    for (const offset of offsets) {
        const x = tx + offset.dx
        const y = ty + offset.dy
        // check if tile is floor
        const tileInfo = getTileInfo(map.get(x, y))
        const thing = map.getThing(x, y)
        // can't build or dig through a non walkable thing
        if (tileInfo.isFloor && (thing === null || thing.canWalkThrough)) {
            // check if tile is adjacent to player
            if (Math.max(Math.abs(px - x), Math.abs(py - y)) === 1) {
                return true
            }
        }
    }
    */

    return false
}

// function canPlayerReachFar(tx: number, ty: number, map: GameMap) {
//     const player = gameState().thePlayer!

//     const px = Math.floor(player.x)
//     const py = Math.floor(player.y)

//     const offsets = [
//         {dx:-1, dy:-1},
//         {dx:-1, dy: 0},
//         {dx:-1, dy:+1},
//         {dx: 0, dy:-1},
//         {dx: 0, dy:+1},
//         {dx:+1, dy:-1},
//         {dx:+1, dy: 0},
//         {dx:+1, dy:+1},
//     ]

//     for (const offset of offsets) {
//         const x = tx + offset.dx
//         const y = ty + offset.dy
//         // check if tile is floor
//         const tileInfo = getTileInfo(map.get(x, y))
//         // TODO: check if there's a placed non-walkable object there
//         if (tileInfo.isFloor) {
//             // check if tile is adjacent to player
//             if (Math.max(Math.abs(px - x), Math.abs(py - y)) === 1) {
//                 return true
//             }
//         }
//     }

//     return false
// }

function findCharacterAt(tx: number, ty: number): Character | null {
    const character = gameState().theFloor!.characters.find((character) => Math.floor(character.x) === tx && Math.floor(character.y) === ty)
    return character ? character : null
}

function isTileNearPlayer(tx: number, ty: number, range: number) {
    return getPlayerTileDistance(tx, ty) <= range
}

function getPlayerTileDistance(tx: number, ty: number) {
    const player = gameState().thePlayer!
    const px = Math.floor(player.x)
    const py = Math.floor(player.y)
    return Math.max(Math.abs(px - tx), Math.abs(py - ty))
}
