import { assert } from "./assert"
import { Vec2D, Box2D, RGBA } from "./core"
import { Config } from "./config"
import { gameState } from "./game-state"
import { RenderContext } from "./map-canvas"
import { PlayerState } from "./player-state"
import { randomChoice } from "./core"
import { isValidPosition } from "./character"
import { findPath } from './path-find';
import { MouseEventType, EquipmentType, Direction } from './enums';
import { getTileInfo } from './tiles';
import { GuiThingSlotUtils } from "./gui-thing-slot-utils"
import { GameTime } from "./game-time"
import { GameMap } from "./game-map"

// -----------------------------------------------------------------------------
// computeMousePosition
// -----------------------------------------------------------------------------
export function computeMousePosition(canvas: HTMLCanvasElement, clientX: number, clientY: number, out: GuiMouseEvent) {
    const tilePixelSize = Config.TILE_PIXEL_SIZE

    const camera = gameState().theCamera
    // get x y coordinates of canvas in screen space
    const rect = canvas.getBoundingClientRect()
    const x = clientX - rect.left
    const y = clientY - rect.top
    // percentage x and y
    const px = x / (rect.right - rect.left)
    const py = y / (rect.bottom - rect.top)
    // from percentage to canvas pixel coordinates
    const cx = px * canvas.width
    const cy = py * canvas.height
    // world coordinates
    const wx = cx + camera.viewLeft
    const wy = cy + camera.viewTop
    // tile coordinates
    const tx = Math.floor(wx / tilePixelSize)
    const ty = Math.floor(wy / tilePixelSize)
    // reverse into "snapped" canvas coordinates
    const cxs = tx * tilePixelSize - camera.viewLeft
    const cys = ty * tilePixelSize - camera.viewTop

    // tile coordinates: integer
    out.tx = tx
    out.ty = ty

    // top left corner of the tile in canvas space: integer
    out.cxs = cxs
    out.cys = cys

    // x y coordinates of the mouse in canvas space: float
    out.cx = cx
    out.cy = cy

    // HTML5 mouse event coordinates
    out.clientX = clientX
    out.clientY = clientY
}

// -----------------------------------------------------------------------------
// GuiMouseEvent
// -----------------------------------------------------------------------------
export class GuiMouseEvent {
    type: MouseEventType = MouseEventType.NONE

    clientX: number = 0
    clientY: number = 0

    // world tile coordinates: integer
    tx: number = 0
    ty: number = 0
    // x y coordinates of the mouse in canvas space: float
    cx: number = 0
    cy: number = 0
    // top left corner of the tile in canvas space: integer
    cxs: number = 0
    cys: number = 0

    isDraggingL: boolean = false
    isDraggingR: boolean = false
}

// -----------------------------------------------------------------------------
// GuiWidget
// -----------------------------------------------------------------------------
export class GuiWidget {
    priority: number = 0

    x: number = 0
    y: number = 0
    w: number = -1
    h: number = -1
    name: string = ''
    color: RGBA = new RGBA(0, 0, 0, 0)
    colorHover: RGBA = new RGBA(0, 0, 0, 0)

    constructor() {
    }

    onMouseDown(e: GuiMouseEvent): boolean {
        return false
    }

    onMouseUp(e: GuiMouseEvent): boolean {
        return false
    }

    onMouseClickLeft(e: GuiMouseEvent): boolean {
        return false
    }

    onMouseClickRight(e: GuiMouseEvent): boolean {
        return false
    }

    onMouseMove(e: GuiMouseEvent): boolean {
        return false
    }

    onMouseDragBegin(e: GuiMouseEvent): boolean {
        return false
    }

    isHoverThis(mouse: Vec2D): boolean {
        if (mouse.x >= this.x && mouse.x < this.x + this.w && mouse.y >= this.y && mouse.y < this.y + this.h) {
            return true
        } else {
            return false
        }
    }

    computeBoundingBoxThis(): Box2D {
        return new Box2D(this.x, this.y, this.w, this.h)
    }

    isHover(mouse: Vec2D): boolean {
        return this.isHoverThis(mouse)
    }

    computeBoundingBox(): Box2D {
        return this.computeBoundingBoxThis()
    }

    render(ctx: RenderContext, mouse: Vec2D): void {
    }
}
