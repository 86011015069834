import { assert } from "./assert"
import { RGBA } from "./core"
import { TileCategory, TileLevel } from "./enums"
import { ImageTransform } from "./image-transform"
import { Texture } from "./textures"

const TileRegistry: any = []
const TileNameRegistry: { [key: string]: number } = {}

export function getTileInfo(tileId: number): TileInfo {
    assert(tileId >= 0 && tileId < TileRegistry.length, `TileId out of range: ${tileId}`)
    assert(TileRegistry[tileId] !== undefined, `TileInfo not found for tileId: ${tileId}`)
    return TileRegistry[tileId]
}

export function getTileId(name: string): number {
    assert(TileNameRegistry[name] !== undefined, `TileId not found for name: ${name}`)
    return TileNameRegistry[name]
}

export function getTiles(): TileInfo[] {
    return TileRegistry
}

export function filterTiles(filter: (tileInfo: TileInfo) => boolean): TileInfo[] {
    return TileRegistry.filter(filter)
}

export function filterTilesNames(filter: (tileInfo: TileInfo) => boolean): string[] {
    return TileRegistry.filter(filter).map((tileInfo: TileInfo) => tileInfo.name)
}

export type TileType = number

export class TileId {
    static EMPTY = -1

    static ERROR = -1 // RED

    static SUBSTRATUM_ROCK = -1

    static FLOOR_CAVE = -1
    static FLOOR_GRASS = -1
    static FLOOR_DIRT = -1
    static FLOOR_ROAD = -1
    static FLOOR_SAND = -1
    static FLOOR_SNOW = -1
    static FLOOR_ICE = -1
    static FLOOR_SWAMP = -1
    static FLOOR_STONE = -1 // finished stone floor
    static FLOOR_WOOD = -1  // finished wooden floor
    static FLOOR_WATER_SHALLOW = -1
    static FLOOR_WATER_RIVER = -1
    static FLOOR_WATER_SEA = -1
    static FLOOR_LAVA = -1 // BASALT, OBSIDIAN, VOLCANIC_CAVE

    static WALL_CAVE = -1 // natural cave wall
    static WALL_ROCK = -1  // natural rock wall - interior/exterior
    static WALL_STONE_EXT = -1 // building stone wall - exterior/buildings
    static WALL_BRICK_EXT = -1 // building brick wall - exterior/buildings
    static WALL_STONE_INT = -1 // building stone wall - interior/dungeon
    static WALL_BRICK_INT = -1 // building brick wall - interior/dungeon
    // WALL_WOOD, // wooden wall, wooden fence, wooden palisade, wooden barricade, wooden gate, wooden door
}

//------------------------------------------------------------------------------------------
// TileInfo
//------------------------------------------------------------------------------------------
export class TileInfo {
    tileId: TileType = TileId.EMPTY
    name: string = ''
    texture: Texture | null = null
    averageColor: RGBA = new RGBA(0, 0, 0, 255)

    level: TileLevel = TileLevel.SUBSTRATUM

    canSeeThrough: boolean = false
    canWalkThrough: boolean = false
    canFlyOver: boolean = false
    canSwimThrough: boolean = false
    canDig: boolean = false

    // isRustic: boolean = false
    // isDecorative: boolean = false
    // isDirtyFloor: boolean = false
}

export function registerTile(info: TileInfo) {
    info.tileId = TileRegistry.length
    assert(info.tileId <= 65535, `TileId overflow: ${info.tileId} > 65535`)
    info.name = info.name || `Tile-${info.tileId}`
    if (info.texture) {
        info.averageColor = ImageTransform.averageColor(info.texture)
    }
    TileRegistry.push(info)
    TileNameRegistry[info.name] = info.tileId
    return info.tileId
}
