import { assert } from "./assert"

///////////////////////////////////////////////////////////////////////////////
// FogOfWarClouds
///////////////////////////////////////////////////////////////////////////////
export class FogOfWarClouds {
    private buffer: Uint8Array = new Uint8Array(0)
    public w: number = 0
    public h: number = 0

    constructor() {
    }

    getNeighbors(x: number, y: number): number {
        // assert is integer
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)

        let neighbors = 0
        for (let dy = -1; dy <= 1; ++dy) {
            for (let dx = -1; dx <= 1; ++dx) {
                if (dx === 0 && dy === 0) {
                    continue
                }
                const nx = x + dx
                const ny = y + dy
                if (nx < 0 || nx >= this.w || ny < 0 || ny >= this.h) {
                    continue
                }
                const i = ny * this.w + nx
                if (this.buffer[i] === 1) {
                    neighbors++
                }
            }
        }
        return neighbors
    }

    initAutomata(w: number = 64, h: number = 64) {
        this.w = w
        this.h = h
        const size = w * h
        this.buffer = new Uint8Array(size).fill(0)

        // fill with random 0s or 1 and run game of life 2 passes
        for (let i = 0; i < size; ++i) {
            this.buffer[i] = Math.random() > 0.48 ? 1 : 0
        }

        const passes = 2
        const threshold = 4
        for (let i = 0; i < passes; ++i) {
            const nextBuffer = new Uint8Array(this.w * this.h).fill(0)
            for (let y = 0; y < this.h; ++y) {
                for (let x = 0; x < this.w; ++x) {
                    const neighbors = this.getNeighbors(x, y)

                    const i = y * this.w + x
                    const alive = this.buffer[i] === 1
                    if ((alive && neighbors >= threshold) || (!alive && neighbors > threshold)) {
                        nextBuffer[i] = 1
                    } else {
                        nextBuffer[i] = 0
                    }
                }
            }
            this.buffer = nextBuffer
        }
    }

    get(x: number, y: number): number {
        x = x % this.w
        y = y % this.h
        return this.buffer[y * this.w + x]
    }
}

