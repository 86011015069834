import { CameraFollowMode } from "./enums"
import { gameState } from "./game-state"
import { assert } from "./assert"
import { Config } from "./config"

export function isTileInViewport(x: number, y: number, tilePixelSize: number, camera: Camera): boolean {
    return camera.isRectInViewport(x, y, tilePixelSize, tilePixelSize)
}

///////////////////////////////////////////////////////////////////////////////
// Camera
///////////////////////////////////////////////////////////////////////////////
/**
 * @class Camera
 * @description Camera position in pixels
 */
export class Camera {
    cameraFollowMode: CameraFollowMode = CameraFollowMode.NONE
    // cameraFollowMode: CameraFollowMode = CameraFollowMode.CENTER
    // cameraFollowMode: CameraFollowMode = CameraFollowMode.BORDER

    // used to trigger render updates
    changed: boolean = false

    // viewport left and top inclusive
    viewLeft: number = 0
    viewTop: number = 0
    // viewport width and height
    viewWidth: number = 0
    viewHeight: number = 0

    constructor() {
    }

    // viewport right and bottom inclusive
    viewRight(): number {
        return this.viewLeft + this.viewWidth - 1
    }

    // viewport right and bottom inclusive
    viewBottom(): number {
        return this.viewTop + this.viewHeight - 1
    }

    // x,y,w,h in world pixel coordinates
    isRectInViewport(x: number, y: number, w: number, h: number): boolean {
        const isNotVisible = x + w < this.viewLeft || x > this.viewRight() || y + h < this.viewTop || y > this.viewBottom()
        return !isNotVisible
    }

    updateCameraFollowPlayer(): void {
        const self = this

        if (self.cameraFollowMode === CameraFollowMode.NONE) {
            return
        }

        const camera = self
        const tilePixelSize = Config.TILE_PIXEL_SIZE
        const thePlayer = gameState().thePlayer

        // update camera position

        // Method 1 - Follow the thePlayer with border
        // if edge of viewport is closer than 5 tiles then move camera to thePlayer position and clamp camera position to map size
        if (self.cameraFollowMode === CameraFollowMode.BORDER) {
            const distancePixels = 7 * tilePixelSize
            const px = thePlayer.x
            const py = thePlayer.y
            // player in camera space
            const cpx = px * tilePixelSize - camera.viewLeft
            const cpy = py * tilePixelSize - camera.viewTop
            // right
            if (cpx >= camera.viewWidth - distancePixels) {
                // camera.viewLeft = Math.max(0, Math.min(px * tilePixelSize - (camera.viewWidth - distancePixels), map.getW() * tilePixelSize - canvas.width))
                camera.viewLeft = px * tilePixelSize - (camera.viewWidth - distancePixels)
            }
            // left
            if (cpx < distancePixels) {
                // camera.viewLeft = Math.max(0, Math.min(px * tilePixelSize - distancePixels, map.getW() * tilePixelSize - canvas.width))
                camera.viewLeft = px * tilePixelSize - distancePixels
            }
            // bottom
            if (cpy > (camera.viewHeight - distancePixels)) {
                // camera.viewTop = Math.max(0, Math.min(py * tilePixelSize - (camera.viewHeight - distancePixels), map.getH() * tilePixelSize - canvas.height))
                camera.viewTop = py * tilePixelSize - (camera.viewHeight - distancePixels)
            }
            // top
            if (cpy < distancePixels) {
                // camera.viewTop = Math.max(0, Math.min(py * tilePixelSize - distancePixels, map.getH() * tilePixelSize - canvas.height))
                camera.viewTop = py * tilePixelSize - distancePixels
            }
        } else
            // Method 2 - Follow the thePlayer at the center
            if (self.cameraFollowMode === CameraFollowMode.CENTER) {
                // if center of camera is 1 tile away from thePlayer then center camera on thePlayer and clamp camera position to map size
                const px = thePlayer.x
                const py = thePlayer.y
                assert(px !== undefined, 'px is undefined')
                assert(py !== undefined, 'py is undefined')

                if (0) {
                    // TEST - Smooth camera movement
                    const a1 = px * tilePixelSize - camera.viewWidth / 2
                    const a2 = camera.viewLeft
                    const t = 0.025
                    camera.viewLeft = a1 * t + a2 * (1 - t)
                    const b1 = py * tilePixelSize - camera.viewHeight / 2
                    const b2 = camera.viewTop
                    camera.viewTop = b1 * t + b2 * (1 - t)
                } else {
                    // camera.viewLeft = Math.max(0, Math.min(px * tilePixelSize - camera.viewWidth / 2, map.getW() * tilePixelSize - camera.viewWidth))
                    // camera.viewTop = Math.max(0, Math.min(py * tilePixelSize - camera.viewHeight / 2, map.getH() * tilePixelSize - camera.viewHeight))
                    camera.viewLeft = px * tilePixelSize - camera.viewWidth / 2
                    camera.viewTop = py * tilePixelSize - camera.viewHeight / 2

                    // center to the pixel to avoid blurry rendering
                    camera.viewLeft = Math.floor(camera.viewLeft)
                    camera.viewTop = Math.floor(camera.viewTop)
                }
            }
        // Method 3 - Center camera when thePlayer is not visible
        // if not visible move camera to thePlayer position and clamp camera position to map size
        // let isVisible = isTileInViewport(px, py, camera, self.viewportTiles, tilePixelSize)
        // if (!isVisible) {
        //     camera.viewLeft = Math.max(0, Math.min(thePlayer.x * tilePixelSize - canvas.width / 2, map.getW() * tilePixelSize - canvas.width))
        //     camera.viewTop = Math.max(0, Math.min(py * tilePixelSize - canvas.height / 2, map.getH() * tilePixelSize - canvas.height))
        // }
        // console.log('Visible:', isVisible)
    }
}
