///////////////////////////////////////////////////////////////////////////////
// Graph
///////////////////////////////////////////////////////////////////////////////
export class Graph {
    nodes: any = {}

    constructor() {
    }

    addNode(node: any) {
        const self = this
        self.nodes[node] = []
    }

    addEdge(A: any, B: any) {
        const self = this
        if (!self.nodes[A]) {
            self.addNode(A)
        }
        if (!self.nodes[B]) {
            self.addNode(B)
        }
        self.nodes[A].push(B)
        self.nodes[B].push(A)
    }

    hasEdge(A: any, B: any) {
        const self = this
        if (!self.nodes[A]) {
            return false
        }
        if (!self.nodes[B]) {
            return false
        }
        return self.nodes[A].includes(B)
    }

    getNeighbors(node: any) {
        const self = this
        return self.nodes[node]
    }

    getNodes() {
        const self = this
        return Object.keys(self.nodes)
    }

    getEdges() {
        const self = this
        const edges: any = []
        Object.keys(self.nodes).forEach((node) => {
            self.nodes[node].forEach((neighbor: any) => {
                edges.push([node, neighbor])
            })
        })
        return edges
    }

    getDegree(node: any) {
        const self = this
        return self.nodes[node].length
    }

    getConnectedComponents() {
        const self = this
        const components: any = []
        const visited: any = {}
        Object.keys(self.nodes).forEach((node) => {
            if (!visited[node]) {
                const component = self.getConnectedComponent(node)
                components.push(component)
                component.forEach((node: any) => {
                    visited[node] = true
                })
            }
        })
        return components
    }

    getConnectedComponent(node: any) {
        const self = this
        const component: any = []
        const visited: any = {}
        const queue = [node]
        while (queue.length > 0) {
            const node = queue.shift()
            if (!visited[node]) {
                component.push(node)
                visited[node] = true
                self.getNeighbors(node).forEach((neighbor: any) => {
                    queue.push(neighbor)
                })
            }
        }
        return component
    }

    getShortestPath(A: any, B: any) {
        const self = this
        const visited: any = {}
        const queue: any = [[A]]
        while (queue.length > 0) {
            const path = queue.shift()
            const node = path[path.length - 1]
            if (!visited[node]) {
                visited[node] = true
                if (node == B) {
                    return path
                }
                self.getNeighbors(node).forEach((neighbor: any) => {
                    queue.push(path.concat(neighbor))
                })
            }
        }
        return null
    }

    getDistance(A: any, B: any) {
        const self = this
        const path = self.getShortestPath(A, B)
        return path ? path.length - 1 : null
    }

    getMinimumSpanningTree() {
        const self = this
        const tree = new Graph()
        const visited: any = {}
        const queue: any = [Object.keys(self.nodes)[0]]
        while (queue.length > 0) {
            const node = queue.shift()
            if (!visited[node]) {
                visited[node] = true
                self.getNeighbors(node).forEach((neighbor: any) => {
                    if (!visited[neighbor]) {
                        tree.addEdge(node, neighbor)
                        queue.push(neighbor)
                    }
                })
            }
        }
        return tree
    }

    traverseGraphOnce(startNode: any, callback: Function) {
        const self = this
        const visited: any = {}
        const queue = [startNode]
        while (queue.length > 0) {
            const node = queue.shift()
            if (!visited[node]) {
                visited[node] = true
                callback(node)
                self.getNeighbors(node).forEach((neighbor: any) => {
                    queue.push(neighbor)
                })
            }
        }
    }

}