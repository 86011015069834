import { assert } from './assert'
import { Texture } from './textures'
import { RGBA } from './core'

// TODO:
// [v] Delegate all rendering operations to abstract rendering conxext
// [v] Implement RenderContextHTMLCanvas
// [ ] Implement RenderContextWebGL2
//     - Create basic shaders to render quad texture
//     - Ignore font rendering, line rendering, rect rendering
//     - Implement rect rendering

///////////////////////////////////////////////////////////////////////////////
// RenderContext
///////////////////////////////////////////////////////////////////////////////
export class RenderContext {
    constructor() {
    }

    initAndClearFrame(clearColor: RGBA): void { assert(false, 'initAndClearFrame not implemented') }

    saveState(): void { assert(false, 'saveState not implemented') }

    restoreState(): void { assert(false, 'restoreState not implemented') }

    translate(x: number, y: number): void { assert(false, 'translate not implemented') }

    scale(x: number, y: number): void { assert(false, 'scale not implemented') }

    rotate(angle: number): void { assert(false, 'rotate not implemented') }

    setTransform(a: number, b: number, c: number, d: number, e: number, f: number): void { assert(false, 'setTransform not implemented') }

    drawImage(image: Texture, x1: number, y1: number, w1: number, h1: number, x2: number, y2: number, w2: number, h2: number): void { assert(false, 'drawImage not implemented') }

    drawLine(x1: number, y1: number, x2: number, y2: number): void { assert(false, 'drawLine not implemented') }

    fillRect(x: number, y: number, w: number, h: number): void { assert(false, 'fillRect not implemented') }

    strokeRect(x: number, y: number, w: number, h: number): void { assert(false, 'strokeRect not implemented') }

    // ---

    setGlobalAlpha(alpha: number): void { assert(false, 'setGlobalAlpha not implemented') }

    getGlobalAlpha(): number { assert(false, 'getGlobalAlpha not implemented'); return 0 }

    setStrokeColor(color: RGBA): void { assert(false, 'setStrokeColor not implemented') }

    setLineWidth(w: number): void { assert(false, 'setLineWidth not implemented') }

    // ---

    setFont(font: string): void { assert(false, 'setFont not implemented') }

    setFillColor(color: RGBA): void { assert(false, 'setFillColor not implemented') }

    setTextBaseline(baseline: string): void { assert(false, 'setTextBaseline not implemented') }

    fillText(text: string, x: number, y: number): void { assert(false, 'fillText not implemented') }
}

///////////////////////////////////////////////////////////////////////////////
// RenderContextHTMLCanvas
///////////////////////////////////////////////////////////////////////////////
class RenderContextHTMLCanvas extends RenderContext {
    private ctx: CanvasRenderingContext2D
    private canvas: HTMLCanvasElement // just used to clear the frame

    constructor(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
        super()
        this.ctx = ctx
        this.canvas = canvas
    }

    initAndClearFrame(clearColor: RGBA): void {
        this.ctx.imageSmoothingEnabled = false
        this.ctx.setTransform(1, 0, 0, 1, 0, 0)
        this.ctx.strokeStyle = 'rgba(0,0,0,1)'
        this.ctx.lineWidth = 1
        this.ctx.fillStyle = `rgba(${clearColor.r*255},${clearColor.g*255},${clearColor.b*255},${clearColor.a})`
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
        // this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
    }

    saveState(): void {
        this.ctx.save()
    }

    restoreState(): void {
        this.ctx.restore()
    }

    translate(x: number, y: number): void {
        this.ctx.translate(x, y)
    }

    scale(x: number, y: number): void {
        this.ctx.scale(x, y)
    }

    rotate(angle: number): void {
        this.ctx.rotate(angle)
    }

    setTransform(a: number, b: number, c: number, d: number, e: number, f: number): void {
        this.ctx.setTransform(a, b, c, d, e, f)
    }

    drawImage(image: Texture, x1: number, y1: number, w1: number, h1: number, x2: number, y2: number, w2: number, h2: number): void {
    // drawImage(image: Texture, x: number, y: number, w: number, h: number): void {
        // this.ctx.drawImage(image.handle!, x, y, w, h)
        this.ctx.drawImage(image.handle!, x1, y1, w1, h1, x2, y2, w2, h2)
    }

    drawLine(x1: number, y1: number, x2: number, y2: number): void {
        this.ctx.beginPath()
        this.ctx.moveTo(x1, y1)
        this.ctx.lineTo(x2, y2)
        this.ctx.stroke()
    }

    fillRect(x: number, y: number, w: number, h: number): void {
        this.ctx.fillRect(x, y, w, h)
    }

    strokeRect(x: number, y: number, w: number, h: number): void {
        this.ctx.strokeRect(x, y, w, h)
    }

    // ---

    setGlobalAlpha(alpha: number): void {
        this.ctx.globalAlpha = alpha
    }

    getGlobalAlpha(): number {
        return this.ctx.globalAlpha
    }

    setStrokeColor(color: RGBA): void {
        this.ctx.strokeStyle = color.toCSS()
    }

    setLineWidth(w: number): void {
        this.ctx.lineWidth = w
    }

    setFillColor(color: RGBA): void {
        this.ctx.fillStyle = color.toCSS()
    }

    setFont(font: string): void {
        this.ctx.font = font
    }

    // ---

    setTextBaseline(baseline: string): void {
        switch (baseline) {
            case 'top':
                this.ctx.textBaseline = 'top'
                break
            case 'bottom':
                this.ctx.textBaseline = 'bottom'
                break
            default:
                this.ctx.textBaseline = 'middle'
                break
        }
    }

    fillText(text: string, x: number, y: number): void {
        this.ctx.fillText(text, x, y)
    }
}

///////////////////////////////////////////////////////////////////////////////
// RenderContextWebGL2
///////////////////////////////////////////////////////////////////////////////
class RenderContextWebGL2 extends RenderContext {
    private gl: WebGL2RenderingContext | null
    private canvas: HTMLCanvasElement // just used to clear the frame

    constructor(gl: WebGL2RenderingContext, canvas: HTMLCanvasElement) {
        super()
        this.gl = gl
        this.canvas = canvas
    }
}

///////////////////////////////////////////////////////////////////////////////
// MapCanvas
///////////////////////////////////////////////////////////////////////////////
/**
 * @class MapCanvas
 * @description A canvas to render the map: 2D Canvas or WebGL2
 */
export class MapCanvas {
    private canvas: HTMLCanvasElement
    private ctx: CanvasRenderingContext2D | null = null
    private gl: WebGL2RenderingContext | null = null
    private renderCanvas: RenderContext

    constructor(useWebGL2 = false) {
        this.canvas = document.createElement('canvas')
        assert(this.canvas !== null, 'HTMLCanvasElement is null')

        if (useWebGL2) {
            const attr: WebGLContextAttributes = {
                alpha: true,
                antialias: true,
                depth: true,
                stencil: true,
                premultipliedAlpha: true,
                preserveDrawingBuffer: false,
                failIfMajorPerformanceCaveat: false,
                powerPreference: 'default',
                desynchronized: false,
            }
            const gl = this.gl = this.canvas.getContext('webgl2', attr)
            if (gl !== null) {
                // print version of WebGL
                console.log('WebGL2RenderingContext', gl.getParameter(gl.VERSION))
            } else {
                alert("WebGL2RenderingContext is null")
                assert(this.gl !== null, 'WebGL2RenderingContext is null')
            }
        } else {
            this.ctx = this.canvas.getContext('2d')!
            assert(this.ctx !== null, 'CanvasRenderingContext2D is null')
        }

        this.renderCanvas = useWebGL2 ? new RenderContextWebGL2(this.gl!, this.canvas) : new RenderContextHTMLCanvas(this.ctx!, this.canvas)

        this.canvas.style.width = '100%'
        this.canvas.style.height = '100%'
        this.canvas.width = 100
        this.canvas.height = 100
        // this.canvas.style.imageRendering = 'optimizeSpeed'
        this.canvas.style.imageRendering = 'pixelated'
    }

    public getRC(): RenderContext {
        return this.renderCanvas
    }

    public getCanvasElement(): HTMLCanvasElement {
        assert(this.canvas !== null, 'HTMLCanvasElement is null')
        return this.canvas!
    }

    // public getContext(): CanvasRenderingContext2D {
    //     assert(this.ctx !== null, 'CanvasRenderingContext2D is null')
    //     return this.ctx!
    // }
}
