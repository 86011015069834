import { TileType, TileId, getTileInfo } from "./tiles"
import { Thing } from "./thing"
import { Character } from "./character"
import { assert } from "./assert"
import { Vec2D } from "./core"
import { TemplateInstructionsInstance, TemplatePainter } from "./structure-templates"
import { ChunkGenerator } from "./chunk-generator"
import { FogOfWarType, TileLevel } from "./enums"

const MAX_MAP_XY_COORDS = 32 * 1024 * 1024

// const neighborOffsets = [
//     { dx: -1, dy: -1 },
//     { dx: 0, dy: -1 },
//     { dx: 1, dy: -1 },
//     { dx: -1, dy: 0 },
//     { dx: 1, dy: 0 },
//     { dx: -1, dy: 1 },
//     { dx: 0, dy: 1 },
//     { dx: 1, dy: 1 },
// ]

///////////////////////////////////////////////////////////////////////////////
// MapChunk
///////////////////////////////////////////////////////////////////////////////
export class MapChunk {
    static CHUNK_SIZE = 8

    // TODO: allocate/compress each layer separately: only allocate array if the layer is not a single tile
    // L0 = Substratum
    // L1 = Ground / floor
    // L2 = Wall
    // L3 = Roof
    static TILE_LEVEL_OFFSET = MapChunk.CHUNK_SIZE * MapChunk.CHUNK_SIZE

    public tileMap: Uint16Array = new Uint16Array(0)
    public thingMap: (Thing | null) [] = []
    public fogOfWarMap: Float32Array = new Float32Array(0)
    public isDirty = true

    constructor() {
        console.log(`New MapChunk: ${MapChunk.CHUNK_SIZE}x${MapChunk.CHUNK_SIZE}`)
    }

    initializeTiles(defaultSubstratum = TileId.SUBSTRATUM_ROCK, defaultFloor = TileId.FLOOR_GRASS, defaultWall = TileId.EMPTY) {
        // console.log(`initializeTiles`)
        this.tileMap = new Uint16Array(MapChunk.CHUNK_SIZE * MapChunk.CHUNK_SIZE * TileLevel.COUNT)
        // fill each layer with the default tile
        const levelTiles = [defaultSubstratum, defaultFloor, defaultWall]
        for (let level = 0; level < TileLevel.COUNT; level++) {
            const tileId = levelTiles[level]
            for (let y = 0; y < MapChunk.CHUNK_SIZE; y++) {
                for (let x = 0; x < MapChunk.CHUNK_SIZE; x++) {
                    this.tileMap[y * MapChunk.CHUNK_SIZE + x + level * MapChunk.TILE_LEVEL_OFFSET] = tileId
                }
            }
        }
    }

    initalizeThingMap() {
        // console.log(`initializeThingMap`)
        this.thingMap = new Array(MapChunk.CHUNK_SIZE * MapChunk.CHUNK_SIZE).fill(null)
    }

    initializeFowMap(value: number = 0) {
        // console.log(`initializeFowMap: ${value}`)
        this.fogOfWarMap = new Float32Array(MapChunk.CHUNK_SIZE * MapChunk.CHUNK_SIZE).fill(value)
    }

    fillFowMap(value: number) {
        this.fogOfWarMap.fill(value)
    }

    fillTileMap(value: TileType) {
        this.tileMap.fill(value)
    }

    copyTileMap(src: Uint16Array) {
        assert(src.length === MapChunk.CHUNK_SIZE * MapChunk.CHUNK_SIZE, `src length is not w * h: ${src.length} !== ${MapChunk.CHUNK_SIZE} * ${MapChunk.CHUNK_SIZE}`)
        this.tileMap = src.slice()
    }

    placeThing(thing: Thing, wx: number, wy: number, x: number, y: number, isFloating = false): void {
        // assert is integer
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        assert(wx === Math.floor(wx), `wx is not an integer: ${wx}`)
        assert(wy === Math.floor(wy), `wy is not an integer: ${wy}`)
        this.thingMap[y * MapChunk.CHUNK_SIZE + x] = thing
        thing.x = wx
        thing.y = wy
        thing.isFloating = isFloating
    }

    setTile(x: number, y: number, tileId: TileType) {
        const level = getTileInfo(tileId).level
        assert(level >= 0 && level < TileLevel.COUNT, `level is out of bounds: ${level}`)
        assert(x >= 0 && x < MapChunk.CHUNK_SIZE, `x is out of bounds: ${x}`)
        assert(y >= 0 && y < MapChunk.CHUNK_SIZE, `y is out of bounds: ${y}`)
        assert(tileId >= 0 && tileId < 65536, `tileId is out of bounds: ${tileId}`)
        this.tileMap[y * MapChunk.CHUNK_SIZE + x + level * MapChunk.TILE_LEVEL_OFFSET] = tileId
    }

    getTile(x: number, y: number): TileType {
        assert(x >= 0 && x < MapChunk.CHUNK_SIZE, `x is out of bounds: ${x}`)
        assert(y >= 0 && y < MapChunk.CHUNK_SIZE, `y is out of bounds: ${y}`)
        const wallTile = this.tileMap[y * MapChunk.CHUNK_SIZE + x + TileLevel.WALL * MapChunk.TILE_LEVEL_OFFSET]
        if (wallTile !== TileId.EMPTY) {
            return wallTile
        }
        const floorTile = this.tileMap[y * MapChunk.CHUNK_SIZE + x + TileLevel.FLOOR * MapChunk.TILE_LEVEL_OFFSET]
        if (floorTile !== TileId.EMPTY) {
            return floorTile
        }
        return this.tileMap[y * MapChunk.CHUNK_SIZE + x + TileLevel.SUBSTRATUM * MapChunk.TILE_LEVEL_OFFSET]
    }

    getTileLevel(x: number, y: number, level: TileLevel): TileType {
        assert(level >= 0 && level < TileLevel.COUNT, `level is out of bounds: ${level}`)
        assert(x >= 0 && x < MapChunk.CHUNK_SIZE, `x is out of bounds: ${x}`)
        assert(y >= 0 && y < MapChunk.CHUNK_SIZE, `y is out of bounds: ${y}`)
        return this.tileMap[y * MapChunk.CHUNK_SIZE + x + level * MapChunk.TILE_LEVEL_OFFSET]
    }

    removeWall(x: number, y: number) {
        assert(x >= 0 && x < MapChunk.CHUNK_SIZE, `x is out of bounds: ${x}`)
        assert(y >= 0 && y < MapChunk.CHUNK_SIZE, `y is out of bounds: ${y}`)
        this.tileMap[y * MapChunk.CHUNK_SIZE + x + TileLevel.WALL * MapChunk.TILE_LEVEL_OFFSET] = TileId.EMPTY
    }

    removeFloor(x: number, y: number) {
        assert(x >= 0 && x < MapChunk.CHUNK_SIZE, `x is out of bounds: ${x}`)
        assert(y >= 0 && y < MapChunk.CHUNK_SIZE, `y is out of bounds: ${y}`)
        this.tileMap[y * MapChunk.CHUNK_SIZE + x + TileLevel.FLOOR * MapChunk.TILE_LEVEL_OFFSET] = TileId.EMPTY
    }
}

///////////////////////////////////////////////////////////////////////////////
// GameMap
///////////////////////////////////////////////////////////////////////////////
/**
 * @class GameMap
 * @description A map of tiles
 */
export class GameMap {
    public characters: Array<Character> = []

    chunksInitialized = false

    // x, y, w, h in chunk coordinates
    virtualChunkX = 0 // virtual chunk x-index for top left corner of map
    virtualChunkY = 0 // virtual chunk x-index for top left corner of map
    virtualChunkW = 12
    virtualChunkH = 12
    virtualChunks: Array<MapChunk> = new Array(this.virtualChunkW * this.virtualChunkH).fill(null)

    constructor(other?: GameMap) {
        // TODO: initalize all for now but then initalize them on demand

        // allocate chunks
        for(let i = 0; i < this.virtualChunks.length; i++) {
            const chunk = this.virtualChunks[i] = new MapChunk()
            chunk.initializeTiles()
            chunk.initializeFowMap()
            chunk.initalizeThingMap()
        }

        if (other) {
            // copy tiles data from other
            assert(this.virtualChunks.length === other.virtualChunks.length, `virtualChunks length mismatch: ${this.virtualChunks.length} !== ${other.virtualChunks.length}`)
            for(let i = 0; i < this.virtualChunks.length; i++) {
                const chunk = this.virtualChunks[i]
                const otherChunk = other.virtualChunks[i]
                chunk!.copyTileMap(otherChunk!.tileMap)
            }

        } else {
            // fill chunks with empty tiles
            for(let i = 0; i < this.virtualChunks.length; i++) {
                const chunk = this.virtualChunks[i]
                chunk!.fillTileMap(TileId.EMPTY)
            }
        }
    }

    // --- Chunks ---

    getVirtualChunkX(): number { return this.virtualChunkX; }
    getVirtualChunkY(): number { return this.virtualChunkY; }
    getVirtualChunkW(): number { return this.virtualChunkW; }
    getVirtualChunkH(): number { return this.virtualChunkH; }
    getVirtualChunks(): Array<MapChunk> { return this.virtualChunks; }

    isChunkValid(cx: number, cy: number): boolean {
        return cx >= this.virtualChunkX && cx < this.virtualChunkX + this.virtualChunkW &&
               cy >= this.virtualChunkY && cy < this.virtualChunkY + this.virtualChunkH
    }

    getChunk(cx: number, cy: number): MapChunk {
        assert(cx === Math.floor(cx), `cx is not an integer: ${cx}`)
        assert(cy === Math.floor(cy), `cy is not an integer: ${cy}`)
        assert(cx >= 0, `cx is negative: ${cx}`)
        assert(cy >= 0, `cy is negative: ${cy}`)
        assert(cx < this.virtualChunkW, `cx is out of bounds: ${cx} >= ${this.virtualChunkW}`)
        assert(cy < this.virtualChunkH, `cy is out of bounds: ${cy} >= ${this.virtualChunkH}`)
        return this.virtualChunks[cy * this.virtualChunkW + cx]
    }

    chunkSetOrigin(x: number, y: number, chunkGenerator: ChunkGenerator, fowMode: FogOfWarType): boolean {
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        assert(x >= 0, `x is negative: ${x}`)
        assert(y >= 0, `y is negative: ${y}`)
        const dx = x - this.virtualChunkX
        const dy = y - this.virtualChunkY
        return this.chunkShiftOrigin(dx, dy, chunkGenerator, fowMode)
    }

    chunkShiftOrigin(dx: number, dy: number, chunkGenerator: ChunkGenerator, fowMode: FogOfWarType): boolean {
        const self = this

        assert(dx === Math.floor(dx), `dx is not an integer: ${dx}`)
        assert(dy === Math.floor(dy), `dy is not an integer: ${dy}`)
        assert(self.virtualChunkX + dx >= 0, `x is negative: ${self.virtualChunkX + dx}`)
        assert(self.virtualChunkY + dy >= 0, `y is negative: ${self.virtualChunkY + dy}`)
        assert(self.virtualChunks.length === self.virtualChunkW * self.virtualChunkH, `virtualChunks length mismatch: ${self.virtualChunks.length} !== ${self.virtualChunkW * self.virtualChunkH}`)
        const hasTiles = self.virtualChunks[0]!.tileMap.length > 0
        const hasFow = self.virtualChunks[0]!.fogOfWarMap.length > 0
        const hasThings = self.virtualChunks[0]!.thingMap.length > 0
        assert(hasTiles, "Tile map not initialized")

        // TODO: avoid recomputing chunks that have just been computed and deleted because the user
        // stepped back and forth, use a softwer approach since they will be heavy to compute.
        // Simply schedule delayed deletion to 1m after it's been removed.

        // TODO: recycle chunks instead of disposing and creating new ones? No becasue we need to delay delete them.
        // Maybe on top of the delayed deletion we can recycle the allocated memory.

        const newX = self.virtualChunkX + dx
        const newY = self.virtualChunkY + dy
        if (self.chunksInitialized && newX === self.virtualChunkX && newY === self.virtualChunkY) {
            return false
        } else {
            self.chunksInitialized = true
        }

        // console.log('Shifting Map Chunk Origin:', self.virtualChunkX, self.virtualChunkY, '>>>', newX, newY, '|', newX * MapChunk.CHUNK_SIZE, newY * MapChunk.CHUNK_SIZE)

        // update virtual chunk origin
        self.virtualChunkX = newX
        self.virtualChunkY = newY

        const fowValue = fowMode === FogOfWarType.NONE ? 1 : 0

        const updateChunk: { [key: number]: boolean } = {}

        console.log('Shifting origin X:')
        // shift X chunk column
        if (dx > 0) {
            // shift right
            for (let y = 0; y < self.virtualChunkH; y++) {
                for (let x = 0; x < self.virtualChunkW - dx; x++) {
                    self.virtualChunks[y * self.virtualChunkW + x] = self.virtualChunks[y * self.virtualChunkW + x + dx]
                }
                for (let x = Math.max(0, self.virtualChunkW - dx); x < self.virtualChunkW; x++) {
                    updateChunk[y * self.virtualChunkW + x] = true
                    assert(x >= 0, `x is negative: ${self.virtualChunkX + x}`)
                    assert(y >= 0, `y is negative: ${self.virtualChunkY + y}`)
                    assert(x < self.virtualChunkW, `x is out of bounds: ${self.virtualChunkX + x} >= ${self.virtualChunkW}`)
                    assert(y < self.virtualChunkH, `y is out of bounds: ${self.virtualChunkY + y} >= ${self.virtualChunkH}`)
                }
            }
        } else if (dx < 0) {
            // shift left
            for (let y = 0; y < self.virtualChunkH; y++) {
                for (let x = self.virtualChunkW - 1; x >= Math.max(0, -dx); x--) {
                    self.virtualChunks[y * self.virtualChunkW + x] = self.virtualChunks[y * self.virtualChunkW + x + dx]
                }
                for (let x = Math.max(0, -dx - 1); x >= 0; x--) {
                    updateChunk[y * self.virtualChunkW + x] = true
                    assert(x >= 0, `x is negative: ${self.virtualChunkX + x}`)
                    assert(y >= 0, `y is negative: ${self.virtualChunkY + y}`)
                    assert(x < self.virtualChunkW, `x is out of bounds: ${self.virtualChunkX + x} >= ${self.virtualChunkW}`)
                    assert(y < self.virtualChunkH, `y is out of bounds: ${self.virtualChunkY + y} >= ${self.virtualChunkH}`)
                }
            }
        }

        console.log('Shifting origin Y:')
        // shift Y chunk column
        if (dy > 0) {
            // shift down
            for (let y = 0; y < self.virtualChunkH - dy; y++) {
                for (let x = 0; x < self.virtualChunkW; x++) {
                    self.virtualChunks[y * self.virtualChunkW + x] = self.virtualChunks[(y + dy) * self.virtualChunkW + x]
                }
            }
            for (let y = Math.max(0, self.virtualChunkH - dy); y < self.virtualChunkH; y++) {
                for (let x = 0; x < self.virtualChunkW; x++) {
                    updateChunk[y * self.virtualChunkW + x] = true
                    assert(x >= 0, `x is negative: ${self.virtualChunkX + x}`)
                    assert(y >= 0, `y is negative: ${self.virtualChunkY + y}`)
                    assert(x < self.virtualChunkW, `x is out of bounds: ${self.virtualChunkX + x} >= ${self.virtualChunkW}`)
                    assert(y < self.virtualChunkH, `y is out of bounds: ${self.virtualChunkY + y} >= ${self.virtualChunkH}`)
                }
            }
        } else if (dy < 0) {
            // shift up
            for (let y = self.virtualChunkH - 1; y >= Math.max(0, -dy); y--) {
                for (let x = 0; x < self.virtualChunkW; x++) {
                    self.virtualChunks[y * self.virtualChunkW + x] = self.virtualChunks[(y + dy) * self.virtualChunkW + x]
                }
            }
            for (let y = Math.max(0, -dy - 1); y >= 0; y--) {
                for (let x = 0; x < self.virtualChunkW; x++) {
                    updateChunk[y * self.virtualChunkW + x] = true
                    assert(x >= 0, `x is negative: ${self.virtualChunkX + x}`)
                    assert(y >= 0, `y is negative: ${self.virtualChunkY + y}`)
                    assert(x < self.virtualChunkW, `x is out of bounds: ${self.virtualChunkX + x} >= ${self.virtualChunkW}`)
                    assert(y < self.virtualChunkH, `y is out of bounds: ${self.virtualChunkY + y} >= ${self.virtualChunkH}`)
                }
            }
        }

        // Make sure each chunk is allocated and initialized only once with no wasteful computation
        // due the the X shift happening before the Y shift.
        Object.keys(updateChunk).forEach((xyOffset: any) => {
            assert(xyOffset >= 0, `xyOffset is negative: ${xyOffset}`)
            assert(xyOffset < self.virtualChunks.length, `xyOffset is out of bounds: ${xyOffset} >= ${self.virtualChunks.length}`)
            self.virtualChunks[xyOffset] = new MapChunk()
            self.virtualChunks[xyOffset].initializeTiles()
            hasFow ? self.virtualChunks[xyOffset].initializeFowMap(fowValue) : 0
            hasThings ? self.virtualChunks[xyOffset].initalizeThingMap() : 0
        })

        // Check templates -----------------------------------------------

        const bounds = self.getVirtualBounds()
        const templatesUpdate = chunkGenerator.checkTemplatesStatus(bounds)
        // just put the names
        const templatesUpdate2 = {
            load: templatesUpdate.load.map((instance: TemplateInstructionsInstance) => instance.instanceName),
            unload: templatesUpdate.unload.map((instance: TemplateInstructionsInstance) => instance.instanceName),
        }

        console.log('---> TEMPLATE UPDATES:', JSON.stringify(templatesUpdate2, null, 2))
        // Unload templates first
        templatesUpdate.unload.forEach((instance: TemplateInstructionsInstance) => {
            console.log('---> TEMPLATE UPDATES <<< Unload template:', instance.instanceName)
            this.unloadTemplate(instance)
            // instance.loaded = false
        })
        // Load templates later
        templatesUpdate.load.forEach((instance: TemplateInstructionsInstance) => {
            console.log('---> TEMPLATE UPDATES >>> Load template:', instance.instanceName)
            instance.loaded = true
        })

        // Generate in passes ---------------------------------------------

        // Pass 1: Generate world tiles & structures
        for(let y = 0; y < self.virtualChunkH; y++) {
            for( let x = 0; x < self.virtualChunkW; x++) {
                const chunk = self.virtualChunks[y * self.virtualChunkW + x]
                if (chunk.isDirty) {
                    chunkGenerator.generateChunk_Structure(self, chunk, self.virtualChunkX + x, self.virtualChunkY + y)
                    // console.log(`Generated chunk structure ${self.virtualChunkX + x}, ${self.virtualChunkY + y}`)
                }
            }
        }
        // Templates
        templatesUpdate.load.forEach((instance: TemplateInstructionsInstance) => {
            TemplatePainter.drawTemplate_Structure(instance, this)
        })

        // Pass 2: Generate world things & trees
        for(let y = 0; y < self.virtualChunkH; y++) {
            for( let x = 0; x < self.virtualChunkW; x++) {
                const chunk = self.virtualChunks[y * self.virtualChunkW + x]
                if (chunk.isDirty) {
                    chunkGenerator.generateChunk_Things(self, chunk, self.virtualChunkX + x, self.virtualChunkY + y)
                    // console.log(`Generated chunk things ${self.virtualChunkX + x}, ${self.virtualChunkY + y}`)
                }
            }
        }
        templatesUpdate.load.forEach((instance: TemplateInstructionsInstance) => {
            TemplatePainter.drawTemplate_Things(instance, this)
        })

        // Pass 3: Generate world characters
        for(let y = 0; y < self.virtualChunkH; y++) {
            for( let x = 0; x < self.virtualChunkW; x++) {
                const chunk = self.virtualChunks[y * self.virtualChunkW + x]
                if (chunk.isDirty) {
                    chunkGenerator.generateChunk_Characters(self, chunk, self.virtualChunkX + x, self.virtualChunkY + y)
                    // console.log(`Generated chunk characters ${self.virtualChunkX + x}, ${self.virtualChunkY + y}`)
                    chunk.isDirty = false
                }
            }
        }
        templatesUpdate.load.forEach((instance: TemplateInstructionsInstance) => {
            TemplatePainter.drawTemplate_Characters(instance, this)
        })

        // console.log('<<<< Shifting Origin')

        return true
    }

    unloadTemplate(instance: TemplateInstructionsInstance) {
        instance.loaded = false
        // remove all tracked characters from the map
        instance.characters.forEach((character: Character) => {
            this.removeCharacter(character)
        })
    }

    removeCharacter(character: Character) {
        const index = this.characters.indexOf(character)
        if (index !== -1) {
            this.characters.splice(index, 1)
        }
    }

    // --- Tile Map ---

    hasTileMap(): boolean {
        return this.virtualChunks.length > 0 &&
               this.virtualChunks[0]!.tileMap.length > 0
    }

    copyTileMap(src: Uint32Array, srcW: number, srcH: number, level = 0) {
        const w = this.virtualChunkW * MapChunk.CHUNK_SIZE
        const h = this.virtualChunkH * MapChunk.CHUNK_SIZE
        assert(srcW * srcH <= w * h, `src length is too big: ${srcW} * ${srcH} > ${w} * ${h}`)
        // copy each tile one by one
        for (let y = 0; y < srcH; y++) {
            for (let x = 0; x < srcW; x++) {
                this.set(x, y, src[y * srcW + x])
            }
        }
    }

    public fill(tileId: TileType): void {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        for( let y = vy; y < vy + vh; y++) {
            for( let x = vx; x < vx + vw; x++) {
                this.set(x, y, tileId)
            }
        }
    }

    // --- Tiles getters and setters ---

    public getVirtualBounds(): { vx: number, vy: number, vw: number, vh: number } {
        return {
            vx: this.virtualChunkX * MapChunk.CHUNK_SIZE,
            vy: this.virtualChunkY * MapChunk.CHUNK_SIZE,
            vw: this.virtualChunkW * MapChunk.CHUNK_SIZE,
            vh: this.virtualChunkH * MapChunk.CHUNK_SIZE,
        }
    }

    private getChunkCoords(x: number, y: number): { chunkX: number, chunkY: number, cx: number, cy: number } {
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        assert(x >= 0, `x is negative: ${x}`)
        assert(y >= 0, `y is negative: ${y}`)
        assert(x < MAX_MAP_XY_COORDS, `x is too big: ${x}`)
        assert(y < MAX_MAP_XY_COORDS, `y is too big: ${y}`)
        const chunkX = Math.floor(x / MapChunk.CHUNK_SIZE) - this.virtualChunkX
        const chunkY = Math.floor(y / MapChunk.CHUNK_SIZE) - this.virtualChunkY
        const cx = x % MapChunk.CHUNK_SIZE
        const cy = y % MapChunk.CHUNK_SIZE
        assert(chunkX >= 0 && chunkX < this.virtualChunkW, `chunkX out of bounds: ${chunkX}`)
        assert(chunkY >= 0 && chunkY < this.virtualChunkH, `chunkY out of bounds: ${chunkY}`)
        return { chunkX, chunkY, cx, cy }
    }

    public isInVirtualBounds(wx: number, wy: number): boolean {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        return wx >= vx && wy >= vy && wx < vx + vw && wy < vy + vh
    }

    public get(x: number, y: number): TileType {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        assert(x >= vx, `x is out of bounds: ${x} < ${vx} @1`)
        assert(y >= vy, `y is out of bounds: ${y} < ${vy}`)
        assert(x < vx + vw, `x is out of bounds: ${x} >= ${vx + vw}`)
        assert(y < vy + vh, `y is out of bounds: ${y} >= ${vy + vh}`)
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        return chunk.getTile(cx, cy)
    }

    public getLevel(x: number, y: number, level: TileLevel): TileType {
        assert(level >= 0 && level < TileLevel.COUNT, `level is out of bounds: ${level}`)
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        assert(x >= vx, `x is out of bounds: ${x} < ${vx} @1`)
        assert(y >= vy, `y is out of bounds: ${y} < ${vy}`)
        assert(x < vx + vw, `x is out of bounds: ${x} >= ${vx + vw}`)
        assert(y < vy + vh, `y is out of bounds: ${y} >= ${vy + vh}`)
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        return chunk.getTileLevel(cx, cy, level)
    }

    public set(x: number, y: number, tileId: TileType): void {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        assert(x >= vx, `x is out of bounds: ${x} < ${vx} @2`)
        assert(y >= vy, `y is out of bounds: ${y} < ${vy}`)
        assert(x < vx + vw, `x is out of bounds: ${x} >= ${vx + vw}`)
        assert(y < vy + vh, `y is out of bounds: ${y} >= ${vy + vh}`)
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        chunk.setTile(cx, cy, tileId)
    }

    public removeWall(wx: number, wy: number) {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(wx === Math.floor(wx), `wx is not an integer: ${wx}`)
        assert(wy === Math.floor(wy), `wy is not an integer: ${wy}`)
        assert(wx >= vx, `wx is out of bounds: ${wx} < ${vx} @3`)
        assert(wy >= vy, `wy is out of bounds: ${wy} < ${vy}`)
        assert(wx < vx + vw, `wx is out of bounds: ${wx} >= ${vx + vw}`)
        assert(wy < vy + vh, `wy is out of bounds: ${wy} >= ${vy + vh}`)
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(wx, wy)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        chunk.removeWall(cx, cy)
    }

    public removeFloor(wx: number, wy: number) {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(wx === Math.floor(wx), `wx is not an integer: ${wx}`)
        assert(wy === Math.floor(wy), `wy is not an integer: ${wy}`)
        assert(wx >= vx, `wx is out of bounds: ${wx} < ${vx} @4`)
        assert(wy >= vy, `wy is out of bounds: ${wy} < ${vy}`)
        assert(wx < vx + vw, `wx is out of bounds: ${wx} >= ${vx + vw}`)
        assert(wy < vy + vh, `wy is out of bounds: ${wy} >= ${vy + vh}`)
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(wx, wy)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        chunk.removeFloor(cx, cy)
    }

    // DEPRECATED
    public getSafe_(x: number, y: number): TileType {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        if (x < vx || x >= vx + vw || y < vy || y >= vy + vh) {
            return TileId.EMPTY
        }
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        return chunk.getTile(cx, cy)
    }

    // DEPRECATED
    public setSafe_(x: number, y: number, tileId: TileType, level = 0): void {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        if (x < vx || x >= vx + vw || y < vy || y >= vy + vh) {
            return
        }
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        chunk.setTile(cx, cy, tileId)
    }

    // --- Things & Lists of Things ---

    initializeThingMap(): void {
        this.virtualChunks.forEach(chunk => {
            chunk.initalizeThingMap()
        })
    }

    hasThingMap(): boolean {
        return this.virtualChunks.length > 0 &&
               this.virtualChunks[0]!.thingMap.length > 0
    }

    placeThing(thing: Thing, x: number, y: number, isFloating = false): void {
        // assert is integer
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        assert(x >= vx, `x is out of bounds: ${x} < ${vx} @3`)
        assert(y >= vy, `y is out of bounds: ${y} < ${vy}`)
        assert(x < vx + vw, `x is out of bounds: ${x} >= ${vx + vw}`)
        assert(y < vy + vh, `y is out of bounds: ${y} >= ${vy + vh}`)
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        chunk.thingMap[cy * MapChunk.CHUNK_SIZE + cx] = thing
        thing.x = x
        thing.y = y
        thing.isFloating = isFloating
    }

    placeThingSafe(thing: Thing, x: number, y: number, isFloating = false): void {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        if (x < vx || x >= vx + vw || y < vy || y >= vy + vh) {
            return
        }
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        chunk.thingMap[cy * MapChunk.CHUNK_SIZE + cx] = thing
        thing.x = x
        thing.y = y
        thing.isFloating = isFloating
    }

    removeThingXY(x: number, y: number): Thing | null {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        assert(x >= vx, `x is out of bounds: ${x} < ${vx} @3`)
        assert(y >= vy, `y is out of bounds: ${y} < ${vy}`)
        assert(x < vx + vw, `x is out of bounds: ${x} >= ${vx + vw}`)
        assert(y < vy + vh, `y is out of bounds: ${y} >= ${vy + vh}`)
        // remove thing at x y if exists and return it without destroying it
        const thing = this.getThing(x, y)
        if (thing) {
            thing.x = -1
            thing.y = -1
            const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
            const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
            chunk.thingMap[cy * MapChunk.CHUNK_SIZE + cx] = null
            return thing
        }
        return null
    }

    removeThing(thing: Thing): Thing | null {
        // remove thing at x y if exists and return it without destroying it
        const x = thing.x
        const y = thing.y
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        assert(chunk.thingMap[cy * MapChunk.CHUNK_SIZE + cx] === thing, `Thing ${thing.name} not found at ${thing.x}, ${thing.y}`)
        chunk.thingMap[cy * MapChunk.CHUNK_SIZE + cx] = null
        return thing
    }

    getThing(x: number, y: number): Thing | null {
        // assert is integer
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        assert(x >= vx, `x is out of bounds: ${x} < ${vx} @3`)
        assert(y >= vy, `y is out of bounds: ${y} < ${vy}`)
        assert(x < vx + vw, `x is out of bounds: ${x} >= ${vx + vw}`)
        assert(y < vy + vh, `y is out of bounds: ${y} >= ${vy + vh}`)
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        assert(chunk.thingMap.length > 0, "Thing map not initialized")
        return chunk.thingMap[cy * MapChunk.CHUNK_SIZE + cx]
    }

    // --- Fog of War ---

    getFow(x: number, y: number): number {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        assert(x >= vx, `x is out of bounds: ${x} < ${vx} @3`)
        assert(y >= vy, `y is out of bounds: ${y} < ${vy}`)
        assert(x < vx + vw, `x is out of bounds: ${x} >= ${vx + vw}`)
        assert(y < vy + vh, `y is out of bounds: ${y} >= ${vy + vh}`)
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        assert(chunk.fogOfWarMap.length > 0, "Fog of war map not initialized")
        return chunk.fogOfWarMap[cy * MapChunk.CHUNK_SIZE + cx]
    }

    getFowSafe(x: number, y: number, def: number = 1): number {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        if (x < vx || x >= vx + vw || y < vy || y >= vy + vh) {
            return def
        }
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        assert(chunk.fogOfWarMap.length > 0, "Fog of war map not initialized")
        return chunk.fogOfWarMap[cy * MapChunk.CHUNK_SIZE + cx]
    }

    setFow(x: number, y: number, value: number): void {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        assert(x >= vx, `x is out of bounds: ${x} < ${vx} @4`)
        assert(y >= vy, `y is out of bounds: ${y} < ${vy}`)
        assert(x < vx + vw, `x is out of bounds: ${x} >= ${vx + vw}`)
        assert(y < vy + vh, `y is out of bounds: ${y} >= ${vy + vh}`)
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        assert(chunk.fogOfWarMap.length > 0, "Fog of war map not initialized")
        chunk.fogOfWarMap[cy * MapChunk.CHUNK_SIZE + cx] = value
    }

    setFowSafe(x: number, y: number, value: number): void {
        const { vx, vy, vw, vh } = this.getVirtualBounds()
        assert(x === Math.floor(x), `x is not an integer: ${x}`)
        assert(y === Math.floor(y), `y is not an integer: ${y}`)
        if (x < vx || x >= vx + vw || y < vy || y >= vy + vh) {
            return
        }
        const { chunkX, chunkY, cx, cy } = this.getChunkCoords(x, y)
        const chunk = this.virtualChunks[chunkY * this.virtualChunkW + chunkX]
        assert(chunk.fogOfWarMap.length > 0, "Fog of war map not initialized")
        chunk.fogOfWarMap[cy * MapChunk.CHUNK_SIZE + cx] = value
    }

    initializeFowMap(fill: number = 0): void {
        this.virtualChunks.forEach(chunk => {
            chunk.initializeFowMap(fill)
        })
    }

    hasFowMap(): boolean {
        return this.virtualChunks.length > 0 &&
               this.virtualChunks[0]!.fogOfWarMap.length > 0
    }

    public fillFogOfWar(value: number): void {
        this.virtualChunks.forEach(chunk => {
            chunk.fillFowMap(value)
        })
    }
}