export class FogOfWar {
    visible: Float32Array | null = null;

    constructor(public width: number, public height: number) {
        this.width = width;
        this.height = height;
        this.visible = new Float32Array(width * height);
    }

    markVisible(x: number, y: number, value = 1) {
        const i = y * this.width + x;
        this.visible![i] = value;
    }

    getVisibility(x: number, y: number) {
        const i = y * this.width + x;
        return this.visible![i];
    }
}

/**
 * Symmetric recursive shadowcasting
 * https://gist.github.com/370417/59bb06ced7e740e11ec7dda9d82717f6
 * 
 * See also:
 * - http://www.adammil.net/blog/v125_Roguelike_Vision_Algorithms.html
 * - https://github.com/370417/symmetric-shadowcasting/blob/master/shadowcasting.py
 * 
 * Recursive shadowcasting algorithm.
 * This algorithm creates a field of view centered around (x, y).
 * Opaque tiles are treated as if they have beveled edges.
 * Transparent tiles are visible only if their center is visible, so the algorithm is symmetric.
 * @param cx - x coordinate of center
 * @param cy - y coordinate of center
 * @param transparent - function that takes (x, y) as arguments and returns the transparency of that tile
 * @param reveal - callback function that reveals the tile at (x, y)
 */
type Transform = {
    xx: number;
    xy: number;
    yx: number;
    yy: number;
};

export function shadowcast(
    cx: number, 
    cy: number, 
    transparent: (x: number, y: number) => boolean, 
    reveal: (x: number, y: number) => void
): void {
    const scan = (y: number, start: number, end: number, transform: Transform): void => {
        if (start >= end) {
            return;
        }

        const xmin = Math.round((y - 0.5) * start);
        const xmax = Math.ceil((y + 0.5) * end - 0.5);

        for (let x = xmin; x <= xmax; x++) {
            const realx = cx + transform.xx * x + transform.xy * y;
            const realy = cy + transform.yx * x + transform.yy * y;

            if (transparent(realx, realy)) {
                if (x >= y * start && x <= y * end) {
                    reveal(realx, realy);
                }
            } else {
                if (x >= (y - 0.5) * start && x - 0.5 <= y * end) {
                    reveal(realx, realy);
                }
                scan(y + 1, start, (x - 0.5) / y, transform);
                start = (x + 0.5) / y;

                if (start >= end) {
                    return;
                }
            }
        }

        scan(y + 1, start, end, transform);
    };

    const transforms: Transform[] = [
        { xx: 1, xy: 0, yx: 0, yy: 1 },
        { xx: 1, xy: 0, yx: 0, yy: -1 },
        { xx: -1, xy: 0, yx: 0, yy: 1 },
        { xx: -1, xy: 0, yx: 0, yy: -1 },
        { xx: 0, xy: 1, yx: 1, yy: 0 },
        { xx: 0, xy: 1, yx: -1, yy: 0 },
        { xx: 0, xy: -1, yx: 1, yy: 0 },
        { xx: 0, xy: -1, yx: -1, yy: 0 }
    ];

    reveal(cx, cy);

    for (let i = 0; i < 8; i++) {
        scan(1, 0, 1, transforms[i]);
    }
}
