// static class that keeps track of time in seconds as a float number
import { assert } from "./assert"

// where 0 is the start of the game
export class GameTime {
    private static _startTime: number = new Date().getTime() / 1000.0
    private static _now: number = 0

    static updateTime() {
        const _now = new Date().getTime() / 1000.0 - GameTime._startTime
        assert(_now >= GameTime._now, 'GameTime._now is not increasing')
        GameTime._now = _now
    }

    static now(): number {
        return GameTime._now
    }
}

export class Stopwatch {
    private _startTime: number = 0
    private _stopTime: number = 0
    private _running: boolean = false

    constructor() {
        this.reset()
    }

    reset() {
        this._startTime = 0
        this._stopTime = 0
        this._running = false
    }

    start() {
        this._startTime = GameTime.now()
        this._running = true
    }

    stop() {
        this._stopTime = GameTime.now()
        this._running = false
    }

    getElapsedTime(): number {
        if (this._running) {
            return GameTime.now() - this._startTime
        } else {
            return this._stopTime - this._startTime
        }
    }
}
