import { App } from './app'
import { initializeStandardWorld } from './world-standard'

// A map of world names to functions initializing the world
export class WorldCatalog {
    static worlds: { [name: string]: (app: App) => void } = {}

    static registerWorld(name: string, fn: (app: App) => void) {
        this.worlds[name] = fn
    }

    static getWorld(name: string): (app: App) => void {
        if (this.worlds[name] === undefined) {
            alert(`World "${name}" not found`)
            throw new Error(`World "${name}" not found`)
        }
        return this.worlds[name]
    }

    static initialize() {
        WorldCatalog.registerWorld('Standard World', initializeStandardWorld)
    }
}
