import { TileType, getTileInfo, TileId, TileInfo } from "./tiles"
import { RGBA, HSL, randomChoice, hexToRGBA } from './core'
import { Config } from './config'
import { registerTile, getTiles } from './tiles'
import { TextureUtils } from "./textures"
import { ImageTransform } from "./image-transform"
import { assert } from "./assert"
import { TileLevel } from "./enums"

const tileInfoDefault: TileInfo = {
    tileId: TileId.EMPTY,
    name : '',
    texture : null,
    averageColor: new RGBA(0, 0, 0, 255),

    level: TileLevel.SUBSTRATUM,

    canSeeThrough : false,
    canWalkThrough : false,
    canFlyOver : false,
    canSwimThrough : false,
    canDig : false,

    // isRustic : false,
    // isDecorative : false,
    // isDirtyFloor : false
}

///////////////////////////////////////////////////////////////////////////////
// TileInitializer
///////////////////////////////////////////////////////////////////////////////
/**
 * @class TileInitializer
 * @description Register tiles and initialize their textures
 */
export class TileInitializer {
    // textues as a map of string to Image
    static initialized: boolean = false

    private constructor() { }

    static async initializeTiles(): Promise<void> {
        // if already initialized then return
        if (this.initialized) {
            return
        } else {
            this.initialized = true
        }

        this.registerCoreTiles()

        this.registerFloorAndWallTiles()

        // Iterate over all tiles and wait for their textures to load using await and promises
        const promises: any = getTiles().map((tileInfo) => {
            return new Promise((resolve, reject) => {
                const texture = tileInfo.texture
                // if texture is not loaded then wait for it to load
                if (texture && !texture.handle!.complete) {
                    texture.handle!.onload = () => {
                        console.log(`Loaded texture: ${tileInfo.name}`)
                        resolve(texture)
                    }
                } else {
                    resolve(texture)
                }
            })
        })
        // wait for all textures to load
        await Promise.all(promises)

        // Compute the average color so we can paint the front of the walls
        const registry = getTiles()
        // iterate over all tiles
        registry.forEach((tileInfo) => {
            // if texture is not null then generate texture atlas
            assert(tileInfo.texture !== null, `Texture is null for tile: ${tileInfo.name}`)
            const texture = tileInfo.texture!
            // const data = ImageTransform.imageToData(texture)
            tileInfo.averageColor = ImageTransform.averageColor(texture)
        })

        // DEBUG: remove this - test image transformations
        // const info = getTileInfo(getTileId('Wall-8-005'))
        // info.texture = ImageTransform.targetHueImage(info.texture!, { targetHue: 0.666 })
        // info.texture = ImageTransform.setLightnessImage(info.texture!, { lightness: 0.5 })
        // info.texture = ImageTransform.setSaturationImage(info.texture!, { saturation: 0.2 })

        // print number of tiles registered in total
        console.log(`Total tiles registered: ${registry.length}`)

        assert(TileId.EMPTY === 0, `TileId.EMPTY is not 0: ${TileId.EMPTY}`)
    }

    // MIC FIXME move this into Textures
    // static async generateTileIconTextures() {
    //     const promises = []
    //     for(const tileInfo of getTiles()) {
    //         const texture = tileInfo.texture!
    //         assert(texture !== null, `Texture is null for tile: ${tileInfo.name}`)
    //         assert(texture.width !== 0, `Texture width is 0 for tile: ${tileInfo.name}`)
    //         assert(texture.height !== 0, `Texture height is 0 for tile: ${tileInfo.name}`)
    //         assert(texture.complete, `Texture is not loaded for tile: ${tileInfo.name}`)
    //         if (texture.width == Config.TILE_PIXEL_SIZE && texture.height == Config.TILE_PIXEL_SIZE) {
    //             TextureUtils.setTexture(tileInfo.name + ':Icon', texture)
    //         } else {
    //             const textureIcon = ImageTransform.makeIconTopLeft(texture)
    //             TextureUtils.setTexture(tileInfo.name + ':Icon', textureIcon)
    //             // add promise
    //             promises.push(new Promise((resolve, reject) => {
    //                 textureIcon.onload = () => {
    //                     resolve(textureIcon)
    //                 }
    //             }))
    //         }
    //     }

    //     // wait all promises
    //     await Promise.all(promises)
    // }

    static registerFloorAndWallTiles() {
        // get names of all textures
        const textureNames = TextureUtils.getTextureNames()
        // filter for the ones starting with Floor-8-
        const floorTextureNames = textureNames.filter((name) => {
            return name.startsWith('Floor-8-')
        })
        // register each floor texture
        floorTextureNames.forEach((name) => {
            // ================================================================
            /* Here we can do post-processing of the texture
            const texture1 = TextureUtils.getTexture(name)
            const texture2 = ImageTransform.addNoiseImage(texture1, {
                noiseStrength: 0.025,
                noiseCoverage: 0.250
            })
            */
            // ================================================================

            registerTile({
                ...tileInfoDefault,
                name: name,
                texture: TextureUtils.getTexture(name),
                canSeeThrough: true,
                canWalkThrough: true,
                canFlyOver: true,
                canSwimThrough: false,
                canDig: true,
                level: TileLevel.FLOOR,
            })
        })

        // filter for the ones starting with Wall-8-
        const wallTextureNames = textureNames.filter((name) => {
            return name.startsWith('Wall-8-')
        })
        // register each wall texture
        wallTextureNames.forEach((name) => {
            registerTile({
                ...tileInfoDefault,

                name: name,
                texture: TextureUtils.getTexture(name),
                canSeeThrough: false,
                canWalkThrough: false,
                canFlyOver: false,
                canSwimThrough: false,
                canDig: true,

                level: TileLevel.WALL,
            })
        })
    }

    static registerCoreTiles() {
        TileId.EMPTY = registerTile({
            ...tileInfoDefault,

            // Technically the EMPTY tile is the only one that can appear in all 4 levels
            name: 'EMPTY',
            level: TileLevel.SUBSTRATUM,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: false,
            canFlyOver: false,
            canSwimThrough: false,
            canDig: false,
        })

        TileId.ERROR = registerTile({
            ...tileInfoDefault,

            name: 'ERROR',
            level: TileLevel.SUBSTRATUM,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: true,
            canFlyOver: true,
            canSwimThrough: false,
            canDig: false,
        })

        TileId.SUBSTRATUM_ROCK = registerTile({
            ...tileInfoDefault,

            name: 'Substratum-ROCK',
            level: TileLevel.SUBSTRATUM,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: true,
            canFlyOver: true,
            canSwimThrough: false,
            canDig: false,
        })

        TileId.FLOOR_CAVE = registerTile({
            ...tileInfoDefault,

            name: 'Floor-CAVE',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: true,
            canFlyOver: true,
            canSwimThrough: false,
            canDig: true,
        })

        TileId.FLOOR_GRASS = registerTile({
            ...tileInfoDefault,

            name: 'Floor-GRASS',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: true,
            canFlyOver: true,
            canSwimThrough: false,
            canDig: true,
        })

        TileId.FLOOR_DIRT = registerTile({
            ...tileInfoDefault,

            name: 'Floor-DIRT',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: true,
            canFlyOver: true,
            canSwimThrough: false,
            canDig: true,
        })

        TileId.FLOOR_ROAD = registerTile({
            ...tileInfoDefault,

            name: 'Floor-ROAD',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: true,
            canFlyOver: true,
            canSwimThrough: false,
            canDig: true,
        })

        TileId.FLOOR_SAND = registerTile({
            ...tileInfoDefault,

            name: 'Floor-SAND',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: true,
            canFlyOver: true,
            canSwimThrough: true,
            canDig: true,
        })

        TileId.FLOOR_SNOW = registerTile({
            ...tileInfoDefault,

            name: 'Floor-SNOW',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: true,
            canFlyOver: true,
            canSwimThrough: true,
            canDig: true,
        })

        TileId.FLOOR_ICE = registerTile({
            ...tileInfoDefault,

            name: 'Floor-ICE',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: true,
            canFlyOver: true,
            canSwimThrough: true,
            canDig: true,
        })

        TileId.FLOOR_SWAMP = registerTile({
            ...tileInfoDefault,

            name: 'Floor-SWAMP',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: true,
            canFlyOver: false,
            canSwimThrough: true,
            canDig: true,
        })

        TileId.FLOOR_STONE = registerTile({
            ...tileInfoDefault,

            name: 'Floor-STONE',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: true,
            canFlyOver: true,
            canSwimThrough: false,
            canDig: true,
        })

        TileId.FLOOR_WOOD = registerTile({
            ...tileInfoDefault,

            name: 'Floor-WOOD',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: true,
            canFlyOver: true,
            canSwimThrough: false,
            canDig: true,
        })

        TileId.FLOOR_WATER_SHALLOW = registerTile({
            ...tileInfoDefault,

            name: 'Floor-WATER_SHALLOW',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: true,
            canFlyOver: true,
            canSwimThrough: false,
            canDig: true,
        })

        TileId.FLOOR_WATER_RIVER = registerTile({
            ...tileInfoDefault,

            name: 'Floor-WATER_RIVER',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: false,
            canFlyOver: true,
            canSwimThrough: true,
            canDig: false,
        })

        TileId.FLOOR_WATER_SEA = registerTile({
            ...tileInfoDefault,

            name: 'Floor-WATER_SEA',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: false,
            canFlyOver: true,
            canSwimThrough: true,
            canDig: false,
        })

        TileId.FLOOR_LAVA = registerTile({
            ...tileInfoDefault,

            name: 'Floor-LAVA',
            level: TileLevel.FLOOR,

            texture: null,
            canSeeThrough: true,
            canWalkThrough: false,
            canFlyOver: true,
            canSwimThrough: false,
            canDig: false,
        })

        TileId.WALL_CAVE = registerTile({
            ...tileInfoDefault,

            name: 'Wall-CAVE',
            level: TileLevel.WALL,

            texture: null,
            canSeeThrough: false,
            canWalkThrough: false,
            canFlyOver: false,
            canSwimThrough: false,
            canDig: true,
        })

        TileId.WALL_ROCK = registerTile({
            ...tileInfoDefault,

            name: 'Wall-ROCK',
            level: TileLevel.WALL,

            texture: null,
            canSeeThrough: false,
            canWalkThrough: false,
            canFlyOver: false,
            canSwimThrough: false,
            canDig: true,
        })

        TileId.WALL_STONE_EXT = registerTile({
            ...tileInfoDefault,

            name: 'Wall-STONE_EXT',
            level: TileLevel.WALL,

            texture: null,
            canSeeThrough: false,
            canWalkThrough: false,
            canFlyOver: false,
            canSwimThrough: false,
            canDig: true,
        })

        TileId.WALL_BRICK_EXT = registerTile({
            ...tileInfoDefault,

            name: 'Wall-BRICK_EXT',
            level: TileLevel.WALL,

            texture: null,
            canSeeThrough: false,
            canWalkThrough: false,
            canFlyOver: false,
            canSwimThrough: false,
            canDig: true,
        })


        TileId.WALL_STONE_INT = registerTile({
            ...tileInfoDefault,

            name: 'Wall-STONE_INT',
            level: TileLevel.WALL,

            texture: null,
            canSeeThrough: false,
            canWalkThrough: false,
            canFlyOver: false,
            canSwimThrough: false,
            canDig: true,
        })

        TileId.WALL_BRICK_INT = registerTile({
            ...tileInfoDefault,

            name: 'Wall-BRICK_INT',
            level: TileLevel.WALL,

            texture: null,
            canSeeThrough: false,
            canWalkThrough: false,
            canFlyOver: false,
            canSwimThrough: false,
            canDig: true,
        })

        // --- Texture Initialization ---

        this.registerCoreTilesTextures()
    }

    static registerCoreTilesTextures() {
        // error
        getTileInfo(TileId.ERROR).texture = TextureUtils.getTexture('ERROR')

        // empty
        getTileInfo(TileId.EMPTY).texture = TextureUtils.getTexture('EMPTY')

        // substratum
        getTileInfo(TileId.SUBSTRATUM_ROCK).texture = TextureUtils.getTexture('Substratum-ROCK')

        // floor
        getTileInfo(TileId.FLOOR_CAVE).texture =  TextureUtils.getTexture('Floor-CAVE')
        getTileInfo(TileId.FLOOR_GRASS).texture = TextureUtils.getTexture('Floor-GRASS')
        getTileInfo(TileId.FLOOR_DIRT).texture = TextureUtils.getTexture('Floor-DIRT')
        getTileInfo(TileId.FLOOR_ROAD).texture = TextureUtils.getTexture('Floor-ROAD')
        getTileInfo(TileId.FLOOR_SAND).texture = TextureUtils.getTexture('Floor-SAND')
        getTileInfo(TileId.FLOOR_SNOW).texture = TextureUtils.getTexture('Floor-SNOW')
        getTileInfo(TileId.FLOOR_ICE).texture = TextureUtils.getTexture('Floor-ICE')
        getTileInfo(TileId.FLOOR_SWAMP).texture = TextureUtils.getTexture('Floor-SWAMP')
        getTileInfo(TileId.FLOOR_STONE).texture = TextureUtils.getTexture('Floor-STONE')
        getTileInfo(TileId.FLOOR_WOOD).texture = TextureUtils.getTexture('Floor-WOOD')
        getTileInfo(TileId.FLOOR_WATER_SHALLOW).texture = TextureUtils.getTexture('Floor-WATER_SHALLOW')
        getTileInfo(TileId.FLOOR_WATER_RIVER).texture = TextureUtils.getTexture('Floor-WATER_RIVER')
        getTileInfo(TileId.FLOOR_WATER_SEA).texture = TextureUtils.getTexture('Floor-WATER_SEA')
        getTileInfo(TileId.FLOOR_LAVA).texture = TextureUtils.getTexture('Floor-LAVA')

        // wall
        getTileInfo(TileId.WALL_CAVE).texture = TextureUtils.getTexture('Wall-CAVE')
        getTileInfo(TileId.WALL_ROCK).texture = TextureUtils.getTexture('Wall-ROCK')
        getTileInfo(TileId.WALL_STONE_EXT).texture = TextureUtils.getTexture('Wall-STONE_EXT')
        getTileInfo(TileId.WALL_BRICK_EXT).texture = TextureUtils.getTexture('Wall-BRICK_EXT')
        getTileInfo(TileId.WALL_STONE_INT).texture = TextureUtils.getTexture('Wall-STONE_INT')
        getTileInfo(TileId.WALL_BRICK_INT).texture = TextureUtils.getTexture('Wall-BRICK_INT')
    }
}
